import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Redirect, useHistory } from "react-router";
import { Container, Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  questionAPI_Action,
  resetQuestionAPI_Action,
  question_Response_Action,
  resetAnswerResponseAPI_Action,
  questionAPI_Reattempt_Action,
} from "../../stores/actions";
import { Images, StringConstants } from "../../config";
import {
  Button,
  QuestionCard,
  QuestionsHeader,
  AnswerCard,
  TipsPopup,
  HelpTip,
  QuestionTypeSelector,
  Loader,
} from "../../components";
import {
  getData,
  setData,
  TOKEN,
  SUMMARY_ATTEMPT,
  USER_DATA,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";

const Questions = (props) => {
  const history = useHistory();
  let location = useLocation();
  let id = useParams();
  let answerData = ["", ""];
  const questionBankId = id.question_id;
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [questions, setQuestions] = useState([]);
  const [questionNo, setquestionNo] = useState(0);
  const [countQuestion, setCountQuestion] = useState();
  const [questionBankTitle, setQuestionBankTitle] = useState("");
  const [QuestionBankId, setQuestionBankId] = useState("");
  const [questionId, setQuestionId] = useState();
  const [answerSet2, setAnswerSet2] = useState([]);
  const [answerSet1, setAnswerSet1] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [attempt, setAttempt] = useState(1);

  let questionData = useSelector((state) => state.questions);
  let answerResponseData = useSelector((state) => state.answerresponse);
  let previousData = { isFetching: false };

  const selectAnswer = (item, answerKey) => {
    // console.log("item", item, answerKey);
    answerData = answers;
    if (answerKey === 1) {
      answerData[0] = item;
      setError1(false);
    } else {
      answerData[1] = item;
      setError2(false);
    }

    setAnswers(answerData);
  };

  const prevQuestion = () => {
    window.scrollTo(0,0);
    setError1(false);
    setError2(false);
    setquestionNo(questionNo - 1);
    setLoader(true);
    // console.log("a1234", questionNo)
  };

  useEffect(()=>{
    if (history.action === 'POP'){
      setLoader(true);
      window.location.replace('/')
      setLoader(false);
    }
  },[])

  useEffect(() => {
    setQuestionId(questions[questionNo]);
  }, [questionNo]);


  useEffect(() => {
    // console.log("spider", userResponses)
    let answersData = userResponses[questionId];
    if (answersData) {
      setAnswers(answersData.answers);
    }
  }, [questionId]);

  useEffect(() => {
    setLoader(false);
  }, [answers])

  const nextQuestion = () => {
    window.scrollTo(0,0);
    if (
      answers.length !== 2 ||
      answers[0] == undefined ||
      answers[1] == undefined
    ) {
      if (answers[0] === undefined) setError1(true);
      if (answers[1] === undefined) setError2(true);
    } else {
      let data = {
        questionBankId: QuestionBankId,
        questionId: questionId,
        questionOptionIds: answers,
        attempt: attempt,
      };
      setError1(false);
      setError2(false);
      try {
        getData(TOKEN)
          .then((res) => {
            getData(GROUP_DATA).then((groupData) => {
              dispatch(question_Response_Action(res.token, data, groupData.groupId));
            })
          })
          .catch((err) => console.log(err));
      } catch (e) {
        console.log(e);
      }

      if (questionNo + 1 === countQuestion) {
        window.scrollTo(0, 0);
        // history.push("/me/initial-summary");
        if (attempt == 1) {
          history.push({
            pathname: "/me/initial-summary",
            attempt: attempt,
          });
        } else {
          // console.log("attempt2",attempt)
          history.push({
            pathname: "/me/summary",
            attempt: attempt,
          });
        }
      } else {
        let responseData = userResponses;
        let dataset = { answers: answers };
        responseData[questionId] = dataset;
        // setquestionNo(questionNo + 1);
        setUserResponses(responseData => { return responseData });
      }
    }
  };

  const __redirectUrlTop = () => {
    window.scrollTo(0, 0);
  };

  const [type, settype] = useState("text");
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    questionData = [];
    answerResponseData = [];
    setAnswers([]);
    try {
      getData(TOKEN)
        .then((res) => {
          // dispatch(questionAPI_Action(res.token, questionBankId));
          getData(SUMMARY_ATTEMPT).then((sumres) => {
            getData(GROUP_DATA).then((groupData) => {
              if (sumres === 1) {
                dispatch(questionAPI_Action(res.token, groupData.questionBank[0].questionBankId, groupData.groupId));
              } else {
                dispatch(
                  questionAPI_Reattempt_Action(res.token, groupData.questionBank[0].questionBankId, sumres, groupData.groupId)
                );
              }
            })
            
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (questionData.isFetching) {
      setLoader(true);
    } else if (questionData.isSuccess) {
      window.scrollTo(0, 0);
      setLoader(false);
      // console.table("questiondata", questionData);
      let questiondata = questionData.data.data;
      setQuestionBankTitle(questiondata.questionBankDetails.questionBankName);
      setQuestionBankId(questiondata.questionBankDetails.questionBankId);
      setQuestions(questiondata.questionBankDetails.questionBankQuestions);
      setquestionNo(questionNo);
      setQuestionId(
        questiondata.questionBankDetails &&
          questiondata.questionBankDetails.questionBankQuestions[questionNo] &&
          questiondata.questionBankDetails.questionBankQuestions[questionNo]
            .questionId
      );
      setCountQuestion(
        questiondata.questionBankDetails.questionBankQuestions.length
      );
      if(questiondata.userResponses){
        let userresponses = [];
        Object.keys(questiondata.userResponses)?.forEach((key) => {
            let answers = questiondata.userResponses[key];
            userresponses[key] = {answers: answers};
            // console.log("a1234", userresponses)
        })
        if(initial){
          setquestionNo(Object.keys(userresponses).length);
          setInitial(false);
          
        }
        // setUserResponses(userresponses);
        // console.log("spider1", userResponses, userresponses)
      }
      if (
        questiondata.questionBankDetails.questionBankQuestions &&
        questiondata.questionBankDetails.questionBankQuestions[questionNo]
          .questionOptions
      ) {
        setAnswerSet1(
          questiondata.questionBankDetails.questionBankQuestions[
            questionNo
          ].questionOptions.filter((item) => item.questionOptionAnswerSet === 1)
        );
        setAnswerSet2(
          questiondata.questionBankDetails.questionBankQuestions[
            questionNo
          ].questionOptions.filter((item) => item.questionOptionAnswerSet === 2)
        );
      }
      setAttempt(questiondata.questionBankDetails.attempt);
      // setData(SUMMARY_ATTEMPT, questiondata.questionBankDetails.attempt);
    } else if (questionData.isError) {
      setErrorModel(true);
      setErrorDataModel(questionData.data);
    }
    // console.log("a1234", questionNo)
  }, [questionNo, questionData]);

  useEffect(() => {
    if (answerResponseData.isFetching) {
      setLoader(true);
    } else if (answerResponseData.isSuccess && questions.length > 0) {
      // setquestionNo(questionNo + 1);
      setquestionNo(questionNo + 1);
      setLoader(false);
      setAnswers([]);
      if (questions[questionNo].questionOptions) {
        setAnswerSet1(
          questions[questionNo].questionOptions.filter(
            (item) => item.questionOptionAnswerSet === 1
          )
        );
        setAnswerSet2(
          questions[questionNo].questionOptions.filter(
            (item) => item.questionOptionAnswerSet === 2
          )
        );
      }
    } else if (answerResponseData.isError) {
      setErrorModel(true);
      setErrorDataModel(answerResponseData.data);
    }
  }, [answerResponseData]);


  return (
    <>
      <div className="questions-container">
        {!navigator.onLine && <InternetConnectivity />}
        {(questionData.isFetching || loader) && (
          <>
            <Loader />
          </>
        )}
        <div className="question lp-screen0 bg-aliceBlue bg-color0 full-height">
          <div className="container">
            <div className="row justify-content-md-center ">
              <div className="col-md-12 col-lg-10 q-wrp mb-70 pb-4">
                <QuestionsHeader
                  title={questionBankTitle}
                  questionNo={questionNo}
                  total={countQuestion}
                />

                <div className="box-question">
                  <QuestionCard
                    className="question-card"
                    question={questions[questionNo]}
                  />
                </div>
                <div className="wrp-box-question">
                  {answerSet1.length !== 0 &&
                  answerSet1[0] &&
                  answerSet1[0].questionOptionType === "text" ? (
                    <>
                      <div className="wrp_box1">
                        <h6 className="mb-3 select-one-answer font-size-15 font-family-ubuntu font-weight-bold line-height-20  text-color-darkblue">
                         
                        {answerSet1 ? StringConstants.AnswerA :  StringConstants.Select_one }        
                          {/* {StringConstants.Select_one} */}
                        </h6>

                        <AnswerCard
                          answerKey={1}
                          selectAnswer={(data, answerKey) =>
                            selectAnswer(data, answerKey)
                          }
                          answers={answerSet1}
                          selectedAnswer={
                            userResponses[questionId]
                              ? userResponses[questionId].answers[0]
                              : ""
                          }
                        />
                        {error1 && (
                          <h6 className="answer-text mb-3 font-family-ubuntu font-weight-bold mb-0 font-size-15 line-height-20 text-danger">
                            {StringConstants.Please_Select}
                          </h6>
                        )}
                      </div>
                    </>
                  ) : (
                    answerSet1.length !== 0 && (
                      <>
                        <QuestionTypeSelector
                          answerKey={1}
                          error={error1}
                          setError={setError1}
                          selectAnswer={(data, answerKey) =>
                            selectAnswer(data, answerKey)
                          }
                          type={answerSet1[0].questionOptionType}
                          answer={answerSet1}
                          selectedAnswer={
                            userResponses[questionId]
                              ? userResponses[questionId].answers[0]
                              : null
                          }
                        />
                      </>
                    )
                  )}
                  {answerSet2.length !== 0 &&
                  answerSet2[0] &&
                  answerSet2[0].questionOptionType === "text" ? (
                    <>
                      <div className="wrp_box1">
                        <h6 className="mb-3 select-one-answer font-size-15 font-family-ubuntu font-weight-bold line-height-20  text-color-darkblue">
                          {/* {StringConstants.Select_one} */}
                          {answerSet2 ? StringConstants.AnswerB :  StringConstants.Select_one }
        
                        </h6>

                        <AnswerCard
                          answerKey={2}
                          selectAnswer={(data, answerKey) =>
                            selectAnswer(data, answerKey)
                          }
                          selectedAnswer={
                            userResponses[questionId]
                              ? userResponses[questionId].answers[1]
                              : ""
                          }
                          answers={answerSet2}
                        />
                        {error2 && (
                          <h6 className="answer-text mb-3 font-family-ubuntu font-weight-bold mb-0 font-size-15 line-height-20 text-danger">
                            {StringConstants.Please_Select}
                          </h6>
                        )}
                      </div>
                    </>
                  ) : (
                    answerSet2.length !== 0 && (
                      <>
                        <QuestionTypeSelector
                          answerKey={2}
                          error={error2}
                          setError={setError2}
                          selectAnswer={(data, answerKey) =>
                            selectAnswer(data, answerKey)
                          }
                          type={answerSet2[0].questionOptionType}
                          selectedAnswer={
                            userResponses[questionId]
                              ? userResponses[questionId].answers[1]
                              : null
                          }
                          answer={answerSet2}
                        />
                      </>
                    )
                  )}
                </div>
                <div className="next-container0 pt-2 text-md-center">
                  {questionNo !== 0 ? (
                    <button
                      className="prev-btn0 mr-3 btn btn-text0 bg-violet text-white px-3 py-3 register-btn btn-circle-30"
                      onClick={prevQuestion}
                    >
                      {/* {"Previous"} */}
                      <img
                        src={Images.back_arrow_white}
                        className="img100 px-1"
                      />
                    </button>
                  ) : (
                    <button
                      className="gray-block  prev-btn0 mr-3 btn btn-text0 bg-violet text-white px-3 py-3 register-btn btn-circle-30"
                      //onClick={prevQuestion}
                    >
                      {/* {"Previous"} */}
                      <img
                        src={Images.back_arrow_white}
                        className="img100 px-1"
                      />
                    </button>
                  )}
                  <button
                    className="next-btn0 btn btn-text bg-violet text-white px-3 py-3 register-btn btn-circle-30"
                    onClick={nextQuestion}
                  >
                    {questionNo + 1 !== countQuestion
                      ? answerResponseData.isFetching || previousData.isFetching
                        ? "Loading"
                        : "Next"
                      : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="position-fixed lumen-help fixed-lumen-help">
        <HelpTip />
        {/* <TipsPopup /> */}
      </div>
    </>
  );
};

export default Questions;
