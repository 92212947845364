import React from "react";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../config";

const AssessmentCard = (props) => {
  return (
    <>
      <div className="assessment-card py-3 px-3" style={props.style}>
        <div className="w-100">
          <div className="contents align-items-center justify-content-between ">
            <div className="inner-box1 d-flex align-items-center">
              <div className="card-icon">
                <img src={props.icon} className="img100" />
              </div>
              <div className="text-size ml-3">
                <p className="font-size-16 font-family-ubuntu font-weight-bold line-height-18 text-trim title mb-1">
                  {props.title}{" "}
                </p>
                <p className="mb-0 font-size-11 first-letter font-family-ubuntu font-weight-regular line-height-18 text-color-darkgray mb sub-category">
                  {props.subtitle}
                </p>
              </div>
              {
                props.seemore  &&
              
              <div className="d-flex align-items-center">
                <Link
                  className="font-size-13 font-family-ubuntu line-height-18 ml-5 "
                  // to="/assessment-detail"
                  to="/try-example"
                >
                  {props.seemore}
                </Link>
              </div>
              }
              
              {
                props.viewAll > 1 &&
                <div className="d-flex align-items-center">
                  <button
                    className="background-transparent small-width border-0 font-size-13 text-color-white font-family-ubuntu line-height-18 ml-1 "
                    onClick={props.popupClick}
                  >
                    {'View all'}
                  </button>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentCard;
