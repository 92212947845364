import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    data: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.TAXONOMY_LIST_POST_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.TAXONOMY_LIST_POST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                data: action.payload.success
            };
        case types.TAXONOMY_LIST_POST_FAILURE:
            return {
                ...state,
                isFetching: false,
                isError: true,
                data: action.payload.response,
            };
        case types.RESET_TAXONOMY_LIST_POST:
            return {
                ...state,
                isFetching: false,
                isError: false,
                data: null,
            };
        default:
            return {
                ...state
            }
    }
}