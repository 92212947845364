import React from 'react'
import {Images} from "../config";

const Loader = () => {
    return (
        <>
          <div className=" loader  position-fixed w-100">
            <div className="d-flex align-items-center flex-column justify-content-center full-height">
              <img src={Images.lumen_Infinity_gif} alt="infinite" />
              {/* <p className="font-size-22 font-family-ubuntu font-weight-bold colo-text-violet">
                Lumenai ...
              </p> */}
            </div>
          </div>
        </>
    )
}

export default Loader
