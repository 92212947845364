import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  TopHeaderDesktop,
} from "../../components";
import { USER_DATA, setData, getData, TOKEN } from "../../utils/storage";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import * as yup from "yup";
// redux
import {
  contactuslistApi,
  resetcontactuslistApi,
} from "../../stores/actions/contact-us-action";

import {
  resetcontactusbuttonApi_Action,
  contactusbuttonApi_Action,
  contactuslistApi_Action,
} from "../../stores/actions/contact-us-action";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
const contactus_schema = yup.object().shape({
  // sub: yup
  //   .string()
  //   .trim("space are not allow")
  //   .required("Please enter first name"),
  comments: yup

    .string()
    .min(3)
    //.trim("space are not allow")
    .required("Please enter comment"),
});
const ContactUs = (props) => {
  const history = useHistory();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [loader, setLoader] = useState();
  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState();
  const [showData, setShowData] = useState();
  const [modalShow, setModalShow] = useState(false);
  // const [showSuccessMessage, setShowSuccessMessage] = useState();
  const contactButtonData = useSelector((state) => state.contactusButton);
  const contactusSelectList = useSelector((state) => state.contactusfirst);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactus_schema),
  });
  // console.log("consss", contactussecondData);

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          dispatch(contactuslistApi_Action(res.token));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitValues = useCallback((data) => {
    // alert("2")
    try {
      getData(TOKEN).then((res) => {
        dispatch(contactusbuttonApi_Action(res.token, data));
      });
    } catch (e) {
      console.log(e);
    }
    // console.log("data", data);
  });

  const redirecPrevsPg = () => {
    setModalShow(false);
    history.goBack();
  };

  // console.log("contactButtonData",contactButtonData)
  useEffect(() => {
    if (contactButtonData.isFetching) {
      setLoader(true);
    } else if (contactButtonData.isSuccess && contactButtonData.data !== null) {
      setLoader(false);
      setModalShow(true);
      setShowData(contactButtonData && contactButtonData.data.message);
      dispatch(resetcontactusbuttonApi_Action());
    } else if (contactButtonData.isError) {
      setErrorModel(true);
      setErrorDataModel(contactButtonData.data);
    }
  }, [contactButtonData]);
  //end

  //Button
  useEffect(() => {
    if (contactusSelectList.isFetching) {
      setLoader(true);
    } else if (
      contactusSelectList.isSuccess &&
      contactusSelectList.data !== null
    ) {
      setLoader(false);
      setDisplayData(
        contactusSelectList &&
          contactusSelectList.data &&
          contactusSelectList.data.data
      );
    } else if (contactusSelectList.isError) {
      setErrorModel(true);
      // setErrorDataModel(contactusSelectList.data);
    }
  }, [contactusSelectList]);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={() => {
                  redirecPrevsPg();
                }}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                {/* {showData && showData} */}
                Your query are send to lumenai team's
              </h3>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <button
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                onClick={() => {
                  redirecPrevsPg();
                }}
              >
                {StringConstants.Done}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <div className="contact-screen bg-aliceBlue wave-section full-height pt-lg-5 pt-md-4 pb-lg-3">
        <TopHeader />
        {!navigator.onLine && <InternetConnectivity />}
        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0">
            <Col md={10} lg={8}>
              <TopHeaderDesktop />
              <form onSubmit={handleSubmit(submitValues)}>
                <div className="contact-text mt-3 mt-md-0 d-md-none">
                  <h3 className="    mb-3 text-left">
                    {StringConstants.contact_us}
                  </h3>
                </div>
                <Row>
                  <Col sm={12}>
                    <div className="form-group components-form field-style">
                      <label htmlFor={props.id}>
                        {StringConstants.Subject}
                      </label>
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        id="subject"
                        {...register("subject")}
                      />
                      <span className="error">
                        {errors.subject?.message}
                      </span>
                      {/* <select
                        id="subject"
                        name="subject"
                        className="contact-select form-control"
                        {...register("subject")}
                      >
                        <option value="Subject"> </option>
                        <option value="Subject 1">
                          {displayData &&
                            displayData.subjectList &&
                            displayData.subjectList.subject1}
                        </option>
                        <option value="Subject 2">
                          {displayData &&
                            displayData.subjectList &&
                            displayData.subjectList.subject2}
                        </option>
                        <option value="Subject 3">
                          {displayData &&
                            displayData.subjectList &&
                            displayData.subjectList.subject3}
                        </option>
                        <option value="Subject 4">
                          {displayData &&
                            displayData.subjectList &&
                            displayData.subjectList.subject4}
                        </option>
                      </select> */}
                    </div>
                    {/* <TextInput label={StringConstants.Subject} /> */}
                  </Col>

                  <Col sm={12}>
                    <div className="form-group components-form field-style1">
                      <label>{StringConstants.comments}</label>
                      <textarea
                        id="comments"
                        name="comments"
                        rows="12"
                        type="text"
                        className="form-control"
                        cols="50"
                        {...register("comments")}
                      ></textarea>
                      <p className="error">
                        {errorDataModel &&
                          errorDataModel.error &&
                          errorDataModel.error.message}
                      </p>
                      {/* <p>{showSuccessMessage && showSuccessMessage.message}</p> */}
                      <span className="error">{errors.comments?.message}</span>

                      {/* <input
                        type={props.type}
                        name={props.name}
                        className="form-control"
                        id={props.id}
                        {...props}
                      /> */}
                    </div>
                    {/* <TextInput label={StringConstants.comments} type="text" /> */}
                  </Col>

                  <Col sm={12}>
                    <div className=" text-center text-md-right mt-2 mb-4">
                      <button
                        type="submit"
                        // onClick={() => {
                        //   submitValues()
                        // }}
                        // onClick={__redirectUrlTop}
                        className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                      >
                        {StringConstants.submit}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ContactUs;
