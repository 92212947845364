import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function shareResultPostAPI_Action(data, categoryId) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/share-lumen/${categoryId}`,
            method: "POST",
            headers: {
                ...AUTH_HEADERS,
                Authorization: "Bearer",
            },
            body: JSON.stringify(data),
            types: [
                types.SHARERESULTPOST_REQUEST,
                types.SHARERESULTPOST_RECEIVE,
                {
                    type: types.SHARERESULTPOST_FAILURE,
                    meta: (action, state, res) => {
                        return action, state, res;
                    },
                },
            ],
        },
    };
}

export const resetSHARERESULTPOSTAPI_Action = () => {
    return {
        type: types.RESET_SHARERESULTPOST,
        payload: null,
    };
};
