import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserDots } from "../config/Images";

const UserCard = (props) => {

    const { id, first_name, last_name, job_title, email, role, email_verified_at, tags, latest_lumen_report } = props.userData;
    const actionState = props.actionState;
    console.log(latest_lumen_report);

    return (
        <li key={id} className="d-flex people-card-wrapper  align-items-start">
            <div className="d-md-flex  flex-grow-1">
                <div class="first_name">{first_name}</div>
                <div class="email">{email}</div>
                <div class="job_title">{job_title}</div>
            </div>
            <div className="d-flex justify-content-between align-item-center">
                <div className={email_verified_at === "Active" ? "active" : "inactive"}>{email_verified_at === "Active" ? "Active" : "Invite sent"}</div>
                <div className="action-container">
                    <button onClick={(e) => props.onClick(id)} className="menu-button">
                        <img src={UserDots} />
                    </button>
                    <div className={actionState === id ? "show" : "hide"} style={{ zIndex: 1000 }}>
                        <div className={actionState === id ? "actions" : 'actions'}>
                            <p>
                                <Link to={{ pathname: "/edit-user", state: { userId: id, first_name: first_name, last_name: last_name, email: email, job_title: job_title, tags: tags } }} >
                                    Edit details
                                </Link>
                            </p>

                            <p onClick={(e) => props.modalAction(e, email_verified_at === "Active" ? "Reset password" : "Resend invite", email_verified_at === "Active" ? email : id)} id={email_verified_at === "Active" ? "Reset password" : "Resend invite"}>{email_verified_at === "Active" ? "Reset password" : "Resend invite"}</p>
                            {latest_lumen_report !== null && latest_lumen_report !== "" ? (
                                <p>
                                    <a href={latest_lumen_report} target="_blank">Lumen Report</a>
                                </p>
                            ) : null}
                            <p onClick={(e) => props.modalAction(e, "delete", id)} id="delete user">Delete user</p>
                        </div>
                        <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }} onClick={() => props.onClick(null)}>

                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default UserCard;
