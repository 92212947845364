import * as types from "../types/index";

export function toggle_group_selection(res) {
    return {
        type: types.TOGGLE_GROUP_SELECTION,
        payload: res
    }
}

export function set_group(res, toggle_menu) {
    return {
        type: types.SET_GROUP,
        payload: { data: res, toggle_menu: toggle_menu }
    }
}