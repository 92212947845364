import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Images, StringConstants } from "../config";
import { __redirectScrollTop } from "../utils/utility";

const TopHeader = (props) => {
  const history = useHistory();

  return (
    <>
      {props.close ? (
        <div className="breadcrumbs-layer0 py-3 d-md-none">
          <div
            className="breadcrumbs-box d-flex position-relative align-items-center d-flex justify-content-between
          "
          >
            <button
              className="border-0 pl-3 font-size-14 d-flex position-absolute0 align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back"
              onClick={() => {
                history.push("/hub");
                window.scrollTo(0, 0);
              }}
            >
              <img src={Images.close_skill} className="back-icon mr-2" />
              <span className="back_text ">{StringConstants.close}</span>
            </button>
            <div className="lu_logo mx-auto">
              <Link to="/" onClick={__redirectScrollTop}>
                <img src={Images.infinity} className="lunemai-logo img-fluid" />
              </Link>
            </div>

            {/*{props.sharebtn && (*/}
              {/*<Link*/}
                {/*to="/share-my-result"*/}
                {/*onClick={__redirectScrollTop}*/}
                {/*className="font-size-13 text-right d-block font-family-ubuntu font-weight-regular line-height-18 text-color-violet"*/}
              {/*>*/}
                {/*Share my results*/}
              {/*</Link>*/}
            {/*)}*/}
          </div>
        </div>
      ) : (
        <div className="breadcrumbs-layer d-md-none">
          <div className="breadcrumbs-box d-flex position-relative">
            {props.redirectHub === "/hub" ? (
              <button
                className="border-0 pl-3 font-size-14 d-flex position-absolute align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back"
                onClick={() => {
                  history.push({ pathname: "/hub" });
                  window.scrollTo(0, 0);
                }}
              >
                <img src={Images.backArrow} className="back-icon mr-2" />
                <span className="back_text ">{StringConstants.back}</span>
              </button>
            ) : (
              <button
                className="border-0 pl-3 font-size-14 d-flex position-absolute align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back"
                onClick={() => {
                  history.go(-1);
                  window.scrollTo(0, 0);
                  return false;
                }}
              >
                <img src={Images.backArrow} className="back-icon mr-2" />
                <span className="back_text ">{StringConstants.back}</span>
              </button>
            )}

            <div className="lu_logo mx-auto">
              <Link to="/" onClick={__redirectScrollTop}>
                <img src={Images.infinity} className="lunemai-logo img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopHeader;
