import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { Link } from "react-router-dom";
import { Colors, Images, StringConstants } from "../config";
import { __redirectScrollTop } from "../utils/utility";

const SummaryPopupCard = (props) => {
    return (
        <>
            <div className="progress-card mb-4">
                <Link to={props.redirectUrl}>
                    <div
                        className="progress-content d-flex align-items-center justify-content-between"
                        key={props.keyvalue}
                    >
                        <div
                            to={props.softSkillUrl}
                            onClick={() => { __redirectScrollTop() }}
                        // to={{
                        //   pathname:
                        //     "/pathway/soft-skill-explained-detail/" + nick.categoryId,
                        //   state: { data: nick.data },
                        // }}
                        >
                            {" "}
                            <div className="inside-box w-100 d-flex align-items-center text-left">
                                <div className="card-icon mr-3">
                                    <img src={props.icon} className="img100" />
                                </div>
                                <span
                                    className="titleWidthSize font-size-16 font-family-ubuntu font-weight-bold line-height-18 title mb-0 pr-2"
                                    style={{ color: "#EEF7FF" }}
                                >
                                    {props.title}
                                </span>
                            </div>
                        </div>
                        {props.percent > "0%" ? (
                            <span
                                style={{ color: "#EEF7FF" }}
                                className="percents font-size-16 font-family-ubuntu font-weight-bold line-height-18 title mb-1"
                            >
                                {props.percent}
                                {props.percentSymbol && "%"}
                            </span>
                        ) : props.redirect ? (
                            <Link
                                to={props.redirect}
                                onClick={__redirectScrollTop}
                                style={{ backgroundColor: "#381474" }}
                                className="py-1 px-3 learnbtns border-0 btn-circle-30 font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-white"
                            >
                                {"learn"}
                            </Link>
                        ) : (
                            <Link
                                to="/pathway"
                                onClick={__redirectScrollTop}
                                style={{ backgroundColor: "#381474" }}
                                className="py-1 px-3  learnbtns border-0 btn-circle-30 font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-white"
                            >
                                {"learn"}
                            </Link>
                        )}

                    </div>
                    {props.levelData ? (
                        <span style={{ color: "#00FFFF" }} className="d-block">Level 1</span>
                    ) : null}


                    <div className="content">
                        {props.percent > 0 ? (
                            <ProgressBar
                                className={
                                    props.percent === "100"
                                        ? "progressbar"
                                        : "progressbar add-radius3 borderRadius3"
                                }
                            >
                                <ProgressBar
                                    className={
                                        props.categoryCorePercentage === "100"
                                            ? "bg-Cyan"
                                            : "bg-Cyan bar3 borderRadius3"
                                    }
                                    now={props.categoryCorePercentage}
                                    key={3}
                                />
                                <ProgressBar
                                    className={
                                        props.categoryManagerialPercentage === "100"
                                            ? "bg-lightBlue "
                                            : "bg-lightBlue bar2 borderRadius3"
                                    }
                                    now={props.categoryManagerialPercentage}
                                    key={2}
                                />

                                <ProgressBar
                                    className={
                                        props.categoryTalentedPercentage === "100"
                                            ? "bg-color-pink"
                                            : "bg-color-pink bar1 borderRadius3"
                                    }
                                    now={props.categoryTalentedPercentage}
                                    key={1}
                                />
                            </ProgressBar>
                        ) : (
                            <ProgressBar className="progressbar" />
                        )}
                    </div>

                    {/* {console.log("Props:::: ", props)} */}
                    {props.redirectUrl ? (
                        <div className="complete-status d-flex justify-content-between">
                            <Link
                                to={props.redirectUrl}
                                onClick={__redirectScrollTop}
                                className="mt-2 mb-1  font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-white "
                            >
                                {" "}
                                see more
                            </Link>
                            <p className="mt-2 mb-1 status-date font-size-11 text-color-white font-family-ubuntu font-weight-regular line-height-18 opcaity-06">
                                {" "}
                                {props.statusText}{" "}
                            </p>
                        </div>
                    ) : (
                        props.statusText && (
                            <div className="complete-status-date text-right">
                                <p className="mt-2 mb-3 status-date font-size-11 text-color-white font-family-ubuntu font-weight-regular line-height-18 opcaity-06">
                                    {" "}
                                    {props.statusText}{" "}
                                </p>
                            </div>
                        )
                    )}
                </Link>
            </div>

        </>
    );
};

export default SummaryPopupCard;
