import React, { useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { Chart, Pie } from "react-chartjs-2";
import { Images, StringConstants } from "../config";

const LumenReportPieChartProfile = (props) => {
  const images = [
    Images.communication_whitecard,
    Images.creativity_white,
    Images.emotionaljudge_white,
    Images.leadershp_white,
    Images.timemgt_white,
    Images.teamwork_white,
  ];
  useEffect(() => {
    Chart.defaults.plugins.tooltip.enabled = false;
  }, []);

  const drawIcon = (ctx, x, y, icon) => {
    let image = new Image();
    image.src = icon;
    
      image.addEventListener("load", () => {
        setTimeout(()=>{
          ctx.drawImage(image, x, y,25,25);
        },1000)
    
      });
    

    // console.log("draw");
  };

  let corevalue = 12.5;
  let categoryData1 = [];
  if (props.catgoryArray !== undefined && props.catgoryArray !== null) {
    corevalue = 100 / props.catgoryArray.length;
    for (let i = 0; i < props.catgoryArray.length; i++) {
      categoryData1.push(corevalue);
    }
  } else {
    categoryData1 = [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5];
  }
  let categoryData = categoryData1;

  const data = {
    datasets: [
      {
        label: "# of Votes",
        data: categoryData,
        backgroundColor: props.backgroundColorArraytalent,
        borderColor: ["#180148"],
        borderWidth: 1.5,
        weight: 0.5,
      },
      {
        label: "# of Votes",
        data: categoryData,
        backgroundColor: props.backgroundColorArraymanager,
        borderWidth: 1.5,
        borderColor: ["#180148"],
        weight: 1,
      },
      {
        label: "3 of Votes",
        data: categoryData,
        backgroundColor: props.backgroundColorArraycore,
        borderWidth: 1.5,
        borderColor: "#180148",
        weight: 3,
      },
    ],
  };

  const options = {
    events: [],
    responsive: true,
    legend: {
      display: false,
    },

    animation: {
      duration: 0,
    },
    tooltips: {
      enabled: false,
    },
  };

  const iconRender = (chart) => {
    var { height, width } = chart;
    var ctx = chart.ctx;
    // console.log("hello");
    const circleXY = (r, theta) => {
      let substractionAngle = 60;
      if (props.catgoryArray !== undefined && props.catgoryArray !== null) {
        let switcarray = props.catgoryArray;
        switch (switcarray.length) {
          case 1:
            substractionAngle = 360;
            break;
          case 2:
            substractionAngle = 180;
            break;
          case 3:
            substractionAngle = 330;
            break;
          case 4:
            substractionAngle = 320;
            break;
          case 5:
            substractionAngle = -60;
            break;
          case 6:
            substractionAngle = 300;
            break;
          case 7:
            substractionAngle = 295;
            break;
          case 8:
            substractionAngle =  290;
            break;
        }
      }

      // Convert angle to radians
      theta = ((theta - substractionAngle) * Math.PI) / 180;

      return {
        x: r * Math.cos(theta),
        y: -r * Math.sin(theta),
      };
    };

    if (props.catgoryArray !== undefined && props.catgoryArray !== null) {
      let sortedcatArraynew = props.catgoryArray;
      sortedcatArraynew.sort((a, b) => a.categoryOrder - b.categoryOrder);
      sortedcatArraynew.reverse();

      let theta = 360 / sortedcatArraynew.length;
      for (var i = 1; i <= sortedcatArraynew.length + 1; i++) {
        theta = (360 / sortedcatArraynew.length) * i;
        var answer = circleXY(width /4.41, theta);
        let catIconwhite = "";
        let catIcongrey = "";
        let mysorted = [];
        let mysorted2 = [];
        sortedcatArraynew.forEach((element, index) => {
          if (element.categoryOrder ) {
            mysorted.push(element);
          } else {
            mysorted2.push(element);
          }
        });
        // let sortedcatArray = mysorted.concat(mysorted2);
        let sortedcatArray = sortedcatArraynew
        if (
          sortedcatArray[i - 1] !== undefined &&
          sortedcatArray[i - 1] !== null
        ) {
          switch (sortedcatArray[i - 1].categoryName) {
            case "Problem Solving":
              catIconwhite = Images.probsolving_whitecard;
              catIcongrey = Images.problem_solving;
              break;
            case "Critical Thinking":
              catIconwhite = Images.criticalthinking_whitecard;
              catIcongrey = Images.criticalthink_white;
              break;
            case "Teamwork":
              catIconwhite = Images.teamwork_whitecard;
              catIcongrey = Images.teamwork_white;
              break;
            case "Communication":
              catIconwhite = Images.communication_whitecard;
              catIcongrey = Images.comms_white;
              break;
            case "Leadership":
              catIconwhite = Images.leadership;
              catIcongrey = Images.leadershp_white;
              break;
            case "Time Management":
              catIconwhite = Images.timemgt_whitecard;
              catIcongrey = Images.timemgt_white;
              break;
            case "Emotional Judgement":
              catIconwhite = Images.emotionaljudge;
              catIcongrey = Images.emotionaljudge_white;
              break;
            case "Creativity":
              catIconwhite = Images.creativity;
              catIcongrey = Images.creativity_white;
              break;
            case "Flexibility/Adaptability":
              catIconwhite = Images.Flexibilty_active
               catIcongrey = Images.Felixibility_inactive
                  break;
          }
          drawIcon(
            ctx,
            answer.x + width / 2.25,
            answer.y + width / 2.19,
            // answer.x + width / 2.15,
            // answer.y + width / 2.15,
            sortedcatArray[i - 1].highlightCategoryCoreSection == true
              ? catIconwhite
              : catIcongrey
          );
        }
      }
    }
  };

  const plugins = [
    {
      afterDraw: iconRender,
    },
  ];
  
  // const newPie = new Pie({data:data,options:options,plugins:plugins});
  return (
    <>
      <div className="pie-graph-chart position-init1">
        <Pie data={data} options={options} plugins={plugins} />
      </div>
    </>
  );
};

export default LumenReportPieChartProfile;
