import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  TopHeaderDesktop,
  HelpTip,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
const PrivatePolicy = (props) => {
  const onMessage = () => {
    window.open(`https://lumenai.tech/privacy-policy/`, "_blank");
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}

      <div className="content-box full-height bg-aliceBlue  wave-section pt-lg-5 pt-md-4 pb-lg-3">
        <TopHeader />
        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0">
            <Col md={10} lg={8} className="pb-5">
              <TopHeaderDesktop />
              <div className="heading-with-logo mt-3 mt-md-0 d-md-none">
                <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                  Privacy policy
                </h3>
              </div>
              <div className="cards-policy bottom-content  overflow-hidden">
                <div className="card-content padding-lr p-4 jc-inside-space">
                  <p className="font-family-ubuntu font-weight-regular mb-0 font-size-16 line-height-22 text-color-violet">
                    <button
                      onClick={onMessage}
                      className="background-transparent  font-family-ubuntu  px-4 py-2 mt-2 border-2 border-color-white text-color-blue "
                    >
                      Privacy Policy
                    </button>
                  </p>
                </div>
                <div className=" position-fixed lumen-help fixed-lumen-help d-none d-sm-block">
                  <HelpTip />
                  {/* <TipsPopup /> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="d-none d-md-block position-fixed lumen-help fixed-lumen-help">
        <HelpTip />
        {/* <TipsPopup /> */}
      </div>
    </>
  );
};

export default PrivatePolicy;
