import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function lumenReportAPI_Action(data) {
    // var searchbyname = data ? data : "";
    // console.log("data Action--",data);
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/groups/user/list`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ',
                },
            body:JSON.stringify(data),
            types: [
                types.LUMENREPORT_REQUEST,
                types.LUMENREPORT_RECEIVE, {
                    type: types.LUMENREPORT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetlumenReportAPI_Action = () => {
    return {
        type: types.RESET_LUMENREPORT,
        payload: null
    }
};
