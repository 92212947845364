import { useEffect } from "react";
import {
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";
import {
    Company,
    CompanyInvite, Congratulation,
    EditAdminUser,
    EditUser, EndResult,
    Hub, InitialSoft, InitialSummary,
    InviteUser, JoinCompany, JoinCompanyConfirmation, LearningHistory, Page400, Path, PathComplete,
    PeopleManager, QuestionExample, Questions, Settings, ShareMyResult,
    SoftSkillExplained, SoftSkillExplainedDetail,
    SpaceSetup, Summary, SummaryNew, UserProfile, YourActivity
} from "../screens";
import {
    AboutLumenai,
    ChangePassword,
    ContactUs,
    EditAccountDetails,
    Faq,
    PrivatePolicy,
    TermsAndConditions
} from "../screens/ProfileManagement";
import RequestAccess from "../screens/UserManagement/RequestAccess";
import PathActivityDetail from "../screens/Path/path-activity-detail";
import ActivityLearningVeiwAll from "../screens/Path/act-learn-viewall";
import AssessmentDetail from "../screens/Path/assessment-detail";
import SummaryHistoryDetail from "./AppNavigation";
import React, {useState} from "react";


const ReloadComponent = () => {
    window.location.href = window.origin
}

const AdminRoutes = (props) => {
    const [homePageURI, setHomePageURI] = useState(false);

    useEffect(() => {
        const userSpacePopUp = localStorage.getItem('userSpacePopUp');
        setHomePageURI(userSpacePopUp);
    });
    console.log("homePageURI", homePageURI);
    return <Switch>
        <Route exact path="/">
            <Redirect to={"/hub"} />
        </Route>

        <Route exact path="/invite-users">
            <InviteUser />
        </Route>
        <Route exact path="/people-manager">
            <PeopleManager />
        </Route>

        <Route exact path="/admin-space-setup">
            <SpaceSetup />
        </Route>
        <Route exact path="/edit-user">
            <EditUser />
        </Route>
        <Route exact path="/edit-admin-user">
            <EditAdminUser />
        </Route>
        <Route exact path="/company">
            <Company />
        </Route>

        <Route exact path="/company-invite">
            <CompanyInvite />
        </Route>
        <Route exact path="/hub">
            <Hub />
        </Route>
        <Route exact path="/pathway/soft-skill-explained">
            <SoftSkillExplained />
        </Route>
        <Route
            exact
            path="/pathway/soft-skill-explained-detail/:categoryId"
        >
            <SoftSkillExplainedDetail />
        </Route>
        <Route exact path="/hub/your-activity">
            <YourActivity />
        </Route>
        <Route exact path="/me/history-detail/:anyId">
            <SummaryHistoryDetail />
        </Route>
        <Route exact path="/summary-new/history-detail/:anyId">
            <SummaryHistoryDetail />
        </Route>
        <Route exact path="/request-access">
            <RequestAccess />
        </Route>
        <Route exact path="/share-my-result">
            <ShareMyResult />
        </Route>
        <Route exact path="/pathway/complete">
            <PathComplete />
        </Route>
        <Route exact path="/assessment-detail">
            <AssessmentDetail />
        </Route>
        <Route exact path="/hub/activity-learning">
            <ActivityLearningVeiwAll />
        </Route>

        <Route exact path="/pathway/activity-details/:any">
            <PathActivityDetail />
        </Route>
        <Route exact path="/pathway">
            <Path />
        </Route>
        <Route exact path="/pathway/learning-history">
            <LearningHistory />
        </Route>
        <Route exact path="/initial-soft-skill">
            <InitialSoft />
        </Route>

        <Route exact path="/join-company">
            <JoinCompany />
        </Route>

        <Route exact path="/join-company-confirmation">
            <JoinCompanyConfirmation />
        </Route>
        <Route exact path="/me/summary">
            <Summary />
        </Route>
        <Route exact path="/me/initial-summary">
            <InitialSummary />
        </Route>
        <Route exact path="/me/congratulation">
            <Congratulation />
        </Route>
        <Route exact path="/summary-new/summary">
            <Summary />
        </Route>
        <Route exact path="/summary-new/initial-summary">
            <InitialSummary />
        </Route>
        <Route exact path="/summary-new/congratulation">
            <Congratulation />
        </Route>
        <Route exact path="/questions/:question_id">
            <Questions />
        </Route>

        <Route exact path="/me">
            {/* <Me /> */}
            <SummaryNew />
        </Route>

        <Route exact path="/summary-new">
            <SummaryNew />

        </Route>

        <Route exact path="/edit-account-details">
            <EditAccountDetails />
        </Route>

        <Route exact path="/faq">
            <Faq />
        </Route>

        <Route exact path="/change-password">
            <ChangePassword />
        </Route>

        <Route exact path="/PrivatePolicy">
            <PrivatePolicy />
        </Route>

        <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
        </Route>

        <Route exact path="/contact-us">
            <ContactUs />
        </Route>

        <Route exact path="/settings">
            <Settings />
        </Route>

        <Route exact path="/about-lumenai">
            <AboutLumenai />
        </Route>
        <Route exact path="/try-example">
            <QuestionExample />
        </Route>
        <Route exact path="/end-of-example">
            <EndResult />
        </Route>
        <Route exact path="/user-profile">
            <UserProfile />
        </Route>
        <Route exact path="/admin-creation/:path">
            <ReloadComponent />
        </Route>
        <Route exact path="/admin-creation">
            <ReloadComponent />
        </Route>
        <Route >
            <Page400 />
        </Route>
    </Switch>
};

export default AdminRoutes;