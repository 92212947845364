import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StringConstants from "../../config/StringConstants";

// redux
import { testAPI_Action, resetAPI_Action } from "../../stores/actions";

const Error400 = (props) => {
  return (
    <>
      <div className="lp-screen pagepg404 bg-aliceBlue header-minusHeight" style={{height:'100vh'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <h2 className="pt-5">404 Page Not Found</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error400;
