import React, { useCallback, useEffect, useState } from "react";
import { Loader, NestedPiChart, TipsPopup, TopHeader } from "../../components";
import { Images, StringConstants } from "../../config";
import ProgressCard from "../../components/ProgressCard";
import { __redirectScrollTop } from "../../utils/utility";

import { Link, useParams } from "react-router-dom";
import { setData,getData,TOKEN,SUMMARY_ATTEMPT } from "../../utils/storage";

const EndResult = () => {
  const [toggle, setToggle] = useState(false);
  const [attempCount, setAttempCount] = useState(1);

  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([
    "#381474",
    "#381474",
    "#381474",
    "#381474",
    "#381474",
    "#381474",
    "#381474",
    "#381474",
  ]);
  const __popupbox = () => {
    setToggle(!toggle);
  };



useEffect(() => {
  try {
    getData(TOKEN)
      .then((res) => {
        getData(SUMMARY_ATTEMPT).then((sumAttp) => {
            // if(sumAttp === 2 || sumAttp === 3){
              setAttempCount(sumAttp)
            // }
        })
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}, []);

const _onClicktutorialValue = () =>{
  setData('TUTS',true) 
  window.scrollTo(0,0);
  if(attempCount === 1 ){
    window.location.replace('/initial-soft-skill');
  }else if(attempCount === 2 || attempCount === 3 ){
    window.location.replace('/assessment-detail');
  }
}

  return (
    <>
      {toggle === false ? (
        <>
         <div className="overlay_cirlce circle__Postion2"></div>
          {/* <div className="container position-relative">
            <div className="ovcer result-overlay">
              <div className="hole"></div>
            </div>
          </div> */}

          <div className="position-fixed lumen-help fixed-lumen-help increase-zindex">
            <>
              <div className="tips-block initial-postion">
                <div className="d-flex justify-content-between mb-2">
                  <h3 className="font-size-20 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0">
                    How the Lumen works
                  </h3>
                  <button
                    type="button"
                    className="border-0 background-transparent "
                    onClick={__popupbox}
                  >
                    <img src={Images.close_skill} className="close-icon" />
                  </button>
                </div>

                <p className="font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0 pr-4">
                  Each questionnaire that you take will produce a personalised
                  Lumen that shows your different soft skill capabilities.
                </p>

                <div className="d-flex justify-content-md-between">
                  <button
                    onClick={_onClicktutorialValue}
                    className="background-transparent border-0 p-0 font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-blue mt-2 mb-0 pr-2"
                  >
                    {"Skip "}
                  </button>

                  <button
                    onClick={() => setToggle(!toggle)}
                    className="background-transparent border-0 p-0 font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-blue mt-2 mb-0 pr-5"
                  >
                    {"Next >"}
                    {/* <img src={Images.backArrow} alt="img" /> */}
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="tips-button-size border-0 over-position"
                onClick={__popupbox}
              >
                <img
                  src={Images.lumenai_helper}
                  className="logo-width img100"
                />
              </button>
            </>
          </div>
        </>
      ) : null}
      {/* //header  */}
      <header className="lumenai-navbar  bg-aliceBlue nav-bgcolor d-none d-md-block">
        <nav className="navbar navbar-expand-lg nav-height ">
          <div className="container">
            <Link to="/initial-soft-skill" className="navbar-brand blue-logo">
              <img src={Images.infinity} className="lumenai-logo" />
            </Link>
          </div>
        </nav>
      </header>
      {/* //header */}

      <div className="bg-aliceBlue initial-summary-screens position-relative">
     
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8">
              <div className="breadcrumbs-layer0 py-3 d-md-none">
                <div className="breadcrumbs-box d-flex position-relative align-items-center">
                  <button
                    className="border-0 pl-3 font-size-14 d-flex position-absolute0 align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <img src={Images.close_skill} className="back-icon mr-2" />
                    <span className="back_text ">{StringConstants.close}</span>
                  </button>
                  <div className="lu_logo mx-auto">
                    <Link
                      to="/"
                      onClick={__redirectScrollTop}
                    >
                      <img
                        src={Images.infinity}
                        className="lunemai-logo img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              {/* <TopHeader  close /> */}
              <div className="summary-decs">
                <h3 className="block-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                  {/* {StringConstants.congratulation} */}
                  You’ve completed the assessment
                </h3>
                <p className="block-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                  {/* {StringConstants.congratulation_desc} */}
                  {/* Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
                  eius tempor incididunt ut labore */}
                  See the breakdown of your results below
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wave bg-violet mt-3 pb-5 summary-wave endresult-screen">
          <div className="container">
            <div className="row justify-content-md-center pt-5">
              <div className="col-md-6 col-lg-4">
                <div className="category-progress-summary category__block1 mt-md-5">
                  <ProgressCard
                    statusText={"Completed " + "12/01/2021"}
                    icon={Images.assessment_white}
                    title={"Employability assessment"}
                    percentSymbol
                    percent={"62"}
                    categoryTalentedPercentage={"20"}
                    categoryManagerialPercentage={"30"}
                    categoryCorePercentage={"40"}
                  />
                </div>
                <div className="pie-chart">
                  <NestedPiChart
                    backgroundColorArraycore={backgroundColorArraycore}
                  />
                </div>
                <div className="d-none d-md-block block-btn text-md-center mt-3">
                  <Link
                    onClick={_onClicktutorialValue}
                    className="btn min-width-245 px-4 py-2 mt-2 line-height-32 font-size-18 font-weight-bold  border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.next}
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-70 pt-2">
                <div className="category-progress-summary category__block2 mt-5 pb-2">
                  <ProgressCard
                    icon={Images.timemgt_white}
                    redirect={"/initial-soft-skill"}
                    title={"Time management"}
                     percent={"80"}
                    categoryTalentedPercentage={"0"}
                    categoryManagerialPercentage={"30"}
                    categoryCorePercentage={"50"}
                  />
                  <ProgressCard
                    icon={Images.criticalthink_white}
                    title={"Critical thinking"}
                    redirect={"/initial-soft-skill"}
                    percent={"90"}
                    categoryTalentedPercentage={"10"}
                    categoryManagerialPercentage={"30"}
                    categoryCorePercentage={"50"}
                  />
                  <ProgressCard
                    icon={Images.teamwork_white}
                    title={"Teamwork"}
                    percent={"50"}
                    categoryCorePercentage={"50"}
                    redirect={"/initial-soft-skill"}
                  />
                  <ProgressCard
                    icon={Images.leadershp_white}
                    title={"Leadership"}
                    redirect={"/initial-soft-skill"}
                  />
                  <ProgressCard
                    icon={Images.problem_solving}
                    title={"Problem solving"}
                    redirect={"/initial-soft-skill"}
                    percent={"70"}
                    categoryManagerialPercentage={"20"}
                    categoryCorePercentage={"40"}
                    categoryTalentedPercentage={"0"}
                  />
                  <ProgressCard
                    icon={Images.comms_white}
                    title={"Communication"}
                    percent={"83"}
                    categoryManagerialPercentage={"20"}
                    categoryCorePercentage={"50"}
                    categoryTalentedPercentage={"13"}
                    redirect={"/initial-soft-skill"}
                  />
                  <ProgressCard
                    redirect={"/initial-soft-skill"}
                    icon={Images.creativity_white}
                    title={"Creativity"}
                    percent={"100"}
                    categoryManagerialPercentage={"20"}
                    categoryCorePercentage={"50"}
                    categoryTalentedPercentage={"30"}
                  />
                  <ProgressCard
                    redirect={"/initial-soft-skill"}
                    icon={Images.emotionaljudge_white}
                    title={"Emotional Judgement"}
                  />
                </div>

                <div className=" d-md-none block-btn text-md-center mt-3">
                  <Link
                    
                    onClick={_onClicktutorialValue}
                    className="btn min-width-245 px-4 py-2 mt-2 line-height-32 font-size-18 font-weight-bold  border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.next}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EndResult;
