import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// redux
import {
  pathwayComplete_API_Action,
  reset_PathwayComplete_API_Action,
} from "../../stores/actions";
import { Colors, Images, StringConstants } from "../../config";
import { getData, USER_DATA, TOKEN, clearAllData, GROUP_DATA } from "../../utils/storage";
import { LearningCard, TipsPopup, SoftSkillCheckIn } from "../../components";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Page400, Page500, InternetConnectivity } from "../../screens";
const PathComplete = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // states
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState();
  const [displayData, setDisplayData] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  //selector
  const pathwaycompleteData = useSelector((state) => state.pathwayComplete);

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData("quetionBankId").then((quetionBankId) => {
            getData(GROUP_DATA).then((groupData) => dispatch(pathwayComplete_API_Action(res.token, quetionBankId ,groupData.groupId)))
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (pathwaycompleteData.isFetching) {
      setLoader(true);
    } else if (
      pathwaycompleteData.isSuccess &&
      pathwaycompleteData.data !== null
    ) {
      setLoader(false);
      let dashboardData =
        pathwaycompleteData &&
        pathwaycompleteData.data &&
        pathwaycompleteData.data.data;
      setDisplayData(dashboardData);
    } else if (pathwaycompleteData.isError) {
      setErrorModel(true);
      setErrorDataModel(pathwaycompleteData.data);
    }
  }, [pathwaycompleteData]);

  const __redirectScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const yourPath = () => {
    return (
      <div className="your_pathway mb-4 width-70 ">
        <img
          src={Images.infinity_white}
          className="icons img-white-icon d-md-none mb-2 pt-3 infinite-icon-for-mobile"
        />
        <div className="d-flex justify-content-between align-items-center progress-block">
          <p className="font-size-16 font-family-ubuntu pathway-text font-weight-medium  text-color-white line-height-22  mb-3">
            {StringConstants.Your_Pathway}
          </p>
        </div>
        <div className="progress-path w-100">
          <div className="progress custom-progress-style">
            <div
              className="progress-bar bg-Electric-Purple"
              style={{ width: "100%" }}
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const softskill = () => {
    return (
      <div className="soft-skill mb-4">
        <Link 
            // to="/assessment-detail"
            to="/try-example" 
            className="d-md-inline-block">
          <SoftSkillCheckIn />
        </Link>
      </div>
    );
  };
  const activeLearning = () => {
    return (
      <div className="active-learning-box learning-box">
        <div className="d-flex justify-content-between align-items-center width-48 mb-0 mt-2">
          <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22  mb-3">
            {StringConstants.Active_learning}
          </p>
          <Link
            to="/hub/activity-learning"
            onClick={__redirectScrollTop()}
            className="font-size-13 text-color-black font-family-ubuntu font-weight-regular line-height-18  mb-2"
          >
            {StringConstants.view_all}
          </Link>
        </div>
        <div className="active-block pb-1 mb-4">
          {isMobileOnly
            ? displayData &&
              displayData.pathwayCompleted.slice(0, 6).map((item, index) => {
                let activeLearningIcon = "";
                switch (item.pathwayCategories[0]) {
                  case "Problem Solving":
                    activeLearningIcon = Images.probsolving_whitecard;
                    break;
                  case "Critical Thinking":
                    activeLearningIcon = Images.criticalthinking_whitecard;
                    break;
                  case "Teamwork":
                    activeLearningIcon = Images.teamwork_whitecard;
                    break;
                  case "Communication":
                    activeLearningIcon = Images.communication_whitecard;
                    break;
                  case "Leadership":
                    activeLearningIcon = Images.leadership;
                    break;
                  case "Time Management":
                    activeLearningIcon = Images.timemgt_whitecard;
                    break;
                  case "Emotional Judgement":
                    activeLearningIcon = Images.emotionaljudge;
                    break;
                  case "Creativity":
                    activeLearningIcon = Images.creativity;
                    break;

                  default:
                    activeLearningIcon = Images.emotionaljudge;
                }

                return (
                  item.userPathwayStatus === 1 && (
                    <LearningCard
                      status={item.userPathwayStatus}
                      data={item}
                      icon={activeLearningIcon}
                      keyvalue={index}
                      title={item.pathwayTitle}
                      subtitle={item.pathwayCategories[0]}
                    />
                  )
                );
              })
            : displayData &&
              displayData.pathwayCompleted.slice(0, 9).map((item, index) => {
                let activeLearningIcon = "";
                switch (item.pathwayCategories[0]) {
                  case "Problem Solving":
                    activeLearningIcon = Images.probsolving_whitecard;
                    break;
                  case "Critical Thinking":
                    activeLearningIcon = Images.criticalthinking_whitecard;
                    break;
                  case "Teamwork":
                    activeLearningIcon = Images.teamwork_whitecard;
                    break;
                  case "Communication":
                    activeLearningIcon = Images.communication_whitecard;
                    break;
                  case "Leadership":
                    activeLearningIcon = Images.leadership;
                    break;
                  case "Time Management":
                    activeLearningIcon = Images.timemgt_whitecard;
                    break;
                  case "Emotional Judgement":
                    activeLearningIcon = Images.emotionaljudge;
                    break;
                  case "Creativity":
                    activeLearningIcon = Images.creativity;
                    break;
                  case "Flexibility/Adaptability":
                    activeLearningIcon = Images.Flexibilty_active
                    break;

                  default:
                    activeLearningIcon = Images.infinity;
                }

                return (
                  item.userPathwayStatus === 1 && (
                    <LearningCard
                      status={item.userPathwayStatus}
                      data={item}
                      icon={activeLearningIcon}
                      keyvalue={index}
                      title={item.pathwayTitle}
                      subtitle={item.pathwayCategories[0]}
                    />
                  )
                );
              })}
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pathway/learning-history"
            onClick={__redirectScrollTop}
            className="btn  view-learning-btn mt-2 border-2 btn-circle-30 font-weight-bold border-color-darkblue px-3 font-size-13 line-height-18 family-font-ubuntu  text-center"
          >
            {StringConstants.viewlearning}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="pathway-complete-screen bg-aliceBlue before-curve-pathway full-height">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8  mb-70">
              {yourPath()}
              {softskill()}
              {activeLearning()}
            </div>
          </div>

          <div className=" position-fixed lumen-help fixed-lumen-help">
            <TipsPopup nextlabel={"heading"} tips={"hello"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PathComplete;
