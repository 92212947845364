import { combineReducers } from "redux";
import auth from "./auth-reducer";
import forgetPasswordReducer from "./forget-password-reducer";
import termsandconditions from "./termsandconditions-reducer";
import privatepolicy from "./privatepolicy-reducer";
import signin from "./signin-reducer";
import signup from "./signup-reducer";
import companycode from "./companycode-reducer";
import hub from "./hub-reducer";
import questions from "./questions-reducer";
import pathwayIncomplete from "./pathwayIncomplete-reducer";
import pathwayComplete from "./pathwayComplete-reducer";
import editaccountdetails from "./edit-account-details-reducer";
import changepassword from "./change-password-reducer";
import deleteaccount from "./delete-account-reducer";
import requestAccess from "./request-access-reducer";
import markPathwayCompleteReducer from "./mark-pathway-complete-reducer";
import answerReducer from "./answer-reducer";
import assessmentSummary from "./assessment-summary-reducer";
import assessmentCongratulation from "./assessment-congratulation-reducer";
import profileme from "./profile-me-reducer";
import activity from "./activity-reducer";
import category from "./category-reducer";
import assessmentDetails from "./assessment-details-reducer";
import assessmentHistory from "./assessment-history-reducer";
import contactusfirst from "./contactusList-reducer";
import contactusButton from "./contactusbutton-reducer";
import shareResult from "./shareResult-reducer";
import LumenReport from "./lumenReport-reducer";
import createAccountReducer from "./create-account-reducer";
import createAccountDetailsReducer from "./create-account-details-reducer";
import adminSpaceSetupReducer from "./admin-space-setup-reducer";
import organisationDetailsReducer from "./organisation-details-reducer";
import organisationGroupDetailsReducer from "./organisation-group-details-reducer";
import organisationCreateTagReducer from "./organisation-create-tag-reducer";
import inviteNewUserReducer from "./invite-new-user-reducer";
import editUserReducer from "./edit-user-reducer";
import userProfileReducer from "./user-profile-reducer";
import resendInviteUserReducer from "./resend-invite-user-reducer";
import deleteUserReducer from "./delete-user-reducer";
import forgetPasswordLinkReducer from "./forget-password-link-reducer";
import editAdminReducer from "./edit-admin-reducer";
import organisationDeleteTagReducer from "./organisation-delete-tag-reducer";
import generateReportReducer from "./generate-report-reducer";
import organisationEditTagReducer from "./organisation-edit-tag-reducer";
import getUiState from "./ui-state-reducer";
import imageUploadReducer from "./image-upload-reducer";
import preMediaUploadReducer from "./upload-media-reducer";
import adminLogoutReducer from "./admin-logout-reducer";
import organisationAdminDetailsReducer from "./organisation-admin-details-reducer";
import groupTaxonomiesReducer from "./group-taxonomies-reducer";
import teamCreationDataReducer from "./team-creation-data-reducer";
import taxonomyDataReducer from "./taxonomy-data-reducer";
import groupCategoryReducer from "./group-category-reducer";
import taxonomyListPostReducer from "./taxonomy-list-post-reducer";
import summaryNewEmailReducer from "./summary-new-email-reducer";
import shareResultPostReducer from "./share-result-post-reducer";


const rootReducer = combineReducers({
  auth: auth,
  forgetpassword: forgetPasswordReducer,
  contactusButton: contactusButton,
  contactusfirst: contactusfirst,
  privatepolicy: privatepolicy,
  termsandconditions: termsandconditions,
  assessmentHistory: assessmentHistory,
  signup: signup,
  signin: signin,
  hub: hub,
  assessmentDetails: assessmentDetails,
  activity: activity,
  questions: questions,
  category: category,
  answerresponse: answerReducer,
  companycode: companycode,
  pathwayIncomplete: pathwayIncomplete,
  pathwayComplete: pathwayComplete,
  editaccountdetails: editaccountdetails,
  changepassword: changepassword,
  requestAccess: requestAccess,
  deleteaccount: deleteaccount,
  markPathwayComplete: markPathwayCompleteReducer,
  assessmentSummary: assessmentSummary,
  assessmentCongratulation: assessmentCongratulation,
  profileme: profileme,
  shareResult: shareResult,
  lumenReport: LumenReport,
  createAccount: createAccountReducer,
  createAccountDetails: createAccountDetailsReducer,
  adminSpaceSetup: adminSpaceSetupReducer,
  organisationDetails: organisationDetailsReducer,
  organisationGroupDetails: organisationGroupDetailsReducer,
  organisationCreateTag: organisationCreateTagReducer,
  organisationDeleteTag: organisationDeleteTagReducer,
  inviteNewUser: inviteNewUserReducer,
  editUser: editUserReducer,
  userProfile: userProfileReducer,
  resendUserInvite: resendInviteUserReducer,
  deleteUser: deleteUserReducer,
  forgetPasswordLink: forgetPasswordLinkReducer,
  editAdmin: editAdminReducer,
  generateReport: generateReportReducer,
  organisationEditTag: organisationEditTagReducer,
  getUiState: getUiState,
  imageUpload: imageUploadReducer,
  preMediaUpload: preMediaUploadReducer,
  adminLogout: adminLogoutReducer,
  organisationAdminDetails: organisationAdminDetailsReducer,
  grouptaxonomieList: groupTaxonomiesReducer,
  teamCreationData: teamCreationDataReducer,
  taxonomyData: taxonomyDataReducer,
  groupCategory: groupCategoryReducer,
  taxonomyListPost: taxonomyListPostReducer,
  summaryNewEmail: summaryNewEmailReducer,
  shareResultPost: shareResultPostReducer
});

export default rootReducer;
