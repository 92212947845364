import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextInputReadonly,
  TipsPopup,
  TopHeader,
  TopHeaderDesktop,
  HelpTip,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { Images, StringConstants, BASEURL } from "../../config";
import { USER_DATA, getData } from "../../utils/storage";
// redux
import { testAPI_Action, resetAPI_Action } from "../../stores/actions";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { __redirectScrollTop } from "../../utils/utility";
import { API_ENDPOINT } from "../../config/ApiConstants";

const Company = (props) => {
  const [userData, setUserData] = useState();
  const [errorDataModel, setErrorDataModel] = useState();
  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        //pass data to next screen
        setUserData(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="join-company join-company-height bg-aliceBlue ">
        {!navigator.onLine && <InternetConnectivity />}
        <Container>
          <Row className="justify-content-sm-center">
            <Col md={8} className="p-0">
              <TopHeader redirectHub="/hub" />
              <TopHeaderDesktop />
              <div className="wrpfrom-mobile">
                <div className="cards-box jc-card-size jc-inside-space">
                  <div className="company-media d-flex">
                    {userData && userData.companyLogo !== "" ? (
                      <img
                        src={userData.companyLogo}
                        className="jc-icon mr-3 user_logo"
                      />
                    ) : (
                      <img
                        src={Images.infinity}
                        className="jc-icon mr-3 user_logo"
                      />
                    )}

                    <div className="company-body">
                      <h4 className="text-color-blue font-size-18 font-family-ubuntu font-weight-medium line-height-22 mb-1">
                        {userData && userData.user_company_name}
                      </h4>
                      <p className="font-size-13 font-family-ubuntu line-height-18 text-color-violet mb-0">
                        <span>
                          {userData && userData.groupEmployeeCount}{" "}
                        </span>{" "}
                        {StringConstants.Employees}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {userData && (
                <div className="mt-4 mobile-wrpspace">
                  <Row className="">
                    <Col sm={12}>
                      <TextInputReadonly
                        label={StringConstants.Name}
                        Icon={Images.name_icon}
                        type="text"
                        value={userData.name}
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInputReadonly
                        label={StringConstants.Job_title}
                        Icon={Images.job_icon}
                        type="text"
                        value={userData.job_title}
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInputReadonly
                        label={StringConstants.Dept_team}
                        Icon={Images.team_icon}
                        type="text"
                        value={userData.department}
                      />
                    </Col>
                  </Row>

                  <div className="text-sm-right mt-2 mb-4">
                    {/* <button
                    type="button"
                    //   onClick={__redirectScreen}
                    className="btn  btn-text bg-violet text-white py-3 px-3 join-btn btn-circle-30"
                  >
                    {StringConstants.all_correct}
                  </button> */}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      {/* footer of wave */}
      <div className="footer-wave bg-violet pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-sm-left pb-5 pb-md-1 pt-3">
            <Col md={8} lg={8} className="pt-sm-5">
              {userData && userData.share_lumen_code !== null ? (
                <>
                  <p className=" lumen font-size-16 line-height-22 font-family-ubuntu font-weight-medium mb-2">
                    {/*{StringConstants.Your_Lumen}*/}
                  </p>
                  <div className="d-md-flex flex-wrap justify-content-between align-items-center">
                    {/*<a*/}
                      {/*href={`${BASEURL}/s/${userData.share_lumen_code}`}*/}
                      {/*target={"_blank"}*/}
                    {/*>*/}
                      <h4 className="lumenai-com-uniquecode mb-0 font-family-ubuntu">
                        {/* {`${process.env.REACT_APP_BASEURL}/s/${ userData && userData.share_lumen_code}`} */}

                        {/*{`lumenai.com/s/${*/}
                          {/*userData && userData.share_lumen_code*/}
                        {/*}`}*/}
                      </h4>
                    {/*</a>*/}

                    {/*<Link*/}
                      {/*to="/share-my-result"*/}
                      {/*onClick={__redirectScrollTop}*/}
                      {/*className="btn font-family-ubuntu  px-3 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"*/}
                    {/*>*/}
                      {/*{StringConstants.Share_my_lumen}*/}
                    {/*</Link>*/}
                  </div>
                </>
              ) : null}
            </Col>
          </Row>
          <div className=" position-fixed lumen-help fixed-lumen-help">
            <HelpTip />
            {/* <TipsPopup /> */}
          </div>
        </Container>
      </div>
      {/* fotoer wave end */}
    </>
  );
};

export default Company;
