import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// redux
import {
  activityAPI_Action,
  resetactivityAPI_Action,
} from "../../stores/actions";
import { Colors, Images, StringConstants } from "../../config";
import { getData, USER_DATA, TOKEN, clearAllData, GROUP_DATA } from "../../utils/storage";
import {
  CategoryCard,
  LearningCard,
  ProgressCount,
  AssessmentCard,
  TipsPopup,
  TopHeaderDesktop,
  HelpTip,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import moment from "moment";

const YourActivity = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // states
  const [userData, setUserData] = useState();
  const [assessmentdetails, setAssessmentdetails] = useState("");
  const activityData = useSelector((state) => state.activity);
  const [loader, setLoader] = useState();
  const [displayData, setDisplayData] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();

  const [completedDate, setcompletedDate] = useState();
  //selector
  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData(GROUP_DATA).then((groupData) => {
            dispatch(activityAPI_Action(res.token, groupData.groupId));
          })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (activityData.isFetching) {
      setLoader(true);
    } else if (activityData.isSuccess && activityData.data !== null) {
      setLoader(false);

      let yourActivityData =
        activityData && activityData.data && activityData.data.data;
      setDisplayData(yourActivityData);
      if (yourActivityData.most_recent_activity.questionBank !== undefined) {
        let set_date1 = moment(
          yourActivityData.most_recent_activity.questionBank
            .questionBankCreatedAt
        ).format("DD/MM/YYYY");
        setcompletedDate(set_date1);
      }
    } else if (activityData.isError) {
      setErrorModel(true);
      setErrorDataModel(activityData.data);
    }
  }, [activityData]);

  const recentActivity = () => {
    return (
      <div className="your_pathway mb-5 mb-md-4">
        <div className="d-flex justify-content-between align-items-center progress-block">
          <p className="font-family-ubuntu color-white font-size-20 line-height-24 font-weight-medium ">
            {StringConstants.Most_recent}
          </p>
        </div>
        <div className="active-block your_activity pb-1 mb-4 ">
          {displayData &&
            displayData.most_recent_activity &&
            displayData.most_recent_activity.questionBank &&
            displayData.most_recent_activity.questionBank.map((item, index) => {
              return (
                <div key={index}>
                <LearningCard
                  SkillCard="skillcards"
                  icon={Images.assessment_icon_small}
                  // seeMoreText={"see more"}
                  title={item.questionBankName}
                  completeDate={"Completed " + completedDate}
                />
                </div>
              );
            })}
          {displayData &&
            displayData.most_recent_activity &&
            displayData.most_recent_activity.pathway &&
            displayData.most_recent_activity.pathway.map((item, index) => {
              let activeLearningIcon = "";
              switch (item.pathwayCategories[0]) {
                case "Problem Solving":
                  activeLearningIcon = Images.probsolving_whitecard;
                  break;
                case "Critical Thinking":
                  activeLearningIcon = Images.criticalthinking_whitecard;
                  break;
                case "Teamwork":
                  activeLearningIcon = Images.teamwork_whitecard;
                  break;
                case "Communication":
                  activeLearningIcon = Images.communication_whitecard;
                  break;
                case "Leadership":
                  activeLearningIcon = Images.leadership;
                  break;
                case "Time Management":
                  activeLearningIcon = Images.timemgt_whitecard;
                  break;
                case "Emotional Judgement":
                  activeLearningIcon = Images.emotionaljudge;
                  break;
                case "Creativity":
                  activeLearningIcon = Images.creativity;
                  break;
                case "Flexibility/Adaptability":
                  activeLearningIcon = Images.Flexibilty_active;
                  break;

                default:
                  activeLearningIcon = Images.emotionaljudge;
              }

              return (
                <LearningCard
                  status={item.userPathwayStatus}
                  data={item}
                  icon={activeLearningIcon}
                  title={item.pathwayTitle}
                  subtitle={item.pathwayCategories[0]}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const previousActivity = () => {
    return (
      <div className="active-learning-box learning-box">
        {displayData &&
            displayData.previous_activity &&
            displayData.previous_activity.pathway &&
            displayData.previous_activity.pathway.length > 0 ?
            <div className="d-flex justify-content-between align-items-center width-48 mb-0 mt-2">
              <p className="previous previous-activity-text font-size-16 font-family-ubuntu font-weight-medium line-height-22  mb-3 mt-5">
                {StringConstants.Previous}
              </p>
            </div>
        : null 
        }


        <div className="active-block pb-1 mb-4 ">
          {displayData &&
            displayData.previous_activity &&
            displayData.previous_activity.pathway &&
            displayData.previous_activity.pathway.map((item, index) => {
              let activeLearningIcon = "";
              switch (item.pathwayCategories[0]) {
                case "Problem Solving":
                  activeLearningIcon = Images.probsolving_whitecard;
                  break;
                case "Critical Thinking":
                  activeLearningIcon = Images.criticalthinking_whitecard;
                  break;
                case "Teamwork":
                  activeLearningIcon = Images.teamwork_whitecard;
                  break;
                case "Communication":
                  activeLearningIcon = Images.communication_whitecard;
                  break;
                case "Leadership":
                  activeLearningIcon = Images.leadership;
                  break;
                case "Time Management":
                  activeLearningIcon = Images.timemgt_whitecard;
                  break;
                case "Emotional Judgement":
                  activeLearningIcon = Images.emotionaljudge;
                  break;
                case "Creativity":
                  activeLearningIcon = Images.creativity;
                  break;
                  case "Flexibility/Adaptability":
                  activeLearningIcon = Images.Flexibilty_active;
                  break;

                default:
                  activeLearningIcon = Images.emotionaljudge;
              }

              return (
                <LearningCard
                  status={item.userPathwayStatus}
                  data={item}
                  icon={activeLearningIcon}
                  title={item.pathwayTitle}
                  subtitle={item.pathwayCategories[0]}
                />
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <>
      {loader === true ? (
        <>
          <div className="bg-aliceblue loader  position-absolute w-100">
            <div className="d-flex align-items-center flex-column justify-content-center full-height">
              <img src={Images.infinity} alt="infinite" />
              <p className="font-size-22 font-family-ubuntu font-weight-bold colo-text-violet">
                {StringConstants.Lumenai_loader}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="pathway-screen bg-aliceBlue before-curve-pathway full-height">
          {!navigator.onLine && <InternetConnectivity />}

          <div className="container">
            <div className="row justify-content-md-center ">
              <div className="col-md-10 col-lg-8 mb-70">
                <TopHeaderDesktop white />
                {recentActivity()}
                {previousActivity()}
              </div>
            </div>

            <div className=" position-fixed lumen-help fixed-lumen-help">
              <HelpTip />
              {/* <TipsPopup /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default YourActivity;
