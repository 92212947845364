import React, {useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal, Container, Col, Row } from "react-bootstrap";
import { TipsPopup, TopHeader, TopHeaderDesktop } from "../../components";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import { Page400, Page500, InternetConnectivity } from "../../screens";

import { clearAllData } from "../../utils/storage";
import { isLogout } from "../../stores/actions";

const Settings = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const redirectUrl = (e) => {
    history.push(e);
  };
  const logout = useCallback(() => {
    // console.log("logout");
    clearAllData();
    dispatch(isLogout());
    window.location.href = window.location.origin;
  });

  //popup

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={props.onHide}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                Sure, you want to log out
              </h3>
              <button
                className="btn mt-3  min-width-245 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30"
                onClick={logout}
              >
                Log out
              </button>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <button
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                onClick={props.onHide}
              >
                {StringConstants.skip_now}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <div className="join-company-cnf bg-aliceBlue wave-section">
        {!navigator.onLine && <InternetConnectivity />}
        <TopHeader />
        <Container>
          <Row className="pb-3 pt-3 justify-content-sm-center">
            <Col lg={8} md={10} className="pt-4">
              <TopHeaderDesktop />
              <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to="/edit-account-details"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.edit_acc}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>

              <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to="/change-password"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.change_pass}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>
              <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to="/faq"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.faq}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>
              {/* <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to="/about-lumenai"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.about_lumen}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div> */}
              <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to="/pathway/soft-skill-explained"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.about_soft_skills}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>
              <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to={void(0)}
                  onClick={
                    ()=>(
                      window.open( 
                        "https://lumenai.tech/privacy-policy/", "_blank"))
                  }
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.Privacy_Policy}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>
              <div class=" set py-3 px-3 mb-3  ">
                <Link
                   to={void(0)}
                  onClick={
                    ()=>(
                      window.open( 
                        "https://lumenai.tech/privacy-policy/", "_blank"))
                  }
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.tnc}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>
              <div class=" set py-3 px-3 mb-3  ">
                <Link
                  to="/contact-us"
                  className="d-flex justify-content-between align-items-center"
                >
                  <p className=" mb-0 font-family-ubuntu font-size-16 text-color-black line-height-18 font-weight-bold  ">
                    {StringConstants.contact_us}
                  </p>
                  <img src={Images.backArrow} className="right-arrow " />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave bg-violet pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-md-left text-sm-center">
            <Col sm={8} className="pt-sm-5">
              <Link
                // onClick={logout}
                onClick={() => setModalShow(true)}
                className="btn mobile-w-243 px-5 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center logout-"
              >
                Log out
              </Link>
            </Col>
          </Row>
        </Container>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Settings;
