import React, { useEffect, useState } from "react";
import {
    Loader,
    NestedPieChartProfile,
    TipsPopup,
    HelpTip,
} from "../../components";
import { Images, StringConstants } from "../../config";
import ProgressCard from "../../components/ProgressCard";
import { __redirectScrollTop } from "../../utils/utility";
import { useDispatch, useSelector } from "react-redux";
import { Row, Container, Col, Modal } from "react-bootstrap";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import moment from "moment";
import {
    assessmentHistoryAPI_Action,
    resetSUMMARY_NEW_EMAILAPI_Action,
    resetassessmentHistoryAPI_Action,
    summary_new_email_action,
} from "../../stores/actions";
import {
    profileMeAPI_Action,
    resetAssessmentSummaryAPI_Action,
} from "../../stores/actions";
import { Link } from "react-router-dom";
import {
    getData,
    setData,
    TOKEN,
    USER_DATA,
    clearAllData,
    GROUP_DATA,
} from "../../utils/storage";
import AssessmentDetail from "../Path/assessment-detail";
import {
    categoryAPI_Action,
    resetcategoryAPI_Action,
} from "../../stores/actions";
import CategoryShowList from "../LumenReport/CategoryShowList";
import { Downaloadpdf, EmailTemplate, PurpleClose, close, close_blue, close_skill, infinity } from "../../config/Images";
import SummaryPopupCard from "../../components/SummaryNewPopupCard";

const SummaryNew = (props) => {
    const profilemeData = useSelector((state) => state.profileme);
    const historyData = useSelector((state) => state.assessmentHistory);
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorModel, setErrorModel] = useState(false);
    const [errorDataModel, setErrorDataModel] = useState();
    const [completedDate, setcompletedDate] = useState();
    const [usedetails, setUserdetails] = useState();
    const [userData, setUserData] = useState();
    const [assessmentdetails, setAssessmentdetails] = useState({});
    const [assessmentdetailsHistory, setAssessmentdetailsHistory] = useState([]);
    const [categoryArray, setcategoryArray] = useState([]);
    const [categoryArrayArr, setcategoryArrayArr] = useState([]);
    const [categoryImageArray, setcategoryImageArray] = useState([]);
    const [initialName, setInitialName] = useState([]);
    const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
    const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
        useState([]);
    const [summaryAttempt, setSummaryAttempt] = useState();
    const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
        []
    );
    const [talentCategory, setTalentCategory] = useState([]);
    const [downloadpdfUrl, setDownloadpdfUrl] = useState("");
    const [managementCategory, setManagementCategory] = useState([]);
    const [coreCategory, setCoreCategory] = useState([]);
    const [trainingCategory, setTrainingCategory] = useState([]);
    const [assessmentRange, setAssessmentRange] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [groupId, setGroupId] = useState("");
    //ALL API START
    const categoryData = useSelector((state) => state.category);
    const summaryEmailData = useSelector((state) => state.summaryNewEmail);
    const [displayData, setDisplayData] = useState();
    const [softskill, setSoftSkill] = useState();
    const [taxonomyData, setTaxonomyData] = useState([]);
    const [token, setToken] = useState();
    useEffect(() => {
        try {
            getData(TOKEN)
                .then((res) => {
                    setToken(res.token);
                    dispatch(categoryAPI_Action(res.token));
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (categoryData.isFetching) {
            setLoader(true);
        } else if (categoryData.isSuccess && categoryData.data !== null) {
            setLoader(false);
            let dashboardData =
                categoryData && categoryData.data && categoryData.data.data;
            setDisplayData(dashboardData);
            setSoftSkill(categoryData.data.data);
        } else if (categoryData.isError) {
            setErrorModel(true);
            setErrorDataModel(categoryData.data);
        }
    }, [categoryData]);
    //All API END

    useEffect(() => {
        getData(USER_DATA)
            .then((res) => {
                //pass data to next screen
                setUserData(res);
            })
            .catch((error) => {
                // unable to fine user profile
                console.log(error);
            });
    }, []);

    useEffect(() => {
        getData(GROUP_DATA)
            .then((res) => {
                //pass data to next screen
                setGroupId(res.groupId);
            })
            .catch((error) => {
                // unable to fine user profile
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (summaryEmailData.isSuccess && summaryEmailData.data !== null) {
            alert(summaryEmailData.data.message);
            dispatch(resetSUMMARY_NEW_EMAILAPI_Action())
        } else if (summaryEmailData.isError) {
            dispatch(resetSUMMARY_NEW_EMAILAPI_Action())
        }
    }, [summaryEmailData])



    const noDataShowHub = () => {
        return (
            <div className="pt-md-5 pb-3 ">
                <Row className="align-items-center pt-5 mt-md-5 px-lg--5">
                    <Col md={8} className="pr-0 mt-md-5">
                        <div className="company-cnf-media ">
                            <div className="cnf-icon d-sm-flex align-items-center">
                                <img
                                    src={Images.assessment_icon}
                                    className="mr-md-4 mr-sm-2 icon-company-cnf"
                                />
                                <div className="cnf-media-content ">
                                    {userData && (
                                        <h4 className="text-white font-size-18 font-family-ubuntu font-weight-bold line-height-22 mb-2">
                                            {StringConstants.complete_assessment}{" "}
                                            {userData.questionBankName[0]}
                                        </h4>
                                    )}
                                    <p className="text-white font-size-15 font-family-ubuntu font-weight-medium line-height-22 mb-0">
                                        {StringConstants.skill_assessment}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="ml-sm-4 ml-md-0 mt-md-5">
                        <div className="start_assessmet pl-sm-5 ml-sm-5 pl-md-0 ml-md-0 mt-2">
                            {/* {summaryAttempt && summaryAttempt !== 1 ? ( */}
                            <Link
                                // to="/initial-soft-skill"
                                to="/try-example"
                                onClick={__redirectScrollTop}
                                className="btn btn-text  bg-aliceBlue text-violet py-3 px-3 join-btn btn-circle-30"
                            >
                                {StringConstants.start_assessment}
                            </Link>
                            {/* ) : (
                <Link
                  to="/assessment-detail"
                  onClick={__redirectScrollTop}
                  className="btn btn-text  bg-aliceBlue text-violet py-3 px-3 join-btn btn-circle-30"
                >
                  {StringConstants.start_assessment}
                </Link>
              )} */}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    useEffect(() => {
        try {
            getData(TOKEN)
                .then((res) => {
                    setBackgroundColorArraymanager([]);
                    setBackgroundColorArraycore([]);
                    setBackgroundColorArraytalent([]);


                    getData(GROUP_DATA).then((groupData) => {
                        dispatch(assessmentHistoryAPI_Action(res.token, groupData.groupId))
                        dispatch(profileMeAPI_Action(res.token, groupData.groupId));
                    })

                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);

    // useEffect(() => {
    //   try {
    //     getData(TOKEN)
    //       .then((res) => {
    //         setBackgroundColorArraymanager([]);
    //         setBackgroundColorArraycore([]);
    //         setBackgroundColorArraytalent([]);
    //         dispatch(assessmentHistoryAPI_Action(res.token));
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }, []);

    useEffect(() => {
        if (profilemeData.isFetching) {
            setLoader(true);
        } else if (profilemeData.isSuccess && profilemeData.data !== null) {
            setLoader(false);
            if (profilemeData.data.data.questionBankDetails !== undefined) {
                let set_date1 = moment(
                    profilemeData.data.data.questionBankDetails.questionBankCompletedAt
                ).format("DD/MM/YYYY");
                setcompletedDate(set_date1);
                setAssessmentdetails(profilemeData.data.data.questionBankDetails);
            }
            if (profilemeData.data.data.taxonomyData !== undefined) {
                setTaxonomyData(profilemeData.data.data.taxonomyData);
            }

            if (profilemeData.data.data.pdfFileDownloadLink !== undefined) {
                setDownloadpdfUrl(profilemeData.data.data.pdfFileDownloadLink);
            }

            if (profilemeData.data.data.categoryArr !== undefined) {
                setcategoryArray(profilemeData.data.data.categoryArr);
                let newmanagrArray = [];
                let newcoreArray = [];
                let newtalentArray = [];
                let sortedcatArray = profilemeData.data.data.categoryArr;

                sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);

                sortedcatArray.map((item, index) => {
                    // console.log("cat item", item);
                    if (item.highlightCategoryCoreSection === true) {
                        newcoreArray.push("#48FFFF");
                    } else {
                        newcoreArray.push("#381474");
                    }
                    if (item.highlightCategoryManagerialSection === true) {
                        newmanagrArray.push("#275EFF");
                    } else {
                        newmanagrArray.push("#381474");
                    }
                    if (item.highlightCategoryTalentedSection === true) {
                        newtalentArray.push("#F904FF");
                    } else {
                        newtalentArray.push("#381474");
                    }

                    let activeLearningIcon = "";
                    switch (item.categoryName) {
                        case "Problem Solving":
                            activeLearningIcon = Images.problem_solving;
                            break;
                        case "Critical Thinking":
                            activeLearningIcon = Images.criticalthink_white;
                            break;
                        case "Teamwork":
                            activeLearningIcon = Images.teamwork_white;
                            break;
                        case "Communication":
                            activeLearningIcon = Images.comms_white;
                            break;
                        case "Leadership":
                            activeLearningIcon = Images.leadershp_white;
                            break;
                        case "Time Management":
                            activeLearningIcon = Images.timemgt_white;
                            break;
                        case "Emotional Judgement":
                            activeLearningIcon = Images.emotionaljudge_white;
                            break;
                        case "Creativity":
                            activeLearningIcon = Images.creativity_white;
                            break;
                        case "Flexibility/Adaptability":
                            activeLearningIcon = Images.Flexibilty_active;
                            break;
                        default:
                            activeLearningIcon = Images.infinity;
                    }

                    categoryImageArray.push(activeLearningIcon);
                });



                setBackgroundColorArraycore(newcoreArray);
                setBackgroundColorArraymanager(newmanagrArray);
                setBackgroundColorArraytalent(newtalentArray);
            }
            if (profilemeData.data.data.userDetails !== undefined) {
                setUserdetails(profilemeData.data.data.userDetails);
                let init = (
                    profilemeData.data.data.userDetails.first_name.slice(0, 1) +
                    profilemeData.data.data.userDetails.last_name.slice(0, 1)
                ).toUpperCase();
                setInitialName(init);
            }


            if (categoryArray.length > 0) {
                setTalentCategory([]);
                setManagementCategory([]);
                setCoreCategory([]);
                setTrainingCategory([]);
                categoryArray.map((items, index) => {
                    if (items.highlightCategoryTalentedSection) {
                        setTalentCategory((current) => [...current, items])
                    } else if (items.highlightCategoryManagerialSection) {
                        setManagementCategory((current) => [...current, items])
                    } else if (items.highlightCategoryCoreSection) {
                        setCoreCategory((current) => [...current, items])
                    } else {
                        setTrainingCategory((current) => [...current, items])
                    }
                })

            }

            if (assessmentdetails.length !== 0) {
                setAssessmentRange(assessmentdetails.questionBankOverallPercentage ? Number(assessmentdetails.questionBankOverallPercentage.replace(/%/g, "")) : "")
            }

        } else if (profilemeData.isError) {
            setLoader(false);
            setErrorModel(true);
            setErrorDataModel(profilemeData.data);
        }

    }, [profilemeData]);

    useEffect(() => {
        console.log(talentCategory.length > 0 ? talentCategory : null);

    }, [talentCategory])

    useEffect(() => {
        // console.log("history", historyData);
        if (historyData.isFetching) {
            setLoader(true);
        } else if (historyData.isSuccess && historyData.data !== null) {
            setLoader(false);
            if (historyData.data.data.questionBankDetails !== undefined) {
                let set_date1 = moment(
                    historyData.data.data.questionBankDetails.questionBankCompletedAt
                ).format("DD/MM/YYYY");
                setcompletedDate(set_date1);
                setAssessmentdetailsHistory(historyData.data.data.questionBankDetails);
                setcategoryArrayArr(historyData.data.data.categoryArr);
            }

            if (historyData.data.data.userDetails !== undefined) {
                setUserdetails(historyData.data.data.userDetails);
                let init = (
                    historyData.data.data.userDetails.first_name.slice(0, 1) +
                    historyData.data.data.userDetails.last_name.slice(0, 1)
                ).toUpperCase();
                setInitialName(init);
            }
        } else if (historyData.isError) {
            setLoader(false);
            setErrorModel(true);
            setErrorDataModel(historyData.data);
        }
    }, [historyData]);

    const __toggleClick = () => {
        setToggle(!toggle);
    };

    const sendSummaryEmail = () => {
        console.log(groupId, " ", userData.questionBankId[0], " ", assessmentdetails.attempt);

        dispatch(summary_new_email_action(token, groupId, userData.questionBankId[0], assessmentdetails.attempt));
    }

    return (
        <>
            {!navigator.onLine && <InternetConnectivity />}
            {errorDataModel &&
                errorDataModel.error &&
                errorDataModel.error.status_code === 500 &&
                window.location.replace("/error-500")}
            {errorDataModel &&
                errorDataModel.error &&
                errorDataModel.error.status_code === 404 &&
                window.location.replace("/error-400")}
            {errorDataModel &&
                errorDataModel.error &&
                errorDataModel.error.status_code === 401 &&
                window.location.replace("/")}

            {loader === true && <Loader />}
            <div className="mescreen summary-new">
                <div className=" bg-aliceBlue me-screens ">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10 col-lg-8">
                                <Container>
                                    <div className="box-me pt-3 pt-md-0">
                                        <div className="d-md-none mb-3 mob-text-view">
                                            <div className="d-flex align-items-center justify-content-between w-100 ">
                                                <Link to="/">
                                                    <img
                                                        src={Images.infinity}
                                                        className="icons img-white-icon mb-2 pt-2 infinite-icon-for-mobile"
                                                    />
                                                </Link>
                                                <Link
                                                    to="/settings"
                                                    className="for-mob-text font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-color-violet"
                                                >
                                                    Settings
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="set-position">
                                            <div className="d-flex  w-100 align-items-center">
                                                <div className="profile-img">
                                                    <span className="congrats-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold  text-color-violet mb-0">
                                                        {initialName}
                                                    </span>
                                                </div>
                                                <div className="ml-2">
                                                    <h3 className="congrats-text font-mob-16 font-size-13 font-family-ubuntu font-weight-bold line-height-18  text-color-violet mb-0">
                                                        {usedetails && usedetails.name}
                                                    </h3>
                                                    <p className="congrats-desc-text font-mob-16  font-size-11 font-family-ubuntu font-weight-bold  line-height-18  text-color-violet mb-0">
                                                        {usedetails && usedetails.email}
                                                    </p>
                                                    <Link
                                                        to="/settings"
                                                        className="d-none d-md-block font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-color-violet"
                                                    >
                                                        Settings
                                                    </Link>
                                                    {/* <div className="sharelink"> */}
                                                    {/*<Link*/}
                                                        {/*to="/share-my-result"*/}
                                                        {/*onClick={__redirectScrollTop}*/}
                                                        {/*className="congrats-desc-text d-block  me_sharelink  font-mob-16  font-size-11 font-family-ubuntu font-weight-regular  line-height-18  text-color-violet mb-0"*/}
                                                    {/*>*/}
                                                        {/*Share my results*/}
                                                    {/*</Link>*/}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </div>

                    <div className="footer-wave bg-violet mt-3 mt-md-0 pb-5 summary-wave full-height">
                        <div className="container">

                            {assessmentdetails.length !== 0 ? (
                                <>
                                    <div className="row justify-content-md-center pt-5">
                                        <div className="col-md-6 col-lg-5">
                                            <div className=" category-progress-summary category__block1 mt-md-5">

                                                <ProgressCard
                                                    statusText={"Completed " + moment(assessmentdetails.questionBankCompletedAt).format("DD/MM/YYYY")}
                                                    icon={Images.assessment_white}
                                                    title={assessmentdetails.questionBankName}
                                                    levelData={assessmentdetails.questionBankLevel}
                                                    percentSymbol
                                                    percent={
                                                        assessmentdetails.questionBankOverallPercentage &&
                                                        assessmentdetails.questionBankOverallPercentage.slice(
                                                            0,
                                                            assessmentdetails.questionBankOverallPercentage
                                                                .length - 1
                                                        )
                                                    }
                                                    categoryCorePercentage={
                                                        assessmentdetails.questionBankCorePercentage &&
                                                        assessmentdetails.questionBankCorePercentage.slice(
                                                            0,
                                                            assessmentdetails.questionBankCorePercentage
                                                                .length - 1
                                                        )
                                                    }
                                                    categoryManagerialPercentage={
                                                        assessmentdetails.questionBankManagerialPercentage &&
                                                        assessmentdetails.questionBankManagerialPercentage.slice(
                                                            0,
                                                            assessmentdetails.questionBankManagerialPercentage
                                                                .length - 1
                                                        )
                                                    }
                                                    categoryTalentedPercentage={
                                                        assessmentdetails.questionBankTalentedPercentage &&
                                                        assessmentdetails.questionBankTalentedPercentage.slice(
                                                            0,
                                                            assessmentdetails.questionBankTalentedPercentage
                                                                .length - 1
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="pie-chart">
                                                {profilemeData &&
                                                    profilemeData.data &&
                                                    profilemeData.data.data &&
                                                    profilemeData.data.data.categoryArr ? (
                                                    <NestedPieChartProfile
                                                        backgroundColorArraycore={backgroundColorArraycore}
                                                        backgroundColorArraymanager={
                                                            backgroundColorArraymanager
                                                        }
                                                        backgroundColorArraytalent={
                                                            backgroundColorArraytalent
                                                        }
                                                        categoryImageArray={categoryImageArray}
                                                        catgoryArray={
                                                            profilemeData &&
                                                            profilemeData.data &&
                                                            profilemeData.data.data &&
                                                            profilemeData.data.data.categoryArr
                                                        }
                                                    />
                                                ) : null}
                                            </div>

                                            {/* for desktop  */}




                                            <div className="assessment-history text-center mt-4  d-md-block d-none">

                                                {toggle === true ? (
                                                    <>
                                                        {assessmentdetailsHistory
                                                            .slice(0, 3)
                                                            .map((assessmentdetails, index) => {

                                                                // console.log("assessment_successfully_completed",assessmentdetails.assessment_successfully_completed)
                                                                return assessmentdetails.assessment_successfully_completed !== 0 ? null
                                                                    : assessmentdetails.attempt < 3 ? (
                                                                        <ProgressCard
                                                                            data={assessmentdetails}
                                                                            redirectUrl={{
                                                                                pathname:
                                                                                    "/me/history-detail/" +
                                                                                    assessmentdetails.uuid,
                                                                                state: {
                                                                                    data: {
                                                                                        assessmentdetails: assessmentdetails,
                                                                                        assessmentdetailsCategory:
                                                                                            categoryArrayArr[index],
                                                                                    },
                                                                                },
                                                                            }}
                                                                            statusText={"Completed " + moment(assessmentdetails.completed_at).format("DD/MM/YYYY")}
                                                                            percentSymbol
                                                                            icon={Images.assessment_white}
                                                                            title={assessmentdetails.questionBankName}
                                                                            percent={
                                                                                assessmentdetails.questionBankOverallPercentage &&
                                                                                assessmentdetails.questionBankOverallPercentage.slice(
                                                                                    0,
                                                                                    assessmentdetails
                                                                                        .questionBankOverallPercentage
                                                                                        .length - 1
                                                                                )
                                                                            }
                                                                            categoryCorePercentage={

                                                                                assessmentdetails.questionBankCorePercentage &&
                                                                                assessmentdetails.questionBankCorePercentage.slice(
                                                                                    0,
                                                                                    assessmentdetails
                                                                                        .questionBankCorePercentage.length - 1
                                                                                )
                                                                            }
                                                                            categoryManagerialPercentage={

                                                                                assessmentdetails.questionBankManagerialPercentage &&
                                                                                assessmentdetails.questionBankManagerialPercentage.slice(
                                                                                    0,
                                                                                    assessmentdetails
                                                                                        .questionBankManagerialPercentage
                                                                                        .length - 1
                                                                                )
                                                                            }
                                                                            categoryTalentedPercentage={
                                                                                assessmentdetails.questionBankTalentedPercentage &&
                                                                                assessmentdetails.questionBankTalentedPercentage.slice(
                                                                                    0,
                                                                                    assessmentdetails
                                                                                        .questionBankTalentedPercentage
                                                                                        .length - 1
                                                                                )
                                                                            }
                                                                        />) : null
                                                            })}
                                                    </>
                                                ) : null}

                                                {/* {
                                                    assessmentdetails.attempt === 1 && assessmentdetails.assessment_successfully_completed === 1 ? null
                                                        :
                                                        <button
                                                            onClick={__toggleClick}
                                                            className="btn px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center "
                                                        >
                                                            {toggle === true ? (
                                                                <>
                                                                    <div className="d-flex">
                                                                        <img
                                                                            src={Images.close_blue}
                                                                            className="close_icon mr-3"
                                                                        />
                                                                        <span> Close history </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <span>Read Lumen History</span>
                                                            )}
                                                        </button>
                                                } */}
                                            </div>
                                            {/* })  
                        }  */}
                                        </div>
                                        <div className="col-md-6 col-lg-5 mb-70 pt-2">
                                            <div className="category-progress-summary category__block2 mt-5 pb-2">
                                                {
                                                    assessmentdetails.attempt === 1 && assessmentdetails.assessment_successfully_completed === 1 ? null
                                                        :
                                                        <button
                                                            onClick={() => setModalShow(true)}
                                                            className="btn px-4 py-2  mb-3 ml-auto mr-0 d-flex align-self-end border-2 border-color-white text-white btn-circle-30 text-center"
                                                        >
                                                            {toggle === true ? (
                                                                <>
                                                                    <div className="d-flex">
                                                                        <img
                                                                            src={Images.close_blue}
                                                                            className="close_icon mr-3"
                                                                        />
                                                                        <span> Close history </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <span>Read Lumen History</span>
                                                            )}
                                                        </button>
                                                }
                                                {categoryArray !== null &&
                                                    categoryArray !== undefined &&
                                                    categoryArray.length > 0 &&
                                                    categoryArray.map((item, index) => {
                                                        // console.log("items----", item);
                                                        let activeLearningIcon = "";
                                                        switch (item.categoryName) {
                                                            case "Problem Solving":
                                                                activeLearningIcon = Images.problem_solving;
                                                                break;
                                                            case "Critical Thinking":
                                                                activeLearningIcon = Images.criticalthink_white;
                                                                break;
                                                            case "Teamwork":
                                                                activeLearningIcon = Images.teamwork_white;
                                                                break;
                                                            case "Communication":
                                                                activeLearningIcon = Images.comms_white;
                                                                break;
                                                            case "Leadership":
                                                                activeLearningIcon = Images.leadershp_white;
                                                                break;
                                                            case "Time Management":
                                                                activeLearningIcon = Images.timemgt_white;
                                                                break;
                                                            case "Emotional Judgement":
                                                                activeLearningIcon =
                                                                    Images.emotionaljudge_white;
                                                                break;
                                                            case "Creativity":
                                                                activeLearningIcon = Images.creativity_white;
                                                                break;
                                                            case "Flexibility/Adaptability":
                                                                activeLearningIcon = Images.Felixibility_inactive;
                                                                break;

                                                            default:
                                                                activeLearningIcon = Images.emotionaljudge;
                                                        }
                                                        // console.log("itemsssssdata", item);
                                                        return (
                                                            <ProgressCard
                                                                data={item}
                                                                softSkillUrl={{
                                                                    pathname:
                                                                        "/pathway/soft-skill-explained-detail/" +
                                                                        item.categoryIconName,
                                                                    state: { data: item },
                                                                }}
                                                                icon={activeLearningIcon}
                                                                title={item.categoryName}
                                                                percent={
                                                                    item.categoryPercentage &&
                                                                    item.categoryPercentage.slice(
                                                                        0,
                                                                        item.categoryPercentage.length - 1
                                                                    )
                                                                }
                                                                categoryCorePercentage={
                                                                    item.categoryCorePercentage &&
                                                                    item.categoryCorePercentage.slice(
                                                                        0,
                                                                        item.categoryCorePercentage.length - 1
                                                                    )
                                                                }
                                                                categoryManagerialPercentage={
                                                                    item.categoryManagerialPercentage &&
                                                                    item.categoryManagerialPercentage.slice(
                                                                        0,
                                                                        item.categoryManagerialPercentage.length - 1
                                                                    )
                                                                }
                                                                categoryTalentedPercentage={
                                                                    item.categoryTalentedPercentage &&
                                                                    item.categoryTalentedPercentage.slice(
                                                                        0,
                                                                        item.categoryTalentedPercentage.length - 1
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    })}
                                            </div>
                                            {/* for mobile  */}

                                            <div className="assessment-history text-center mt-4   d-md-none">
                                                {toggle === true ? (
                                                    <>
                                                        {/* <div className="line my-4"></div> */}
                                                        <div className="mt-5 line">
                                                            {assessmentdetailsHistory
                                                                .slice(0, 3)
                                                                .map((assessmentdetails, index) => {
                                                                    return assessmentdetails.assessment_successfully_completed !== 0 ? null
                                                                        : assessmentdetails.attempt < 3 ? (
                                                                            <ProgressCard
                                                                                data={assessmentdetails}
                                                                                redirectUrl={{
                                                                                    pathname:
                                                                                        "/me/history-detail/" +
                                                                                        assessmentdetails.uuid,
                                                                                    state: {
                                                                                        data: {
                                                                                            assessmentdetails:
                                                                                                assessmentdetails,
                                                                                            assessmentdetailsCategory:
                                                                                                categoryArrayArr[index],
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                statusText={"Completed " + moment(assessmentdetails.completed_at).format("DD/MM/YYYY")}
                                                                                percentSymbol
                                                                                icon={Images.assessment_white}
                                                                                title={assessmentdetails.questionBankName}
                                                                                percent={
                                                                                    assessmentdetails.questionBankOverallPercentage &&
                                                                                    assessmentdetails.questionBankOverallPercentage.slice(
                                                                                        0,
                                                                                        assessmentdetails
                                                                                            .questionBankOverallPercentage
                                                                                            .length - 1
                                                                                    )
                                                                                }
                                                                                categoryCorePercentage={

                                                                                    assessmentdetails.questionBankCorePercentage &&
                                                                                    assessmentdetails.questionBankCorePercentage.slice(
                                                                                        0,
                                                                                        assessmentdetails
                                                                                            .questionBankCorePercentage.length -
                                                                                        1
                                                                                    )
                                                                                }
                                                                                categoryManagerialPercentage={

                                                                                    assessmentdetails.questionBankManagerialPercentage &&
                                                                                    assessmentdetails.questionBankManagerialPercentage.slice(
                                                                                        0,
                                                                                        assessmentdetails
                                                                                            .questionBankManagerialPercentage
                                                                                            .length - 1
                                                                                    )
                                                                                }
                                                                                categoryTalentedPercentage={

                                                                                    assessmentdetails.questionBankTalentedPercentage &&
                                                                                    assessmentdetails.questionBankTalentedPercentage.slice(
                                                                                        0,
                                                                                        assessmentdetails
                                                                                            .questionBankTalentedPercentage
                                                                                            .length - 1
                                                                                    )
                                                                                }
                                                                            />) : null

                                                                })}
                                                        </div>
                                                    </>
                                                ) : null}

                                                {/* {
                                                    assessmentdetails.attempt === 1 && assessmentdetails.assessment_successfully_completed === 1 ? null
                                                        :

                                                        <button
                                                            onClick={__toggleClick}
                                                            className="btn px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center "
                                                        >
                                                            {toggle === true ? (
                                                                <>
                                                                    <div className="d-flex">
                                                                        <img
                                                                            src={Images.close_blue}
                                                                            className="close_icon mr-3"
                                                                        />
                                                                        <span> Close history </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <span>Read Lumen History</span>
                                                            )}
                                                        </button>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>{noDataShowHub()}</>
                            )}

                            {assessmentdetails.attempt !== "" && assessmentdetails.attempt !== undefined ? (
                                <>
                                    <div className="associated-traits container  mt-5">
                                        <p className="mb-0 text-white">Validated behavioural skill cloud</p>
                                        <div className="d-flex row justify-content-between">
                                            {Array.isArray(taxonomyData) && taxonomyData.length ? (
                                                <ul className="associate-list align-items-start d-flex  col-lg-10 justify-content-start flex-wrap">
                                                    {taxonomyData.map((items, index) => {
                                                        return (
                                                            <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>{items.name}</span></li>
                                                        )
                                                    })}
                                                    {/* <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Lateral thinking</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Logical</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Confident</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Intelligent</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Empathy</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Intelligent</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Charismatic</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Lateral thinking</span></li>
                                        <li className="btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center"><span>Self motivated</span></li> */}
                                                </ul>
                                            ) : null}

                                            <div className="d-flex flex-column">
                                                <a href={downloadpdfUrl} download target="_blank" className="associate-trait-btn btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center">
                                                    <img src={Downaloadpdf} alt="Download PDF" className="mr-2" />
                                                    <span>Download PDF</span></a>
                                                <button onClick={(e) => sendSummaryEmail(e)} className="associate-trait-btn btn px-4 py-2 mr-3 mt-3 border-2 border-color-white text-white btn-circle-30 text-center">
                                                    <img src={EmailTemplate} alt="email" className="mr-2" />
                                                    <span>
                                                        Email report
                                                    </span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 key-points">
                                        <div className="key-points-container">
                                            <h3 className="text-white">Insights and key points to consider</h3>
                                            <div className="d-flex row justify-content-between">
                                                <div className="col-md-6  pr-2">
                                                    <p className="text-white sub-title">The lumen score overall is a combination of all of the aspects of
                                                        workplace to give a generalised score.</p>
                                                    <ul className="pl-0 key-point-list">
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">90-100%</span>
                                                            <p className="col-lg-10 col-9 text-white"><span>Exceptional</span> - This score is very rare and demonstrates that you have a brilliant worker on your hands. This worker should be hired or promoted immediately.</p>
                                                        </li>
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">70-90%</span>
                                                            <p className="col-lg-10 col-9 text-white"><span>Excellent</span> - This is exceptional and demonstrates an exceptionally capable all-round worker who understands what effective workplace behaviours would be expected of them in this context.</p>
                                                        </li>
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">50-70%</span>
                                                            <p className="col-lg-10 col-9 text-white"><span>Very Good</span> - This demonstrates a capable worker, but there are inconsistencies in their application to identifying effective workplace behaviours in this context. It is suggested in this context that this worker should focus on completing their personalised learning and development in their Lumenai pathway and focus on retaking the Lumen.</p>
                                                        </li>
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">0-49%</span>
                                                            <p className="col-lg-10 col-9 text-white"><span>Concern</span> – This worker has either clicked through their questionnaire without thinking or does not really understand what effective workplace behaviours look like. It is suggested that this worker needs thorough workplace evaluation and the appropriate behavioural skills training.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <p className="text-white sub-title">Lumenai questionnaires measure 4 levels of candidate identification of effective workplace behaviours in different contexts.</p>
                                                    <ul className="pl-0 key-point-list">
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">Level 1</span>
                                                            <p className="col-lg-10 col-9 text-white">These questionnaires are good for talent identification or acquisition and are levelled for early school leavers, undergraduate level, long-term unemployed, English as a second language, prison leavers and those without extensive experience of the workplace.</p>
                                                        </li>
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">Level 2</span>
                                                            <p className="col-lg-10 col-9 text-white">These questionnaires are good for talent identification or acquisition, levelled for postgraduates without extensive experience of the workplace.</p>
                                                        </li>
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">Level 3</span>
                                                            <p className="col-lg-10 col-9 text-white">These questionnaires are good for talent identification or acquisition, levelled for employees or candidates with 5-10 years’ experience in the workplace, who are ready for greater levels of responsibility and promotion. You can also deploy these questionnaires for Level 1 and 2 candidates that you have a ‘hunch’ about and want to explore further.</p>
                                                        </li>
                                                        <li className="d-flex">
                                                            <span className="col-lg-2 col-3 px-0 text-white">Level 4</span>
                                                            <p className="col-lg-10 col-9 text-white">These questionnaires are good for talent identification or acquisition, at Senior Strategic level. These are levelled for employees or candidates with 10+ years’ experience in the workplace, who are already in, or ready for C-Suite roles, or Level 1 and 2 candidates that you have a ‘hunch’ about and want to explore further.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="skills-summary-report key-points-container">
                                            <div className="d-flex row justify-content-between">
                                                <div className="col-md-6 pr-2">
                                                    <p className="text-white sub-title">Behavioural Skills</p>
                                                    <ul className="pl-0 key-point-list list-unstyled">
                                                        <li>
                                                            <span className="col-md-2 px-0 text-white">Talented - Brilliant Expertise</span>
                                                            <p className="col-md-10 text-white px-0 mt-2">This is considered to be an above excellent benchmark of behavioural skill capability. This demonstrates that a candidate is independent and able to think on their toes, has the capability to ensure that others in the workforce will perform at their best too, and can also effectively draw these effective qualities together to strategically progress the day to day and overall objectives within this business context.</p>
                                                        </li>
                                                        <li>
                                                            <span className="col-md-2 px-0 text-white">Management Capability - Evaluative Expertise</span>
                                                            <p className="col-md-10 text-white px-0 mt-2">This is considered to be an excellent benchmark of behavioural skill capability, that indicates that the candidate is very effective, independent and able to think on their toes, whilst possessing the necessary behavioural skills to ensure that others in the workforce will perform at their best too.</p>
                                                        </li>
                                                        <li>
                                                            <span className="col-md-2 px-0 text-white">Company Benchmark Capability - Analytical Expertise</span>
                                                            <p className="col-md-10 text-white px-0 mt-2">This is considered to be a baseline benchmark of behavioural capability if you want a workforce that is independent, thinks on their toes and has strong analytical skills.</p>
                                                        </li>
                                                        <li>
                                                            <span className="col-md-2 px-0 text-white">Training Required – Context-based refresher required in this context</span>
                                                            <p className="col-md-10 text-white px-0 mt-2">This is a context-based assessment and the candidate needs training or a refresher on what effective workplace behaviours are in the context of this assessment.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 pr-0">
                                                    <p className="text-white sub-title">Summary Report</p>
                                                    <ul className="pl-0 key-point-list list-unstyled">
                                                        <li>
                                                            <span className="col-md-2 px-0 text-white summary-score-title">First attempt - {assessmentdetails.questionBankOverallPercentage} score ({
                                                                assessmentRange <= 100 && assessmentRange >= 90 ? "Exceptional"
                                                                    : assessmentRange <= 89 && assessmentRange >= 70 ? "Excellent"
                                                                        : assessmentRange <= 69 && assessmentRange >= 50 ? "Very Good" :
                                                                            "Concern"
                                                            })</span>
                                                            {/* <p className="col-md-10 text-white px-0 mt-2"> {usedetails && usedetails.name} is exceptional. He is a genius in strategic vision and goal setting contexts and should be hired or promoted immediately.</p> */}
                                                        </li>
                                                        {Array.isArray(talentCategory) && talentCategory.length ? (

                                                            <li>
                                                                <span className="col-md-2 px-0 text-white">Talented</span>
                                                                <p className="col-md-10 text-white px-0 mt-2">Brilliant expertise in

                                                                    <b> {
                                                                        talentCategory.map((items, index) => {
                                                                            return items.highlightCategoryTalentedSection ? items.categoryName + (talentCategory.length - 2 === index && talentCategory.length > 1 ? " and " : talentCategory.length > 1 && talentCategory.length !== index + 1 ? ", " : "") : null;
                                                                        })}</b>  in the context of <b>{assessmentdetails.questionBankName}</b>


                                                                </p>
                                                            </li>)
                                                            : null}
                                                        {Array.isArray(managementCategory) && managementCategory.length ? (
                                                            <li>
                                                                <span className="col-md-2 px-0 text-white">Management Capability</span>
                                                                <p className="col-md-10 text-white px-0 mt-2">Evaluative expertise in

                                                                    <b> {managementCategory.map((items, index) => {
                                                                        return items.highlightCategoryManagerialSection ? items.categoryName + (managementCategory.length - 2 === index && managementCategory.length > 1 ? " and " : managementCategory.length > 1 && managementCategory.length !== index + 1 ? ", " : "") : null;
                                                                    })}</b> in the context of <b>{assessmentdetails.questionBankName}</b></p>
                                                            </li>
                                                        ) : null}
                                                        {Array.isArray(coreCategory) && coreCategory.length ? (

                                                            <li>
                                                                <span className="col-md-2 px-0 text-white">Company Benchmark Capability</span>
                                                                <p className="col-md-10 text-white px-0 mt-2">Analytical expertise in
                                                                    <b> {coreCategory.map((items, index) => {
                                                                        return items.highlightCategoryCoreSection ? items.categoryName + (coreCategory.length - 2 === index && coreCategory.length > 1 ? " and " : coreCategory.length > 1 && coreCategory.length !== index + 1 ? ", " : "") : null;
                                                                    })}</b> in the context of <b>{assessmentdetails.questionBankName}.</b></p>
                                                            </li>
                                                        ) : null}
                                                        <li>
                                                            <span className="col-md-2 px-0 text-white">Training Needed</span>
                                                            {Array.isArray(trainingCategory) && trainingCategory.length ? (
                                                                <p className="col-md-10 text-white px-0 mt-2">
                                                                    <b> {trainingCategory.map((items, index) => {
                                                                        return items.categoryName ? items.categoryName + (trainingCategory.length - 2 === index && trainingCategory.length > 1 ? " and " : trainingCategory.length > 1 && trainingCategory.length !== index + 1 ? ", " : "") : null;
                                                                    })}</b> in the context of <b>{assessmentdetails.questionBankName}.</b></p>
                                                            ) : <p className="col-md-10 text-white px-0 mt-2">No training needed</p>}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="position-initial">
            <AssessmentCard icon={Images.assessment_icon_small} title={assessmentdetails.questionBankName !== undefined ? assessmentdetails.questionBankName : null} subtitle={"20 questions"} />
        </div> */}
                </div>
                <CategoryShowList />
                <div className="d-none d-md-block  position-fixed lumen-help fixed-lumen-help" style={{ bottom: '6rem' }}>
                    <HelpTip />
                    {/* <TipsPopup /> */}
                </div>
                {/* <div className="modal fade" id="historyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                content
                            </div>
                        </div>
                    </div>

                </div> */}
                <Modal show={modalShow} onHide={() => setModalShow(false)} className="modal-summary-new" size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header className="modal-header d-flex">
                        <div className="d-flex">
                            <img src={infinity} />
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalShow(false)}>
                            <img src={close_skill} />
                        </button>

                    </Modal.Header>
                    <Modal.Body className="modal-content">
                        <div className="assessment-history text-center mt-4  d-md-block">


                            <>
                                {assessmentdetailsHistory
                                    .slice(0, 3)
                                    .map((assessmentdetails, index) => {

                                        // console.log("assessment_successfully_completed",assessmentdetails.assessment_successfully_completed)
                                        return assessmentdetails.assessment_successfully_completed !== 0 ? null
                                            : assessmentdetails.attempt < 3 ? (
                                                <>
                                                    <div>
                                                        <SummaryPopupCard
                                                            data={assessmentdetails}
                                                            redirectUrl={{
                                                                pathname:
                                                                    "/me/history-detail/" +
                                                                    assessmentdetails.uuid,
                                                                state: {
                                                                    data: {
                                                                        assessmentdetails: assessmentdetails,
                                                                        assessmentdetailsCategory:
                                                                            categoryArrayArr[index],
                                                                    },
                                                                },
                                                            }}
                                                            statusText={"Completed " + moment(assessmentdetails.completed_at).format("DD/MM/YYYY")}
                                                            percentSymbol
                                                            icon={Images.assessment_white}
                                                            title={assessmentdetails.questionBankName}
                                                            percent={
                                                                assessmentdetails.questionBankOverallPercentage &&
                                                                assessmentdetails.questionBankOverallPercentage.slice(
                                                                    0,
                                                                    assessmentdetails
                                                                        .questionBankOverallPercentage
                                                                        .length - 1
                                                                )
                                                            }
                                                            categoryCorePercentage={

                                                                assessmentdetails.questionBankCorePercentage &&
                                                                assessmentdetails.questionBankCorePercentage.slice(
                                                                    0,
                                                                    assessmentdetails
                                                                        .questionBankCorePercentage.length - 1
                                                                )
                                                            }
                                                            categoryManagerialPercentage={

                                                                assessmentdetails.questionBankManagerialPercentage &&
                                                                assessmentdetails.questionBankManagerialPercentage.slice(
                                                                    0,
                                                                    assessmentdetails
                                                                        .questionBankManagerialPercentage
                                                                        .length - 1
                                                                )
                                                            }
                                                            categoryTalentedPercentage={
                                                                assessmentdetails.questionBankTalentedPercentage &&
                                                                assessmentdetails.questionBankTalentedPercentage.slice(
                                                                    0,
                                                                    assessmentdetails
                                                                        .questionBankTalentedPercentage
                                                                        .length - 1
                                                                )
                                                            }
                                                        />

                                                    </div>
                                                </>) : null
                                    })}
                            </>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default SummaryNew;
