import * as types from "../types/index";

const INITIAL_STATE = {
  data: { status: false },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.IS_AUTH:
      return {
        ...state,
        data: action.data,
      };
    case types.IS_LOGOUT:
      return {
        ...state,
        data: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
