import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function summary_new_email_action(token, groupId, questionBankId, attempt) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/assessment/${groupId}/${questionBankId}/${attempt}/summary/email`,
            method: "GET",
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token,
                groupId: groupId
            },
            body: "",
            types: [
                types.SUMMARY_NEW_EMAIL_REQUEST,
                types.SUMMARY_NEW_EMAIL_RECEIVE,
                {
                    type: types.SUMMARY_NEW_EMAIL_FAILURE,
                    meta: (action, state, res) => {
                        return action, state, res;
                    },
                },
            ],
        },
    };
}

export const resetSUMMARY_NEW_EMAILAPI_Action = () => {
    return {
        type: types.RESET_SUMMARY_NEW_EMAIL,
        payload: null,
    };
};
