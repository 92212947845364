import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
// redux
import {
  pathwayIncomplete_API_Action,
  reset_PathwayIncomplete_API_Action,
} from "../../stores/actions";

import { Colors, Images, StringConstants } from "../../config";
import {
  getData,
  setData,
  USER_DATA,
  TOKEN,
  SUMMARY_ATTEMPT,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";
import {
  CategoryCard,
  LearningCard,
  Loader,
  ProgressCount,
  HelpTip,
  SoftSkillCheckIn,
  TipsPopup,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { isMobile, isMobileOnly } from "react-device-detect";

const Path = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // states
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState();
  const [displayData, setDisplayData] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [progressValue, setProgressValue] = useState();
  const [pageNo, setPageNo] = useState("1");
  const [summaryAttempt, setSummaryAttempt] = useState();
  const getUiState = useSelector(state => state.getUiState);
  //selector
  const [selectedGroup, setSelectedGroup] = useState();
  const pathwayIncompleteData = useSelector((state) => state.pathwayIncomplete);

  // useEffect(() => {
  //   try {
  //     getData(TOKEN)
  //       .then((res) => {
  //         dispatch(pathwayIncomplete_API_Action(res.token));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);


  useEffect(() => {
    // if (getUiState.selectedGroup !== null) {
    //   getData(GROUP_DATA).then((groupdata) => {
    //     if (groupdata !== null) {
    //       setSelectedGroup(groupdata)
    //     }
    //   })
    // }

    if (getUiState.selectedGroup !== null && getUiState.selectedGroup.groupId !== selectedGroup?.groupId) {
      getData(TOKEN).then((res) => {
        getData(GROUP_DATA).then((groupdata) => {
          if (groupdata !== null) {
            setSelectedGroup(groupdata)
            __apiCall("")
          }
        })
      })
    }

  }, [getUiState])



  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        //pass data to next screen
        setUserData(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getData(SUMMARY_ATTEMPT)
      .then((res) => {
        //pass data to next screen
        setSummaryAttempt(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (pathwayIncompleteData.isFetching) {
      setLoader(true);
    } else if (
      pathwayIncompleteData.isSuccess &&
      pathwayIncompleteData.data !== null
    ) {
      setLoader(false);
      let dashboardData =
        pathwayIncompleteData &&
        pathwayIncompleteData.data &&
        pathwayIncompleteData.data.data;
      setDisplayData(dashboardData);
      setData(SUMMARY_ATTEMPT, dashboardData.upComingAssessment.attempt);
      __progressValue();
    } else if (pathwayIncompleteData.isError) {
      setErrorModel(true);
      setErrorDataModel(pathwayIncompleteData.data);
    }
  }, [pathwayIncompleteData]);

  // console.log("pathway", pathwayIncompleteData);

  const __redirectScrollTop = () => {
    window.scrollTo(0, 0);
  };
  const __progressValue = () => {
    // console.log("display",  hubData.data.data)
    let totalCountValue =
      pathwayIncompleteData.data.data.pathwayCompleteCount +
      pathwayIncompleteData.data.data.pathwayIncompleteCount;
    let totalProgressValue =
      pathwayIncompleteData.data.data.pathwayCompleteCount / totalCountValue;
    let finalvalue = Math.floor(totalProgressValue * 100);
    setProgressValue(finalvalue);
  };

  useEffect(() => {
    __apiCall("");
  }, []);

  useEffect(() => {
    __apiCall("");
  }, [pageNo]);

  const __apiCall = (data) => {
    try {
      getData(TOKEN)
        .then((res) => {
          // if (
          //   pathwayIncompleteData.isFetching === false &&
          //   pathwayIncompleteData.data === null
          // ) {
            getData(GROUP_DATA).then(
              (groupData) => {
              dispatch(pathwayIncomplete_API_Action(res.token, data, pageNo, groupData.groupId));

            })

          // dispatch(pathwayIncomplete_API_Action(res.token, data, pageNo));
          getData(SUMMARY_ATTEMPT).then((SUMMARY_ATTEMPT) => {
            // console.log("SUMMARY_ATTEMPT", SUMMARY_ATTEMPT);
            setSummaryAttempt(SUMMARY_ATTEMPT);
          });
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const noDataShowHub = () => {
    return (
      <div className=" mt-md-5  pt-md-5 pb-3 ">
        <Row className="align-items-center ">
          <Col md={8} className="pr-0">
            <div className="company-cnf-media ">
              <div className="cnf-icon d-sm-flex align-items-center">
                <img
                  src={Images.assessment_icon}
                  className="mr-md-4 mr-sm-2 icon-company-cnf"
                />

                <div className="cnf-media-content ">
                  {userData && (
                    <h4 className="text-color-white  font-size-18 font-family-ubuntu font-weight-bold line-height-22 mb-2">
                      {StringConstants.complete_assessment}{" "}
                      {userData.questionBankName[0]}
                    </h4>
                  )}
                  {displayData &&
                  displayData.upComingAssessment
                    .assessment_successfully_completed === 1 ? (
                    <p className="text-color-white font-size-15 font-family-ubuntu font-weight-medium line-height-22 mb-0">
                      {"You have successfully completed this lumen."}
                    </p>
                  ) : (
                    <p className="text-color-violet font-size-15 font-family-ubuntu font-weight-medium line-height-22 mb-0">
                      {StringConstants.skill_assessment}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4} className="ml-sm-4 ml-md-0">
            <div className="start_assessmet pl-sm-5 ml-sm-5 pl-md-0 ml-md-0 mt-2">
              {displayData && displayData.upComingAssessment.attempt === 1 ? (
                <Link
                  to="/initial-soft-skill"
                  onClick={__redirectScrollTop}
                  className="btn btn-text bg-violet text-white py-3 px-3 join-btn btn-circle-30"
                >
                  {StringConstants.start_assessment}
                </Link>
              ) : (
                <div
                  className={
                    displayData &&
                    displayData.upComingAssessment
                      .assessment_successfully_completed === 1
                      ? "disable allcomplete"
                      : "enable"
                  }
                >
                  <Link
                    // to="/assessment-detail"
                    to="/try-example"
                    onClick={__redirectScrollTop}
                    className="custome-btn btn btn-text bg-violet text-white py-3 px-3 join-btn btn-circle-30"
                  >
                    {StringConstants.start_assessment}
                  </Link>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const yourPathBlank = () => {
    return (
      <div className="your_pathway mb-5 mb-md-4 width-70 ">
        <img
          src={Images.infinity_white}
          className="icons img-white-icon d-md-none mb-2 pt-3 infinite-icon-for-mobile"
        />
        <div className="d-flex justify-content-between align-items-center progress-block">
          <p className="font-size-16 pathway-text font-family-ubuntu font-weight-medium line-height-22 text-color-white mb-3">
            {/* {StringConstants.Your_Pathway} */}
            Your Pathway is empty
          </p>
        </div>
        <div className="progress-path w-100">
          <div
            className="progress custom-progress-style"
            style={{ backgroundColor: "#000000" }}
          >
            <div
              className="progress-bar bg-Electric-Purple"
              style={{ width: `0%` }}
              role="progressbar"
              aria-valuenow={0}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const yourPath = () => {
    return (
      <div className="your_pathway mb-5 mb-md-4  mt-4">
        <img
          src={Images.infinity_white}
          className="icons img-white-icon d-md-none mb-2 pt-3 infinite-icon-for-mobile"
        />
        <div className="content">
          <p className="font-size-16 pathway-text font-family-ubuntu font-weight-medium line-height-22 text-color-white mb-3">
            {StringConstants.Your_Pathway}
          </p>
          <p className="font-size-13 pathway-text1 font-family-ubuntu font-weight-regular line-height-22 text-color-white mb-2">
            {StringConstants.path1}
          </p>
          <p className="font-size-13 pathway-text1 font-family-ubuntu font-weight-regular line-height-22 text-color-white mb-2">
            {StringConstants.path2}
          </p>
          <p className="font-size-13 pathway-text1 font-family-ubuntu font-weight-regular line-height-22 text-color-white mb-3">
            {StringConstants.path3}
          </p>
        </div>
        <div className="width-70">
        <div className="d-flex justify-content-between align-items-center progress-block">
          
        </div>
        <div className="progress-path w-100">
          <div className="progress custom-progress-style">
            <div
              className="progress-bar bg-Electric-Purple"
              style={{ borderRadius: "3px", width: `${progressValue}%` }}
              role="progressbar"
              aria-valuenow={progressValue}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
       

        {displayData && displayData.pathwayIncompleteCount === 0 ? (
          <div
            className={
              // displayData && displayData.upComingAssessment.attempt > 3 ||
              (displayData && displayData.pathwayIncompleteCount !== 0) ||
              (displayData && displayData.upComingAssessment.re_attempt === 0)
                ? "disable"
                : "enable"
            }
          >
            <div>{softskill()}</div>
          </div>
        ) : (
          <>
            <div className="progress-status grid-2box flex-wrap w-100 mt-4">
              <ProgressCount
                status="complete"
                count={displayData.pathwayCompleteCount}
                statusName={StringConstants.Done}
              />
              <ProgressCount
                count={displayData.pathwayIncompleteCount}
                statusName={StringConstants.left}
              />
            </div>
          </>
        )}
      </div>
      </div>
    );
  };


  const softskill = () => {
    return (
      <>
        {displayData && (
          // <div className={displayData.upComingAssessment && displayData.upComingAssessment.re_attempt === 0 ? ('soft-skill  mb-4 disable-box') : ('opcity-block-box soft-skill mb-4')}>
          <div
            className={
              (displayData && displayData.upComingAssessment.attempt > 3) ||
              (displayData && displayData.pathwayIncompleteCount !== 0) ||
              (displayData && displayData.upComingAssessment.re_attempt === 0)
                ? // displayData && displayData.upComingAssessment.attempt > 3 ||
                  // displayData && displayData.upComingAssessment.re_attempt === 0
                  "disable mt-3"
                : "enable mt-3"
            }
          >
            <Link
              // to="/assessment-detail"
              to="/try-example"
              className="d-md-inline-block opcity-block-box"
            >
              <SoftSkillCheckIn
                title={
                  displayData.upComingAssessment.length !== 0
                    ? displayData.upComingAssessment.questionBankName
                    : userData && userData.questionBankName[0]
                }
                subtitle={
                  displayData.upComingAssessment
                    .assessment_successfully_completed === 1
                    ? "You have successfully completed this lumen."
                    : displayData.pathwayIncompleteCount !== 0
                    ? "Complete your pathways to retry the Lumen"
                    : displayData.upComingAssessment.attempt <= 3
                    ? "Ready to Start"
                    : "You have completed your Lumen"
                  // displayData.upComingAssessment
                  // .assessment_successfully_completed === 1 &&  displayData.pathwayIncompleteCount === 0
                  // ? "You have successfully completed this lumen."
                  // : "Complete your pathways Or wait for 24hrs" ||
                  //   displayData.upComingAssessment.attempt > 3
                  // ? " Ready to Start "
                  // : "You have allow only 3 Attempt "
                }
              />
            </Link>
          </div>
        )}
      </>
    );
  };

  const activeLearning = () => {
    return (
      <div className="active-learning-box learning-box">
        <div className="d-flex justify-content-between align-items-center width-48 mb-0 mt-2">
          <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22  mb-3">
            {StringConstants.Active_learning}
          </p>
          <Link
            to="/hub/activity-learning"
            onClick={__redirectScrollTop}
            className="font-size-13 font-family-ubuntu  text-color-black font-weight-regular line-height-18  mb-2"
          >
            {StringConstants.view_all}
          </Link>
        </div>
        <div className="active-block pb-1 mb-4">
          {isMobileOnly
            ? displayData &&
              displayData.pathwayInCompleted.map((item, index) => {
                let activeLearningIcon = "";
                switch (item.pathwayCategories[0]) {
                  case "Problem Solving":
                    activeLearningIcon = Images.probsolving_whitecard;
                    break;
                  case "Critical Thinking":
                    activeLearningIcon = Images.criticalthinking_whitecard;
                    break;
                  case "Teamwork":
                    activeLearningIcon = Images.teamwork_whitecard;
                    break;
                  case "Communication":
                    activeLearningIcon = Images.communication_whitecard;
                    break;
                  case "Leadership":
                    activeLearningIcon = Images.leadership;
                    break;
                  case "Time Management":
                    activeLearningIcon = Images.timemgt_whitecard;
                    break;
                  case "Emotional Judgement":
                    activeLearningIcon = Images.emotionaljudge;
                    break;
                  case "Creativity":
                    activeLearningIcon = Images.creativity;
                    break;

                  default:
                    activeLearningIcon = Images.emotionaljudge;
                }

                return (
                  <LearningCard
                    status={item.userPathwayStatus}
                    data={item}
                    icon={activeLearningIcon}
                    title={item.pathwayTitle}
                    subtitle={item.pathwayCategories[0]}
                  />
                );
              })
            : displayData &&
              displayData.pathwayInCompleted.slice(0, 10).map((item, index) => {
                let activeLearningIcon = "";
                switch (item.pathwayCategories[0]) {
                  case "Problem Solving":
                    activeLearningIcon = Images.probsolving_whitecard;
                    break;
                  case "Critical Thinking":
                    activeLearningIcon = Images.criticalthinking_whitecard;
                    break;
                  case "Teamwork":
                    activeLearningIcon = Images.teamwork_whitecard;
                    break;
                  case "Communication":
                    activeLearningIcon = Images.communication_whitecard;
                    break;
                  case "Leadership":
                    activeLearningIcon = Images.leadership;
                    break;
                  case "Time Management":
                    activeLearningIcon = Images.timemgt_whitecard;
                    break;
                  case "Emotional Judgement":
                    activeLearningIcon = Images.emotionaljudge;
                    break;
                  case "Creativity":
                    activeLearningIcon = Images.creativity;
                    break;
                  case "Flexibility/Adaptability":
                    activeLearningIcon = Images.Flexibilty_active
                    break;

                  default:
                    activeLearningIcon = Images.infinity;
                }

                return (
                  <LearningCard
                    status={item.userPathwayStatus}
                    data={item}
                    icon={activeLearningIcon}
                    title={item.pathwayTitle}
                    subtitle={item.pathwayCategories[0]}
                  />
                );
              })}
        </div>
        <div className="text-center mb-5">
          <Link
            to="/pathway/learning-history"
            onClick={__redirectScrollTop}
            className="btn  view-learning-btn mt-2 border-2 btn-circle-30 font-weight-bold border-color-darkblue px-3 font-size-13 line-height-18 font-family-ubuntu  text-center"
          >
            {StringConstants.viewlearning}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      {loader === true && <Loader />}

      <div className="pathway-screen bg-aliceBlue before-curve-pathway headerFooter-minusHeight">
        <div className="container">
          <div className="row justify-content-md-center ">
            <div className="col-md-10 col-lg-8 mb-70">
              {displayData && displayData.pathwayInCompleted.length > 0 ? (
                <>
                  {yourPath()}
                  {activeLearning()}
                </>
              ) : (
                <>
                  {yourPathBlank()}
                  {noDataShowHub()}
                </>
              )}
            </div>
          </div>

          <div className=" position-fixed lumen-help fixed-lumen-help">
            <HelpTip />
            {/* <TipsPopup /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Path;
