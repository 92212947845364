import React,{useState} from 'react'
import { StringConstants } from '../../config'
import { Col, Container, Row } from "react-bootstrap";
import {InternetConnectivity } from "../../screens";
import { useLocation,useHistory } from 'react-router-dom';

const LumenReportLogin = (props) => {

    return (
        <>
      {!navigator.onLine && <InternetConnectivity />}
      
      <div className="login-screen bg-aliceBlue wave-section" style={{minHeight:'380px',height: 'calc(100vh - 208px)'}}>
        <Container>
          <Row className="pb-5 justify-content-sm-center">
            <Col md={8} className="p-0">
              
              <form onSubmit={props.onSubmitData}>
              {/* <form > */}
                <div className="wrpfrom-mobile">
                  <div className="heading-with-logo pt-5">
                    <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                      Please enter the report password
                    </h3>
                  </div>
                  <div className="cards-box lp-card-size  inside-space">
                    
                  <div className="form-group components-form field-style text-left">
                      <label htmlFor="password" className="font-size-18">
                        {StringConstants.Password}
                        
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        onChange={props.handleSetPassword}
                        required
                        autoComplete={'off'}
                      />
                     {props.showError && <span className="error">{props.showError}</span>}
                    </div>
                    
                  </div>
                </div>
                  <div className="text-right lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.Login}
                    </button>
                  </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      {/* footer of wave */}
      <div className="footer-wave  pb-5 position-relative">  
      </div>
      {/* fotoer wave end */}
    
        </>
    )
}

export default LumenReportLogin
