import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Breadcrumb,
} from "react-bootstrap";
import { Page400, Page500, InternetConnectivity } from "../../screens";
// redux

import { Colors, Images, StringConstants } from "../../config";
import { getData, USER_DATA, clearAllData, GROUP_DATA } from "../../utils/storage";
import {
  CategoryCard,
  LearningCard,
  Loader,
  Pagination,
  ProgressCount,
  TipsPopup,
  HelpTip,
  TopHeader,
  TopHeaderDesktop,
} from "../../components";

import {
  pathwayComplete_API_Action,
  reset_markPathwayComplete_API_Action,
  reset_PathwayComplete_API_Action,
} from "../../stores/actions";

import { TOKEN } from "../../utils/storage";
const LearningHistory = (props) => {
  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState();
  const [loader, setLoader] = useState();
  const [errorDataModel, setErrorDataModel] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [pageNo, setPageNo] = useState("1");
  const pathwayCompleteData = useSelector((state) => state.pathwayComplete);

  // useEffect(() => {
  //   searchPaywayByTitle("");
  // }, []);

  // const searchPaywayByTitle = (data) => {
  //   try {
  //     getData(TOKEN)
  //       .then((res) => {
  //         dispatch(pathwayComplete_API_Action(res.token, data));
  //       })
  //       .catch((error) => {
  //         // unable to fine user profile
  //         // console.log("ERROR GET STORAGE");
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    __apiCall("");
  }, []);

  useEffect(() => {
    __apiCall("");
  }, [pageNo]);

  const __apiCall = (data) => {
    try {
      getData(TOKEN)
        .then((res) => {
          // if (
          //   pathwayCompleteData.isFetching === false &&
          //   pathwayCompleteData.data === null
          // ) {
            getData(GROUP_DATA).then(
              (groupData) => {
              dispatch(pathwayComplete_API_Action(res.token, data, pageNo, groupData.groupId));

            })
          // dispatch(pathwayComplete_API_Action(res.token, data, pageNo));
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (pathwayCompleteData.isFetching) {
      setLoader(true);
    } else if (
      pathwayCompleteData.isSuccess &&
      pathwayCompleteData.data !== null
    ) {
      setLoader(false);
      let dashboardData =
        pathwayCompleteData &&
        pathwayCompleteData.data &&
        pathwayCompleteData.data.data;
      setDisplayData(dashboardData.pathwayCompleted);

      // console.log("dash", dashboardData);
    } else if (pathwayCompleteData.isError) {
      setErrorModel(true);
      setErrorDataModel(pathwayCompleteData.data);
    }
  }, [pathwayCompleteData]);

  const searchPaywayByTitle = (data) => {
    dispatch(reset_markPathwayComplete_API_Action());
    setDisplayData([]);
    setPageNo(1);
    __apiCall(data);
  };

  const Search = () => {
    return (
      <div className="learning-history-block mb-5 mb-md-4 width-70">
        <div className="d-flex justify-content-between align-items-center progress-block">
          <p className="font-size-16 pathway-text font-family-ubuntu font-weight-medium line-height-22  text-color-white mb-3">
            {StringConstants.personalies}
          </p>
        </div>

        <div className=" searchbar d-flex align-items-center">
          <input
            type="search"
            onChange={(e) => searchPaywayByTitle(e.target.value)}
            className=" py-3 search-field see w-100 "
            placeholder="Search Activities"
          />
        </div>
      </div>
    );
  };

  const activeLearning = () => {
    return (
      <div className="active-learning-box learning-box mb-5">
        <div className="d-flex justify-content-between align-items-center width-48 mb-0 mt-2">
          <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22  mb-3">
            {/* {StringConstants.Your_activities} */}
            {StringConstants.Completed_activities}
          </p>
        </div>
        <div className="active-block pb-1 mb-4 ">
          {displayData &&
            displayData.map((item, index) => {
              let activeLearningIcon = "";
              switch (item.pathwayCategories[0]) {
                case "Problem Solving":
                  activeLearningIcon = Images.probsolving_whitecard;
                  break;
                case "Critical Thinking":
                  activeLearningIcon = Images.criticalthinking_whitecard;
                  break;
                case "Teamwork":
                  activeLearningIcon = Images.teamwork_whitecard;
                  break;
                case "Communication":
                  activeLearningIcon = Images.communication_whitecard;
                  break;
                case "Leadership":
                  activeLearningIcon = Images.leadership;
                  break;
                case "Time Management":
                  activeLearningIcon = Images.timemgt_whitecard;
                  break;
                case "Emotional Judgement":
                  activeLearningIcon = Images.emotionaljudge;
                  break;
                case "Creativity":
                  activeLearningIcon = Images.creativity;
                  break;
                case "Flexibility/Adaptability":
                    activeLearningIcon = Images.Flexibilty_active
                    break;

                default:
                  activeLearningIcon = Images.infinity;
              }

              return (
                item.userPathwayStatus === 1 && (
                  <LearningCard
                    status={item.userPathwayStatus}
                    data={item}
                    icon={activeLearningIcon}
                    title={item.pathwayTitle}
                    subtitle={item.pathwayCategories[0]}
                  />
                )
              );
            })}
        </div>
      </div>
    );
  };

  const __pagination = () => {
    if (pathwayCompleteData && pathwayCompleteData.data === null) {
      return;
    }

    return (
      <Pagination
        ResponseData={
          pathwayCompleteData &&
          pathwayCompleteData.data.data.pathwayCompletedPagination
        }
        onSubmit={(res) => {
          __applyPage(res);
        }}
      />
    );
  };

  const __applyPage = (res) => {
    // console.log("res apply", res);
    setPageNo(res);
    dispatch(reset_PathwayComplete_API_Action());
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      {loader === true && <Loader />}

      <div className="active-learning-screen full-height bg-aliceBlue before-curve-pathway">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8 mb-70">
              <TopHeaderDesktop white />
              {Search()}
              {activeLearning()}
              {__pagination()}
            </div>
          </div>
        </div>
        <div className=" position-fixed lumen-help fixed-lumen-help">
          <HelpTip />
          {/* <TipsPopup /> */}
        </div>
      </div>
    </>
  );
};

export default LearningHistory;
