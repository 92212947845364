import React from "react";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../config";

const StaticText = (props) => {
  const __redirectScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="soft-skill-content">
        <div className="heading d-flex align-items-end justify-content-between mb-2">
          <div className="soft-skill-heading">
            {/* <h3
              className=" font-size-18 font-family-ubuntu font-weight-bold line-height-21  title mb-2"
              style={{ ...props }}
            >
              {props.series}
            </h3> */}
            <p className="mb-0 letter-space-085  font-size-16 font-family-ubuntu font-weight-bold line-height-22 text-color-aliceBlue mb sub-category" 
            style={{ ...props }}
            >
              {props.tr}
            </p>
          </div>

          <div className="lumenai-chart-slice">
            <img src={props.icon} className="img100" />
          </div>

        </div>
        <div className="description">
          <p className="font-weight-300 text-color-aliceBlue  font-size-15 line-height-17 font-family-ubuntu">
            {props.description}
          </p>
        </div>

      </div>
    </>
  );
};

export default StaticText;
