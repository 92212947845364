import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function preupload_media_action(token, req) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/file/pre-signed-url`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(req),
            types: [
                types.PREUPLOAD_REQUEST,
                types.PREUPLOAD_RECEIVE, {
                    type: types.PREUPLOAD_FAILURE,
                    meta: (action, state, res) => {
                        return (res);
                    }
                }]
        }
    }
}

export const reset_preupload_media_action = () => { 
    return {
        type: types.RESET_PREUPLOAD,
        payload: null
    }
};

