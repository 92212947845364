import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Images, StringConstants } from "../../config";
import { TextInput, TopHeader, TipsPopup } from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { signup_Action, reset_signup_Action } from "../../stores/actions";
import { useHistory , Link} from "react-router-dom";

import { getData, setData, TOKEN, USER_DATA } from "../../utils/storage";

const Signup_schema = yup.object().shape({
  first_name: yup
    .string()
    .trim("space are not allow")
    .required("Please enter first name"),
  last_name: yup
    .string()
    .trim("space are not allow")
    .required("Please enter last name"),
  mobile_number: yup
    .string()
    .min(10, "Mobile number must be at least 10 digits")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid."
    ),
  email: yup
    .string()
    .required("Please enter valid email id")
    .email("Please enter valid email id"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must contain at least 8 characters including one uppercase letter, one lowercase letter, and one number."
    )
    .required("Please enter password"),
  password_confirmation: yup
    .string()
    .required("Please confirm password")
    .oneOf([yup.ref("password"), null], "Password does not match"),
  tnc_check: yup.bool().oneOf([true], "Please accept terms and conditions"),
});

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const registerData = useSelector((state) => state.signup);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Signup_schema),
  });

  useEffect(() => {
    if (registerData.isFetching) {
      setLoader(true);
    } else if (registerData.isSuccess && registerData.data !== null) {
      setLoader(false);
      let data = registerData.data.data;
      setData(TOKEN, { token: data.token, expires_at: data.expires_at });
      setData(USER_DATA, data.user);

      window.location.href = window.location.origin;
      window.scrollTo(0, 0);
    } else if (registerData.isError) {
      setErrorModel(true);
      setErrorDataModel(registerData.data);
    }
  }, [registerData]);

  const onSubmitdata = useCallback((data) => {
   
    try {
      dispatch(signup_Action(data));

    } catch (e) {
      console.log(e);
    }
  });

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      <div className="signup-screen bg-aliceBlue wave-section pt-lg-5 pt-sm-4 pb-lg-3">
        <Container>
          <Row className="justify-content-sm-center">
            <Col md={10} lg={8}>
              <TopHeader />
              <div className="heading-with-logo">
                {/* {isDesktop && <TopHeaderDesktop />} */}
                <img
                  src={Images.infinity}
                  className="logos logo-md mb-4 d-none d-sm-block"
                />
                <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 ">
                  {StringConstants.CreatnewAccount}
                </h3>
              </div>
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <Row>
                  <Col sm={6}>
                    {/* <TextInput
                      id="fname"
                      name="first_name"
                      label={StringConstants.Fname}
                      {...register("first_name")}
                      showerror={errors.first_name?.message}
                    /> */}

                    <div className="form-group components-form field-style">
                      <label htmlFor="first_name">
                        {StringConstants.Fname}
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        id="first_name"
                        {...register("first_name")}
                      />
                      <span className="error">
                        {errors.first_name?.message}
                      </span>
                    </div>
                  </Col>
                  <Col sm={6}>
                    {/* <TextInput
                      id="Lname"
                      name="last_name"
                      label={StringConstants.Lname}
                      type="text"
                      {...register("last_name")}
                      showerror={errors.last_name?.message}
                    /> */}
                    <div className="form-group components-form field-style">
                      <label htmlFor="last_name">{StringConstants.Lname}</label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        id="last_name"
                        {...register("last_name")}
                      />
                      <span className="error">{errors.last_name?.message}</span>
                    </div>
                  </Col>
                  <Col sm={12}>
                    {/* <TextInput
                      id="email"
                      name="email"
                      label={StringConstants.EmailAddress}
                      type="email"
                      {...register("email")}
                      showerror={errors.email?.message}
                    /> */}

                    <div className="form-group components-form field-style">
                      <label htmlFor="email">
                        {StringConstants.EmailAddress}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        {...register("email")}
                      />
                      <span className="error">{errors.email?.message}</span>
                    </div>
                  </Col>
                  <Col sm={6}>
                    {/* <TextInput
                      id="password"
                      name="password"
                      label={StringConstants.Password}
                      type="password"
                      {...register("password")}
                      showerror={errors.password?.message}
                    /> */}
                    <div className="form-group components-form field-style">
                      <label htmlFor="password">
                        {StringConstants.Password}
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        autoComplete="off"
                        {...register("password")}
                      />
                      <span className="error">{errors.password?.message}</span>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="form-group components-form field-style">
                      <label htmlFor="password_confirmation">
                        {StringConstants.Cpassword}
                      </label>
                      <input
                        type="password"
                        name="password_confirmation"
                        className="form-control"
                        autoComplete="off"
                        id="password_confirmation"
                        {...register("password_confirmation")}
                      />
                      <span className="error">
                        {errors.password_confirmation?.message}
                      </span>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="form-group components-form field-style">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="mobile_number">
                          {StringConstants.Mobile}
                        </label>
                        <h4 className="font-size-11 font-family-ubuntu font-weight-300 text-color-black">
                          Optional
                        </h4>
                      </div>
                      <input
                        type="integer"
                        name="mobile_number"
                        className="form-control"
                        autoComplete="off"
                        id="mobile_number"
                        //{...register("mobile_number")}
                      />
                      <span className="error">
                        {errors.mobile_number?.message}
                      </span>
                    </div>
                  </Col>

                  <div className="w-100 footer-wave bg-violet mobile-view pb-4 position-relative">
                    <Col sm={12}>
                      <fieldset className="tnc-style">
                        <div className="form-check">
                          <label
                            title=""
                            htmlFor="formHorizontalCheck"
                            className="checkbox-container mb-0 font-size-13 font-family-ubuntu font-weight-medium text-color-violet line-height-18"
                          >
                            {/* {StringConstants.Register_Tnc} */}
                            For Terms and Conditions please see <Link to='#'
                              onClick={
                                ()=>(
                                  window.open( 
                                    "https://lumenai.tech/privacy-policy/", "_blank"))
                              }
                            > www.lumenai.tech</Link>
                            <input
                              type="checkbox"
                              id="formHorizontalCheck"
                              className="form-check-input"
                              name="tnc_check"
                              {...register("tnc_check")}
                            />
                            <span className="checkmark"></span>
                          </label>

                          {/* <input
                            type="checkbox"
                            id="formHorizontalCheck"
                            class="form-check-input"
                            name="tnc_check"
                            {...register("tnc_check")}
                          />
                          <label
                            title=""
                            htmlFor="formHorizontalCheck"
                            className="mb-0 font-size-13 font-family-ubuntu font-weight-medium text-color-violet line-height-18"
                          >
                            {StringConstants.Register_Tnc}{" "}
                          </label> */}
                        </div>
                        <span className="error">
                          {errors.tnc_check?.message}
                        </span>
                      </fieldset>

                      {registerData.isFetching ? (
                        <div className="align-box text-sm-right mt-2 mb-4">
                          <button
                            type="submit"
                            className="btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                          >
                            {StringConstants.Loading}
                          </button>
                        </div>
                      ) : (
                        <div className="align-box text-sm-right mt-2 mb-4">
                          <button
                            type="submit"
                            className="btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                          >
                            {StringConstants.Caccount}
                          </button>
                        </div>
                      )}
                      <span className="error">
                        {errorDataModel && errorDataModel.error.message}
                      </span>
                    </Col>
                  </div>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave py-4 position-relative d-none d-sm-block">
        <div className="position-absolute init-top-right d-none d-sm-block ">
          <TipsPopup />
        </div>
      </div>
      {/* fotoer wave end */}

      {/* <CompanyCodePopup /> */}
    </>
  );
};

export default Register;
