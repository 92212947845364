import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function organisation_group_details_action(groupId,token,organisId, search, tagId,per_page,page) {
    return {
      [RSAA]: {
        endpoint: `${API_ENDPOINT}/organisation/group/${groupId}/details?q=${search}&tagId=${tagId}&per_page=${per_page}&page=${page}`,
        method: "GET",
        headers: {
          ...AUTH_HEADERS,
          'Authorization': 'Bearer ' + token,
          organisationId: organisId
        },
  
        // body: JSON.stringify(data),
        types: [
          types.ORGANISATION_GROUP_DETAILS_REQUEST,
          types.ORGANISATION_GROUP_DETAILS_RECEIVE,
          {
            type: types.ORGANISATION_GROUP_DETAILS_FAILURE,
            meta: (action, state, res) => {
              return action, state, res;
            },
          },
        ],
      },
    };
  }
  
  export const reset_organisation_group_details_Action = () => {
    return {
      type: types.RESET_ORGANISATION_GROUP_DETAILS,
      payload: null,
    };
  };