import React, { useState } from "react";
import { Images } from "../config";

const TipsPopup = (props) => {
  const [toggle, setToggle] = useState(false);

  const __popupbox = () => {
    setToggle(!toggle);
  };

  return (
    <>
      {toggle === true ? (
        <>
          <div className="tips-block initial-postion">
            <div className="d-flex justify-content-between mb-2">
              {props.nextlabel ? (
                <h3 className="font-size-16 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0">
                  {props.nextlabel}
                </h3>
              ) : (
                <h3 className="font-size-20 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0">
                  What’s next?
                </h3>
              )}
              <button
                type="button"
                className="border-0 background-transparent "
                onClick={__popupbox}
              >
                <img src={Images.close_skill} className="close-icon" />
              </button>
            </div>
            {props.tips ? (
              <p className="font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0 pr-5">
                {props.tips}
              </p>
            ) : (
              <p className="font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0 pr-5">
                Tap me to find out what your next steps are and how you can
                progress in the app.
              </p>
            )}
            {props.closetext && (
              <button
                onClick={() => setToggle(!toggle)}
                className="background-transparent border-0 p-0 font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-blue mt-2 mb-0 pr-5"
              >
                {props.closetext}
              </button>
            )}
          </div>
          <button
            type="button"
            className="tips-button-size border-0 over-position"
            onClick={__popupbox}
          >
            {/* <img src={Images.infinity_white} className="logo-width img100" /> */}
            <img src={Images.lumenai_helper} className="logo-width img100" />
          </button>
        </>
      ) : (
        <button
          type="button"
          className="tips-button-size border-0 over-position"
          onClick={__popupbox}
        >
          <img src={Images.lumenai_helper} className="logo-width img100" />
        </button>
      )}
    </>
  );
};

export default TipsPopup;
