import React from "react";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../config";

const LearningCard = (props) => {
  // console.log("props.data", props.data);
  const __redirectScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {props.SkillCard === "skillcards" ? (
        <div
          className="active-learning core-skill  py-3 px-3"
          key={props.keyvalue}
        >
          <div className="card-decs w-100">
            <div className="d-flex contents align-items-center justify-content-between ">
              <div className="content-box d-flex align-items-center w-100">
                <div className="card-icon">
                  <img src={props.icon} className="img100" />
                </div>

                <div className=" ml-3 d-flex flex-column w-100">
                  <h3 className="font-size-16 font-family-ubuntu font-weight-bold line-height-18 text-trim title mb-1">
                    {props.title}{" "}
                  </h3>

                  <div className="bottom-box d-flex justify-content-between">
                    <p className="mb-0 font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-color-darkgray mb sub-category">
                      {props.completeDate}
                    </p>
                    {/* <Link to="/" className="mb-0 font-size-13 font-family-ubuntu font-weight-regular line-height-18 text-color-darkgray" style={{color:'#4C7EF3'}}>
                      {props.seeMoreText}
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
        {props.data.pathwayId !== null &&
          props.data.pathwayId !== undefined ? (
            <Link
              to={{
                pathname:
                  "/pathway/activity-details/" + props.data.pathwayId,
                state: { data: props.data },
              }}
              onClick={__redirectScrollTop}
            >
        <div className="active-learning  py-3 px-3" key={props.keyvalue}>
          <div className="card-decs w-100">
            <div className="d-flex contents align-items-center justify-content-between ">
             
                  <div className="inner-box1 d-flex align-items-center">
                    <div className="card-icon">
                      <img src={props.icon} className="img100" />
                    </div>
                    <div className="box-size ml-3">
                      <h3 className="font-size-16 font-family-ubuntu font-weight-bold line-height-18 text-trim title mb-1">
                        {props.title}{" "}
                      </h3>
                      <p className="mb-0 font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-color-darkgray mb sub-category">
                        {props.subtitle}
                      </p>
                    </div>
                  </div>
               

              <div className="inner-box2 text-center">
                {props.status === 1 ? (
                  <>
                    <img
                      className="check-icon icon"
                      src={Images.complete_checkbox}
                      alt="icon"
                    />
                    <p className="incomplete-text mb-0 font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-purple">
                      {StringConstants.Complete}
                    </p>
                  </>
                ) : (
                  <>
                    {/* <Link to="/pathway/complete" onClick={__redirectScrollTop}> */}
                    <img
                      className="check-icon icon"
                      src={Images.incomplete}
                      alt="icon"
                    />
                    <p className="incomplete-text mb-0 font-size-11 font-family-ubuntu font-weight-regular line-height-18 text-color-darkgray">
                      {StringConstants.Incomplete}
                    </p>
                    {/* </Link> */}
                  </>
                )}
              </div>


            </div>
          </div>
        </div>

        </Link>
        ) : null}
        </>
      )}
    </>
  );
};

export default LearningCard;
