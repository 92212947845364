import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    data: null,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.ANSWER_RESPONSE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.ANSWER_RESPONSE_RECEIVE:
                return {
                    ...state,
                    isFetching: false,
                    isSuccess: true,
                    data: action.payload.success,
                };
        case types.ANSWER_RESPONSE_FAILURE:
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    data: action.payload.response,
                };
        case types.RESET_ANSWER_RESPONSE:
                return {
                    ...state,
                    isFetching: false,
                    isError: false,
                    data: null,
                };
        default:
            return {
                ...state
            }
    }
}