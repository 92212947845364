import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function assessmentCongratulationAPI_Action(token,categoryId, groupId) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/assessment/${categoryId}/summary`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token,
                groupId: groupId
                },
            body: '',
            types: [
                types.ASSESSMENT_CONGRATULATION_REQUEST,
                types.ASSESSMENT_CONGRATULATION_RECEIVE, {
                    type: types.ASSESSMENT_CONGRATULATION_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetAssessmentCongratulationAPI_Action = () => {
    return {
        type: types.RESET_ASSESSMENT_CONGRATULATION,
        payload: null
    }
};
