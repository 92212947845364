import React, { useCallback, useRef, useEffect, useState, useLayoutEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { InputTags } from "react-bootstrap-tagsinput";
import { useDispatch, useSelector } from "react-redux";
import { UserCard, ModalContent, Pagination as PageComp, ChatBox } from "../../components";
import { UserDots, ButtonOn, ButtonOff } from "../../config/Images";
import { forget_password_link, generate_report_Action, organisation_details_action, organisation_group_details_action, resend_user_invite_Action, reset_generate_report_Action, reset_organisation_details_Action, reset_organisation_group_details_Action } from "../../stores/actions";
import { getData, GROUP_SELECTED, setData, TOKEN, USER_DATA } from "../../utils/storage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { organisation_create_tag_action, reset_organisation_create_tag_Action } from "../../stores/actions/organisation-create-tag-action";
import { organisation_delete_tag_action, reset_organisation_delete_tag_Action } from "../../stores/actions/organisation-delete-tag-action";
import { Link } from "react-router-dom";
import { delete_user_Action, resetdelete_user_Action } from "../../stores/actions/delete-user-action";
import { organisation_edit_tag_action, reset_organisation_edit_tag_Action } from "../../stores/actions/organisation-edit-tag-action";
import { tree } from "d3";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


const PeopleManager = () => {
    const [width, height] = useWindowSize()
    const [groupState, setGroupState] = useState("");
    const [actionState, setActionState] = useState(null);
    const [createTags, setCreateTags] = useState(true);
    const [tagsState, setTagsState] = useState("");
    const [tagAction, setTagAction] = useState();
    const [userAction, setUserAction] = useState();
    const [token, setToken] = useState();
    const [organisationId, setOrganisationId] = useState();
    const dispatch = useDispatch();
    const organisationDetails = useSelector((state) => state.organisationDetails);
    const [organisationData, setOrganisationData] = useState([]);
    const organisationGroupDetails = useSelector((state) => state.organisationGroupDetails);
    const [userData, setUserData] = useState([]);
    const [tagData, setTagData] = useState([]);
    const [tagName, setTagName] = useState([]);
    const [tagValue, setTagValue] = useState();
    const [handleEdit, setHandleEdit] = useState("");
    const [editTagValue, setEditTagValue] = useState("");
    const [testData, setTestData] = useState();
    const [license, setLicense] = useState(0);
    const [modalUserId, setModalUserId] = useState();
    const [searchVal, setSearchVal] = useState("");
    const [pagination, setPagination] = useState({});
    const [pageNo, setPageNo] = useState("1");
    const deletSelector = useSelector((state) => state.organisationDeleteTag);
    const [groupReportName, setGroupReportName] = useState("");
    const [tagReportName, setTagReportName] = useState("All Tags");
    const [generateReport, setGenerateReport] = useState(false);
    const [enabledEditTag, setEnableEditTag] = useState(false);
    const [groupNumberUser, setGroupNumberUser] = useState(0);
    const [reportId, setReportId] = useState("");
    const [allTagData, setAllTagData] = useState("");
    const editTagAction = useSelector((state) => state.organisationEditTag);
    const creatTagAction = useSelector((state) => state.organisationCreateTag);
    const generateReportState = useSelector((state) => state.generateReport);
    const deleteUserSelector = useSelector((state) => state.deleteUser);

    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');

    const [assesmentToggle, setAssesmentToggle] = useState(true);
    const [tagsToggle, setTagsToggle] = useState(true);
    const [reportToggle, setReportToggle] = useState(true);


    useEffect(() => {
        if (width < 992) {
            setAssesmentToggle(false)
            setTagsToggle(false)
            setReportToggle(false)
        } else {
            setAssesmentToggle(true)
            setTagsToggle(true)
            setReportToggle(true)
        }
    }, [width])



    const modalChange = (e, val, id) => {
        if (e.currentTarget.id !== undefined) {
            setUserAction(e.currentTarget.id);
            setModalUserId(id);
            if (val === "Resend invite" && id !== "") {
                dispatch(resend_user_invite_Action(token, id, groupState, organisationId));
            } else if (val === "delete user" && id !== "") {
                dispatch(delete_user_Action(token, id, groupState, organisationId));
            } else if (val === "Reset password" && id !== "") {
                let userdataVal = { email: id }
                dispatch(forget_password_link(userdataVal, token, organisationId));
            }
        } else {
            setUserAction();
        }
    }

    useEffect(() => {
        if (deleteUserSelector.isSuccess && deleteUserSelector.data !== null) {
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo));
            dispatch(resetdelete_user_Action());
        } else if (deleteUserSelector.isError) {
            alert(deleteUserSelector.data.error.message)
            dispatch(resetdelete_user_Action());
        }
    }, [deleteUserSelector])


    useEffect(() => {
        getData(TOKEN)
            .then((res) => {
                setToken(res.token);
                getData(USER_DATA)
                    .then((userRes) => {
                        setOrganisationId(userRes.companyId);
                        setLicense(userRes.companyNumberOfLicenses);
                        dispatch(organisation_details_action(res.token, userRes.companyId, searchVal, tagsState, 10, pageNo))
                    })
            })
    }, [])


    useEffect(() => {
        if (generateReportState.isSuccess && generateReportState.data !== null) {
            setGenerateReport(false)
            setMessage(generateReportState.data.message)
            setShowAlert(true)
            dispatch(reset_generate_report_Action())
        } else if (generateReportState.isError) {
            setGenerateReport(false)
            setMessage(generateReportState.data.error.message)
            setShowAlert(true)
            dispatch(reset_generate_report_Action())
        }
    }, [generateReportState])




    useEffect(() => {
        if (organisationDetails.isSuccess && organisationDetails.data !== null) {
            setOrganisationData(organisationDetails.data.data);
            setGroupState(groupState === "" ? organisationDetails.data.data?.defaultGroup.group.groupId : groupState);
            setGroupReportName(groupReportName === "" ? organisationDetails.data.data?.defaultGroup.group.groupName : groupReportName)
            setUserData(organisationDetails.data.data?.defaultGroup.groupUsers);
            setTagData(organisationDetails.data.data?.tags);
            // setTagData(organisationDetails.data.data?.defaultGroup.groupTagUserCount);
            setPagination(organisationDetails.data.data?.defaultGroup.pagination);
            setTestData(organisationDetails.data.data?.defaultGroup.questionBank);
            // setLicense(organisationDetails.data.data?.organisation.organisationLicenses);
            setGroupNumberUser(organisationDetails.data.data?.defaultGroup.group.groupNumberOfUsers);



            if (tagData && tagData.length > 0) {
                if (tagName.length === 0) {
                    tagData.map((items) => {
                        if (items.tagName !== undefined) {
                            setTagName(tagName => [...tagName, items.tagName]);
                        }
                    })
                }

            }
        } else if (organisationDetails.isError) {
            dispatch(reset_organisation_details_Action());
        } else {
            console.log("error");
        }
    }, [organisationDetails])


    useEffect(() => {
        if (tagData && tagData.length > 0 && reportId === "") {
            setReportId(tagData[0].tagId);
        }
    }, [tagData])



    useEffect(() => {
        if (groupState !== "") {
            getData(TOKEN).then((res) =>
                getData(USER_DATA)
                    .then((userres) => dispatch(organisation_group_details_action(groupState, res.token, userres.companyId, searchVal, tagsState, 10, pageNo)))
            )

        }
    }, [groupState])


    useEffect(() => {
        if (organisationGroupDetails.isSuccess && organisationGroupDetails.data !== null) {
            setGroupState(groupState === "" ? organisationGroupDetails.data.data?.defaultGroup.group.groupId : groupState);
            setUserData(organisationGroupDetails.data.data?.defaultGroup?.groupUsers);
            setTagData(organisationGroupDetails.data.data?.tags);
            // setTagData(organisationGroupDetails.data.data?.defaultGroup.groupTagUserCount);
            setTestData(organisationGroupDetails.data.data?.defaultGroup.questionBank);
            setPagination(organisationGroupDetails.data.data?.defaultGroup.pagination);
            // setLicense(organisationGroupDetails.data.data?.organisation.organisationLicenses);
            setGroupNumberUser(organisationGroupDetails.data.data?.defaultGroup.group.groupNumberOfUsers);
            if (tagData && tagData.length > 0) {
                if (tagName.length === 0) {
                    tagData.map((items) => {
                        if (items.tagName !== undefined) {
                            setTagName(tagName => [...tagName, items.tagName]);
                        }
                    })
                }

            }
            console.log(license, "license");

        } else if (organisationGroupDetails.isError) {
            dispatch(reset_organisation_group_details_Action());
        }
    }, [organisationGroupDetails]);




    useEffect(() => {
        if (deletSelector.isSuccess && deletSelector.data !== null) {
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo));
            // dispatch(reset_organisation_group_details_Action());
            dispatch(reset_organisation_delete_tag_Action());
        }
    }, [deletSelector])

    useEffect(() => {
        if (pageNo !== "1") {
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo))
        }
    }, [pageNo]);


    useEffect(() => {
        if (searchVal !== "") {
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo))
        } else {
            // dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, 1))
            dispatch(reset_organisation_group_details_Action())
        }
    }, [searchVal])

    useEffect(() => {
        setData(GROUP_SELECTED, { groupState: groupState, tagData: tagData });
    }, [groupState]);

    useEffect(() => {
        if (editTagAction.isSuccess && editTagAction.data !== null) {
            setEditTagValue("");
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo));
            dispatch(reset_organisation_edit_tag_Action());
        } else if (editTagAction.isError) {
            dispatch(reset_organisation_edit_tag_Action());
        }
    }, [editTagAction])

    useEffect(() => {
        if (creatTagAction.isSuccess && creatTagAction.data !== null) {
            setEditTagValue("");
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo));
            dispatch(reset_organisation_create_tag_Action());
        } else if (creatTagAction.isError) {
            alert(creatTagAction.data.error.message);
            dispatch(reset_organisation_create_tag_Action());
        }
    }, [creatTagAction])


    const __applyPage = (res) => {
        setPageNo(res);
        dispatch(reset_organisation_group_details_Action());
    };

    const handleGroupId = (element, data) => {
        setGroupState(element);
        setGroupReportName(data);
        setPageNo("1");
    }


    const deleteTag = (e) => {
        let tagID = e.currentTarget.getAttribute("data-attr");
        try {
            dispatch(organisation_delete_tag_action(token, organisationId, tagID));
            setTagAction(null);
        } catch {
            console.log("error");
        }
    }
    const editTag = (e, tagid) => {
        let value = e.currentTarget.getAttribute("data-attr");
        setEnableEditTag(true);
        setHandleEdit(tagid);
        setTagAction(null);
        setCreateTags(false);
        setEditTagValue(value);
        console.log("here");

    }

    // useEffect(() => {
    //     console.log(editTagValue);
    // }, [editTagValue])

    const handleSearch = (e) => {
        setSearchVal(e.target.value);
        // dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, 1))
    }


    const handleTagState = (val, name) => {
        if (val !== undefined) {
            setTagsState(val);
            setReportId(val);
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, val, 10, pageNo))
        } else {
            setTagsState("");
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, "", 10, pageNo))
        }
        setTagReportName(name)

    }

    const generateFullReport = () => {
        try {
            dispatch(generate_report_Action(token, organisationId, groupState, reportId));
        } catch {
            console.log("error");
        }
    }



    const tag_creation = yup.object().shape({
        name: yup
            .string()
            // .default(editTagValue !== "" ? editTagValue : "")
            .trim("space are not allow")
            .required("Please enter tag name"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(tag_creation),
    });


    useEffect(() => {
        if (editTagAction.isSuccess && editTagAction.data !== null) {
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo));
            setEditTagValue("");
            dispatch(reset_organisation_edit_tag_Action());
        } else if (editTagAction.isError) {
            dispatch(reset_organisation_edit_tag_Action());
        }
    }, [editTagAction])

    useEffect(() => {
        if (creatTagAction.isSuccess && creatTagAction.data !== null) {
            dispatch(organisation_group_details_action(groupState, token, organisationId, searchVal, tagsState, 10, pageNo));
            dispatch(reset_organisation_create_tag_Action());
        } else if (creatTagAction.isError) {
            dispatch(reset_organisation_create_tag_Action());
        }
    }, [creatTagAction])


    const onSubmitData = useCallback((data) => {
        try {
            if (editTagValue !== "" && enabledEditTag) {
                dispatch(organisation_edit_tag_action(token, organisationId, handleEdit, data))

            } else {
                dispatch(organisation_create_tag_action(token, organisationId, data))
            }
            setCreateTags(!createTags)

        } catch {
            console.log("error");
        }
    }, [editTagValue]);
    const __renderLinks = () => {
        return <>
            <div className="content-data">
                <a onClick={() => setGenerateReport(!generateReport)} className={generateReport ? "generate btn mr-2 btn-text bg-violet text-white py-3 px-4 register-btn btn-circle-30" : " btn mr-2 btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"}>
                    Create Report
                </a>
                {generateReport ? (
                    <div className="report-container">
                        <h3>Create report</h3>
                        <p>You are about to generate a report link for</p>
                        <div>
                            <span>{groupReportName}</span>
                            <span>{tagReportName}</span>
                        </div>
                        <div className="report-button-container d-flex justify-content-between">
                            <button className={generateReport ? "generate btn mr-2 btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30" : "btn mr-2 btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"} onClick={() => generateFullReport()} disabled={generateReportState.isFetching ? true : false}>{generateReportState.isFetching ? "Requesting" : "Generate"}</button>
                            <button className="btn mr-2 btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30" onClick={() => setGenerateReport(false)}>Cancel</button>
                        </div>
                    </div>
                ) : null}
            </div>
            <Link to={license !== undefined && groupNumberUser !== "" && license === groupNumberUser ? "#" : "/invite-users"} title={license} className={license !== undefined && groupNumberUser !== "" && license === groupNumberUser ? "btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30 disbaled-opacity" : "btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"}>
                Invite User
            </Link>
        </>
    }

    return (
        <div className="people-manager bg-aliceBlue">
            <Container>
                <Row className="d-flex justify-content-between align-items-center manager-header">
                    <Col md={12} lg={3}>
                        <div className="d-flex justify-content-space-between title">
                            <div className="flex-grow-1">
                                <h3>Your space</h3></div>
                            <button className="toggleReport" onClick={() => {
                                setReportToggle(width > 992 ? true : !reportToggle)
                                setGenerateReport(false)
                            }}>
                                {/* <span className="line" />
                                <span className="line" />
                                <span className="line" /> */}
                                {reportToggle ? (<img src={ButtonOn} />) : <img src={ButtonOff} />}

                            </button>
                        </div>
                    </Col>

                    <Col md={12} className={reportToggle ? "d-sm-block d-lg-none" : 'd-none'}>
                        <div className="d-flex justify-content-center generate-report pb-4">{
                            __renderLinks()
                        }</div>
                    </Col>
                    <Col md={12} lg={4} >
                        <form>
                            <input className="search" placeholder="Search by name" type="text" value={searchVal} onChange={handleSearch} />
                        </form>
                    </Col>
                    <Col md={4} className="d-none d-lg-block">
                        <div className="d-flex justify-content-sm-end generate-report">{
                            __renderLinks()
                        }</div>
                    </Col>
                </Row>

                {organisationDetails.isSuccess === true && organisationData.groups !== undefined ? (
                    <div className=" group-list-wpr">
                        {organisationData.groups.map((items, index) => {
                            return (<button onClick={() => handleGroupId(items.groupId, items.groupName)} className={'btn btn-transparent group-list ' + (items.groupId === groupState && 'active')}>
                                <span>{items.groupName}</span>
                            </button>)
                        })}
                    </div>
                ) : null}

                <Row>
                    <Col md={3}>
                        <div className="people-manage-notice">
                            <button className="btn btn-transparent btn-block" onClick={() => {
                                setAssesmentToggle(width > 992 ? true : !assesmentToggle)
                            }}><h3>Active assessment
                                </h3></button>
                            {testData ? (
                                <div className={"manage-card " + (assesmentToggle ? ' d-block' : ' d-none')} id={testData[0].questionBankId}>

                                    <h4>{testData[0].questionBankName}</h4>
                                    <span>{testData[0].questionBankApproxEffort}</span>
                                    <p dangerouslySetInnerHTML={{ __html: testData[0].questionBankDescription }} />
                                </div>
                            ) : null}

                        </div>



                        <div className="people-manage-notice tags-container">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="flex-grow-1">
                                    <button className="btn btn-transparent btn-block" onClick={() => {
                                        setTagsToggle(width > 992 ? true : !tagsToggle)
                                    }}><h3>Your tags</h3></button>
                                </div>
                                <div>
                                    <button className="btn btn-transparent" onClick={() => {
                                        setCreateTags(!createTags)
                                        setTagsToggle(true)
                                    }}><span className="tags-create">+ Create tags</span></button>
                                </div>

                            </div>
                            <div className={(tagsToggle ? ' d-block' : ' d-none')}>
                                {(createTags && tagData.length <= 0) ? (
                                    <div className="manage-card">
                                        <span>No tags</span>
                                        <p>Create tags to help group together your space e.g. department, location etc.</p>
                                        <button onClick={() => setCreateTags(true)} className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30">
                                            Create tags
                                        </button>
                                    </div>
                                ) : (
                                    createTags
                                ) ? (<ul className="tag-list-container">
                                    {tagData.map((items, index) => {
                                        return (
                                            <li id={items.tagId} className={items.tagId === tagsState ? "d-flex justify-content-between active" : index === 0 && tagsState === "" ? "d-flex justify-content-between active" : "d-flex justify-content-between"} >
                                                <button onClick={() => handleTagState(items.tagId, items.tagName)}>{items.tagName}</button>
                                                <div className="action-container">
                                                    <span className="mr-2"><strong>{items.tagUserCount}</strong> people</span>
                                                    {items.tagName !== 'All Tags' ? (
                                                        <button onClick={() => setTagAction(index)}>
                                                            <img src={UserDots} />
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <div className={index === tagAction ? "show" : "hide"} style={{ zIndex: 100 }}>
                                                    <div className="edit-tag-container">
                                                        <button data-attr={items.tagName} onClick={(e) => editTag(e, items.tagId)}>Edit tag</button>
                                                        <button data-attr={items.tagId} disabled={items.tagUserCount > 0 ? true : false} onClick={(e) => deleteTag(e)}>Delete tag</button>
                                                    </div>
                                                    <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(255, 255, 255, 0.3)" }} onClick={() => setTagAction(null)}></div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>) :
                                    <>
                                        {tagData && tagData.length > 0 ? (
                                            <>
                                                <form onSubmit={handleSubmit(onSubmitData)}>
                                                    <div className="form-group components-form field-style tags-form">
                                                        <label htmlFor="name">
                                                            enter tag name
                                                        </label>
                                                        <input
                                                            type="name"
                                                            name="name"
                                                            className="form-control"
                                                            id="name"
                                                            {...register("name")}
                                                            value={editTagValue !== "" ? editTagValue : ""}
                                                            onChange={(e) => setEditTagValue(e.target.value)}
                                                        />
                                                        <span className="error">{errors.name?.message}</span>
                                                    </div>
                                                    <button className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30" type="submit">save </button>
                                                </form>
                                            </>
                                        ) : null}
                                    </>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={9} className="users-list">
                        <div className="d-flex justify-content-between">
                            <span>Your people</span>
                            <span className="license"><strong>{groupNumberUser + "/" + license}</strong> licenses</span>
                            {/* <span className="license"><strong>{license && groupNumberUser ? groupNumberUser + "/" + license : groupNumberUser + "/" + license}</strong> licenses</span> */}
                        </div>
                        <div className={(userData && userData.length === 0) ? "people-content dotted" : "people-content"}>
                            {userData.length > 0 && organisationDetails.isSuccess ? (
                                <>
                                    <ul>
                                        {userData.map((items, index) => {
                                            return (
                                                <UserCard userData={items} onClick={(id) => setActionState(id)} actionState={actionState} userAction={setUserAction} modalAction={modalChange} />
                                            )
                                        })}
                                    </ul>
                                    {/* <Pagination ResponseData={pagination ? pagination : null }  onSubmit={(res) => { __applyPage(res); }} /> */}
                                    <PageComp ResponseData={pagination ? pagination : null} onSubmit={(res) => { __applyPage(res); }} />
                                </>
                            ) :
                                <div className="content position-relative ">
                                    <div className="position-absolute">
                                        <span>No people</span>
                                        <p>Add your employees and send them an invite to set up their profile</p>
                                        {/* <button className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30">
                                            Invite user
                                        </button> */}
                                        <Link to={license !== undefined && groupNumberUser !== "" && license === groupNumberUser ? "#" : "/invite-users"} className={license !== undefined && groupNumberUser !== "" && license === groupNumberUser ? "btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30 disbaled-opacity" : "btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"}>
                                            Invite users
                                        </Link>
                                    </div>
                                </div>}
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModalContent action={userAction} modalAction={modalChange} id={modalUserId} />
            {
                showAlert &&
                <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(255,255,255,0.5)" }}>
                    <Modal.Dialog>
                        <Modal.Body>
                            <p>{message}</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowAlert(false)
                            }>Close</Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                </div>
            }
            <ChatBox />
        </div>
    )
}

export default PeopleManager;