import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Container, Tooltip, OverlayTrigger,Modal } from "react-bootstrap";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import {
  FooterUIButton,
  TipsPopup,
  TopHeader,
  TopHeaderDesktop,
  CompanyCode,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { Images, StringConstants } from "../../config";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  company_code_Action,
  reset_company_code_Action,
} from "../../stores/actions";
import { clearAllData } from "../../utils/storage";
import { isLogout } from "../../stores/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { getData, setData, TOKEN, USER_DATA } from "../../utils/storage";

const Signup_schema = yup.object().shape({
  code: yup
    .string()
    .min(6, "Please enter 6 digits invite code")
    .max(6, "Please enter 6 digits invite code")
    .required("Please enter the company code"),
});

const CompanyCodePopup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] =useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const companyCodeData = useSelector((state) => state.companycode);
  const [email, setEmail] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(Signup_schema),
  });

  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        //pass data to next screen
        setEmail(res.email);
        console.log(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (companyCodeData.isFetching) {
      setLoading(true);
    } else if (companyCodeData.isSuccess && companyCodeData.data !== null) {
      window.scrollTo(0, 0);
      setLoading(false);
      if (
        companyCodeData.data !== null &&
        companyCodeData.data.data !== undefined
      ) {
        let data = companyCodeData.data.data;
        // console.log("data-----invite", data);
        setData(USER_DATA, data.user, () => {
          history.push({
            pathname: "/join-company",
            state: {
              email: email,
            },
          });
        });
        try {
          dispatch(reset_company_code_Action());
        } catch (e) {
          console.log(e);
        }
      } else {
        // console.log("company-data",companyCodeData.data)
        setErrorModel(true);
        setErrorDataModel(companyCodeData.data);
      }
    } else if (companyCodeData.isError) {
      setErrorModel(true);
      // console.log("error model", errorModel);
      setErrorDataModel(companyCodeData.data);
    }
  }, [companyCodeData]);

  const onSubmitCode = useCallback((data) => {
    try {
      getData(TOKEN)
        .then((res) => {
          // console.log("on submit IN USER");
          // console.log("on submit res",res);
          data.email = email;
          // console.log(data,typeof(data));
          dispatch(company_code_Action(res.token, data));
        })
        .catch((error) => {
          // unable to fine user profile
          // console.log("ERROR GET STORAGE");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  });

  const __redirectUrlTop = () => {
    window.scrollTo(0, 0);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

// logout code
  const logout = useCallback(() => {
    // console.log("logout");
    clearAllData();
    dispatch(isLogout());
    window.location.href = window.location.origin;
  });

  //popup logout
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={props.onHide}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                Sure, you want to log out
              </h3>
              <button
                className="btn mt-3  min-width-245 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30"
                onClick={logout}
              >
                Log out
              </button>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <button
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                onClick={props.onHide}
              >
                {StringConstants.skip_now}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }




  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      <div className="company-invite-box  bg-aliceBlue wave-section">
        <Container>
          <Row className="pb-5 justify-content-sm-center pt-md-5">
            <Col md={8} className="p-0">
              <div className="wrpfrom-mobile">
                <div className="cards-box lp-card-size  inside-space">
                  <div className="heading-with-logo0">
                    <h4 className="subtitle font-family-ubuntu text-color-violet font-weight-bold font-size-18 mb-2">
                      {StringConstants.invite_company}
                    </h4>
                    <p className="decs font-family-ubuntu line-height-20 text-color-violet font-weight-medium font-size-15 mb-4 pb-2">
                      {StringConstants.invite_company_decs}
                    </p>
                  </div>
                  <div className="company_code">
                    <div className="mb-4">
                      <h4 className="subtitle-sub font-family-ubuntu text-color-violet font-weight-bold font-size-16 mb-1">
                        Your registered email ID:
                      </h4>
                      <p className="email-text font-family-ubuntu text-color-blue font-weight-medium font-size-16 mb-2">
                        {email}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                    
                      <label className="companycode-name text-color-black line-height-18 font-size-13 font-family-ubuntu font-weight-medium mb-1">
                        {StringConstants.Company_code}
                      </label>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <img src={Images.info} className="info-icon" alt="" />
                      </OverlayTrigger>
                    </div>
                    <form onSubmit={handleSubmit(onSubmitCode)}>
                      <div className="code-box">
                        <input
                          type="text"
                          className="form-control company-codebox"
                          name="code"
                          {...register("code")}
                        />
                      </div>
                      <span className="error">
                        {errors.code && errors.code.message}
                      </span>
                      <p className="error">
                        {errorDataModel && errorDataModel.message}
                      </p>
                      {companyCodeData.isFetching ? (
                        <div className="mt-4 text-center companycodegrp-btn">
                          <button
                            type="submit"
                            className="btn btn-text bg-violet text-white py-3 px-3 btn-circle-30"
                          >
                            {StringConstants.Loading}
                          </button>
                        </div>
                      ) : (
                        <div className="mt-4 text-center companycodegrp-btn">
                          <button
                            type="submit"
                            className="btn btn-text bg-violet text-white py-3 px-3 btn-circle-30"
                          >
                            {StringConstants.Done}
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                  {/* <CompanyCode onClick={__redirectUrl} /> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave  pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-sm-left">
            <Col md={8} className="pt-sm-5">

            <div className="d-md-flex justify-content-between align-items-center">           
              <div className="boxs1">
              <p className=" mx-auto ml-sm-0 text-w-190 font-size-14 text-white font-family-ubuntu font-weight-regular mb-2">
                {StringConstants.Not_join}
              </p>
              <Link
                to="/request-access"
                onClick={__redirectUrlTop}
                className="btn mobile-w-243 min-width-178 px-4 min-width-178 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.req_access}
              </Link>
            </div>
              <Link
                onClick={() => setModalShow(true)}
                className="btn mobile-w-243 px-5 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center logout-"
              >
                Log out
              </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* fotoer wave end */}

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default CompanyCodePopup;
