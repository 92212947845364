import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AssessmentCard,
  CategoryCard,
  LearningCard,
  Loader,
  NestedPieChartProfile,
  TipsPopup,
  TopHeader,
  HelpTip,
  TopHeaderDesktop,
} from "../components";
import { BASEURL, Images, StringConstants } from "../config";
import { __redirectScrollTop } from "../utils/utility";

import {
  profileMeAPI_Action,
  resetAssessmentSummaryAPI_Action,
} from "../stores/actions";

import { Link, useHistory } from "react-router-dom";
import {
  getData,
  setData,
  TOKEN,
  SUMMARY_ATTEMPT,
  USER_DATA,
  clearAllData,
  GROUP_DATA,
} from "../utils/storage";

const ShareMyResult = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [categoryArray, setcategoryArray] = useState([]);
  const [categoryImageArray, setcategoryImageArray] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [attemptValue, setAttemptValue] = useState();
  const [shareData, setShareData] = useState();
  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
  const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
    useState([]);
  const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
    []
  );

  const profilemeData = useSelector((state) => state.profileme);
  const [shareResult, setShareResult] = useState({
    questionBankId: "",
    attempt: "",
    groupId: "",
  });

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          setBackgroundColorArraymanager([]);
          setBackgroundColorArraycore([]);
          setBackgroundColorArraytalent([]);

          getData(GROUP_DATA).then((groupData) => {
            dispatch(profileMeAPI_Action(res.token, groupData.groupId));
          })

          getData("USER_DATA").then((userData) => {
            // console.log("userdata",userData)
            setUpdateData(userData);
          });
          getData("SUMMARY_ATTEMPT").then((attemptcount) => {
            setAttemptValue(attemptcount);
          });
          getData(GROUP_DATA).then((groupId) => {
            setShareResult({ ...shareResult, groupId: groupId.groupId })
          })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);



  useEffect(() => {
    if (profilemeData.isFetching) {
      setLoader(true);
    } else if (profilemeData.isSuccess && profilemeData.data !== null) {
      setLoader(false);

      if (profilemeData.data.data.questionBankDetails !== null) {
        setShareResult({ ...shareResult, attempt: profilemeData.data.data.questionBankDetails.attempt, questionBankId: profilemeData.data.data.questionBankDetails.questionBankUuid });
      }




      if (profilemeData.data.data.categoryArr !== undefined) {
        setcategoryArray(profilemeData.data.data.categoryArr);
        let newmanagrArray = [];
        let newcoreArray = [];
        let newtalentArray = [];
        let sortedcatArray = profilemeData.data.data.categoryArr;

        sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
        setShareData(sortedcatArray);
        sortedcatArray.map((item, index) => {
          if (item.highlightCategoryCoreSection === true) {
            newcoreArray.push("#48FFFF");
          } else {
            newcoreArray.push("#381474");
          }
          if (item.highlightCategoryManagerialSection === true) {
            newmanagrArray.push("#275EFF");
          } else {
            newmanagrArray.push("#381474");
          }
          if (item.highlightCategoryTalentedSection === true) {
            newtalentArray.push("#F904FF");
          } else {
            newtalentArray.push("#381474");
          }
        });

        setBackgroundColorArraycore(newcoreArray);
        setBackgroundColorArraymanager(newmanagrArray);
        setBackgroundColorArraytalent(newtalentArray);
        // for (let i = 0; i < 8; i++) {
        //   if (i < newmanagrArray.length) {
        //     backgroundColorArraymanager.push(newmanagrArray[i]);
        //     backgroundColorArraycore.push(newcoreArray[i]);
        //     backgroundColorArraytalent.push(newtalentArray[i]);
        //   } else {
        //     backgroundColorArraymanager.push("#381474");
        //     backgroundColorArraycore.push("#381474");
        //     backgroundColorArraytalent.push("#381474");
        //   }
        // }
      }
    } else if (profilemeData.isError) {
      setErrorModel(true);
      setErrorDataModel(profilemeData.data);
    }
  }, [profilemeData]);

  useEffect(() => {
    console.log(shareResult);
    setData("SHARE_RESULT", shareResult)

  }, [shareResult])

  const message = () => {
    window.open(`${BASEURL}/s/${updateData.share_lumen_code}`, "_blank");
  };

  return (
    <>
      {loader === true && <Loader />}
      <div className="bg-aliceBlue share-screens ">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8">
              <div className="mobile-bg-transparent">
                <TopHeader black />
              </div>
              <div className="d-none d-md-block">
                <TopHeaderDesktop black />
              </div>
              <div className="summary-decs">
                <h3 className="congrats-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                  {StringConstants.Your_Lumen}
                </h3>
                <p className="congrats-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                  {StringConstants.we_have}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-wave pb-5 summary-wave share-blue-screen">
        <div className="container">
          <div className="row justify-content-md-center pt-3 pt-md-5">
            <div className="col-md-6 col-lg-4 mob-order-2">
              <div className="pie-chart">
                {profilemeData.data && profilemeData.data.data.categoryArr ? (
                  <NestedPieChartProfile
                    backgroundColorArraycore={backgroundColorArraycore}
                    backgroundColorArraymanager={backgroundColorArraymanager}
                    backgroundColorArraytalent={backgroundColorArraytalent}
                    categoryImageArray={categoryImageArray}
                    catgoryArray={
                      profilemeData.data && profilemeData.data.data.categoryArr
                    }
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3 d-flex align-items-center ">
              {updateData && updateData.share_lumen_code !== null ? (
                <div className="category-progress-summary text-center text-md-left mt-5 pb-2 mx-auto my-md-auto">
                  <p className=" lumen font-size-16 line-height-22 font-family-ubuntu font-weight-medium mb-2">
                    {StringConstants.share_via}
                  </p>
                  <a
                    href={`${BASEURL}/s/${updateData.share_lumen_code}`}
                    target={"_blank"}
                  >
                    <h4 className="lumenai-com-uniquecode text-center font-family-ubuntu text-md-left ">
                      {/* { updateData &&
                       `${process.env.REACT_APP_BASEURL}/s/${updateData.share_lumen_code}`
                    } */}
                      {updateData &&
                        `lumenai.com/s/${updateData.share_lumen_code}`}
                    </h4>
                  </a>

                  <button
                    onClick={message}
                    className="btn  font-family-ubuntu  px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.Share_my_lumen}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="d-none d-md-block  position-fixed lumen-help fixed-lumen-help">
        <HelpTip />
        {/* <TipsPopup /> */}
      </div>
    </>
  );
};

export default ShareMyResult;
