import React from 'react';
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import registerServiceWorker from './serviceworker/registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import reducers from './stores/reducers/'
import thunk from 'redux-thunk'; //Thunk middleware for Redux
import { Provider } from 'react-redux';
import AppNavigation from './navigation/AppNavigation';
/**
 *
 *TODO:
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


/**
 * Out of the box, the Redux store can only support the synchronous flow of data.
 * Using middleware like thunk helps support asynchronicity in a Redux application.
 * You can think of thunk as a wrapper for the store’s dispatch() method; rather than returning action objects,
 * we can use thunk action creators to dispatch functions or Promises.
 * Note that without thunks, synchronous dispatches are the default.
 */
const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(apiMiddleware, thunk)
    )
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppNavigation />
        </BrowserRouter>
    </Provider>,
    document.getElementById('lumenai'));
registerServiceWorker();
