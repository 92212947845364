import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function organisation_edit_tag_action(token, organisId, tagId, data) {
    return {
      [RSAA]: {
        endpoint: `${API_ENDPOINT}/organisation/tag/${tagId}/edit`,
        method: "POST",
        headers: {
          ...AUTH_HEADERS,
          'Authorization': 'Bearer ' + token,
          organisationId: organisId
        },
  
        body: JSON.stringify(data),
        types: [
          types.ORGANISATION_EDIT_TAG_REQUEST,
          types.ORGANISATION_EDIT_TAG_RECEIVE,
          {
            type: types.ORGANISATION_EDIT_TAG_FAILURE,
            meta: (action, state, res) => {
              return action, state, res;
            },
          },
        ],
      },
    };
  }
  
  export const reset_organisation_edit_tag_Action = () => {
    return {
      type: types.RESET_ORGANISATION_EDIT_TAG,
      payload: null,
    };
  };