import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function organisation_admin_detail_action(token, organisId) {
    return {
      [RSAA]: {
        endpoint: `${API_ENDPOINT}/organisation/admin-details`,
        method: "GET",
        headers: {
          ...AUTH_HEADERS,
          'Authorization': 'Bearer ' + token,
          organisationId: organisId
        },
  
        // body: JSON.stringify(data),
        types: [
          types.ORGANISATION_ADMIN_DETAILS_REQUEST,
          types.ORGANISATION_ADMIN_DETAILS_RECEIVE,
          {
            type: types.ORGANISATION_ADMIN_DETAILS_FAILURE,
            meta: (action, state, res) => {
              return action, state, res;
            },
          },
        ],
      },
    };
  }
  
  export const reset_organisation_admin_details_Action = () => {
    return {
      type: types.RESET_ORGANISATION_ADMIN_DETAILS,
      payload: null,
    };
  };