import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  TopHeaderDesktop,
  TextInputReadonly,
  HelpTip,
} from "../../components";
import {
  deleteAccount_API_Action,
  reset_deleteAccount_API_Action,
} from "../../stores/actions";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  editAccount_API_Action,
  reset_editAccount_API_Action,
} from "../../stores/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { USER_DATA, setData, getData, TOKEN } from "../../utils/storage";
import { clearAllData } from "../../utils/storage";
const Editaccountdetails_schema = yup.object().shape({
  first_name: yup
    .string()
    .trim("space are not allow")
    .required("Please enter first name"),
  last_name: yup
    .string()
    .trim("space are not allow")
    .required("Please enter last name"),
});

const EditAccountDetails = (props) => {
  const editAccountDetailsData = useSelector(
    (state) => state.editaccountdetails
  );
  const DeleteAccount = useSelector((state) => state.deleteaccount);

  const [userData, setUserData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState();
  const [displayData, setDisplayData] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Editaccountdetails_schema),
  });

  useEffect(() => {
    try {
      getData(USER_DATA)
        .then((res) => {
          // console.log("userdata1", res);
          setUserData(res);
          //pass data to next screen
        })
        .catch((error) => {
          // unable to fine user profile
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const deleteAcc = useCallback(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          // console.log("resxx", res);
          //pass data to next screen
          dispatch(deleteAccount_API_Action(res.token));
        })
        .catch((error) => {
          // unable to fine user profile
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    //console.log("editAccountDetailsData", editAccountDetailsData);

    if (editAccountDetailsData.isFetching) {
      setLoader(true);
    } else if (
      editAccountDetailsData.isSuccess &&
      editAccountDetailsData.data !== null
    ) {
      setLoader(false);
      let setMassage = editAccountDetailsData && editAccountDetailsData.data;
      setDisplayData(setMassage);
      //console.log("after edit", editAccountDetailsData.data.data.user);
      setData(USER_DATA, editAccountDetailsData.data.data.user);
      setUserData(editAccountDetailsData.data.data.user);
      dispatch(reset_editAccount_API_Action());
      window.location.replace("/settings");
    } else if (editAccountDetailsData.isError) {
      setErrorModel(true);
      setErrorDataModel(editAccountDetailsData.data);
    }
  }, [editAccountDetailsData]);

  useEffect(() => {
    if (DeleteAccount.isFetching) {
      setLoader(true);
    } else if (DeleteAccount.isSuccess && DeleteAccount.data !== null) {
      setLoader(true);
      setModalShow(false);
      setLoader(false);
      clearAllData();
      window.location.href = window.location.origin;
    } else if (DeleteAccount.isError) {
      setErrorModel(true);
      setErrorDataModel(DeleteAccount.data);
    }
  }, [DeleteAccount]);

  const onSubmit = (data) => {
    try {
      getData(TOKEN)
        .then((res) => {
          //pass data to next screen
          dispatch(editAccount_API_Action(res.token, data));
        })
        .catch((error) => {
          // unable to fine user profile
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={props.onHide}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                {StringConstants.Delete_Account}
              </h3>
              <button
                className="btn mt-3  min-width-245 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30"
                onClick={() => {
                  deleteAcc();
                }}
              >
                Delete
              </button>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <button
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                onClick={props.onHide}
              >
                {StringConstants.skip_now}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //console.log("setMassage", displayData && displayData.message);
  return (
    <>
      <div className="edit-profile bg-aliceBlue wave-section pt-lg-5 pt-md-4 pb-lg-3">
        <TopHeader />
        {!navigator.onLine && <InternetConnectivity />}

        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0">
            <Col md={10} lg={8}>
              <div className="d-none d-md-block">
                <TopHeaderDesktop black redirectHub="/settings" />
              </div>

              <div className="heading-with-logo mt-3 mt-md-0 d-md-none">
                <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                  {StringConstants.edit_your}
                </h3>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {userData && (
                  <Row>
                    <Col sm={6}>
                      <div className="form-group components-form field-style">
                        <label htmlFor="first_name">
                          {StringConstants.Fname}
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          defaultValue={userData.first_name}
                          id="first_name"
                          onChange={(e) => {
                            return e.target.value;
                          }}
                          {...register("first_name")}
                        />
                        <span className="error">
                          {errors.first_name?.message}
                        </span>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="form-group components-form field-style">
                        <label htmlFor="last_name">
                          {StringConstants.Lname}
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          defaultValue={userData.last_name}
                          className="form-control"
                          id="last_name"
                          {...register("last_name")}
                        />
                        <span className="error">
                          {errors.last_name?.message}
                        </span>
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group components-form field-style">
                        <label htmlFor="email">
                          {StringConstants.EmailAddress}
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={userData.email}
                          readOnly
                          className="form-control"
                          id="email"
                          {...register("email")}
                        />
                        <span className="error">{errors.email?.message}</span>
                      </div>
                    </Col>

                    <Col sm={12}>
                      <p className="error">
                        {errorDataModel && errorDataModel.error.message}
                      </p>
                      <p className="font-family-ubuntu text-color-violet font-weight-medium font-size-20 ">
                        {displayData && displayData.message}
                      </p>
                      {editAccountDetailsData.isFetching ? (
                        <div className="align-box text-sm-right mt-2 mb-4">
                          <Button
                            type="submit"
                            className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                          >
                            {StringConstants.Loading}
                          </Button>
                        </div>
                      ) : (
                        <div className="align-box text-sm-right mt-2 mb-4">
                          <Button
                            type="submit"
                            className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                          >
                            {StringConstants.Save}
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave bg-violet pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-md-left">
            <Col sm={8} className="pt-sm-5">
              <Link
                onClick={() => setModalShow(true)}
                className="btn mobile-w-243 min-width-178 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.Delete_account}
              </Link>
            </Col>
          </Row>
          <div className="position-absolute init-top-right d-none d-sm-block">
            <HelpTip />
            {/* <TipsPopup /> */}
          </div>
        </Container>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* fotoer wave end */}
    </>
  );
};

export default EditAccountDetails;
