import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  request_access_Action,
  reset_request_access_Action,
} from "../../stores/actions";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { Images, StringConstants } from "../../config";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  TopHeaderDesktop,
} from "../../components";
import {
  getData,
  setData,
  TOKEN,
  USER_DATA,
  clearAllData,
} from "../../utils/storage";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Requestaccess_schema = yup.object().shape({
  first_name: yup
    .string()
    .trim("space are not allow")
    .required("Please enter first name"),
  department: yup.string().required("Please enter department"),
  job_title: yup.string().required("Please enter job title"),
  company: yup.string().required("Please enter Company Name"),

  last_name: yup
    .string()
    .trim("space are not allow")
    .required("Please enter last name"),
  email: yup
    .string()
    .required("Please enter valid email id")
    .email("Please enter valid email id"),
  // company: yup
  //   .string()
  //   .min(3)
  //   .required("Please enter min 3 character company name"),
});

const RequestAccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorModel, setErrorModel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [errorDataModel, setErrorDataModel] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [emailstring, setEmailString] = useState();
  const requestaccessData = useSelector((state) => state.requestAccess);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Requestaccess_schema),
  });

  useEffect(() => {
    // console.log("requestaccessData--", requestaccessData);
    if (requestaccessData.isFetching) {
      setLoader(true);
    } else if (requestaccessData.isSuccess && requestaccessData.data !== null) {
      setLoader(false);
      // setShowMessage("Thank you");
      setModalShow(true);

      dispatch(reset_request_access_Action());
      // let requestData = requestaccessData.data.data;
    } else if (requestaccessData.isError) {
      setErrorModel(true);
      setErrorDataModel(requestaccessData.data);
    }
  }, [requestaccessData]);

  const onSubmitData = useCallback((data) => {
    try {
      // console.log("up",data)
      getData(TOKEN)
        .then((res) => {
          // console.log("LOGGED IN USER");
          // console.log("res token",res.token);
          dispatch(request_access_Action(res.token, data));
        })
        .catch((error) => {
          // unable to fine user profile
          // console.log("ERROR GET STORAGE");
          console.log(error);
        });
      //
      // console.log("data",data)
    } catch (e) {
      console.log(e);
    }
  });

  const _clearTokenData = () => {
    window.scrollTo(0, 0);
    clearAllData();
    window.location.href = window.location.origin;
    // history.push('/login')
  };

  //popup

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                // onClick={props.onHide}
                onClick={_clearTokenData}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                {StringConstants.Thanks}
              </h3>
              <p className="px-2 mt-4 mb-3 font-size-15 family-font-ubuntu font-weight-medium line-height-22 text-color-violet">
                Your access code will be sent to you shortly.
              </p>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <button
                onClick={_clearTokenData}
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.Done}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      <div className="request-access bg-aliceBlue wave-section pt-lg-5 pt-md-4 pb-lg-3">
        <TopHeader />
        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0">
            <Col md={10} lg={8}>
              <TopHeaderDesktop />
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="heading-with-logo mt-3 mt-md-0 d-md-none">
                  <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left line-height-18">
                    {StringConstants.Request}
                  </h3>
                </div>
                <Row>
                  <Col sm={6}>
                    <div className="form-group components-form field-style">
                      <label htmlFor="first_name">
                        {StringConstants.Fname}
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        id="first_name"
                        {...register("first_name")}
                      />
                      <span className="error">
                        {errors.first_name?.message}
                      </span>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="form-group components-form field-style">
                      <label htmlFor="last_name">{StringConstants.Lname}</label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        id="last_name"
                        {...register("last_name")}
                      />
                      <span className="error">{errors.last_name?.message}</span>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group components-form field-style">
                      <label htmlFor="email">
                        {StringConstants.EmailAddress}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        {...register("email")}
                      />
                      <span className="error">{errors.email?.message}</span>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="form-group components-form field-style">
                      <label htmlFor="job_title">{StringConstants.job}</label>
                      <input
                        type="text"
                        name="job_title"
                        className="form-control"
                        id="job_title"
                        {...register("job_title")}
                      />
                      <span className="error">{errors.job_title?.message}</span>
                    </div>
                  </Col>

                  <Col sm={6}>
                    <div className="form-group components-form field-style">
                      <label htmlFor="department ">{StringConstants.dep}</label>
                      <input
                        type="text"
                        name="department"
                        className="form-control"
                        id="department "
                        {...register("department")}
                      />
                      <span className="error">
                        {errors.department?.message}
                      </span>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group components-form field-style">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="company">
                          {StringConstants.company}
                        </label>
                      </div>

                      <input
                        type="text"
                        name="company"
                        className="form-control"
                        id="company"
                        {...register("company")}
                      />
                      <span className="error">{errors.company?.message}</span>
                    </div>

                    <p className="error">
                      {errorDataModel && errorDataModel.error.message}
                    </p>
                  </Col>
                  <Col sm={12}>
                    {requestaccessData.isFetching ? (
                      <div className="align-box text-sm-right mt-2 mb-4">
                        <button
                          type="submit"
                          className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                        >
                          {StringConstants.Loading}
                        </button>
                      </div>
                    ) : (
                      <div className="align-box text-sm-right mt-2 mb-4">
                        <button
                          type="submit"
                          className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                        >
                          {StringConstants.Request}
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
      <MyVerticallyCenteredModal show={modalShow} />

      {/* footer of wave */}
      <div className="footer-wave  py-4 position-relative d-none d-md-block"></div>
      {/* fotoer wave end */}
    </>
  );
};

export default RequestAccess;
