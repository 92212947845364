import React from "react";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../config";

const HelpTip = (props) => {
  return (
    <>
      <button
        type="button"
        className="tips-button-size border-0 over-position"
        onClick={() => (window.location.href = "/pathway/soft-skill-explained")}
      >
        <img src={Images.lumenai_helper} className="logo-width img100" />
      </button>
      {/* <TipsPopup /> */}
    </>
  );
};
export default HelpTip;
