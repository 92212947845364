import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import App from "../App";
import { isAuthenticated } from "../stores/actions";
import {
  Page400,
  Login,
  ForgotPassword,
  ResetPassword,
  ShareLumen,
  LumenReport,
  CreateAdminUser,
} from "../screens";
import { getData, setData, TOKEN, USER_DATA } from "../utils/storage";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";


const AppNavigation = (props) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const isLoggedIn = useSelector((state) => state.auth.data);

  useEffect(() => {
    // check if user is already logged in

    getData(TOKEN)
      .then((res) => {
        getData(USER_DATA).then((userRes) => {
          setUserData(userRes);
          dispatch(
            isAuthenticated({
              status: true,
              token: res.token,
              expires_at: res.expires_at,
            })
          );
        });
      })
      .catch((error) => {
        // unable to fine user profile
        dispatch(isAuthenticated({ status: false }));
      });
  }, []);

  const ReloadComponent = () => {
    window.location.href = window.origin
  }

  return (
    <Router>
      <App>
        <Route exact path="/s/:unicode">
          <ShareLumen />
        </Route>
        <Route exact path="/lumen-report/:groupId/:departmentName/:randumNumber">
          <LumenReport />
        </Route>

        {/* User Management */}

        {isLoggedIn.status === false ? (
          <>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/register">
                <Redirect
                  to={{
                    pathname: "/login",
                  }}
                />
              </Route>

              <Route exact path="/login">
                <Login />
              </Route>

              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/admin-creation">
                <CreateAdminUser />
              </Route>
              <Route path="/admin-creation">
                <CreateAdminUser />
              </Route>
              {/* <Route exact path="/admin-space-setup">
                <SpaceSetup />
              </Route> */}

              <Route exact path="/error-400">
                <Page400 />
              </Route>

              <Route >
                <Login />
              </Route>
            </Switch>
          </>
        ) : userData ?
            (
                userData && userData.userType === "organisation-admin"
                  ? <AdminRoutes userData={userData} />
                  : <UserRoutes />
            )
          : <Route >
              <Page400 />
            </Route>}
      </App>
    </Router>
  );
};

export default AppNavigation;
