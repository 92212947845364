
import React from 'react'
import{Images} from "../../config"

const LumenReportSearch = (props) => {
    return (
        <>
           
           <div className="lumenreport__search">
                <div className="lumenreport__form">
                    <div className="lumenreport__searchBox">
                        <input 
                            type='search'
                            onChange={props.searchByTitle}
                            value={props.value}
                            placeholder="Search by name" 
                            name="search" className="lumenreport--search"
                        />
                        <img  src={Images.search} className="lumenreport--searchIcon" alt="searchicon"/>
                    </div>
                    
                </div>
            </div> 
        </>
    )
}

export default LumenReportSearch
