import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Images } from '../../config'

const LumenReportHeader = (props) => {
const history = useHistory();

const redirectRootURl = () =>{
    window.location.replace('/')
}

    return (
        <>
           <div className="lumenreport__header ">
                    <nav className="navbar py-4  ">
                        <div className="container">
                            <Link className="navbar-brand position-relative " onClick={redirectRootURl} to='/'>
                                <img src={Images.infinity} className="lumenai-logo" />
                            </Link>
                        </div>
                    </nav>   
                
                    <div className="lumenreport__headercontent smallcontainer">
                        <div className="container">
                            <div className="row flex-column">
                                <p className="para">You are viewing the Lumen report for</p>
                                <div className="lumenreport_wrp d-md-flex align-items-center justify-content-between">
                                <div className="lumenreport_cards">
                                    
                                   
                                    <div className="lumenreport__box">
                                        <div className="lumenreport__block1">
                                            <p className="lumenreport__grpname">{props.groupName}</p>
                                            <div className="listblock">
                                                <p className="textstyle--name"> {props.departmentName}</p>
                                                <p className="textstyle--name"> {props.questionBankName}</p>
                                            </div>
                                        </div>
                                        <div className="lumenreport__block2">
                                            <p className="textstyle"> <span className="people--count">{props.userCount}</span> people</p>
                                        </div>
                                    </div>
                               
                                </div>
                                    <div className="lumenreport_btns">
                                        {props.pdfFileLink === 1  ? 
                                        <>
                                        <Link to={{pathname:props.pdfLink}} target='_blank' className="btn lumenreport--btn">Download PDF</Link>
                                            {
                                                props.pdfFileLinkDate &&
                                                <p className="datepdf textstyle text-center font-size-11 text-color-voilet pt-3 mb-1 mx-1 font-weight-regular"> Report correct as of:  {props.pdfFileLinkDate}</p>
                                            }            
                                        </>
                                        // <button className="btn lumenreport--btn">Download PDF</button>
                                        : null
                                        }
                                        
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
        </>
    )
}

export default LumenReportHeader
