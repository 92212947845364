import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Page400, Page500, InternetConnectivity } from "../../screens";
// redux
import { testAPI_Action, resetAPI_Action } from "../../stores/actions";
import { Link } from "react-router-dom";
const AboutLumenai = (props) => {
  return (
    <div className="col-md-12 col-lg-12">
      {!navigator.onLine && <InternetConnectivity />}
      <Link to="/settings" className="btn btn-link">
        Back
      </Link>
      <div className="col-md-6 col-lg-6">
        <div>
          <br />
          <h3>about lumenai</h3>
          <br />
        </div>
      </div>
    </div>
  );
};

export default AboutLumenai;
