import { set } from "lodash";
import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory, useLocation, } from "react-router-dom";
import { StringConstants } from "../../config";
import { getData, USER_DATA } from "../../utils/storage";
import AdminHeader from "./AdminHeader";
import AfterLoginHeader from "./AfterLoginHeader";
import BeforeLoginHeader from "./BeforeLoginHeader";


const Header = (props) => {
  let history = useHistory();
  let location = useLocation();
  const [BooleanValue, setBooleanValue] = useState(false);
  const [dataId, setDataId] = useState();
  const [categoryData, setCategoryData] = useState();
  const [categorydataId, setCategorydataId] = useState();
  const [unicode, setUnicode] = useState();
  const locationData = location.state;
  const catadata = location.state;
  const unicodeId = location.state;

  useEffect(() => {
    // console.log("location", location);
  }, [location]);


  useEffect(() => {
    if (catadata && catadata.data !== null) {
      setCategorydataId(
        catadata && catadata.data && catadata.data.categoryIconName
      );
    } else {
      setCategorydataId("");
    }
  }, [catadata]);

  useEffect(() => {
    if (
      (locationData &&
        locationData.data &&
        locationData.data.assessmentdetails !== null) ||
      (locationData &&
        locationData.data &&
        locationData.data.assessmentdetails !== undefined)
    ) {
      // console.log("un")
      setDataId(
        locationData &&
        locationData.data &&
        locationData.data.assessmentdetails &&
        locationData.data.assessmentdetails.uuid
      );
    } else {
      setDataId("");
    }
  }, [locationData]);

  // useEffect(() => {
  //   if (
  //     (unicodeId ?.unicode !== null) ||
  //     (unicodeId ?.unicode !== undefined)
  //   ) {
  //     // console.log("unicode")
  //     setUnicode(unicodeId ?.unicode);
  //   } else {
  //     setUnicode("");
  //   }
  // }, [unicode]);
  // console.log("unicode--",unicode)


  useEffect(() => {
    try {
      getData(USER_DATA)
        .then((res) => {
          //pass data to next screen
          // console.log("res",res)
          setUnicode(res.share_lumen_code);
        })
    }
    catch (error) {
      console.log(error);
    }
  }, [])

  const redirectUrl = (e) => {
    history.push(e);
  };

  const __scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const test = () => {
    let url_link = "";
    switch (location.pathname) {
      case "/request-access":
        url_link = <BeforeLoginHeader />;
        break;
    }
  };

  return (
    <>
      {(() => {
        if (location.pathname === "/request-access") {
          return (
            <BeforeLoginHeader headingTitle={StringConstants.req_access} />
          );
        } else if (location.pathname === `/s/${unicode}`) {
          return (<div className="summary-header share">
            <BeforeLoginHeader bluelogo />
          </div>)
        } else if (location.pathname === "/company-invite") {
          return <BeforeLoginHeader />;
        } else if (location.pathname === "/join-company") {
          return <BeforeLoginHeader />;
        } else if (location.pathname === "/join-company-confirmation") {
          return <BeforeLoginHeader />;
        } else if (location.pathname === "/initial-soft-skill") {
          return;

          <BeforeLoginHeader />;
        } else if (location.pathname === "/terms-and-conditions") {
          return <BeforeLoginHeader headingTitle={StringConstants.tnc} />;
        } else if (location.pathname === "/PrivatePolicy") {
          return (
            <BeforeLoginHeader headingTitle={StringConstants.Privacy_Policy} />
          );
        } else if (location.pathname === "/settings") {
          return <BeforeLoginHeader />;
        } else if (location.pathname === "/contact-us") {
          return (
            <BeforeLoginHeader headingTitle={StringConstants.contact_us} />
          );
        } else if (location.pathname === "/faq") {
          return <BeforeLoginHeader headingTitle={StringConstants.faq} />;
        } else if (location.pathname === "/change-password") {
          return (
            <BeforeLoginHeader headingTitle={StringConstants.change_pass} />
          );
        } else if (location.pathname === "/edit-account-details") {
          return (
            <BeforeLoginHeader
              headingTitle={StringConstants.Edit_your_details}
            />
          );
        } else if (location.pathname === "/hub") {
          return <AfterLoginHeader isMobileGroup={true} />;
        } else if (location.pathname === "/hub/your-activity") {
          return (
            <div className="hide-logo">
              {" "}
              <AfterLoginHeader whiteLogo />{" "}
            </div>
          );
        } else if (location.pathname === "/me") {
          return (
            <div className="summary-header me-header">
              <AfterLoginHeader rootpathwhite blueheader />
            </div>
          );
        } else if (location.pathname === "/summary-new") {
          return (
            <div className="summary-header me-header">
              <AfterLoginHeader rootpathwhite blueheader />
            </div>
          );
        }

        else if (location.pathname === "/me/summary") {
          return (
            <div className="summary-header">
              <AfterLoginHeader rootpathwhite blueheader />
            </div>
          );
        } else if (location.pathname === "/me/congratulation") {
          return (
            <div className="summary-header congr">
              <AfterLoginHeader rootpathwhite blueheader />
            </div>
          );
        } else if (location.pathname === `/me/history-detail/${dataId}`) {
          return (
            <div className="summary-header me-header">
              <AfterLoginHeader rootpathwhite blueheader />
            </div>
          );
        } else if (
          location.pathname ===
          `/pathway/soft-skill-explained-detail/${categorydataId}`
        ) {
          return (
            <div className=" me-header">
              <AfterLoginHeader blueheader logo_badges />
            </div>
          );
        } else if (location.pathname === "/company") {
          return <AfterLoginHeader blueheader />;
        } else if (location.pathname === "/share-my-result") {
          return (
            <div className="summary-header share">
              <BeforeLoginHeader bluelogo />
            </div>
          );
        } else if (location.pathname === "/me/initial-summary") {
          return (
            <div className="summary-header ">
              <BeforeLoginHeader rootpathwhite bluelogo />
            </div>
          );
        } else if (location.pathname === "/end-of-example") {
          return; // (
          //   <div className="summary-header ">
          //     <BeforeLoginHeader bluelogo />
          //   </div>
          // )
        } else if (location.pathname === "/try-example") {
          return (
            <div className="try-exmple ">
              <AfterLoginHeader />
            </div>
          );
        } else if (location.pathname === "/people-manager") {
          return (
            <div className="admin-header">
              <AdminHeader companyDetails={true} />
            </div>
          )
        } else if (location.pathname === "/admin-creation") {
          return (
            <div className="admin-header">
              <AdminHeader companyDetails={false} />
            </div>
          )
        } else if (location.pathname === "/admin-space-setup") {
          return (
            <div className="admin-header">
              <AdminHeader companyDetails={false} />
            </div>
          )
        } else if (location.pathname === "/invite-users") {
          return (
            <div className="admin-header">
              <AdminHeader companyDetails={true} />
            </div>
          )
        } else if (location.pathname === "/edit-user") {
          return (
            <div className="admin-header">
              <AdminHeader companyDetails={true} />
            </div>
          )
        } else if (location.pathname === "/edit-admin-user") {
          return (
            <div className="admin-header">
              <AdminHeader companyDetails={true} />
            </div>
          )
        } else if (location.pathname === "/user-profile") {
          return (
            <div className="admin-header ">
              <AdminHeader companyDetails={false} />
            </div>
          );
        }

        else {
          return <>
            <AfterLoginHeader />
          </>;
        }
      })()}

    </>
  );
};

export default Header;
