import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Image, Button, Container, Card } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Page400, Page500, InternetConnectivity } from "../../screens";
// redux
import {
  questionAPI_Action,
  assessmentdetailsAPI_Action,
  questionAPI_Reattempt_Action,
} from "../../stores/actions";
import {
  getData,
  setData,
  USER_DATA,
  SUMMARY_ATTEMPT,
  TOKEN,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";
import { __redirectScrollTop } from "../../utils/utility";
import {
  CategoryCard,
  HelpTip,
  LearningCard,
  ProgressCount,
  TipsPopup,
  TopHeaderDesktop,
} from "../../components";
import { StringConstants, Colors, Images } from "../../config";

const AssessmentDetail = (props) => {
  // const assessmentDetailsData = useSelector((state) => state.assessmentDetails);
  let questionData = useSelector((state) => state.questions);
  const [displayData, setDisplayData] = useState();
  const [loader, setLoader] = useState();
  const location = useLocation();
  const history = useHistory();

  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [questionBankId, setQuestionBankId] = useState('');
  const [questionInactive, setQuestionInactive] = useState(false);
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   if (history.action === 'POP'){
  //     setLoader(true);
  //     window.location.replace('/')
  //     setLoader(false);
  //   } 
  // },[])

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData(USER_DATA).then((userData) => {
            getData(SUMMARY_ATTEMPT).then((sumres) => {
              setTimeout(() => {
                getData(GROUP_DATA).then((groupData) => {

                  dispatch(
                    questionAPI_Reattempt_Action(
                      res.token,
                      groupData.questionBank[0].questionBankId,
                      sumres,
                      groupData.groupId
                    )
                  );
                })
              }, 1000)
              
              // console.log("sumres-- else", sumres);
              
            });
            // getData(SUMMARY_ATTEMPT).then((sumres) => {
            //   if(sumres === 1 && assessmentDetailsData &&
            //     assessmentDetailsData.data &&
            //     assessmentDetailsData.data.data &&
            //     assessmentDetailsData.data.data.questionBankDetails &&
            //     assessmentDetailsData.data.data.questionBankDetails.attempt ){
            //     console.log("if",sumres)
            //       dispatch(
            //         // questionAPI_Action(res.token, userData.questionBankId[0])
            //         assessmentdetailsAPI_Action(res.token, userData.questionBankId[0])
            //       );
            //   }else{
            //     console.log("sumres-- else",sumres);
            //       dispatch(
            //         questionAPI_Reattempt_Action(
            //           res.token,
            //           userData.questionBankId[0],
            //           sumres
            //         )
            //       );

            //   }
            // });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (questionData.isFetching) {
      setLoader(true);
    } else if (questionData.isSuccess && questionData.data !== null) {
      setLoader(false);
      let AssessmentData =
        questionData && questionData.data && questionData.data.data;
      // console.log("assesssssss", AssessmentData);
      setDisplayData(AssessmentData);

      setQuestionBankId(AssessmentData.questionBankDetails.questionBankId);
      setData("quetionBankId", AssessmentData.questionBankId);
      AssessmentData.questionBankDetails.questionBankQuestions.length > 0
        ? setQuestionInactive(false)
        : setQuestionInactive(true);
    } else if (questionData.isError) {
      setErrorModel(true);
      setLoader(false);
      setErrorDataModel(questionData.data);
    }
  }, [questionData]);

  return (
    <>
      {loader === true ? (
        <>
          <div className="bg-aliceblue loader  position-absolute w-100">
            <div className="d-flex align-items-center flex-column justify-content-center full-height">
              <img src={Images.infinity} alt="infinite" />
              <p className="font-size-22 font-family-ubuntu font-weight-bold colo-text-violet">
                Lumenai ...
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="pathway-active-details-screen background-icon  bg-aliceBlue full-height position-relative  overflow-hidden">
          <div className="background-icon-postion">
            {" "}
            <img src={Images.large_assessment_backkground} className="" />
          </div>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-10 col-lg-8 mb-5">
                <div className="mt-md-4">
                  <TopHeaderDesktop redirectHub="/hub" black />
                </div>
                <div className="emp-assessment mt-sm-5">
                  <div className="wrp-for-mobile assesment-box assessment-inner-space">
                    <div className="cards-box padding-lr top-heading jc-inside-space jc-card-size d-flex align-items-center mb-32 mob-tab">
                      <div className="company-media">
                        <img
                          src={Images.assessment_icon_small}
                          className="card-img-left mr-3"
                        />
                      </div>
                      <div className="company-content">
                        {displayData && displayData.questionBankDetails && (
                          <>
                            <h4 className="font-family-ubuntu font-weight-bold mb-1 font-size-18 line-height-22 text-color-violet text-black ">
                              {displayData &&
                                displayData.questionBankDetails &&
                                displayData.questionBankDetails
                                  .questionBankName}
                            </h4>
                            <p className="font-family-ubuntu font-weight-medium mb-0 font-size-13 line-height-20 text-color-darkgray">
                              {/* 20 questions */}
                              {displayData &&
                              displayData.questionBankDetails &&
                              displayData.questionBankDetails
                                .questionBankQuestionsCount === 1 ? (
                                <>
                                  {displayData &&
                                    displayData.questionBankDetails &&
                                    displayData.questionBankDetails
                                      .questionBankQuestionsCount}
                                  <span> question</span>
                                </>
                              ) : (
                                <>
                                  {displayData &&
                                    displayData.questionBankDetails &&
                                    displayData.questionBankDetails
                                      .questionBankQuestionsCount}
                                  <span> questions</span>
                                </>
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="cards-box bottom-content  overflow-hidden">
                      <div className="card-content padding-lr jc-inside-space">
                        <p
                          className="font-family-ubuntu font-weight-regular mb-0 font-size-16 line-height-22 text-color-violet"
                          dangerouslySetInnerHTML={{
                            __html:
                              displayData &&
                              displayData.questionBankDetails &&
                              displayData.questionBankDetails
                                .questionBankDescription,
                          }}
                        ></p>
                      </div>
                      <div className="content-footer bg-dithered jc-inside-space bg- d-flex align-items-center justify-content-between">
                        <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                          {StringConstants.Approx_effort}
                        </p>
                        <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                          {displayData &&
                            displayData.questionBankDetails &&
                            displayData.questionBankDetails
                              .questionBankApproxEffort}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="associat-skill mt-4 pb-3">
                  <p className="line-height-18 font-size-13 font-family-ubuntu font-weight-regular text-color-sapphire ">
                    {StringConstants.Associated}
                  </p>

                  <Row className="">
                    <Col md={8} lg={7} className="">
                      <div className="category-box d-sm-flex flex-sm-wrap cards-mobile ">
                        {/* <CategoryCard
                          color={Colors.color_Sapphire}
                          cardIcon={Images.probsolving_whitecard}
                          categoryName="Problem solving"
                        />
                        <Link>
                          <CategoryCard
                            color={Colors.color_Sapphire}
                            cardIcon={Images.criticalthinking_whitecard}
                            categoryName="Critical thinking"
                          />
                        </Link> */}

                        {
                          // let newRecentTraining = displayData.recentTraining.slice(1,2)
                          displayData &&
                            displayData.associatedCategories.map(
                              (item, index) => {
                                let recentIcon = "";

                                switch (item.categoryName) {
                                  case "Problem Solving":
                                    recentIcon = Images.probsolving_whitecard;
                                    break;
                                  case "Critical Thinking":
                                    recentIcon =
                                      Images.criticalthinking_whitecard;
                                    break;
                                  case "Teamwork":
                                    recentIcon = Images.teamwork_whitecard;
                                    break;
                                  case "Communication":
                                    recentIcon = Images.communication_whitecard;
                                    break;
                                  case "Leadership":
                                    recentIcon = Images.leadership;
                                    break;
                                  case "Time Management":
                                    recentIcon = Images.timemgt_whitecard;
                                    break;
                                  case "Emotional Judgement":
                                    recentIcon = Images.emotionaljudge;
                                    break;
                                  case "Creativity":
                                    recentIcon = Images.creativity;
                                    break;
                                  case "Flexibility/Adaptability":
                                    recentIcon = Images.Flexibilty_active
                                    break;

                                  default:
                                    recentIcon = Images.infinity;
                                }

                                return (
                                  <CategoryCard
                                    // backgroundColor={recentColor}
                                    color={Colors.royalblue}
                                    cardIcon={recentIcon}
                                    categoryName={item.categoryName}
                                  />
                                );
                              }
                            )
                        }
                      </div>
                    </Col>

                    <Col md={4} lg={5} className="align-self-end text-md-right">
                      <div className="mb-2 mt-3 mt-sm-0 mb-md-0">
                        {questionInactive ? (
                          <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                            {StringConstants.Questions_loading}
                          </p>
                        ) : (
                          <>
                            {(questionData &&
                              questionData.data &&
                              questionData.data.data &&
                              questionData.data.data.questionBankDetails
                                .attempt > 3) ||
                            (questionData &&
                              questionData.data &&
                              questionData.data.data &&
                              questionData.data.data.questionBankDetails
                                .re_attempt === 0) ? (
                              <button
                                aria-disabled
                                className="btn disable-btn mobile-w-size btn-text bg-violet text-white py-3 px-3 text-white btn-circle-30 "
                              >
                                {StringConstants.start_now}
                              </button>
                            ) : (
                              questionBankId !== '' ?
                              <Link
                                to={"/questions/" + questionBankId}
                                onClick={__redirectScrollTop}
                                className="btn mobile-w-size btn-text bg-violet text-white py-3 px-3 text-white btn-circle-30 "
                              >
                                {StringConstants.start_now}
                              </Link> : ''
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="row mb-5 align-items-center">
                  <div className="col-md-6 col-lg-6"></div>

                  <div className="col-md-6 col-lg-6 align-self-end text-md-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div className=" position-fixed lumen-help fixed-lumen-help">
            <HelpTip />
            {/* <TipsPopup /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default AssessmentDetail;
