import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AssessmentCard,
  CategoryCard,
  LearningCard,
  NestedPiChart,
  TipsPopup,
  HelpTip,
  TopHeader,
  TopHeaderDesktop,
  NestedPieChartProfile,
} from "../../components";
import { Images, StringConstants } from "../../config";
import ProgressCard from "../../components/ProgressCard";
import { __redirectScrollTop } from "../../utils/utility";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, useHistory } from "react-router-dom";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  getData,
  setData,
  TOKEN,
  USER_DATA,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";
import moment from "moment";
import {
  assessmentCongratulationAPI_Action,
  resetAssessmentCongratulationAPI_Action,
} from "../../stores/actions";
import {
  categoryAPI_Action,
  resetcategoryAPI_Action,
} from "../../stores/actions";
const Congratulation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // state

  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();

  const [assessmentdetails, setAssessmentdetails] = useState("");
  const [categoryArray, setcategoryArray] = useState([]);
  const [pathway, setPathway] = useState([]);

  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
  const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
    useState([]);
  const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
    []
  );
  const [categoryImageArray, setcategoryImageArray] = useState([]);

  const [completedDate, setcompletedDate] = useState();

  // selector
  const assessmentCongratulationData = useSelector(
    (state) => state.assessmentCongratulation
  );

  //ALL API START
  const categoryData = useSelector((state) => state.category);
  const [displayData, setDisplayData] = useState();
  const [softskill, setSoftSkill] = useState();
  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          dispatch(categoryAPI_Action(res.token));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (categoryData.isFetching) {
      setLoader(true);
    } else if (categoryData.isSuccess && categoryData.data !== null) {
      setLoader(false);
      let dashboardData =
        categoryData && categoryData.data && categoryData.data.data;
      setDisplayData(dashboardData);
      setSoftSkill(categoryData.data.data);
    } else if (categoryData.isError) {
      setErrorModel(true);
      setErrorDataModel(categoryData.data);
    }
  }, [categoryData]);
  // console.log("ALL", categoryData);
  //All API END

  //useEffet
  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData("quetionBankId").then((quetionBankId) => {
            getData(GROUP_DATA).then((groupData) => dispatch(
              assessmentCongratulationAPI_Action(res.token, quetionBankId, groupData.groupId)
            ))
            
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (assessmentCongratulationData.isFetching) {
      setLoader(true);
    } else if (
      assessmentCongratulationData.isSuccess &&
      assessmentCongratulationData.data !== null
    ) {
      setLoader(false);
      if (
        assessmentCongratulationData.data.data.questionBankDetails !== undefined
      ) {
        setAssessmentdetails(
          assessmentCongratulationData.data.data.questionBankDetails
        );
        let set_date1 = moment(
          assessmentCongratulationData.data.data.questionBankDetails
            .questionBankCompletedAt
        ).format("DD/MM/YYYY");
        setcompletedDate(set_date1);
      }
      if (assessmentCongratulationData.data.data.categoryArr !== undefined) {
        setcategoryArray(assessmentCongratulationData.data.data.categoryArr);
        let sortedcatArray = assessmentCongratulationData.data.data.categoryArr;

        sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
        sortedcatArray.map((item, index) => {
          if (item.categoryCorePercentage > 0) {
            backgroundColorArraycore.push("#48FFFF");
          } else {
            backgroundColorArraycore.push("#381474");
          }
          if (item.categoryManagerialPercentage > 0) {
            backgroundColorArraymanager.push("#275EFF");
          } else {
            backgroundColorArraymanager.push("#381474");
          }
          if (item.categoryTalentedPercentage > 0) {
            backgroundColorArraytalent.push("#F904FF");
          } else {
            backgroundColorArraytalent.push("#381474");
          }

          let activeLearningIcon = "";
          switch (item.categoryName) {
            case "Problem Solving":
              activeLearningIcon = Images.problem_solving;
              break;
            case "Critical Thinking":
              activeLearningIcon = Images.criticalthink_white;
              break;
            case "Teamwork":
              activeLearningIcon = Images.teamwork_white;
              break;
            case "Communication":
              activeLearningIcon = Images.comms_white;
              break;
            case "Leadership":
              activeLearningIcon = Images.leadershp_white;
              break;
            case "Time Management":
              activeLearningIcon = Images.timemgt_white;
              break;
            case "Emotional Judgement":
              activeLearningIcon = Images.emotionaljudge_white;
              break;
            case "Creativity":
              activeLearningIcon = Images.creativity_white;
              break;
            case "Flexibility/Adaptability":
                activeLearningIcon = Images.Flexibilty_inactive;
                break;

            default:
              activeLearningIcon = Images.emotionaljudge;
          }

          categoryImageArray.push(activeLearningIcon);
        });
      }
      if (assessmentCongratulationData.data.data.activeLearning !== undefined) {
        setPathway(assessmentCongratulationData.data.data.activeLearning);
      }
    } else if (assessmentCongratulationData.isError) {
      setLoader(true);
      setErrorModel(true);
      setErrorDataModel(assessmentCongratulationData.data);
    }
  }, [assessmentCongratulationData]);

  return (
    <>
      <div className="bg-aliceBlue summary-screens">
        {!navigator.onLine && <InternetConnectivity />}
        {errorDataModel && errorDataModel.error.status_code == "500" && (
          <Page500 />
        )}
        {errorDataModel && errorDataModel.error.status_code == "400" && (
          <Page400 />
        )}
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8">
              <TopHeader close />
              <TopHeaderDesktop />
              <div className="summary-decs">
                <h3 className="congrats-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                  {StringConstants.congos}
                </h3>
                <p className="congrats-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                  {StringConstants.boost}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wave bg-violet mt-5 pb-5 summary-wave">
          <div className="container">
            <div className="row justify-content-md-center pt-5">
              <div className="col-md-6 col-lg-4">
                <div className="d-none d-md-block category-progress-summary category__block1 mt-md-5">
                  <ProgressCard
                    statusText={"Completed " + moment(assessmentdetails.questionBankCompletedAt).format("DD/MM/YYYY")}
                    icon={Images.assessment_white}
                    title={assessmentdetails.questionBankName}
                    percentSymbol
                    percent={
                      assessmentdetails.questionBankOverallPercentage &&
                      assessmentdetails.questionBankOverallPercentage.slice(
                        0,
                        assessmentdetails.questionBankOverallPercentage.length -
                          1
                      )
                    }
                    categoryCorePercentage={
                      assessmentdetails.questionBankCorePercentage &&
                      assessmentdetails.questionBankCorePercentage.slice(
                        0,
                        assessmentdetails.questionBankCorePercentage.length - 1
                      )
                    }
                    categoryManagerialPercentage={
                      assessmentdetails.questionBankManagerialPercentage &&
                      assessmentdetails.questionBankManagerialPercentage.slice(
                        0,
                        assessmentdetails.questionBankManagerialPercentage
                          .length - 1
                      )
                    }
                    categoryTalentedPercentage={
                      assessmentdetails.questionBankTalentedPercentage &&
                      assessmentdetails.questionBankTalentedPercentage.slice(
                        0,
                        assessmentdetails.questionBankTalentedPercentage
                          .length - 1
                      )
                    }
                  />
                </div>
                <div className="pie-chart">
                  <NestedPieChartProfile
                    backgroundColorArraycore={backgroundColorArraycore}
                    backgroundColorArraymanager={backgroundColorArraymanager}
                    backgroundColorArraytalent={backgroundColorArraytalent}
                    categoryImageArray={categoryImageArray}
                    catgoryArray={
                      assessmentCongratulationData.data.data.categoryArr
                    }
                  />
                </div>
                <div className="assessment-history text-center mt-4   d-none d-md-block">
                  <Link
                    to="/hub"
                    onClick={__redirectScrollTop}
                    className="btn px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center "
                  >
                    {StringConstants.Assessment_history}
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-70 ">
                <div className="category-progress-summary category__block2 mt-5 pb-2">
                  {categoryArray !== null &&
                    categoryArray !== undefined &&
                    categoryArray.length > 0 &&
                    categoryArray.map((item, index) => {
                      let activeLearningIcon = "";
                      switch (item.categoryName) {
                        case "Problem Solving":
                          activeLearningIcon = Images.problem_solving;
                          break;
                        case "Critical Thinking":
                          activeLearningIcon = Images.criticalthink_white;
                          break;
                        case "Teamwork":
                          activeLearningIcon = Images.teamwork_white;
                          break;
                        case "Communication":
                          activeLearningIcon = Images.comms_white;
                          break;
                        case "Leadership":
                          activeLearningIcon = Images.leadershp_white;
                          break;
                        case "Time Management":
                          activeLearningIcon = Images.timemgt_white;
                          break;
                        case "Emotional Judgement":
                          activeLearningIcon = Images.emotionaljudge_white;
                          break;
                        case "Creativity":
                          activeLearningIcon = Images.creativity_white;
                          break;
                        case "Flexibility/Adaptability":
                          activeLearningIcon = Images.Flexibilty_inactive;
                          break;

                        default:
                          activeLearningIcon = Images.emotionaljudge;
                      }
                      return (
                        <ProgressCard
                          data={item}
                          softSkillUrl={{
                            pathname:
                              "/pathway/soft-skill-explained-detail/" +
                              item.categoryIconName,
                            state: { data: item },
                          }}
                          icon={activeLearningIcon}
                          title={item.categoryName}
                          percent={item.categoryPercentage}
                          categoryCorePercentage={item.categoryCorePercentage}
                          categoryManagerialPercentage={
                            item.categoryManagerialPercentage
                          }
                          categoryTalentedPercentage={
                            item.categoryTalentedPercentage
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-initial congratulation-card">
          {/* <AssessmentCard
            icon={Images.timemgt_white}
            title={StringConstants.time_management}
            subtitle={"Managerial skill achieved now!"}
            style={{ backgroundColor: "#BF06FF" }}
          /> */}
          <AssessmentCard
            icon={Images.timemgt_white}
            title={
              assessmentdetails.questionBankName !== undefined
                ? assessmentdetails.questionBankName
                : null
            }
            subtitle={StringConstants.Manage}
            style={{ backgroundColor: "#BF06FF" }}
          />
        </div>
      </div>

      <div className="d-none d-md-block  position-fixed lumen-help fixed-lumen-help">
        <HelpTip />
        {/* <TipsPopup /> */}
      </div>
    </>
  );
};

export default Congratulation;
