import React, { useState } from 'react';
import { Images } from '../config';
import * as _ from 'lodash'

const Pagination = props => {
    const data = props.ResponseData;
    // console.log('data-- pagination',data)
    // const [currentPage, setCurrentPage] = useState(data.current_page);

    const currentPage = data.current_page;

    const __loadPage = (page) => {
        props.onSubmit(page)
    }

    const __renderPages = () => {
        let noOfPages = Math.ceil(data.total / data.per_page);

        let page = []
        _.times(noOfPages, (item) => {
            let displayPage = item + 1;
            page.push(<li className="page-item" key={item}>
                <button className={data.current_page === displayPage ? "btn btn-link btn-sm active" : "btn btn-default btn-sm"} onClick={() => {
                    window.scroll(0, 0);
                    __loadPage(displayPage)
                }}>{displayPage}</button></li>)
        })

        return page;
    }


    if (!data.has_more_pages && data.current_page === 1) {
        return (null)
    }
    return (
        <div className="pagination-wpr pb-5">
            <nav>
                <ul className="pagination  pagination-sm  justify-content-end">
                    {
                        data.prev_page_url !== null &&
                        <li className="page-item">
                            <button className="btn btn-default btn-sm" onClick={() => {
                                window.scroll(0, 0);
                                __loadPage(currentPage - 1)
                            }}>
                                <img src={Images.backArrow} alt="prev" />
                                <span className="ml-2 font-size-14 text-color-violet font-family-ubuntu font-weight-medium line-height-18 ">Prev</span>
                           </button></li>
                    }

                    {__renderPages()}

                    {
                        data.next_page_url !== null &&
                        <li className="page-item"><button className="btn btn-default btn-sm" onClick={() => {
                            window.scroll(0, 0);
                            __loadPage(currentPage + 1)

                        }}>
                            <span className="mr-2 font-size-14 text-color-violet font-family-ubuntu font-weight-medium line-height-18 ">Next</span>
                            <img src={Images.backArrow} style={{transform:'rotate(180deg)'}} alt="next" />
                        </button></li>
                    }
                </ul>
            </nav>
        </div>
    );
};

export default Pagination;