import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function create_account_action(data, userId) {
    return {
      [RSAA]: {
        endpoint: `${API_ENDPOINT}/create-account/${userId}`,
        method: "POST",
        headers: {
          ...AUTH_HEADERS,
        },
  
        body: JSON.stringify(data),
        types: [
          types.CREATE_ACCOUNT_REQUEST,
          types.CREATE_ACCOUNT_RECEIVE,
          {
            type: types.CREATE_ACCOUNT_FAILURE,
            meta: (action, state, res) => {
              return action, state, res;
            },
          },
        ],
      },
    };
  }
  
  export const reset_create_account_Action = () => {
    return {
      type: types.RESET_CREATE_ACCOUNT,
      payload: null,
    };
  };