import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  TopHeaderDesktop,
} from "../../components";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  changePassword_API_Action,
  reset_changePassword_API_Action,
} from "../../stores/actions";
import { useHistory } from "react-router-dom";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  getData,
  setData,
  TOKEN,
  USER_DATA,
  clearAllData,
} from "../../utils/storage";
import { useDispatch } from "react-redux";

const Changepassword_schema = yup.object().shape({
  old_password: yup
    .string()
    /*
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    */
    .required("Please enter password"),
  new_password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Please enter password"),
  new_password_confirmation: yup
    .string()
    .required("Please confirm password")
    .oneOf([yup.ref("new_password"), null], "Password does not match"),
});

const ChangePassword = (props) => {
  const [errorModel, setErrorModel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState();
  const changepasswordData = useSelector((state) => state.changepassword);
  const dispatch = useDispatch();
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Changepassword_schema),
  });

  useEffect(() => {
    // console.log("changepasswordData--", changepasswordData);
    if (changepasswordData.isFetching) {
      setLoader(true);
    } else if (
      changepasswordData.isSuccess &&
      changepasswordData.data !== null
    ) {
      setLoader(false);

      setShowSuccessMessage(changepasswordData.data);
      setModalShow(true);
      // clearAllData();
      // window.location.replace('/login');
      dispatch(reset_changePassword_API_Action());
    } else if (changepasswordData.isError) {
      setErrorModel(true);
      setErrorDataModel(changepasswordData.data);

      //  hanlde server response 401
      // console.log("error",changepasswordData.data && changepasswordData.data.error.status_code)
      if (
        changepasswordData &&
        changepasswordData.data &&
        changepasswordData.data.status_code === 401
      ) {
        dispatch(changePassword_API_Action());
        clearAllData();
        window.location.replace("/login");
        // history.push('/login');
      }
    }
  }, [changepasswordData]);

  // console.log("changepassword", changepasswordData);

  const onSubmit = (data) => {
    // console.log("onSubmit data: ", data);

    try {
      getData(TOKEN)
        .then((res) => {
          dispatch(changePassword_API_Action(res.token, data));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    dispatch(reset_changePassword_API_Action());
  };

  const __settings = () => {
    window.location.replace("/settings");
  };

  const __login = () => {
    clearAllData();
    window.scrollTo(0, 0);
    window.location.replace("/login");
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={props.onHide}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                {showSuccessMessage && showSuccessMessage.message}
              </h3>
              <button
                className="btn mt-3  min-width-245 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30"
                onClick={__login}
              >
                Login
              </button>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <button
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                onClick={__settings}
              >
                {StringConstants.skip_now}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <div className="request-access bg-aliceBlue header-minusHeight mob-height wave-section pt-lg-5 pt-md-4 pb-lg-3">
        <TopHeader />
        {!navigator.onLine && <InternetConnectivity />}

        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0">
            <Col md={10} lg={8}>
              <TopHeaderDesktop />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="heading-with-logo mt-3 mt-md-0 d-md-none">
                  <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                    {StringConstants.change_password}
                  </h3>
                </div>
                <Row>
                  <Col sm={12}>
                    {/* <TextInput
                      id="old_password"
                      name="old_password"
                      label={StringConstants.Current_password}
                      type="password"
                      {...register("old_password")}
                      showerror={errors.old_password?.message}
                    /> */}

                    <div className="form-group components-form field-style">
                      <label htmlFor="">
                        {StringConstants.Current_password}
                      </label>
                      <input
                        type="password"
                        name="old_password"
                        className="form-control"
                        id="old_password"
                        {...register("old_password")}
                      />
                      <span className="error">
                        {errors.old_password?.message}
                      </span>
                    </div>
                  </Col>

                  <Col sm={12}>
                    {/* <TextInput
                      id="new_password"
                      name="new_password"
                      label={StringConstants.New_password}
                      type="password"
                      {...register("new_password")}
                      showerror={errors.new_password?.message}
                    /> */}

                    <div className="form-group components-form field-style">
                      <label htmlFor="">{StringConstants.New_password}</label>
                      <input
                        type="password"
                        name="new_password"
                        className="form-control"
                        id="new_password"
                        {...register("new_password")}
                      />
                      <span className="error">
                        {errors.new_password?.message}
                      </span>
                    </div>
                  </Col>

                  <Col sm={12}>
                    {/* <TextInput
                      id="new_password_confirmation"
                      name="new_password_confirmation"
                      label={StringConstants.Confirm_new_password}
                      type="password"
                      {...register("new_password_confirmation")}
                      showerror={errors.new_password_confirmation?.message}
                    /> */}

                    <div className="form-group components-form field-style">
                      <label htmlFor="">{StringConstants.Cpassword}</label>
                      <input
                        type="password"
                        name="new_password_confirmation"
                        className="form-control"
                        id="new_password_confirmation"
                        {...register("new_password_confirmation")}
                      />
                      <span className="error">
                        {errors.new_password_confirmation?.message}
                      </span>
                    </div>
                    <p className="error">
                      {errorDataModel && errorDataModel.message}
                    </p>
                  </Col>

                  <Col sm={12}>
                    {changepasswordData.isFetching ? (
                      <div className="align-box-change-password text-sm-right   mt-2 mb-4">
                        <button
                          type="submit"
                          className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                        >
                          {StringConstants.Loading}
                        </button>
                      </div>
                    ) : (
                      <div className="align-box-change-password text-sm-right   mt-2 mb-4">
                        <button
                          type="submit"
                          className="btn request-btn font-size-18 font-weight-bold line-height-22 text-coloe-white min-width-245 bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                        >
                          {StringConstants.Save}
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ChangePassword;
