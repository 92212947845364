import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import { Link } from "react-router-dom";
import {
  TextInputReadonly,
  TipsPopup,
  TopHeader,
  TopHeaderDesktop,
  HelpTip,
} from "../../components";
import { USER_DATA, getData } from "../../utils/storage";
import { Images, StringConstants } from "../../config";
import { Page400, Page500, InternetConnectivity } from "../../screens";
const JoinCompanyConfirmation = () => {
  const [userData, setUserData] = useState();
  const [errorDataModel, setErrorDataModel] = useState();
  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        //pass data to next screen
        setUserData(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  const __redirectUrl = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="join-company-cnf bg-aliceBlue wave-section">
        {errorDataModel && errorDataModel.error.status_code == "500" && (
          <Page500 />
        )}
        {errorDataModel && errorDataModel.error.status_code == "400" && (
          <Page400 />
        )}
        {!navigator.onLine && <InternetConnectivity />}
        <Container>
          <Row className="pb-5 justify-content-sm-center">
            <Col lg={8} md={10} className="p-0">
              <TopHeader />
              <TopHeaderDesktop />
              <div className="wrpfrom-mobile">
                <div className="heading-with-logo ">
                  <h3 className="subtitle  font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                    {StringConstants.now_connect}
                  </h3>
                </div>
                <div className="cards-box jc-card-size jc-inside-space">
                  <div className="company-media d-flex align-items-center">
                    {userData && userData.user_company_logo !== "" ? (
                      <img
                        src={userData.user_company_logo}
                        className="jc-icon mr-3 user_logo"
                      />
                    ) : (
                      <img
                        src={Images.infinity}
                        className="jc-icon mr-3 user_logo"
                      />
                    )}
                    <div className="company-body">
                      <h4 className="text-color-blue font-size-18 font-family-ubuntu font-weight-medium line-height-22 mb-0">
                        {/* {StringConstants.Lumenai} */}
                        {userData && userData.user_company_name}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 mobile-wrpspace">
                <Row className="align-items-center pt-5">
                  <Col md={8} className="pr-0">
                    <div className="company-cnf-media">
                      <div className="cnf-icon d-md-flex align-items-center">
                        <img
                          src={Images.assessment_icon}
                          className="mr-md-4 icon-company-cnf"
                        />
                        {userData && (
                          <div className="cnf-media-content">
                            <h4 className="text-color-violet font-size-18 font-family-ubuntu font-weight-bold line-height-22 mb-2">
                              {StringConstants.complete_assessment}{" "}
                              <span>{userData.questionBankName[0]}  </span>
                              { userData.questionBankName[1] && <span>,  {userData.questionBankName[1]} </span> }
                            </h4>
                            <p className="text-color-violet font-size-15 font-family-ubuntu font-weight-medium line-height-22 mb-0">
                              {StringConstants.skill_assessment}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="start_assessmet">
                      <Link
                        // to="/initial-soft-skill"
                        to="/try-example"
                        onClick={__redirectUrl}
                        className="btn btn-text bg-violet text-white py-3 px-3 join-btn btn-circle-30"
                      >
                        {StringConstants.start_assessment}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave bg-violet pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-sm-left">
            <Col md={8} className="pt-sm-5">
              <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-2">
                <span>{StringConstants.do_it_decs1}</span>
                <span className="d-block">{StringConstants.do_it_decs2}</span>
              </p>
              <Link
                to="/hub"
                onClick={__redirectUrl}
                className="btn mobile-w-243 min-width-178 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.do_it_letter}
              </Link>
            </Col>
          </Row>
          <div className="position-absolute init-top-right d-none d-sm-block">
            <HelpTip />
            {/* <TipsPopup /> */}
          </div>
        </Container>
      </div>
      {/* fotoer wave end */}
    </>
  );
};

export default JoinCompanyConfirmation;
