import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Welcome from "../../components/Welcome";
import { StringConstants } from "../../config";
import { ChatBox, ShowPassword } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InfinityWhite } from "../../config/Images";
import { useDispatch, useSelector } from "react-redux";
import { create_account_action, create_account_details_action } from "../../stores/actions";
import Error400 from "../ErrorPages/error-404";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { getData, setData, TOKEN, USER_DATA } from "../../utils/storage";


const CreateAdminUser = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const emailId = useSelector(state => state.createAccountDetails);
  const creationData = useSelector(state => state.createAccount);
  const [loginData, setLoginData] = useState();
  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const location = window.location.pathname;
  const token = location.split("/")[2];



  useEffect(() => {
    if (token !== undefined) {
      dispatch(create_account_details_action(token))
    } else {
      window.location.href = window.location.origin;
    }

  }, [])

  useEffect(() => {
    if (creationData.isFetching) {
      setLoader(true);
    } else if (creationData.isSuccess && creationData.data !== null) {
      setLoader(false);
      setLoginData(creationData.data);
      let data = creationData.data.data;

      setData(TOKEN, { token: data.token, expires_at: data.expires_at });
      setData(USER_DATA, data.user);
      window.location.href = window.location.origin
    } else if (creationData.isError) {
      setErrorModel(true);
      setErrorDataModel(creationData.data);
    }
  }, [creationData]);

  const schema = yup.object().shape({
    email: yup.string().default(emailId.isSuccess ? emailId.data.data.email : "").required("Please enter valid email id").email("Please enter valid email id"),
    password: yup.string().min(8, "Password must be at least 8 characters").matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/, "Password must contain at least 8 characters including one uppercase letter, one lowercase letter, and one number.").required("Please enter password"),
    password_confirmation: yup.string().required("Please confirm password").oneOf([yup.ref("password"), null], "Password does not match"),
    tnc_check: yup.bool().oneOf([true], "Please accept terms and conditions"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitData = useCallback((data) => {

    try {
      dispatch(create_account_action(data, token));

    } catch (e) {
      console.log(e);
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState("");

  const showPasswordFunction = () => {
    setShowPassword(!showPassword);
  }

  const showConfirmPasswordFunction = () => {
    setShowConfirmPassword(!showConfirmPassword);
    // console.log(showConfirmPassword, " ", showPassword);
  }

  const __redirectUrlTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      <div className="admin-user-creation form-mobile-view bg-aliceBlue d-flex justify-content-between">
        <Welcome title={StringConstants.WelcomeTitle} data={StringConstants.WelcomeData} imgSrc={InfinityWhite} />
        <Container className="bg-aliceBlue admin-form-container">
          <Row className="pb-5 justify-content-sm-center">
            <Col md={10} className="p-0">
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="wrpfrom-mobile">
                <div className="heading-with-logo">
                  <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                    {StringConstants.AdminCreationTitle}
                  </h3>
                  <p dangerouslySetInnerHTML={{__html:StringConstants.AdminCreationDescription}} />
                </div>
                <div className="cards-box lp-card-size  inside-space">
                  <div className="form-group components-form field-style ddd">
                    <label htmlFor="email">
                      {StringConstants.EmailAddress}
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      value={emailId.isSuccess ? emailId.data.data.email : ""}
                      disabled
                      {...register("email")}
                    />
                    <span className="error">{errors.email?.message}</span>
                  </div>

                  <div className="form-group components-form field-style dd">
                    <div className="d-flex justify-content-between">
                      <label htmlFor="password">
                        {StringConstants.Password}
                      </label>
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      id="password"
                      {...register("password")}
                    />
                    <span className="error">{errors.password?.message}</span>
                    <ShowPassword actionProp={showPassword} passFun={showPasswordFunction} />
                  </div>
                  <div className="form-group components-form field-style dd">
                    <div className="d-flex justify-content-between">
                      <label htmlFor="password_confirmation">
                        {StringConstants.Cpassword}
                      </label>
                    </div>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="password_confirmation"
                      className="form-control"
                      id="password"
                      {...register("password_confirmation")}
                    />
                    <span className="error">{errors.password_confirmation?.message}</span>
                    <ShowPassword actionProp={showConfirmPassword} passFun={showConfirmPasswordFunction} />
                  </div>
                  <fieldset className="tnc-style">
                    <div className="form-check">
                      <label
                        title=""
                        htmlFor="formHorizontalCheck"
                        className="checkbox-container mb-0 lh-2.5 font-size-13 font-family-ubuntu font-weight-medium text-color-violet line-height-18"
                      >
                        {/* {StringConstants.Register_Tnc} */}
                        I accept the full terms and conditions on <Link to='#'
                          onClick={
                            () => (
                              window.open(
                                "https://lumenai.tech/privacy-policy/", "_blank"))
                          }
                        > lumenai.tech</Link>
                        <input
                          type="checkbox"
                          id="formHorizontalCheck"
                          className="form-check-input"
                          name="tnc_check"
                          {...register("tnc_check")}
                        />
                        <span class="checkmark"></span>
                      </label>

                      {/* <input
                            type="checkbox"
                            id="formHorizontalCheck"
                            class="form-check-input"
                            name="tnc_check"
                            {...register("tnc_check")}
                          />
                          <label
                            title=""
                            htmlFor="formHorizontalCheck"
                            className="mb-0 font-size-13 font-family-ubuntu font-weight-medium text-color-violet line-height-18"
                          >
                            {StringConstants.Register_Tnc}{" "}
                          </label> */}
                    </div>
                    <span className="error">
                      {errors.tnc_check?.message}
                    </span>
                  </fieldset>

                  {/* <div className="text-right">
                      <Link
                        to="/forgot-password"
                        onClick={__redirectUrlTop}
                        className="text-color-violet font-size-13 font-weight-medium font-ubuntu"
                      >
                        {StringConstants.forgot_password}
                      </Link>
                    </div> */}
                  <span className="error">
                    {errorDataModel && errorDataModel.error.message}
                  </span>
                </div>
                {/* {creationData.isFetching === true ? ( */}
                {/* <div className="text-left lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.Loading}
                    </button>
                  </div> */}
                {/* ) : ( */}
                <div className="text-left lp-card-size mt-4">
                  <button
                    type="submit"
                    className="btn  btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                  >
                    {StringConstants.Caccount}
                  </button>
                </div>
                {/* )} */}
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
      <ChatBox />
    </>
  )

}

export default CreateAdminUser;