
export default {
  closeCap:'Close',
  AnswerA:"Answer A",
  AnswerB:"Answer B",
  Training:'Training',
  TrainingPathway:"Training Pathway:",
  TrainingPathwayStep1:'1. Follow the Training button below to link to the relevant training',
  TrainingPathwayStep2:'2. Once you have completed your Training mark as Complete and return to your Training Pathways',
  TrainingPathwayStep3:'3. You will be able to re-take your Lumen once you have completed all of your Training Pathways',
  returnTrainingPathway:"Return to Training Pathways",
  Loading: "loading...",
  next: "Next",
  close: "close",
  back: "back",
  repeat: "Repeat",
  left: "Left",
  RegisterNow: "Register now",
  Login: "Login",
  Retry: "Retry",
  Complete: "Complete",
  Incomplete: "Incomplete",
  Lumenai: "Lumenai",
  Employees: "Employees",
  Communitcation: "Communitcation",
  Name: "Name",
  Job_title: "Job title",
  Dept_team: "Department or team",
  Title_item: "Title of item",
  start_assessment: "Start Lumen",
  try_again: "Try again",
  start_now: "Start now",
  req_access: "Request access",
  // landing Screen
  Welcome_Lumenai: "Welcome to Lumenai",
  letsgo_start: "Let’s get started",
  Already_register: "Already registered?",
  SliderText1:
    "Improve and excel in a wide variation of soft skills to further your career",
  // Register screen
  CreatnewAccount: "Create new account",
  Fname: "First name",
  Lname: "Last name",
  EmailAddress: "Email address",
  Password: "Password",
  Mobile: "Mobile number",
  Cpassword: "Confirm password",
  Register_Tnc:
    "I accept the full terms and conditions and allow for sharing of my detail as necessary for the platform and connected companies.",
  Caccount: "Create account",
  // login screen
  // Login_Title: "Login to your account",
  Login_Title: "Log in",
  forgot_password: "Forgotten password?",
  //Login: "Login",
  not_got_account: "Not got an account?",
  // ForgotPassword screen
  Email: "Email",
  Login_now: "Login now",
  ready_login: "Ready to login?",
  Reset_password: "Reset password",
  forgot_tips:
    "Please enter your email address associated with your account. We will then send you instructions to your email",
  Company_code: "Company code",
  Done: "Done",
  // invite company code
  invite_company: "Have you been invited by a company?",
  invite_company_decs:
    "Your invite email should have a code, please enter it and we will connect you to your team or job",
  Not_join: "Not joining with a company or don’t have a code?",
  skip_now: "Skip for now",
  //me tab
  softSkill: "Soft Skill Check-in",
  ready_to_start: "Ready to start",
  Start: "Start",
  //reset password
  reset_tips:
    "Please follow the instuctions on the email provided, if you did not recieve an email please check your junk or spam folders.",
  send_email: "We’ve sent an email to:",
  //join company
  do_it_decs2: " You’ll find it in the company tab",
  do_it_decs1: " Rather do it later? ",
  do_it_letter: "Do it later",
  right_org_txt: "Not the right organisation?",
  connect_with: "You are looking to connect with…",
  now_connect: "You’re now connected to…",
  all_correct: "All correct?",
  complete_assessment: "Complete",
  company: "Company",
  Request: "Request access",
  skill_assessment:
    "To start you on your Lumenai pathway please complete your Behavioural Skills Lumen",
  // Recent_training: "Recent training",
  Upcoming: "Upcoming",
  // Your_Pathway: "Your Pathway",
  view_all: "view all",
  //Summary Screen
  employability_assessment: "Employability Lumen",
  completed_now: "Completed Now",
  congratulation: "Well Done - Keep it up!",
  congratulation_desc:
    "Review your personalised learning and development pathways before taking your next Lumen",
  time_management: "Time Management",
  time_mt: "Time mgt",
  critical_thinking: "Critical Thinking",
  teamwork: "Teamwork",
  leadership: "Leadership",
  problem_solving: "Problem Solving",
  communication: "Communication",
  creativity: "Creativity",
  emotional_judgement: "Emotional Judgement",
  about_soft_skills: "About soft skills",
  Recent_training: "Recent Learning ",
  dev: "and Development",
  //Upcoming: "Upcoming",
  Your_Pathway: "Your Learning and Development Pathway",
  boost: "You’ve boosted one of your skills up to a managerial level!",
  congos: "Congratulations!",
  Manage: "Managerial skill achieved now!",
  //view_all: "view all",
  Active_learning: "Active Learning and Development",
  Emotional_judgement: "Emotional judgement",
  Start_training: "Start training",
  Save: "Save changes",
  Delete_account: "Delete account",
  edit_your: "Edit your details",
  change_password: "Change password",
  Current_password: "Current password",
  New_password: "New password",
  start: "Start",
  Confirm_new_password: "Confirm new password",
  edit_acc: "Edit account details",
  change_pass: " Change password",
  faq: " FAQ",
  about_lumen: "About Lumenai",
  optional: "optional",
  job: "Job Title",
  dep: "Department ",
  Privacy_Policy: " Privacy Policy",
  tnc: "Terms & Conditions",
  personalies: "Personalised Learning and Development History",
  Your_activities: " Your activities",
  Search_Activities: "Search Activities",
  Subject: "Subject",
  comments: "comments",
  contact_us: "Contact us",
  Edit_your_details: "Edit your details",
  submit: "Submit",
  Most_recent: "Most recent activity",
  Complete14: "Completed 14/01/21",
  See_more: "See more",
  Previous: "Previous activity",
  Lumenai_loader: "Lumenai ...",
  Emp_count: "70",
  Share_my_lumen: "Share my Lumen",
  Your_Lumen: "Your Lumen",
  Error_400: "400 Page Not Found",
  Error_500: "500 Page Not Found",
  Internet_connection: "Internet Connection Issue, Please check",
  Hello: "Hello,",
  Assessment_history: "Lumen history",
  active_learning: " Active learning",
  Search_activities: "Search Activities",
  Your_activitie: "Your activities",
  Approx_effort: "Approx time",
  Questions_loading: "Please wait ... Questions are being loaded",
  Associated: "Associated skills",
  viewlearning: "view learning history",
  Completed_activities: "Completed Activities",
  Pathway_activity: "Pathway Activity",
  You_have: "You’ve already completed this activity",
  Completed: "Completed",
  Mark_as: "Completed",
  // Mark_as: "Mark as completed",
  Delete_Account: "Sure, you want to Delete Account",
  Frequently: "Frequently asked questions",
  Term_and_conditions: "Term and Conditions",
  Select_one: " Select one answer",
  Please_Select: " Please Select one answer",
  Thanks: "Thanks for requesting access to Lumenai",
  Welcome: " Welcome to your first Lumen",
  Assessment_detail: "Lumen details",
  Assessment_skills: "This lumen will assess the following soft skills",
  Want_to: "Want to see what the Lumen is like before you do it for real?",
  Try_example: "Try example questions",
  we_have:
    "We have various ways you can share your Soft Skills Lumen to show on your CV, portfolio or job application",
  share_via: "Share via the link",
  Everything: "Everything you need to know",
  Select_a_soft_skill: " Select a soft skill below to find out more",
  The_different_levels: "How does the Lumen work?",
  attempts3 : "You have up to 3 attempts at each Lumen:",
  Snapshot:"Snapshot:",
  first_attempt_:'This is your first attempt and gives insight into your innate capabilities, especially in areas of management and talent against different workflows.',
  Upskilling:'Upskilling',
  second_attempt:'This is your second attempt after you have followed your personalised learning programme that you were given after your snapshot Lumen.',
  Outgoing:'Outgoing',
  path1:'Below is your active learning and development pathways. Please now click on these pathways to see what personalised training you have been assigned. ',
  path2:'Please follow the guidance and training module in each pathway. You will need to make sure you have marked it, and all of your other assigned learning and development pathways, as complete when you have finished each module.',
  path3:'Remember, all of your active learning and development pathways need to be marked as complete before you can unlock your questionnaire for any further re-takes of the questionnaire.',
  third_attempt:'This is your final attempt, if you have not already met all of your company Benchmarks.',
  TrainingReq:'Your training needs are where you have not met the company benchmark standards. These are laid out in your personalised learning programme. You will be assessed through questionnaires that will evaluate your upskilling as you progress through your personalised learning pathway.',
  CompanyBench:'Company Benchmarks are the skill capability benchmarks set by your current or future employer. These are in relation to specific workflows, so you won’t always understand what is expected or easily meet all of these benchmarks in your first Lumen.',
  ManerialCapability:'To achieve management capability level in your Lumen is very hard. If you achieve this level in your Lumen then you are exceeding the basic standards set by your current or future employer. You are demonstrating that you have management capabilities in relation to the specific workflow that the Lumen measurement has been assigned to. Management Capabilities might be assessed differently across different Lumens as your decision making will be different for different workflows and will demonstrate strengths and weaknesses in different ways. The key way to work out your overall strengths would be to compare your skill capabilities across different Lumens.',
  TalentSkills:'To achieve Talent capability level in your Lumen is very hard. If you achieve this level in your Lumen then you are exceeding the basic standards set by your current or future employer. You are demonstrating that you have talent capabilities in relation to the specific workflow that the Lumen measurement has been assigned to. Talent Capabilities might be assessed differently across different Lumens as your decision making will be different for different workflows and will demonstrate strengths and weaknesses in different ways. The key way to work out your overall strengths would be to compare your skill capabilities across different Lumens.',
  lumenwork:'How does the Lumen work?',
  threetime:'Each Lumen can be taken three times:',
  Lstep1:'This the first first Lumen attempt and gives insight into innate capabilities, especially in areas of management and talent.',
  Lstep2:'This is the second Lumen attempt, only accessible after completing the personalised learning programme that was assigned after the first Lumen.',
  Lstep3:'This the third and final attempt of each Lumen, only accessible after completing the personalised learning programme that was assigned after the second Lumen.',
  Tq:'Training required',
  Tqdecs:'Training required to meet company benchmark standards. These standards can be reassessed after users progress through their personalised learning pathways and re-take their questionnaire.',
  Cb:'Company Benchmark',
  Cbdecs:'Company benchmark standards achieved. These standards are specific to different workflow, so employees won’t always understand what is expected or easily meet all of these benchmark in their first Lumen.',
  Mc:'Managerially Competent',
  Mcdecs:'Management capability standards achieved. This demonstrates management capabilities in relation to the specific workflow that each Lumen questionnaire measures. Management capabilities might be assessed differently across different Lumens as decision making capabilities will be different for different workflows and will demonstrate strengths and weaknesses in different ways.',
  Ts:'Talented Skillset',
  Tsdecs:'Talent capability standards achieved. This demonstrates talent capabilities in relation to the specific workflow that each Lumen questionnaire measures. Talent capabilities might be assessed differently across different Lumens as decision making capabilities will be different for different workflows and will demonstrate strengths and weaknesses in different ways.',
  WelcomeTitle: "Welcome to Lumenai",
  WelcomeData: "The home of agile, 360 standardised context-based behavioural assessments, insights and analytics.",
  LoginDescription: "Please use your credentials to log in below. If you have been invited but not set up your profile please follow the email instructions or contact your account owner.",
  // welcome screen 
  WelcomeFooterHeader: "Want to find out more about Lumenai?",
  WelcomeFooterDescription: "If you are not already part of the platform you can find out more and request a demo of the product.",
  WelcomeFooterLink: "Find out more",
  // admin creation form text 
  AdminCreationTitle: "Finish creating your account",
  AdminCreationDescription: "You have been invited to set up and manage your Lumenai user account where you can take your Lumenai assessments and, if necessary, complete your personalised learning and development pathways that have been assigned to you.<br> Add your organisation email and password to complete your registration and to get started.",
  SpaceSetupTitle: "Lets get your space set up",
  SpaceSetupDescription: "Before we start getting your users added we need to set up your space ready to start inviting!",
  SpaceSetupCompanyName: "Your company name",
  CompanyNameDescription: "This will be the name for your lumenai space and cannot be changed in the future.",
  SpaceSetupCompanyLogo: "Your company logo",
  ImageUploadHelpertext: "For best results please upload a square photo at least 250 x 250 pixels.",
  GetStartedCTA: "Get Started",
  SendInvite: "Send Invite",
  Cancel: "Cancel",
  Newuser: "New user details",
  NewUserDetail: "Assign your new user to your chosen group of measurements, enter their work email, name, and job titles. You can also add tags to each user to give you more options when exploring your Lumenai insights and analytics",
  InviteUserWelcomeTitle: "Invite a new user",
  InviteUserWelcomeData: "Set up a new user and invite them to join different measurement groups in your Lumenai 360 Behavioural Skills measurement hub",
  UserProfileTitle: "You are all set up",
  UserProfileDescription: "Congratulations, we’ve made your account. If you could check & add the below details we can get started!",
  UserProfileDetail: "Your lumenai details",
  UserProfileDetailTitle: "Pocket App",
  UserProfileNumber: "70 Employee",
  UserParaData: "If there is anything incorrect with the details above please <a href='/contact-us' target='_blank'>contact</a> your admin.",
  Age: "Age",
  Gender: "Gender",
  EditUserTitle: "Edit user details",
  EdituserDescription: "Edit the details of the user below, these changes will be reflected in their user account.",
  EditUserDetail: "Edit user details",
  EditUserData: "Edit your user details to effectively organise and streamline your insight and analytics. Adjust job titles, or add or remove tags across groups, to ensure you get a deeper dive into the potential of your organisational Lumenai data.",
  SaveChanges: "Save changes",
  EditAdminUserTitle: "Edit Organisation Admin Details",
  AdminSpace: "Admin Space",
  AssessmentSpace: "Assessment Space"
};
