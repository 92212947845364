import React from "react";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import { __redirectScrollTop } from "../../utils/utility";
const SoftSkillCard = (props) => {
  return (
    <>
      <div className="skill-card   " key={props.keyvalue}>
        <Link
          to={{
            pathname:
              "/pathway/soft-skill-explained-detail/" +
              props.data.categoryIconName,
            state: { data: props.data },
          }}
          onClick={()=>{__redirectScrollTop()}}
        >
          <div className=" d-flex  justify-content-start align-items-center">
            <img src={props.icon} className=" skills-card-icon mr-3 mr-md-4 " />
            <h4 className="text-color-sapphire mb-0 font-size-16 font-family-ubuntu font-weight-medium line-height-18 mb-0">
              {props.title}
            </h4>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SoftSkillCard;
