import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function pathwayComplete_API_Action(token, data,pageNo, groupId) {
  var searchFor = data ? data : "";
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/pathway/completed?q=${searchFor}&page=${pageNo}`,
      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
        groupId: groupId
      },
      //body: JSON.stringify(data),
      types: [
        types.PATHWAY_COMPLETE_REQUEST,
        types.PATHWAY_COMPLETE_RECEIVE,
        {
          type: types.PATHWAY_COMPLETE_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_PathwayComplete_API_Action = () => {
  return {
    type: types.RESET_PATHWAY_COMPLETE,
    payload: null,
  };
};
