import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button, Container, Card } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//custome imports
import { Colors, Images } from "../config";
import {
  getData,
  USER_DATA,
  TOKEN,
  SUMMARY_ATTEMPT,
  setData,
  clearAllData,
  GROUP_DATA,
} from "../utils/storage";
import {
  CategoryCard,
  Loader,
  TipsPopup,
  TopPopup,
  HelpTip,
} from "../components";
import StringConstants from "../config/StringConstants";
import { __redirectScrollTop } from "../utils/utility";

import { assessmentdetailsAPI_Action } from "../stores/actions";
import { Page400, Page500, InternetConnectivity } from "../screens";
const InitialSoft = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorDataModel, setErrorDataModel] = useState();
  const [close, setClose] = useState(true);
  const [questionBankId, setQuestionBankId] = useState();
  const assessmentDetailsData = useSelector((state) => state.assessmentDetails);
  const [displayData, setDisplayData] = useState();
  const [loader, setLoader] = useState();
  const location = useLocation();
  const [errorModel, setErrorModel] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [tutorialValue, setTutorialValue] = useState(true);


  const __closePopup = () => {
    setClose(false);
    history.push("/hub");
  };

  // useEffect(()=>{
  //   if (history.action === 'POP'){
  //     setLoader(true);
  //     window.location.replace('/')
  //     setLoader(false);
  //   }
  // },[])

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData(USER_DATA).then((userData) => {
            // getData(SUMMARY_ATTEMPT).then((sumres) => {
            //   console.log("sumres-- else",sumres);
            //   dispatch(
            //     questionAPI_Reattempt_Action(
            //       res.token,
            //       userData.questionBankId[0],
            //       sumres
            //     )
            //   );
            // });
            getData(GROUP_DATA).then((groupData) => {
              dispatch(assessmentdetailsAPI_Action(res.token, groupData.questionBank[0].questionBankId, groupData.groupId))
            }).catch((error) => console.log(error));
            
          });
          getData("TUTS").then((tutorials) => {
              if(tutorials === false){
                setTutorialValue(tutorials)
              }
          })

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (assessmentDetailsData.isFetching) {
      setLoader(true);
    } else if (
      assessmentDetailsData.isSuccess &&
      assessmentDetailsData.data !== null
    ) {
      setLoader(false);
      let AssessmentData =
        assessmentDetailsData &&
        assessmentDetailsData.data &&
        assessmentDetailsData.data.data;
      setDisplayData(AssessmentData);

      setQuestionBankId(AssessmentData.questionBankDetails.questionBankId);
      setData("quetionBankId", AssessmentData.questionBankId);
      setData(SUMMARY_ATTEMPT, AssessmentData.questionBankDetails.attempt);
      
    } else if (assessmentDetailsData.isError) {
      setErrorModel(true);
      setErrorDataModel(assessmentDetailsData.data);
    }
  }, [assessmentDetailsData]);

  // console.log('enter',assessmentDetailsData);

  // console.log('ssdd',assessmentDetailsData?.data?.data?.questionBankDetails?.attempt)

  useEffect(()=>{
    if(assessmentDetailsData.isTutorial === true){
        window.location.replace("/try-example")
    } 
  },[])

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      {loader === true && <Loader />}

      <>
        <div className="initial-softskill  bg-aliceBlue  wave-section">
          {close && (
            <div className="position-absolute init-top">
              <div className="initial-soft bg-violet pb-2 before-curve-pathway">
                <div className="container">
                  <div className="bg-violet nav-bgcolor nav-height  d-flex justify-content-between align-items-center">
                    <Link to="/hub" className="navbar-brand">
                      <img
                        src={Images.infinity_white}
                        className="lumenai-logo"
                      />
                    </Link>
                    <div className="ml-auto">
                      <button
                        type="button"
                        onClick={() => {
                          __closePopup();
                        }}
                        className="background-transparent border-0"
                      >
                        <img src={Images.close_blue} className="close-icon" />
                      </button>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                      <div className="inner ">
                        <h4 className="  text-color-aliceBlue line-height-24 font-size-20 font-family-ubuntu font-weight-bold mb-2">
                          {StringConstants.Welcome}
                        </h4>
                        <p className=" text-left text-white wx-734 line-height-24 font-size-15 font-family-ubuntu font-weight-regular mb-2">
                          A series of multiple choice questions giving you an
                          insight into your soft skills capabilities, where you
                          can improve and where you already thrive!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container pt-5">
            <div
              className={
                close === true
                  ? "row justify-content-center margin-top-110"
                  : "row justify-content-center"
              }
            >
              <div className="col-lg-8 col-md-10">
                <div className="emp-assessment mt-sm-5">
                  <h4 className="font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3 d-sm-none ">
                    {StringConstants.Assessment_detail}
                  </h4>
                  <div className="wrp-for-mobile assesment-box assessment-inner-space">
                    <div className="cards-box padding-lr top-heading jc-inside-space jc-card-size d-flex align-items-center mb-32 mob-tab0">
                      <div className="company-media">
                        <img
                          src={Images.assessment_icon_small}
                          className="card-img-left mr-3"
                        />
                      </div>
                      <div className="company-content">
                        {displayData && displayData.questionBankDetails && (
                          <>
                            <h4 className="font-family-ubuntu font-weight-bold mb-1 font-size-18 line-height-22 text-color-violet text-black ">
                              {/* {StringConstants.employability_assessment} */}
                              {displayData &&
                                displayData.questionBankDetails &&
                                displayData.questionBankDetails
                                  .questionBankName}
                            </h4>
                            <p className="font-family-ubuntu font-weight-medium mb-0 font-size-13 line-height-20 text-color-darkgray">
                              {/* 20 questions */}
                              {/* {displayData &&
                                  displayData.questionBankDetails &&
                                  displayData.questionBankDetails
                                    .questionBankQuestionsCount} */}
                              {displayData &&
                              displayData.questionBankDetails &&
                              displayData.questionBankDetails
                                .questionBankQuestionsCount === 1 ? (
                                <>
                                  {displayData &&
                                    displayData.questionBankDetails &&
                                    displayData.questionBankDetails
                                      .questionBankQuestionsCount}
                                  <span> Question</span>
                                </>
                              ) : (
                                <>
                                  {displayData &&
                                    displayData.questionBankDetails &&
                                    displayData.questionBankDetails
                                      .questionBankQuestionsCount}
                                  <span> Questions</span>
                                </>
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="cards-box bottom-content  overflow-hidden">
                      <div className="card-content padding-lr jc-inside-space">
                        <p
                          className="font-family-ubuntu font-weight-regular mb-0 font-size-16 line-height-22 text-color-violet"
                          dangerouslySetInnerHTML={{
                            __html:
                              displayData &&
                              displayData.questionBankDetails &&
                              displayData.questionBankDetails
                                .questionBankDescription,
                          }}
                        ></p>
                      </div>
                      <div className="content-footer bg-dithered jc-inside-space bg- d-flex align-items-center justify-content-between">
                        <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                          {StringConstants.Approx_effort}
                        </p>
                        <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                          {displayData &&
                            displayData.questionBankDetails &&
                            displayData.questionBankDetails
                              .questionBankApproxEffort}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="associat-skill mt-4 pb-3">
                  <p className="line-height-18 font-size-13 font-family-ubuntu font-weight-regular text-color-sapphire d-none d-md-block">
                    {StringConstants.Associated}
                  </p>
                  <h4 className="mb-3 font-size-13 font-family-ubuntu font-weight-regular  text-color-darkblue d-md-none">
                    {StringConstants.Assessment_skills}
                  </h4>

                  <Row className="">
                    <Col md={8} lg={7} className="">
                      <div className="category-box d-sm-flex flex-sm-wrap cards-mobile ">
                        {
                          // let newRecentTraining = displayData.recentTraining.slice(1,2)
                          displayData &&
                            displayData.associatedCategories.map(
                              (item, index) => {
                                let recentIcon = "";
                                switch (item.categoryName) {
                                  case "Problem Solving":
                                    recentIcon = Images.probsolving_whitecard;
                                    break;
                                  case "Critical Thinking":
                                    recentIcon =
                                      Images.criticalthinking_whitecard;
                                    break;
                                  case "Teamwork":
                                    recentIcon = Images.teamwork_whitecard;
                                    break;
                                  case "Communication":
                                    recentIcon = Images.communication_whitecard;
                                    break;
                                  case "Leadership":
                                    recentIcon = Images.leadership;
                                    break;
                                  case "Time Management":
                                    recentIcon = Images.timemgt_whitecard;
                                    break;
                                  case "Emotional Judgement":
                                    recentIcon = Images.emotionaljudge;
                                    break;
                                  case "Creativity":
                                    recentIcon = Images.creativity;
                                    break;
                                  case "Flexibility/Adaptability":
                                    recentIcon = Images.Flexibilty_active;
                                    break;

                                  default:
                                    recentIcon = Images.infinity;
                                }

                                return (
                                  <div key={index}>
                                  <CategoryCard
                                    // backgroundColor={recentColor}
                                    color={Colors.royalblue}
                                    cardIcon={recentIcon}
                                    categoryName={item.categoryName}
                                  />
                                  </div>
                                );
                              }
                            )
                        }
                      </div>
                    </Col>
                    <Col md={4} lg={5} className="align-self-end text-md-right">
                      <div className="mb-2 mt-3 mt-sm-0 mb-md-0">
                        <Link
                          to={"/questions/" + questionBankId}
                          onClick={__redirectScrollTop}
                          className="btn mobile-w-size btn-text bg-violet text-white py-3 px-3 text-white btn-circle-30 "
                        >
                          {StringConstants.start_now}
                        </Link>
                      </div>
                    </Col>
                    <p className="error">
                      {errorDataModel && errorDataModel.error.message}
                    </p>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wave bg-violet pb-5 position-relative ">
          <div className=" position-absolute init-bottom-right">
            <HelpTip />
          </div>
          {/* <Container>
            <Row className="justify-content-sm-center text-center text-sm-left">
              <Col md={10} lg={8} className="centeredButton pt-sm-5">
                <p className="font-size-14 text-white mx-auto ml-sm-0 text-w-203 font-family-ubuntu font-weight-regular mb-2 ">
                  {StringConstants.Want_to}
                </p>
                <Link
                  to="/try-example"
                  onClick={__redirectScrollTop}
                  className="btn px-4 py-2 mt-2 border-2 border-color-white font-family-ubuntu text-white btn-circle-30 text-center "
                >
                  {StringConstants.Try_example}
                </Link>
              </Col>
            </Row>
          </Container> */}
        </div>
      </>
    </>
  );
};

export default InitialSoft;
