import ProgressBar from "react-bootstrap/ProgressBar";
import React, { useCallback, useEffect, useState } from "react";
import {
  AssessmentCard,
  CategoryCard,
  LearningCard,
  Loader,
  NestedPieChartProfile,
  TipsPopup,
  HelpTip,
  TopHeader,
  TopHeaderDesktop,
} from "../../components";
import { Colors, Images, StringConstants } from "../../config";
import ProgressCard from "../../components/ProgressCard";
import { __redirectScrollTop } from "../../utils/utility";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal } from "react-bootstrap";
import {
  assessmentSummaryAPI_Action,
  resetAssessmentSummaryAPI_Action,
} from "../../stores/actions";
import { Link, useLocation } from "react-router-dom";

import {
  getData,
  setData,
  TOKEN,
  SUMMARY_ATTEMPT,
  USER_DATA,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";

import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  categoryAPI_Action,
  resetcategoryAPI_Action,
} from "../../stores/actions";
const Summary = (props) => {
  let arraValue = "";
  const assessmentSummaryData = useSelector((state) => state.assessmentSummary);

  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [firstLoader, setFirstLoader] = useState(true);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [assessmentdetails, setAssessmentdetails] = useState("");
  const [categoryArray, setcategoryArray] = useState([]);
  const [pathway, setPathway] = useState([]);
  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
  const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
    useState([]);
  const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
    []
  );
  const [categoryImageArray, setcategoryImageArray] = useState([]);
  const [completedDate, setcompletedDate] = useState();
  const [improvedCategories, setImprovedCategories] = useState([]);
  const [categoriesArray, setCategoriesArray] = useState([]);

  //ALL API START
  const categoryData = useSelector((state) => state.category);
  const [displayData, setDisplayData] = useState();
  const [softskill, setSoftSkill] = useState();
  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          dispatch(categoryAPI_Action(res.token));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (categoryData.isFetching) {
      setLoader(true);
    } else if (categoryData.isSuccess && categoryData.data !== null) {
      setLoader(false);
      let dashboardData =
        categoryData && categoryData.data && categoryData.data.data;
      setDisplayData(dashboardData);
      setSoftSkill(categoryData.data.data);
    } else if (categoryData.isError) {
      setErrorModel(true);
      setErrorDataModel(categoryData.data);
    }
  }, [categoryData]);
  //All API END

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData("USER_DATA").then((userData) => {
            setBackgroundColorArraymanager([]);
            setBackgroundColorArraycore([]);
            setBackgroundColorArraytalent([]);

            getData(SUMMARY_ATTEMPT).then((SUMMARY_ATTEMPT) => {
              setTimeout(() => {
                getData(GROUP_DATA).then((groupData) => {
                  dispatch(assessmentSummaryAPI_Action(
                      res.token,
                      groupData.questionBank[0].questionBankId,
                      SUMMARY_ATTEMPT,
                      groupData.groupId
                    )
                  )
                })
              }, 3000);
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (assessmentSummaryData.isFetching) {
      setLoader(true);
    } else if (
      assessmentSummaryData.isSuccess &&
      assessmentSummaryData.data !== null
    ) {
      let improve_category =
        assessmentSummaryData &&
        assessmentSummaryData.data &&
        assessmentSummaryData.data.data &&
        assessmentSummaryData.data.data.improvedCategories;
      let dataCategory =
        assessmentSummaryData &&
        assessmentSummaryData.data &&
        assessmentSummaryData.data.data &&
        assessmentSummaryData.data.data.categoryArr;
      setCategoriesArray(dataCategory);
      setImprovedCategories(improve_category);
      if (assessmentSummaryData.data.data.questionBankDetails !== undefined) {
        setAssessmentdetails(
          assessmentSummaryData.data.data.questionBankDetails
        );
        let set_date1 = moment(
          assessmentSummaryData.data.data.questionBankDetails
            .questionBankCompletedAt
        ).format("DD/MM/YYYY");
        setcompletedDate(set_date1);
      }
      if (assessmentSummaryData.data.data.categoryArr !== undefined) {
        setcategoryArray(assessmentSummaryData.data.data.categoryArr);
        let newmanagrArray = [];
        let newcoreArray = [];
        let newtalentArray = [];
        let sortedcatArray = assessmentSummaryData.data.data.categoryArr;
        sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
        sortedcatArray.map((item, index) => {
          if (item.highlightCategoryCoreSection === true) {
            newcoreArray.push("#48FFFF");
          } else {
            newcoreArray.push("#381474");
          }
          if (item.highlightCategoryManagerialSection === true) {
            newmanagrArray.push("#275EFF");
          } else {
            newmanagrArray.push("#381474");
          }

          if (item.highlightCategoryTalentedSection === true) {
            newtalentArray.push("#F904FF");
          } else {
            newtalentArray.push("#381474");
          }

          let activeLearningIcon = "";
          switch (item.categoryName) {
            case "Problem Solving":
              activeLearningIcon = Images.problem_solving;
              break;
            case "Critical Thinking":
              activeLearningIcon = Images.criticalthink_white;
              break;
            case "Teamwork":
              activeLearningIcon = Images.teamwork_white;
              break;
            case "Communication":
              activeLearningIcon = Images.comms_white;
              break;
            case "Leadership":
              activeLearningIcon = Images.leadershp_white;
              break;
            case "Time Management":
              activeLearningIcon = Images.timemgt_white;
              break;
            case "Emotional Judgement":
              activeLearningIcon = Images.emotionaljudge_white;
              break;
            case "Creativity":
              activeLearningIcon = Images.creativity_white;
              break;
              case "Flexibility/Adaptability":
                activeLearningIcon = Images.Flexibilty_active
                break;
            default:
              activeLearningIcon = Images.emotionaljudge;
          }

          categoryImageArray.push(activeLearningIcon);
        });

        setLoader(false);
        setFirstLoader(false);

        setBackgroundColorArraycore(newcoreArray);
        setBackgroundColorArraymanager(newmanagrArray);
        setBackgroundColorArraytalent(newtalentArray);
      }
      if (assessmentSummaryData.data.data.activeLearning !== undefined) {
        setPathway(assessmentSummaryData.data.data.activeLearning);
      }
    } else if (assessmentSummaryData.isError) {
      setErrorModel(true);
      setErrorDataModel(assessmentSummaryData.data);
    }
  }, [assessmentSummaryData]);

  let objtoarr = Object.entries(improvedCategories);
  // show congratulation popup multile list

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box summary-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-right">
            <button
              className="p-3 background-transparent border-0"
              onClick={props.onHide}
            >
              <img src={Images.close_skill} />
            </button>
          </div>
          <div className="popup-content">
            <div className="text-left mt-3 mb-5 px-sm-3">
              <h3 className="congrats-text text-center font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-2">
                {"Congratulations!"}
              </h3>
              <p className="congrats-desc-text text-center  font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                {`You’ve boosted one of your skills up to a ${arraValue} level!`}
              </p>
              <div className="position-initial01">
                {improvedCategories.length !== 0
                  ? Object.entries(improvedCategories).map((item, index) => {
                      let activeLearningIcon = "";
                      let cardBGColor = "";
                      switch (item[0]) {
                        case "Problem Solving":
                          activeLearningIcon = Images.probsolving_whitecard;
                          break;
                        case "Critical Thinking":
                          activeLearningIcon =
                            Images.criticalthinking_whitecard;
                          break;
                        case "Teamwork":
                          activeLearningIcon = Images.teamwork_whitecard;
                          break;
                        case "Communication":
                          activeLearningIcon = Images.communication_whitecard;
                          break;
                        case "Leadership":
                          activeLearningIcon = Images.leadership;
                          break;
                        case "Time Management":
                          activeLearningIcon = Images.timemgt_whitecard;
                          break;
                        case "Emotional Judgement":
                          activeLearningIcon = Images.emotionaljudge;
                          break;
                        case "Creativity":
                          activeLearningIcon = Images.creativity;
                          break;
                        case "Flexibility/Adaptability":
                          activeLearningIcon = Images.Flexibilty_active;
                          break;
                        default:
                          activeLearningIcon = Images.infinity;
                      }

                      // console.log("item[1]",item[1].length)
                      // console.log("item---",item[1].map((item2,index2)=>{return(<></>)})  )
                      arraValue = item[1].map((item2, index2) => {
                        return item2;
                      });

                      if (arraValue == "managerial") {
                        cardBGColor = Colors.Navy_Blue;
                      } else if (arraValue == "talented") {
                        cardBGColor = Colors.pink;
                      } else if (arraValue == "core") {
                        cardBGColor = Colors.cyan;
                      } else {
                        cardBGColor = Colors.pink;
                      }
                      return (
                        <div className=" congratulation-card mt-3">
                          <AssessmentCard
                            icon={activeLearningIcon}
                            title={item[0]}
                            subtitle={`${arraValue.pop()}  skill achieved now!`}
                            popupClick={() => {
                              // console.log("tog");
                              setModalShow(true);
                            }}
                            style={{
                              color: "#fff",
                              backgroundColor: cardBGColor,
                            }}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              {/* <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-1">
              Not ready to start yet?
            </p> */}
              <button
                onClick={props.onHide}
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.closeCap}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      {loader === true && <Loader />}
      {firstLoader === true && <Loader />}

      <div>
        <div className="bg-aliceBlue summary-screens">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-10 col-lg-8">
                <TopHeader close />
                <div className="d-none d-md-block">
                  <TopHeaderDesktop black redirectHub="/hub" />
                </div>

                {improvedCategories.length !== 0 ? (
                  // Object.entries(improvedCategories).map((item, index) => {
                  // console.log("impo obt to arr",item)
                  // let arraValue = item[1].map((item2, index2) => {
                  //   return item2;
                  // });
                  
                  // return (
                  objtoarr.slice(0, 1).map((item, index) => {
                    let arraValue = item[1].map((item2, index2) => {
                      // console.log("item2 inside",item2)
                      
                      return item2;
                    });
                    
                    return (
                      <>
                        <div className="summary-decs">
                          <h3 className="congrats-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                            {"Congratulations!"}
                          </h3>
                          <p className="congrats-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                            {/* {`"You’ve boosted one of your skills up to a  level!"`} */}
                            

                            {`You’ve boosted one of your skills up to a`}{" "}
                            <span className="capitalsText">{arraValue.pop()}</span>{" "}
                            {`level!`}
                          </p>
                        </div>
                      </>
                    );
                  })
                ) : (
                  // );
                  // })
                  <div className="summary-decs">
                    <h3 className="congrats-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                      {StringConstants.congratulation}
                    </h3>
                    <p className="congrats-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                      {StringConstants.congratulation_desc}
                    </p>
                  </div>
                )}

                  <div className="sharelink">
                    {/*<Link*/}
                      {/*to="/share-my-result"*/}
                      {/*onClick={__redirectScrollTop}*/}
                      {/*className="font-size-13 text-right d-none d-md-block font-family-ubuntu font-weight-regular line-height-18 text-color-violet"*/}
                    {/*>*/}
                      {/*Share my results*/}
                    {/*</Link>*/}
                  </div>

              </div>
            </div>
          </div>

          <div className="footer-wave bg-violet mt-5 pb-5 summary-wave">
            <div className="container">
              <div className="row justify-content-md-center pt-5">
                <div className="col-md-6 col-lg-4">
                  <div className="d-none d-md-block category-progress-summary category__block1 mt-md-5">
                    <ProgressCard
                      statusText={"Completed " + moment(assessmentdetails.questionBankCompletedAt).format("DD/MM/YYYY")}
                      icon={Images.assessment_white}
                      title={assessmentdetails.questionBankName}
                      percentSymbol
                      percent={
                        assessmentdetails.questionBankOverallPercentage &&
                        assessmentdetails.questionBankOverallPercentage.slice(
                          0,
                          assessmentdetails.questionBankOverallPercentage
                            .length - 1
                        )
                      }
                      categoryCorePercentage={
                        assessmentdetails.questionBankCorePercentage &&
                        assessmentdetails.questionBankCorePercentage.slice(
                          0,
                          assessmentdetails.questionBankCorePercentage.length -
                            1
                        )
                      }
                      categoryManagerialPercentage={
                        assessmentdetails.questionBankManagerialPercentage &&
                        assessmentdetails.questionBankManagerialPercentage.slice(
                          0,
                          assessmentdetails.questionBankManagerialPercentage
                            .length - 1
                        )
                      }
                      categoryTalentedPercentage={
                        assessmentdetails.questionBankTalentedPercentage &&
                        assessmentdetails.questionBankTalentedPercentage.slice(
                          0,
                          assessmentdetails.questionBankTalentedPercentage
                            .length - 1
                        )
                      }
                    />
                  </div>
                  <div className="pie-chart">
                    
                    {assessmentSummaryData.data &&
                    assessmentSummaryData.data.data &&
                    assessmentSummaryData.data.data.categoryArr ? (
                      <NestedPieChartProfile
                        backgroundColorArraycore={backgroundColorArraycore}
                        backgroundColorArraymanager={
                          backgroundColorArraymanager
                        }
                        backgroundColorArraytalent={backgroundColorArraytalent}
                        categoryImageArray={categoryImageArray}
                        catgoryArray={
                          assessmentSummaryData.data &&
                          assessmentSummaryData.data.data &&
                          assessmentSummaryData.data.data.categoryArr
                        }
                      />
                    ) : null}
                  </div>

                  {improvedCategories.length !== 0 ? null : (
                    <div className="learning-cards-box d-none d-md-block">
                      {pathway !== null &&
                      pathway !== undefined &&
                      pathway.length > 0 ? (
                        <div className="inside-what-next d-flex justify-content-between align-items-center  mb-0 mt-4">
                          <p className="what-next  font-size-16 font-family-ubuntu font-weight-medium line-height-22 text-color-white  mb-3">
                            What’s next
                          </p>
                          <Link
                            to="/pathway"
                            onClick={__redirectScrollTop}
                            className="what-view-all font-size-13 font-family-ubuntu  text-color-white font-weight-regular line-height-18  mb-2"
                          >
                            {StringConstants.view_all}
                          </Link>
                        </div>
                      ) : null}

                      {pathway !== null &&
                        pathway !== undefined &&
                        pathway.length > 0 &&
                        pathway.slice(0, 2).map((item, index) => {
                          let activeLearningIcon = "";
                          switch (item.pathwayCategories[0]) {
                            case "Problem Solving":
                              activeLearningIcon = Images.probsolving_whitecard;
                              break;
                            case "Critical Thinking":
                              activeLearningIcon =
                                Images.criticalthinking_whitecard;
                              break;
                            case "Teamwork":
                              activeLearningIcon = Images.teamwork_whitecard;
                              break;
                            case "Communication":
                              activeLearningIcon =
                                Images.communication_whitecard;
                              break;
                            case "Leadership":
                              activeLearningIcon = Images.leadership;
                              break;
                            case "Time Management":
                              activeLearningIcon = Images.timemgt_whitecard;
                              break;
                            case "Emotional Judgement":
                              activeLearningIcon = Images.emotionaljudge;
                              break;
                            case "Creativity":
                              activeLearningIcon = Images.creativity;
                              break;
                            case "Flexibility/Adaptability":
                              activeLearningIcon = Images.Flexibilty_active;
                              break;

                            default:
                              activeLearningIcon = Images.infinity;
                          }

                          return (
                            <>
                              <LearningCard
                                status={item.userPathwayStatus}
                                data={item}
                                icon={activeLearningIcon}
                                title={item.pathwayTitle}
                                subtitle={item.pathwayCategories[0]}
                              />
                            </>
                          );
                        })}
                    </div>
                  )}
                </div>

                <div className="col-md-6 col-lg-4 mb-70 pt-2">
                  <div className="category-progress-summary category__block2 mt-5 pb-2">
                    {categoryArray !== null &&
                      categoryArray !== undefined &&
                      categoryArray.length > 0 &&
                      categoryArray.map((item, index) => {
                        let activeLearningIcon = "";
                        switch (item.categoryName) {
                          case "Problem Solving":
                            activeLearningIcon = Images.problem_solving;
                            break;
                          case "Critical Thinking":
                            activeLearningIcon = Images.criticalthink_white;
                            break;
                          case "Teamwork":
                            activeLearningIcon = Images.teamwork_white;
                            break;
                          case "Communication":
                            activeLearningIcon = Images.comms_white;
                            break;
                          case "Leadership":
                            activeLearningIcon = Images.leadershp_white;
                            break;
                          case "Time Management":
                            activeLearningIcon = Images.timemgt_white;
                            break;
                          case "Emotional Judgement":
                            activeLearningIcon = Images.emotionaljudge_white;
                            break;
                          case "Creativity":
                            activeLearningIcon = Images.creativity_white;
                            break;
                            case "Flexibility/Adaptability":
                            activeLearningIcon = Images.Felixibility_inactive;
                            break;

                          default:
                            activeLearningIcon = Images.emotionaljudge;
                        }
                        return (
                          <ProgressCard
                            data={item}
                            softSkillUrl={{
                              pathname:
                                "/pathway/soft-skill-explained-detail/" +
                                item.categoryIconName,
                              state: { data: item },
                            }}
                            icon={activeLearningIcon}
                            title={item.categoryName}
                            percent={
                              item.categoryPercentage &&
                              item.categoryPercentage.slice(
                                0,
                                item.categoryPercentage.length - 1
                              )
                            }
                            categoryCorePercentage={
                              item.categoryCorePercentage &&
                              item.categoryCorePercentage.slice(
                                0,
                                item.categoryCorePercentage.length - 1
                              )
                            }
                            categoryManagerialPercentage={
                              item.categoryManagerialPercentage &&
                              item.categoryManagerialPercentage.slice(
                                0,
                                item.categoryManagerialPercentage.length - 1
                              )
                            }
                            categoryTalentedPercentage={
                              item.categoryTalentedPercentage &&
                              item.categoryTalentedPercentage.slice(
                                0,
                                item.categoryTalentedPercentage.length - 1
                              )
                            }
                          />
                        );
                      })}
                  </div>

                  {improvedCategories.length !== 0 ? null : (
                    <div className="learning-cards-box d-md-none">
                      {pathway !== null &&
                      pathway !== undefined &&
                      pathway.length > 0 ? (
                        <div className="inside-what-next d-flex justify-content-between align-items-center  mb-0 mt-4">
                          <p className="what-next  font-size-16 font-family-ubuntu font-weight-medium line-height-22 text-color-white  mb-3">
                            What’s next
                          </p>
                          <Link
                            to="/pathway"
                            onClick={__redirectScrollTop}
                            className="what-view-all font-size-13 font-family-ubuntu  text-color-white font-weight-regular line-height-18  mb-2"
                          >
                            {StringConstants.view_all}
                          </Link>
                        </div>
                      ) : null}
                      {pathway !== null &&
                        pathway !== undefined &&
                        pathway.length > 0 &&
                        pathway.slice(0, 2).map((item, index) => {
                          let activeLearningIcon = "";
                          switch (item.pathwayCategories[0]) {
                            case "Problem Solving":
                              activeLearningIcon = Images.probsolving_whitecard;
                              break;
                            case "Critical Thinking":
                              activeLearningIcon =
                                Images.criticalthinking_whitecard;
                              break;
                            case "Teamwork":
                              activeLearningIcon = Images.teamwork_whitecard;
                              break;
                            case "Communication":
                              activeLearningIcon =
                                Images.communication_whitecard;
                              break;
                            case "Leadership":
                              activeLearningIcon = Images.leadership;
                              break;
                            case "Time Management":
                              activeLearningIcon = Images.timemgt_whitecard;
                              break;
                            case "Emotional Judgement":
                              activeLearningIcon = Images.emotionaljudge;
                              break;
                            case "Creativity":
                              activeLearningIcon = Images.creativity;
                              break;
                            case "Flexibility/Adaptability":
                              activeLearningIcon = Images.Flexibilty_active;
                              break;

                            default:
                              activeLearningIcon = Images.emotionaljudge;
                          }

                          return (
                            <>
                              <LearningCard
                                status={item.userPathwayStatus}
                                data={item}
                                icon={activeLearningIcon}
                                title={item.pathwayTitle}
                                subtitle={item.pathwayCategories[0]}
                              />
                            </>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="position-initial">
            {
              // typeof Object.keys(arrayOfObj_improveCategory) !== 'undefined' &&
              // Object.keys(arrayOfObj_improveCategory).length > 0 ) ?
              //typeof Object.keys(improvedCategories) !== 'undefined' &&  Object.keys(improvedCategories).length > 0 ?

              improvedCategories.length !== 0 ? (
                objtoarr.slice(0, 1).map((item, index) => {
                  let activeLearningIcon = "";
                  let cardBGColor = "";
                  switch (item[0]) {
                    case "Problem Solving":
                      activeLearningIcon = Images.probsolving_whitecard;
                      break;
                    case "Critical Thinking":
                      activeLearningIcon = Images.criticalthinking_whitecard;
                      break;
                    case "Teamwork":
                      activeLearningIcon = Images.teamwork_whitecard;
                      break;
                    case "Communication":
                      activeLearningIcon = Images.communication_whitecard;
                      break;
                    case "Leadership":
                      activeLearningIcon = Images.leadership;
                      break;
                    case "Time Management":
                      activeLearningIcon = Images.timemgt_whitecard;
                      break;
                    case "Emotional Judgement":
                      activeLearningIcon = Images.emotionaljudge;
                      break;
                    case "Creativity":
                      activeLearningIcon = Images.creativity;
                      break;
                    case "Flexibility/Adaptability":
                      activeLearningIcon = Images.Felixibility_inactive;
                      break;
                    default:
                      activeLearningIcon = Images.infinity;
                  }

                  // console.log("item[1]",item[1].length)
                  // console.log("item---",item[1].map((item2,index2)=>{return(<></>)})  )
                  let arraValue = item[1].map((item2, index2) => {
                    return item2;
                  });

                  if (arraValue == "managerial") {
                    cardBGColor = Colors.Navy_Blue;
                  } else if (arraValue == "talented") {
                    cardBGColor = Colors.pink;
                  } else if (arraValue == "core") {
                    cardBGColor = Colors.cyan;
                  } else {
                    cardBGColor = Colors.pink;
                  }

                  return (
                    <div className=" congratulation-card mt-3">
                      <AssessmentCard
                        icon={activeLearningIcon}
                        title={item[0]}
                        subtitle={`${arraValue}  skill achieved now!`}
                        popupClick={() => {
                          setModalShow(true);
                        }}
                        viewAll={objtoarr.length}
                        style={{ color: "#fff", backgroundColor: cardBGColor }}
                      />
                    </div>
                  );
                })
              ) : (
                <AssessmentCard
                  icon={Images.assessment_icon_small}
                  title={
                    assessmentdetails.questionBankName !== undefined
                      ? assessmentdetails.questionBankName
                      : null
                  }
                  subtitle={
                    assessmentdetails.questionBankQuestionsCount + " Questions"
                  }
                />
              )
            }
          </div>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <div className="d-none d-md-block  position-fixed lumen-help fixed-lumen-help">
          <HelpTip />
          {/* <TipsPopup /> */}
        </div>
      </div>
    </>
  );
};

export default Summary;
