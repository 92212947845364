import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  pathwayIncomplete_API_Action,
  reset_PathwayIncomplete_API_Action,
} from "../../stores/actions";

// redux
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { Images, StringConstants } from "../../config";
import { GROUP_DATA, TOKEN, getData } from "../../utils/storage";
import {
  CategoryCard,
  LearningCard,
  ProgressCount,
  TipsPopup,
  TopHeader,
  HelpTip,
  TopHeaderDesktop,
  Pagination,
  Loader,
} from "../../components";
import ReactPaginate from "react-paginate";

const ActivityLearningVeiwAll = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const [displayData, setDisplayData] = useState();
  const pathwayIncompleteData = useSelector((state) => state.pathwayIncomplete);
  const [errorDataModel, setErrorDataModel] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [pageNo, setPageNo] = useState("1");

  useEffect(() => {
    __apiCall("");
  }, []);

  useEffect(() => {
    __apiCall("");
  }, [pageNo]);

  const __apiCall = (data) => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData(GROUP_DATA).then((groupValues) => dispatch(pathwayIncomplete_API_Action(res.token, data, pageNo, groupValues.groupId)));
          // if (
          //   pathwayIncompleteData.isFetching === false &&
          //   pathwayIncompleteData.data === null
          // ) {
            // dispatch(pathwayIncomplete_API_Action(res.token, data, pageNo, groupdata.groupId))
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   searchPaywayByTitle("");
  // }, []);

  const searchPaywayByTitle = (data) => {
    dispatch(reset_PathwayIncomplete_API_Action());
    setDisplayData([]);
    setPageNo(1);
    __apiCall(data);
  };

  // console.log("pathway use--", pathwayIncompleteData && pathwayIncompleteData.data &&  pathwayIncompleteData.data.data.pathwayInCompleted);

  useEffect(() => {
    if (pathwayIncompleteData.isFetching) {
      setLoader(true);
    } else if (
      pathwayIncompleteData.isSuccess &&
      pathwayIncompleteData.data !== null
    ) {
      setLoader(false);
      let dashboardData =
        pathwayIncompleteData &&
        pathwayIncompleteData.data &&
        pathwayIncompleteData.data.data;
      setDisplayData(dashboardData.pathwayInCompleted);
    } else if (pathwayIncompleteData.isError) {
      setErrorModel(true);
      setErrorDataModel(pathwayIncompleteData.data);
    }
  }, [pathwayIncompleteData]);

  // console.log("pathway", pathwayIncompleteData);

  const Search = () => {
    return (
      <div className="active-search mb-5 mb-md-4">
        <div className="d-flex justify-content-between align-items-center progress-block">
          <p className="font-size-20 pathway-text text-color-white font-family-ubuntu font-weight-medium line-height-22  mb-3">
            {StringConstants.active_learning}
          </p>
        </div>

        <div className=" searchbar d-flex align-items-center">
          <input
            type="search"
            onChange={(e) => searchPaywayByTitle(e.target.value)}
            className=" py-3 search-field see w-100 "
            placeholder={StringConstants.Search_activities}
          />
        </div>
      </div>
    );
  };

  const activeLearning = () => {
    return (
      <div className="active-learning-box learning-box mb-5">
        <div className="d-flex justify-content-between align-items-center width-48 mb-0 mt-2">
          <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22  mb-3">
            {StringConstants.Your_activitie}
          </p>
        </div>
        <div className="active-block pb-1 mb-4">
          {displayData &&
            displayData.map((item, index) => {
              let activeLearningIcon = "";
              switch (item.pathwayCategories[0]) {
                case "Problem Solving":
                  activeLearningIcon = Images.probsolving_whitecard;
                  break;
                case "Critical Thinking":
                  activeLearningIcon = Images.criticalthinking_whitecard;
                  break;
                case "Teamwork":
                  activeLearningIcon = Images.teamwork_whitecard;
                  break;
                case "Communication":
                  activeLearningIcon = Images.communication_whitecard;
                  break;
                case "Leadership":
                  activeLearningIcon = Images.leadership;
                  break;
                case "Time Management":
                  activeLearningIcon = Images.timemgt_whitecard;
                  break;
                case "Emotional Judgement":
                  activeLearningIcon = Images.emotionaljudge;
                  break;
                case "Creativity":
                  activeLearningIcon = Images.creativity;
                  break;

                default:
                  activeLearningIcon = Images.emotionaljudge;
              }

              return (
                <LearningCard
                  status={item.userPathwayStatus}
                  data={item}
                  icon={activeLearningIcon}
                  title={item.pathwayTitle}
                  subtitle={item.pathwayCategories[0]}
                />
              );
            })}
        </div>
      </div>
    );
  };

  //   const handlePageClickReiew = (e) => {
  //     //  window.scrollTo(0, 0);
  //     const selectedPage = e.selected;
  //     const offset = selectedPage * perPagereview;
  //     setCurrentpagereview(selectedPage)
  //     setOffsetreview(offset)
  // }

  const __pagination = () => {
    if (pathwayIncompleteData && pathwayIncompleteData.data === null) {
      return;
    }

    return (
      <Pagination
        ResponseData={
          pathwayIncompleteData &&
          pathwayIncompleteData.data.data.pathwayInCompletedPagination
        }
        onSubmit={(res) => {
          __applyPage(res);
        }}
      />
    );
  };

  const __applyPage = (res) => {
    setPageNo(res);

    dispatch(reset_PathwayIncomplete_API_Action());
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      {loader === true && <Loader />}

      <div className="active-learning-screen bg-aliceBlue before-curve-pathway full-height">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8 mb-70">
              <TopHeaderDesktop white />
              {Search()}
              {activeLearning()}

              {__pagination()}
            </div>
          </div>
        </div>

        <div className=" position-fixed lumen-help fixed-lumen-help">
          <HelpTip />
          {/* <TipsPopup /> */}
        </div>
      </div>
    </>
  );
};

export default ActivityLearningVeiwAll;
