import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Images, StringConstants } from "../config";

const OGButton = (props) => {
  const answer = props.answer;
  const [selected, setSelected] = useState();

  useEffect(() => {

    if (props.answerKey === 1) {
      setSelected(props.selectedAnswer)
    
    }
    if (props.answerKey === 2) {
      setSelected(props.selectedAnswer)
     
    }
  
   
  }, [props.answer]);


  const onclickRadio=(item)=>{
    setSelected(item.questionOptionId)
    props.selectAnswer(item.questionOptionId, item.questionOptionAnswerSet)
  
  }
  return (
    <>
      <div className="answer d-flex flex-column og">
        <h6
          className="mb-3 select-one-answer font-size-15 font-family-ubuntu font-weight-bold line-height-20  text-color-darkblue"
          style={{ color: "#284482" }}
        >
          {props.answerKey === 1 ? StringConstants.AnswerA : props.answerKey === 2 ? StringConstants.AnswerB : StringConstants.Select_one }
        </h6>
      </div>
        <div className="qcard-size">

        {props.selectAnswer &&
          props.answer.map((item, index) => {
            return (
              <div className="radio-btn-grp mb-3 d-flex justify-content-between" key={index}>
                {/* <Form.Check
                className="radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet"
                type="radio"
                label={item.questionOptionText}
                name={"answer" + props.answerKey}
                id={item.questionOptionId}
                defaultValue={props.selectedAnswer}
                defaultChecked={ item.questionOptionId === selected  ? true : false}
                onClick={() => props.selectAnswer(item.questionOptionId, item.questionOptionAnswerSet)}
              /> */}
              <label className="radio-container">
                <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet"
               > {item.questionOptionText} </p>
                 <input 
                  type="radio" 
                  onClick={() => onclickRadio(item)} 
                  checked={item.questionOptionId ===selected?true:false}
                 // defaultChecked={ item.questionOptionId === selected  ? true : false} 
                  name={"answer" + props.answerKey}
                  id={item.questionOptionId}  
                  //defaultValue={props.selectedAnswer} 
                />
                <span className="radio-checkmark"></span>
              </label>

                <div className="q_hint ml-2">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-3">
                        {item.questionOptionTip}
                      </Tooltip>
                    }
                  >
                    <img className="icon-radio-hint" src={Images.info} />
                  </OverlayTrigger>
                </div>
              </div>
            );
          })}
        </div>
        {props.error && (
          <h6 className="answer-text mb-3 font-family-ubuntu font-weight-bold mb-0 font-size-15 line-height-20 text-danger">
            Please Select one answer
          </h6>
        )}
    </>
  );
};

export default OGButton;

