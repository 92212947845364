import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { StringConstants } from "../../config";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  TextInput,
  TipsPopup,
  TopHeader,
  TopHeaderDesktop,
  ShowPassword,
  ChatBox
} from "../../components";
import {
  isMobile,
  isDesktop,
  ConsoleView,
  isTablet,
} from "react-device-detect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { signin_Action, reset_signin_action } from "../../stores/actions";

import { getData, setData, TOKEN, USER_DATA } from "../../utils/storage";
import Error400 from "../ErrorPages/error-404";
import Welcome from "../../components/Welcome";
import { InfinityWhite, SmallChart } from "../../config/Images";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginRData = useSelector((state) => state.signin);
  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState("");
  const [loginData, setLoginData] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordFunction = () => {
    setShowPassword(!showPassword);
  }

  useEffect(() => {
    // check if user is already logged in
    getData(TOKEN)
      .then((res) => {

        // console.log(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (loginRData.isFetching) {
      setLoader(true);
    } else if (loginRData.isSuccess && loginRData.data !== null) {
      setLoader(false);
      setLoginData(loginRData.data);

      let data = loginRData.data.data;
      setData(TOKEN, { token: data.token, expires_at: data.expires_at });
      setData(USER_DATA, data.user);

      // dispatch(reset_signin_action());
      // window.location.reload();
      window.location.href = window.location.origin;
      //  history.push({
      //     pathname: "/admin-creation",
      //   });
    } else if (loginRData.isError) {
      setErrorModel(true);
      setErrorDataModel(loginRData.data);
    }
  }, [loginRData]);

  //valition schema
  const Login_Schema = yup.object().shape({
    email: yup.string().required("Email is required").email("Email is invalid"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password is Invalid"
      )
      .required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Login_Schema),
  });

  // on submit button call api
  const onSubmitData = useCallback((data) => {
    try {
      dispatch(signin_Action(data));
    } catch (e) {
      console.log(e);
    }
    // console.log("data", data);
  });

  const __redirectUrlTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      <div className="login-screen form-mobile-view bg-aliceBlue wave-section nonreport-login">
        <Welcome title={StringConstants.WelcomeTitle} data={StringConstants.WelcomeData} imgSrc={InfinityWhite} />
        <Container className="admin-form-container">
          <Row className="pb-5 justify-content-sm-center">
            <Col md={10} className="p-0">
              {/* <TopHeader /> */}
              {/* <TopHeaderDesktop /> */}
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="wrpfrom-mobile">
                  <div className="heading-with-logo">
                    <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                      {StringConstants.Login_Title}
                    </h3>
                    <p>{StringConstants.LoginDescription}</p>
                  </div>
                  <div className="cards-box lp-card-size  inside-space">
                    <div className="form-group components-form field-style ddd">
                      <label htmlFor="email">
                        {StringConstants.EmailAddress}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        {...register("email")}
                      />
                      <span className="error">{errors.email?.message}</span>
                    </div>

                    <div className="form-group components-form field-style dd">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password">
                          {StringConstants.Password}
                        </label>
                        <Link
                          to="/forgot-password"
                          onClick={__redirectUrlTop}
                          className="text-color-violet-forgot font-size-21 font-color-#006BF1 font-weight-bold font-ubuntu"
                        >
                          {StringConstants.forgot_password}
                        </Link>
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        id="password"
                        {...register("password")}
                      />
                      <span className="error">{errors.password?.message}</span>
                      <ShowPassword actionProp={showPassword} passFun={showPasswordFunction} />
                    </div>

                    {/* <div className="text-right">
                      <Link
                        to="/forgot-password"
                        onClick={__redirectUrlTop}
                        className="text-color-violet font-size-13 font-weight-medium font-ubuntu"
                      >
                        {StringConstants.forgot_password}
                      </Link>
                    </div> */}
                    <span className="error">
                      {errorDataModel && errorDataModel.error.message}
                    </span>
                  </div>
                </div>

                {loginRData.isFetching === true ? (
                  <div className="text-left lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn btn-text ml-4 bg-violet text-white py-3 px-3 register-btn btn-circle-30 login-button"
                    >
                      {StringConstants.Loading}
                    </button>
                  </div>
                ) : (
                  <div className="text-left lp-card-size  mt-4">
                    <button
                      type="submit"
                      className="btn ml-4  btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30 login-button"
                    >
                      {StringConstants.Login_Title}
                    </button>
                  </div>
                )}
              </form>
              <div className="d-flex justify-content-start content-info">
                <figure>
                  <img src={SmallChart} />
                </figure>
                <div className="ml-4">
                  <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">{StringConstants.WelcomeFooterHeader}</h3>
                  <p>{StringConstants.WelcomeFooterDescription}</p>
                  <a href="https://www.lumenai.tech" target="_blank">
                    {StringConstants.WelcomeFooterLink}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* footer of wave */}
      {/* <div className="footer-wave  pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-md-left">
            <Col md={8} className="pt-sm-5">
              <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-1">
                {StringConstants.not_got_account}
              </p>
              <Link
                to="/register"
                onClick={__redirectUrlTop}
                className="btn mobile-w-243 min-width-178 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.RegisterNow}
              </Link>
            </Col>
          </Row>
          <div className="position-absolute init-top-right d-none d-md-block">
            <TipsPopup />
          </div>
        </Container>
      </div> */}
      {/* fotoer wave end */}
      {/* <ChatBox /> */}
    </>
  );
};

export default Login;
