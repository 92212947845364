import * as types from "../types/index";
import { setInitialState } from "../InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GROUP_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true,
                isError: false,
                isSuccess: false,
            };
        case types.GROUP_CATEGORY_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: true,
                data: action.payload.success,
            };
        case types.GROUP_CATEGORY_FAILURE:
            return {
                ...state,
                isFetching: false,
                isSuccess: false,
                isError: true,
                data: action.payload.response,
            };
        case types.RESET_GROUP_CATEGORY:
            return {
                ...state,
                isFetching: false,
                isError: false,
                isSuccess: false,
                data: null,
            };
        default:
            return {
                ...state,
            };
    }
}
