import ProgressBar from "react-bootstrap/ProgressBar";
import React, { useCallback, useEffect, useState } from "react";
import {
  AssessmentCard,
  CategoryCard,
  LearningCard,
  Loader,
  NestedPieChartProfile,
  TipsPopup,
  HelpTip,
  TopHeader,
  TopHeaderDesktop,
} from "../../components";
import { Modal } from "react-bootstrap";
import { Images, StringConstants } from "../../config";
import ProgressCard from "../../components/ProgressCard";
import { __redirectScrollTop } from "../../utils/utility";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  assessmentSummaryAPI_Action,
  resetAssessmentSummaryAPI_Action,
} from "../../stores/actions";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  getData,
  setData,
  TOKEN,
  SUMMARY_ATTEMPT,
  USER_DATA,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";

import { Page400, Page500, InternetConnectivity } from "../../screens";
import {
  categoryAPI_Action,
  resetcategoryAPI_Action,
} from "../../stores/actions";
const InitialSummary = (props) => {
  const assessmentSummaryData = useSelector((state) => state.assessmentSummary);
  const categoryData = useSelector((state) => state.category);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // let id = useParams();
  // const questionBankId = id.question_id;

  const [loader, setLoader] = useState(false);
  const [firstLoader, setFirstLoader] = useState(true);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [assessmentdetails, setAssessmentdetails] = useState("");
  const [categoryArray, setcategoryArray] = useState([]);
  const [pathway, setPathway] = useState([]);
  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
  const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
    useState([]);
  const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
    []
  );
  const [categoryImageArray, setcategoryImageArray] = useState([]);
  const [completedDate, setcompletedDate] = useState();

  const [modalShow, setModalShow] = useState(false);

  // console.log("location attemp",location.attempt);

  //ALL API START
  const [displayData, setDisplayData] = useState();
  const [softskill, setSoftSkill] = useState();
  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          dispatch(categoryAPI_Action(res.token));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(()=>{
    if (history.action === 'POP'){
      window.location.replace('/')
    }
  },[])

  useEffect(() => {
    // console.log("category")
    if (categoryData.isFetching) {
      setLoader(true);
    } else if (categoryData.isSuccess && categoryData.data !== null) {
      setLoader(false);
      let dashboardData =
        categoryData && categoryData.data && categoryData.data.data;
      setDisplayData(dashboardData);
      setSoftSkill(categoryData.data.data);
    } else if (categoryData.isError) {
      setErrorModel(true);
      setErrorDataModel(categoryData.data);
    }
  }, [categoryData]);
  // console.log("ALL", categoryData);
  //All API END

  useEffect(() => {
    // console.log("summ_att back")
    try {
      getData(TOKEN)
        .then((res) => {
          getData(USER_DATA).then((userData) => {
            // console.log("location.attempt1",location.attempt)
            setBackgroundColorArraymanager([]);
            setBackgroundColorArraycore([]);
            setBackgroundColorArraytalent([]);

            getData(SUMMARY_ATTEMPT).then((SUMMARY_ATTEMPT) => {
              // console.log("SUMMARY_ATTEMPT", SUMMARY_ATTEMPT);
              // console.log(
              //   "userData.questionBankId[0]",
              //   userData.questionBankId[0]
              // );
              setTimeout(() => {
                getData(GROUP_DATA).then((groupData) => {
                  dispatch(assessmentSummaryAPI_Action(
                      res.token,
                      groupData.questionBank[0].questionBankId,
                      SUMMARY_ATTEMPT,
                      groupData.groupId
                    )
                  )
                })
                
              }, 3000);
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (assessmentSummaryData.isFetching) {
      setLoader(true);
    } else if (
      assessmentSummaryData.isSuccess &&
      assessmentSummaryData.data !== null
    ) {
      setLoader(false);
      setFirstLoader(false);
      if (assessmentSummaryData.data.data.questionBankDetails !== undefined) {
        setAssessmentdetails(
          assessmentSummaryData.data.data.questionBankDetails
        );
        let set_date1 = moment(
          assessmentSummaryData.data.data.questionBankDetails
            .questionBankCompletedAt
        ).format("DD/MM/YYYY");
        setcompletedDate(set_date1);
      }
      if (assessmentSummaryData.data.data.categoryArr !== undefined) {
        setcategoryArray(assessmentSummaryData.data.data.categoryArr);
        let newmanagrArray = [];
        let newcoreArray = [];
        let newtalentArray = [];
        let sortedcatArray = assessmentSummaryData.data.data.categoryArr;
        sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
        sortedcatArray.map((item, index) => {
          if (item.highlightCategoryCoreSection === true) {
            newcoreArray.push("#48FFFF");
            } else {
              newcoreArray.push("#381474");
            }
            if (item.highlightCategoryManagerialSection === true) {
            newmanagrArray.push("#275EFF");
          } else {
            newmanagrArray.push("#381474");
          }

          if (item.highlightCategoryTalentedSection === true) {
            newtalentArray.push("#F904FF");
          } else {
            newtalentArray.push("#381474");
          }

          let activeLearningIcon = "";
          switch (item.categoryName) {
            case "Problem Solving":
              activeLearningIcon = Images.problem_solving;
              break;
            case "Critical Thinking":
              activeLearningIcon = Images.criticalthink_white;
              break;
            case "Teamwork":
              activeLearningIcon = Images.teamwork_white;
              break;
            case "Communication":
              activeLearningIcon = Images.comms_white;
              break;
            case "Leadership":
              activeLearningIcon = Images.leadershp_white;
              break;
            case "Time Management":
              activeLearningIcon = Images.timemgt_white;
              break;
            case "Emotional Judgement":
              activeLearningIcon = Images.emotionaljudge_white;
              break;
            case "Creativity":
              activeLearningIcon = Images.creativity_white;
              break;
            case "Flexibility/Adaptability":
                activeLearningIcon = Images.Flexibilty_active;
                break;

            default:
              activeLearningIcon = Images.emotionaljudge;
          }

          categoryImageArray.push(activeLearningIcon);
        });

        setBackgroundColorArraycore(newcoreArray);
        setBackgroundColorArraymanager(newmanagrArray);
        setBackgroundColorArraytalent(newtalentArray);

      }
      if (assessmentSummaryData.data.data.activeLearning !== undefined) {
        setPathway(assessmentSummaryData.data.data.activeLearning);
      }
    } else if (assessmentSummaryData.isError) {
      setErrorModel(true);
      setErrorDataModel(assessmentSummaryData.data);
    }
  }, [assessmentSummaryData]);

  //popup

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={props.onHide}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                Okay great - what’s next?
              </h3>
              {/*<div className="block mb-4 pb-3 px-2">*/}
                {/*<p className="px-4 mt-4 mb-3 font-size-14 family-font-ubuntu font-weight-medium line-height-20 text-color-violet2">*/}
                  {/*Want to use your assessment to generate a soft skill map for*/}
                  {/*your CV or portfolio?*/}
                {/*</p>*/}
                {/*<Link*/}
                  {/*to="/share-my-result"*/}
                  {/*onClick={__redirectScrollTop}*/}
                  {/*className="min-width-245 bg-violet text-white py-3 px-3 btn-circle-30 font-size-16 d-block font-family-ubuntu font-weight-bold line-height-18 text-white"*/}
                {/*>*/}
                  {/*Create shareable Lumen*/}
                {/*</Link>*/}
              {/*</div>*/}
              <div className="block mb-4 px-2">
                <p className="px-4 mt-4 mb-3 font-size-14 family-font-ubuntu font-weight-medium line-height-20 text-color-violet2">
                  Check our your personalised learning pathway and activities
                  based on your results…
                </p>
                <Link
                  to="/pathway"
                  onClick={__redirectScrollTop}
                  className="min-width-245 bg-violet text-white py-3 px-3 btn-circle-30 font-size-16 d-block font-family-ubuntu font-weight-bold line-height-18 text-white"
                >
                  Go to my pathway
                </Link>
              </div>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-4 position-relative">
              <p className="mb-2 mt-2 font-size-14 family-font-ubuntu font-weight-regular line-height-18 text-white">
                Not sure and just want to browse?
              </p>
              <Link
                to="/hub"
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                Go to my Dashboard
              </Link>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  
  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      {loader === true && <Loader />}
      {firstLoader === true && <Loader />}
      <div className="bg-aliceBlue initial-summary-screens summary-screens">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8">
              <TopHeader sharebtn close />
              <div className="summary-decs mt-5">
                <h3 className="block-text text-center text-md-left font-size-20 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                  {/* {StringConstants.congratulation} */}
                  You’ve completed the lumen
                </h3>
                <p className="block-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                  {/* {StringConstants.congratulation_desc} */}
                  See the breakdown of your results below
                </p>
              </div>
              {/*<Link*/}
                {/*to="/share-my-result"*/}
                {/*onClick={__redirectScrollTop}*/}
                {/*className="font-size-13 text-right d-none d-md-block font-family-ubuntu font-weight-regular line-height-18 text-color-violet"*/}
              {/*>*/}
                {/*Share my results*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>

        <div className="footer-wave bg-violet mt-3 pb-5 summary-wave">
          <div className="container">
            <div className="row justify-content-md-center pt-5">
              <div className="col-md-6 col-lg-4">
                <div className="category-progress-summary category__block1 for-mobile mt-md-5">
                  <ProgressCard
                    statusText={"Completed " + moment(assessmentdetails.questionBankCompletedAt).format("DD/MM/YYYY")}
                    icon={Images.assessment_white}
                    title={assessmentdetails.questionBankName}
                    percentSymbol
                    percent={
                      assessmentdetails.questionBankOverallPercentage &&
                      assessmentdetails.questionBankOverallPercentage.slice(
                        0,
                        assessmentdetails.questionBankOverallPercentage.length -
                          1
                      )
                    }
                    categoryCorePercentage={
                      assessmentdetails.questionBankCorePercentage &&
                      assessmentdetails.questionBankCorePercentage.slice(
                        0,
                        assessmentdetails.questionBankCorePercentage.length - 1
                      )
                    }
                    categoryManagerialPercentage={
                      assessmentdetails.questionBankManagerialPercentage &&
                      assessmentdetails.questionBankManagerialPercentage.slice(
                        0,
                        assessmentdetails.questionBankManagerialPercentage
                          .length - 1
                      )
                    }
                    categoryTalentedPercentage={
                      assessmentdetails.questionBankTalentedPercentage &&
                      assessmentdetails.questionBankTalentedPercentage.slice(
                        0,
                        assessmentdetails.questionBankTalentedPercentage
                          .length - 1
                      )
                    }
                  />
                </div>
                <div className="pie-chart">
                  {assessmentSummaryData &&
                  assessmentSummaryData.data &&
                  assessmentSummaryData.data.data &&
                  assessmentSummaryData.data.data.categoryArr !== null &&
                  assessmentSummaryData &&
                  assessmentSummaryData.data &&
                  assessmentSummaryData.data.data &&
                  assessmentSummaryData.data.data.categoryArr !== undefined ? (
                    <NestedPieChartProfile
                      backgroundColorArraycore={backgroundColorArraycore}
                      backgroundColorArraymanager={backgroundColorArraymanager}
                      backgroundColorArraytalent={backgroundColorArraytalent}
                      categoryImageArray={categoryImageArray}
                      catgoryArray={
                        assessmentSummaryData &&
                        assessmentSummaryData.data &&
                        assessmentSummaryData.data.data &&
                        assessmentSummaryData.data.data.categoryArr
                      }
                    />
                  ) : null}
                </div>
                <div className="d-none d-md-block block-btn text-md-center mt-3">
                  <button
                    onClick={() => setModalShow(true)}
                    className="btn min-width-245 px-4 py-2 mt-2 line-height-32 font-size-18 font-weight-bold  border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.next}
                  </button>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-70 pt-2">
                <div className="category-progress-summary category__block2 mt-5 pb-2">
                  {categoryArray !== null &&
                    categoryArray !== undefined &&
                    categoryArray.length > 0 &&
                    categoryArray.map((item, index) => {
                      let activeLearningIcon = "";
                      switch (item.categoryName) {
                        case "Problem Solving":
                          activeLearningIcon = Images.problem_solving;
                          break;
                        case "Critical Thinking":
                          activeLearningIcon = Images.criticalthink_white;
                          break;
                        case "Teamwork":
                          activeLearningIcon = Images.teamwork_white;
                          break;
                        case "Communication":
                          activeLearningIcon = Images.comms_white;
                          break;
                        case "Leadership":
                          activeLearningIcon = Images.leadershp_white;
                          break;
                        case "Time Management":
                          activeLearningIcon = Images.timemgt_white;
                          break;
                        case "Emotional Judgement":
                          activeLearningIcon = Images.emotionaljudge_white;
                          break;
                        case "Creativity":
                          activeLearningIcon = Images.creativity_white;
                          break;
                        case "Flexibility/Adaptability":
                          activeLearningIcon = Images.Felixibility_inactive;
                          break;

                        default:
                          activeLearningIcon = Images.emotionaljudge;
                      }
                      return (
                        <ProgressCard
                          key={index}
                          data={item}
                          softSkillUrl={{
                            pathname:
                              "/pathway/soft-skill-explained-detail/" +
                              item.categoryIconName,
                            state: { data: item },
                          }}
                          icon={activeLearningIcon}
                          title={item.categoryName}
                          percent={
                            item.categoryPercentage &&
                            item.categoryPercentage.slice(
                              0,
                              item.categoryPercentage.length - 1
                            )
                          }
                          categoryCorePercentage={
                            item.categoryCorePercentage &&
                            item.categoryCorePercentage.slice(
                              0,
                              item.categoryCorePercentage.length - 1
                            )
                          }
                          categoryManagerialPercentage={
                            item.categoryManagerialPercentage &&
                            item.categoryManagerialPercentage.slice(
                              0,
                              item.categoryManagerialPercentage.length - 1
                            )
                          }
                          categoryTalentedPercentage={
                            item.categoryTalentedPercentage &&
                            item.categoryTalentedPercentage.slice(
                              0,
                              item.categoryTalentedPercentage.length - 1
                            )
                          }
                        />
                      );
                    })}
                </div>

                <div className=" d-md-none block-btn text-md-center mt-3">
                  <button
                    onClick={() => setModalShow(true)}
                    className="btn min-width-245 px-4 py-2 mt-2 line-height-32 font-size-18 font-weight-bold  border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.next}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <div className="position-initial">
            <AssessmentCard
              icon={Images.assessment_icon_small}
              title={
                assessmentSummaryData.data &&
                assessmentSummaryData.data.data &&
                assessmentSummaryData.data.data.questionBankDetails &&
                assessmentSummaryData.data.data.questionBankDetails
                  .questionBankName !== undefined
                  ? assessmentSummaryData.data &&
                    assessmentSummaryData.data.data &&
                    assessmentSummaryData.data.data.questionBankDetails &&
                    assessmentSummaryData.data.data.questionBankDetails
                      .questionBankName
                  : null
              }
              subtitle={
                assessmentSummaryData.data &&
                assessmentSummaryData.data.data &&
                assessmentSummaryData.data.data.questionBankDetails &&
                assessmentSummaryData.data.data.questionBankDetails
                  .questionBankQuestionsCount + " Questions"
              }
            />
          </div>
        }

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <div className="position-fixed lumen-help fixed-lumen-help">
          <HelpTip />
          {/* <TipsPopup /> */}
        </div>
      </div>
    </>
  );
};

export default InitialSummary;
