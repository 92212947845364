import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  TextInputReadonly,
  TipsPopup,
  TopHeader,
  TopHeaderDesktop,
  HelpTip,
} from "../../components";
import { Images, StringConstants } from "../../config";
import { USER_DATA, getData } from "../../utils/storage";
import { Page400, Page500, InternetConnectivity } from "../../screens";
const JoinCompany = () => {
  const history = useHistory();
  const location = useLocation();
  const [userData, setUserData] = useState();
  const [errorDataModel, setErrorDataModel] = useState();

  // console.log("props", location);
  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        //pass data to next screen
        setUserData(res);
      })
      .catch((error) => {
        // unable to fine user profile
        console.log(error);
      });
  }, []);

  const __redirectScreen = () => {
    window.scrollTo(0, 0);
    history.push("/join-company-confirmation");
  };

  const __redirectUrlTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="join-company bg-aliceBlue wave-section">
        {!navigator.onLine && <InternetConnectivity />}

        <Container>
          <Row className="justify-content-sm-center">
            <Col md={8} className="p-0">
              <TopHeader redirectHub="/hub" />
              <TopHeaderDesktop />
              <div className="wrpfrom-mobile">
                <div className="heading-with-logo-0">
                  <h3 className="subtitle  font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-2 mb-md-3 text-left">
                    {StringConstants.connect_with}
                  </h3>
                </div>
                <div className="cards-box jc-card-size jc-inside-space">
                  <div className="company-media d-flex">
                    {userData && userData.user_company_logo !== "" ? (
                      <img
                        src={userData.user_company_logo}
                        className="jc-icon mr-3 user_logo"
                      />
                    ) : (
                      <img
                        src={Images.infinity}
                        className="jc-icon mr-3 user_logo"
                      />
                    )}

                    <div className="company-body">
                      <h4 className="text-color-blue font-size-18 font-family-ubuntu font-weight-medium line-height-22 mb-1">
                        {/* {StringConstants.Lumenai} */}
                        {userData && userData.user_company_name}
                      </h4>
                      <p className="font-size-13 font-family-ubuntu line-height-18 text-color-violet mb-0">
                        <span>
                          {userData && userData.groupEmployeeCount}{" "}
                        </span>{" "}
                        {StringConstants.Employees}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {userData && (
                <div className="mt-4 mobile-wrpspace">
                  <Row className="">
                    <Col sm={12}>
                      <TextInputReadonly
                        label={StringConstants.Name}
                        Icon={Images.name_icon}
                        type="text"
                        value={userData.name}
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInputReadonly
                        label={StringConstants.Job_title}
                        Icon={Images.job_icon}
                        type="text"
                        value={userData.job_title}
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInputReadonly
                        label={StringConstants.Dept_team}
                        Icon={Images.team_icon}
                        type="text"
                        value={userData.department}
                      />
                    </Col>
                  </Row>

                  <div className="text-sm-right mt-2 mb-4">
                    <button
                      type="button"
                      onClick={__redirectScreen}
                      className="btn  btn-text bg-violet text-white py-3 px-3 join-btn btn-circle-30 font-family-ubuntu"
                    >
                      {StringConstants.all_correct}
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      {/* footer of wave */}
      <div className="footer-wave bg-violet pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-sm-left">
            <Col md={8} className="pt-sm-5">
              <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-2">
                {StringConstants.right_org_txt}
              </p>
              <Link
                to="/company-invite"
                onClick={__redirectUrlTop}
                className="btn mobile-w-243 min-width-178 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center font-family-ubuntu"
              >
                {StringConstants.try_again}
              </Link>
            </Col>
          </Row>
          <div className="position-absolute init-top-right d-none d-sm-block">
            <HelpTip />
            {/* <TipsPopup /> */}
          </div>
        </Container>
      </div>
      {/* fotoer wave end */}
    </>
  );
};

export default JoinCompany;
