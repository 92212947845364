

import { reject } from "lodash";
import { UIAlert } from "./utility";

export const TOKEN = "TOKEN";
export const USER_DATA = "USER_DATA";
export const COMPANY_PROFILE = "COMPANY_PROFILE";
export const SUMMARY_ATTEMPT = "SUMMARY_ATTEMPT";
export const GROUP_DATA = "GROUP_DATA";
export const GROUP_SELECTED = "GROUP_SELECTED";
export const SHARE_RESULT = "SHARE_RESULT";

export const setData = (key, data, callback = null) => {
    try {
        // const encrypted_data = CryptoJS.AES.encrypt(JSON.stringify(data),X_AUTHORIZATION);
        localStorage.setItem(key, JSON.stringify(data));
        if (callback !== null && typeof callback === "function") {
            setTimeout(() => {
                callback()
            }, 500)
        }
    } catch (error) {
        console.log(error)
        UIAlert("error", "There was error while loading the data");
    }
};

export const getData = (key) => {
    // console.log(sessionStorage.getItem(key));
    return new Promise((resolved, reject) => {
        if (localStorage.getItem(key) === null) {
            reject({ message: "unable to find key" })
        } else {
            try {
                const storedValue = localStorage.getItem(key);

                if (typeof storedValue === 'undefined') {
                    reject({})
                } else {
                    //  const bytes = CryptoJS.AES.decrypt(storedValue.toString(),X_AUTHORIZATION);
                    const obj = JSON.parse(storedValue.toString());
                    resolved(obj)
                }
            } catch (error) {
                console.log(error);
                reject(error)
            }
        }
    });
};

export const clearData = (key) => {
    try {
        localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}

export const clearAllData = (key) => {
    try {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(COMPANY_PROFILE);
        localStorage.removeItem(SUMMARY_ATTEMPT);
        localStorage.removeItem(GROUP_DATA);
        localStorage.removeItem(GROUP_SELECTED);
        localStorage.removeItem(SHARE_RESULT);
        localStorage.clear();
        return true;
    } catch (error) {
        return false;
    }
}
