import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button, Container, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
//custome imports
import { Colors, Images } from "../config";
import { useDispatch, useSelector } from "react-redux";
import {
  CategoryCard,
  TipsPopup,
  TopPopup,
  HelpTip,
  TopHeaderDesktop,
  SoftSkillCard,
} from "../components";
import { categoryAPI_Action, resetcategoryAPI_Action } from "../stores/actions";
import { isMobile, isMobileOnly } from "react-device-detect";
import StringConstants from "../config/StringConstants";
import { __redirectScrollTop } from "../utils/utility";
import { Page400, Page500, InternetConnectivity } from "../screens";
import { getData, USER_DATA, TOKEN, clearAllData } from "../utils/storage";
import { LearningCard, ProgressCount } from "../components";
const SoftSkillExplained = () => {
  const dispatch = useDispatch();

  const [displayData, setDisplayData] = useState();
  const [loader, setLoader] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const categoryData = useSelector((state) => state.category);

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          dispatch(categoryAPI_Action(res.token));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (categoryData.isFetching) {
      setLoader(true);
    } else if (categoryData.isSuccess && categoryData.data !== null) {
      setLoader(false);
      let dashboardData =
        categoryData && categoryData.data && categoryData.data.data;
      setDisplayData(dashboardData);
    } else if (categoryData.isError) {
      setErrorModel(true);
      setErrorDataModel(categoryData.data);
    }
  }, [categoryData]);
  // console.log("cat", categoryData);

  const activeLearning = () => {
    return (
      <div className="soft-skill-explain-box mt-5 pt-5">
        <p className="font-size-18 mt-3 mt-md-1 soft-heading font-family-ubuntu line-height-22  font-weight-bold text-color-violet  mb-3">
          Select a soft skill below to find out more
        </p>
        <div className="skill-block pb-1 mb-4">
          {/* {console.log("data..",displayData)} */}
          {displayData &&
            displayData.categories.map((item, index) => {
              let activeLearningIcon = "";
              switch (item.categoryName) {
                case "Problem Solving":
                  activeLearningIcon = Images.probsolving_whitecard;
                  break;
                case "Critical Thinking":
                  activeLearningIcon = Images.criticalthinking_whitecard;
                  break;
                case "Teamwork":
                  activeLearningIcon = Images.teamwork_whitecard;
                  break;
                case "Communication":
                  activeLearningIcon = Images.communication_whitecard;
                  break;
                case "Leadership":
                  activeLearningIcon = Images.leadership;
                  break;
                case "Time Management":
                  activeLearningIcon = Images.timemgt_whitecard;
                  break;
                case "Emotional Judgement":
                  activeLearningIcon = Images.emotionaljudge;
                  break;
                case "Creativity":
                  activeLearningIcon = Images.creativity;
                  break;
                case "Flexibility/Adaptability":
                  activeLearningIcon = Images.Flexibilty_active;
                  break;
                default:
                  activeLearningIcon = Images.infinity;
              }

              return (
                <SoftSkillCard
                  data={item}
                  keyvalue={index}
                  icon={activeLearningIcon}
                  title={item.categoryName}
                />
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <>
      {loader === true ? (
        <>
          <div className="bg-aliceblue loader  position-absolute w-100">
            <div className="d-flex align-items-center flex-column justify-content-center full-height">
              <img src={Images.infinity} alt="infinite" />
              <p className="font-size-22 font-family-ubuntu font-weight-bold colo-text-violet">
                Lumenai ...
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="soft-skill-screen soft-skill-curve header-minusHeight  bg-aliceBlue before-curve-pathway ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-10 col-lg-8 mb-70">
                <TopHeaderDesktop white />
                <div className="inner-soft mt-4">
                  <h4 className="text-color-aliceBlue line-height-24 font-size-20 font-family-ubuntu font-weight-bold mb-2">
                    {StringConstants.Everything}
                  </h4>
                  <p className="text-left text-color-violet line-height-17 font-size-15 font-family-ubuntu font-weight-300 mb-2">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim adem. */}
                  </p>
                </div>
                {activeLearning()}
              </div>
            </div>
          </div>

          <div className=" position-fixed lumen-help fixed-lumen-help">
            <HelpTip />

            {/* <TipsPopup /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SoftSkillExplained;
