import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function create_account_details_action(userId) {
    return {
      [RSAA]: {
        endpoint: `${API_ENDPOINT}/create-account-details/${userId}`,
        method: "GET",
        headers: {
          ...AUTH_HEADERS,
        },
  
        // body: JSON.stringify(data),
        types: [
          types.CREATE_ACCOUNT_DETAILS_REQUEST,
          types.CREATE_ACCOUNT_DETAILS_RECEIVE,
          {
            type: types.CREATE_ACCOUNT_DETAILS_FAILURE,
            meta: (action, state, res) => {
              return action, state, res;
            },
          },
        ],
      },
    };
  }
  
  export const reset_create_account_details_Action = () => {
    return {
      type: types.RESET_CREATE_ACCOUNT_DETAILS,
      payload: null,
    };
  };