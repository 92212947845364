import React,{useState} from 'react'
import { LumenReportPieChartProfile } from '../../components';
import { Images } from '../../config';
import ProgressBarPie from './ProgressBarPie';
import {CategoryDataDummy} from './CategoryDataDummy';
const categoryData=[
  {
    id:1,
  },
  {
    id:2,
  },
  {
    id:3,
  },
]

const RendarGraphPie = (props) => {

    const [categoryImageArray, setcategoryImageArray] = useState([]);

    return (
        <>

          <div className="graphreport__box smallcontainer d-sm-flex">
            <div className="graphreport__inform">
                <h3 className="graphreport__inform--name">{props.userName}</h3>
                <p className="graphreport__inform--jobtitle">{props.jobTitle}</p>
            </div>
                    <div className="graphreport__pie">
                        <div className="report__graph d-flex">
                          
                        {   //array value pass
props.userAttempts.length === 0 ?  
<>{
            categoryData.map((itemBlank,index)=>{
              let newmanagrArray = [];
                                let newcoreArray = [];
                                let newtalentArray = [];
                                let sortedcatArray = CategoryDataDummy;
                    sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
                sortedcatArray.map((item, index) => {
                  if (item.highlightCategoryCoreSection === true) {
                    newcoreArray.push("#48FFFF");
                  } else {
                    newcoreArray.push("#381474");
                  }
                  if (item.highlightCategoryManagerialSection === true) {
                    newmanagrArray.push("#275EFF");
                  } else {
                    newmanagrArray.push("#381474");
                  }
                  if (item.highlightCategoryTalentedSection === true) {
                    newtalentArray.push("#F904FF");
                  } else {
                    newtalentArray.push("#381474");
                  }
                  let activeLearningIcon = "";
                  switch (item.categoryName) {
                    case "Problem Solving":
                      activeLearningIcon = Images.problem_solving;
                      break;
                    case "Critical Thinking":
                      activeLearningIcon = Images.criticalthink_white;
                      break;
                    case "Teamwork":
                      activeLearningIcon = Images.teamwork_white;
                      break;
                    case "Communication":
                      activeLearningIcon = Images.comms_white;
                      break;
                    case "Leadership":
                      activeLearningIcon = Images.leadershp_white;
                      break;
                    case "Time Management":
                      activeLearningIcon = Images.timemgt_white;
                      break;
                    case "Emotional Judgement":
                      activeLearningIcon = Images.emotionaljudge_white;
                      break;
                    case "Creativity":
                      activeLearningIcon = Images.creativity_white;
                      break;
                    case "Flexibility/Adaptability":
                        activeLearningIcon = Images.Flexibilty_active;
                        break;
                    default:
                      activeLearningIcon = Images.infinity;
                  }
        
                  categoryImageArray.push(activeLearningIcon);
              
              })
              return (
                
                  
                   <div className="block" key={index}>
              <p className="font-size-20 text-white font-family-ubuntu font-weight-regular line-height-18 mb-0 attemptCount">#{index + 1}</p>
              
<LumenReportPieChartProfile
    backgroundColorArraycore={newcoreArray}
    backgroundColorArraymanager={newmanagrArray}
    backgroundColorArraytalent={newtalentArray}
    // categoryImageArray={categoryImageArray}
    // catgoryArray={CategoryDataDummy}
/>  

<ProgressBarPie     
  categoryCorePercentage={'0'}
  categoryManagerialPercentage={'0'}
  categoryTalentedPercentage={'0'}
/>                

</div>

              )


                  })
                 

}
</>

:

                            props.userAttempts.map((userAttempt,index)=>{
                                let lastValueRemoveArray;
                                let objArryValue = (typeof userAttempt === 'object') ? Object.values(userAttempt).map((keys)=>{return keys}) : [];
                                lastValueRemoveArray=objArryValue.pop();
                                
                                if(userAttempt.hasOwnProperty('questionBankDetails')){
                                    delete userAttempt['questionBankDetails']
                                }else{
                                    // console.log('Not Found')
                                }

                                let objArry = (typeof userAttempt === 'object') ? Object.values(userAttempt).map((keys)=>{return keys}) : [];
                                let copyObjArry = objArry;

                                let newmanagrArray = [];
                                let newcoreArray = [];
                                let newtalentArray = [];
                                let sortedcatArray = copyObjArry;

                                sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
                                sortedcatArray.map((item, index) => {
                                    if (item.highlightCategoryCoreSection === true) {
                                      newcoreArray.push("#48FFFF");
                                    } else {
                                      newcoreArray.push("#381474");
                                    }
                                    if (item.highlightCategoryManagerialSection === true) {
                                      newmanagrArray.push("#275EFF");
                                    } else {
                                      newmanagrArray.push("#381474");
                                    }
                                    if (item.highlightCategoryTalentedSection === true) {
                                      newtalentArray.push("#F904FF");
                                    } else {
                                      newtalentArray.push("#381474");
                                    }
                                    let activeLearningIcon = "";
                                    switch (item.categoryName) {
                                      case "Problem Solving":
                                        activeLearningIcon = Images.problem_solving;
                                        break;
                                      case "Critical Thinking":
                                        activeLearningIcon = Images.criticalthink_white;
                                        break;
                                      case "Teamwork":
                                        activeLearningIcon = Images.teamwork_white;
                                        break;
                                      case "Communication":
                                        activeLearningIcon = Images.comms_white;
                                        break;
                                      case "Leadership":
                                        activeLearningIcon = Images.leadershp_white;
                                        break;
                                      case "Time Management":
                                        activeLearningIcon = Images.timemgt_white;
                                        break;
                                      case "Emotional Judgement":
                                        activeLearningIcon = Images.emotionaljudge_white;
                                        break;
                                      case "Creativity":
                                        activeLearningIcon = Images.creativity_white;
                                        break;
                                      case "Flexibility/Adaptability":
                                          activeLearningIcon = Images.Flexibilty_active;
                                          break;
                                      default:
                                        activeLearningIcon = Images.infinity;
                                    }
                          
                                    categoryImageArray.push(activeLearningIcon);
                                
                                })   


                                return(
                                     <div className="block" key={index}>
                                        { copyObjArry ? ( 
                                            <>
                                            
                                        <p className="font-size-20 text-white font-family-ubuntu font-weight-regular line-height-18 mb-0 attemptCount">#{index + 1}</p>
                                        <LumenReportPieChartProfile
                                            backgroundColorArraycore={newcoreArray}
                                            backgroundColorArraymanager={newmanagrArray}
                                            backgroundColorArraytalent={newtalentArray}
                                            categoryImageArray={categoryImageArray}
                                            catgoryArray={copyObjArry}
                                        />
                                        </>
                                         ) 
                                        : null}
                                        <>
                                        <ProgressBarPie                                           
                                            questionBankCompletedAt = {lastValueRemoveArray.questionBankCompletedAt}
                                            percent={
                                                lastValueRemoveArray.questionBankOverallPercentage &&
                                                lastValueRemoveArray.questionBankOverallPercentage.slice(
                                                  0,
                                                  lastValueRemoveArray.questionBankOverallPercentage
                                                    .length - 1
                                                )
                                            }
                                            categoryCorePercentage={
                                                lastValueRemoveArray.questionBankCorePercentage &&
                                                lastValueRemoveArray.questionBankCorePercentage.slice(
                                                  0,
                                                  lastValueRemoveArray.questionBankCorePercentage
                                                    .length - 1
                                                )
                                            }
                                            categoryManagerialPercentage={
                                                lastValueRemoveArray.questionBankManagerialPercentage &&
                                                lastValueRemoveArray.questionBankManagerialPercentage.slice(
                                                  0,
                                                  lastValueRemoveArray.questionBankManagerialPercentage
                                                    .length - 1
                                                )
                                            }
                                            categoryTalentedPercentage={
                                                lastValueRemoveArray.questionBankTalentedPercentage &&
                                                lastValueRemoveArray.questionBankTalentedPercentage.slice(
                                                  0,
                                                  lastValueRemoveArray.questionBankTalentedPercentage
                                                    .length - 1
                                                )
                                            }
                                        />
                                            </>
                                        

                                         </div>
                                    
                                )
                            })
                        }
                        
                      </div>

                       
                    </div>
                </div>


        </>
    )
}

export default RendarGraphPie
