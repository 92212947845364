import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function assessmentHistoryAPI_Action(token, groupId,data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/assessment/summary/history`,
      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
        groupId: groupId
      },
      body: "",
      types: [
        types.ASSESSMENT_HISTORY_REQUEST,
        types.ASSESSMENT_HISTORY_RECEIVE,
        {
          type: types.ASSESSMENT_HISTORY_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const resetassessmentHistoryAPI_Action = () => {
  return {
    type: types.RESET_ASSESSMENT_HISTORY,
    payload: null,
  };
};
