import React, { useEffect,useState } from 'react';
import { Link,useHistory,useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import '../../assets/css/scss/lumen-report.scss';
import { Loader} from '../../components';
import { Images, StringConstants } from "../../config";
import { search } from '../../config/Images';
import { lumenReportAPI_Action,resetlumenReportAPI_Action } from '../../stores/actions/lumenReport-action';
import Pagination from 'react-responsive-pagination';
import CategoryShowList from './CategoryShowList';
import LumenReportHeader from './LumenReportHeader';
import RendarGraphPie from './RendarGraphPie';
import LumenReportLogin from './LumenReportLogin';
import { InternetConnectivity } from '..';
import LumenReportSearch from './lumenReportSearch';
import moment from 'moment';

const LumenReport = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const lumenReportData = useSelector((state) => state.lumenReport);
    const [loader,setLoader] = useState(false);
    const [errorModel,setErrorModel] = useState(false);
    const [errorDataModel,setErrorDataModel] = useState(false);
    const [groupDatails,setGroupDatails] = useState();
    const [userlists,setUserlists] = useState();
    const [pageNo, setPageNo] = useState("1");
    const [perPageNo, setPerPageNo] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [inputTimer, setInputTimer] = useState(null);
    const [password, setPassword] = useState();
    const [savePassword, setSavePassWord] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const [filterArrayData, setFilterArrayData] = useState([]);

    let {departmentName,groupId,randumNumber} = useParams();
   
    useEffect(() => {
        document.body.classList.add('hideHeader');
        
    }, []);
    
    useEffect(() => {
        __apiCall();
    }, [pageNo]);




//retrive data    
    useEffect(() => {
        if (lumenReportData.isFetching) {
          setLoader(true);
        } else if (lumenReportData.isSuccess && lumenReportData.data !== null) {
          setLoader(false);
          if (lumenReportData.data.data !== undefined) {
              let grpDetails =lumenReportData.data && lumenReportData.data.data && lumenReportData.data.data.groupDetails;
              setGroupDatails(grpDetails);
              if(lumenReportData.data && lumenReportData.data.data && lumenReportData.data.data.users.length >= 0) {
                let userAttemptCategory =  lumenReportData.data && lumenReportData.data.data && lumenReportData.data.data.users.map((userDataCategory)=>{
                    return userDataCategory
                });
               setUserlists(userAttemptCategory);
              }else{
                  console.log('any on user not attempt assesment')
              }
         }            
          
        } else if (lumenReportData.isError) {
          setLoader(false);
          setErrorModel(true);
          setErrorDataModel(lumenReportData.data);   
        //setErrorMsg(errorDataModel.error && errorDataModel.error.message)
        }
      }, [lumenReportData]);

     
      const __apiCall = () => {
          let data={
            groupId:groupId,
            departmentName:departmentName,
            page:Number(pageNo),
            token:randumNumber,
            password:password,
            query:searchTerm,
            per_page:perPageNo,
            
        }
        try {
            dispatch(lumenReportAPI_Action(data));
        } catch (error) {
          console.log(error);
        }
      }
  
      //on search name
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(searchTerm || searchTerm.length == 0){
                 __apiCall();
            }else{
                resetlumenReportAPI_Action();
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

    const searchByTitle = (e) => {
        let searchname = e.target.value;
        // let searchname = e.target.value.trim();
        // searchname = searchname.replace(/[^A-Za-z]/ig, '')
        // let sear = searchname.replace(/^\s+/g,'');
        let sear = searchname.replace(/^\s\s*/,'').replace(/\s\s*$/,' '); 
        // var lastLetter = sear.charAt(sear.length - 1)
        // if(lastLetter === ' '){
        //     sear="jjj";
        // //    / sear.trim();
        //     // sear.slice(0,sear.lenght - 1);
        //     console.log("add space");
        // }
        // console.log('indeo',sear.substr(0,sear.indexOf('')));
        // console.log("sear",sear.length - 1,sear)
        // let str = sear.split(' ')[0]
        // console.log("str",str.length,str);
        // console.log('indeo',searchname.substr(0,searchname.lastIndexOf('')));
        // let demp =searchname.replace(/\s{2,}/g,' ').trim();
        // console.log("demp",demp.length ) 
        sear.trim();
        setSearchTerm(sear) 
        setPageNo(1)
    }

        
    const handlePageChange = (page)=> {
        window.scrollTo(0,0);
        setPageNo(page);
    }
    
    const PaginationList =()=>{
        let pageNumber = pageNo ? Number(pageNo) : pageNo; 
        let totalPage =lumenReportData.data && lumenReportData.data.data && lumenReportData.data.data.total;
        // console.log('totalPage',totalPage)

        return (
            <Pagination
                maxWidth={300}
                previousLabel={'<'}
                nextLabel={'>'}
                className={"customePagi"}
                total={Math.ceil(totalPage/perPageNo ) }
                // total={11}
                current={pageNumber}
                onPageChange={page => handlePageChange(page)}
            />
        )
    }


    const onSubmitData =(e)=>{  
        e.preventDefault();
        setSavePassWord(password)
        try {
            __apiCall();
        } catch (error) {
            console.log('error',error)    
        }
        setErrorMsg('This password is incorrect.')
        // setErrorMsg(lumenReportData.data && lumenReportData.data.error && lumenReportData.data.error.errors && lumenReportData.data.error.errors.password.toString())    
    }   

// set password in api
const handleSetPassword =(e) =>{
    let passwordSet = e.target.value;
    setPassword(passwordSet)
    setErrorMsg('');
};

let totaltUser = lumenReportData && lumenReportData.data && lumenReportData.data.data &&  lumenReportData.data.data.total

    return(
        <>
        {!navigator.onLine && <InternetConnectivity />}
        {loader === true && <Loader /> }
        {

        (savePassword === undefined || savePassword === null) || lumenReportData.isError ? 
          
          <LumenReportLogin 
          onSubmitData={onSubmitData}
          handleSetPassword={handleSetPassword}
          showError={errorMsg}
          /> 
          :
       
        <>
            {groupDatails !== undefined ?
            <LumenReportHeader
                groupName={groupDatails.groupName}
                departmentName={groupDatails.departmentName}   
                questionBankName={groupDatails.questionBankName}
                userCount={groupDatails.userCount}
                pdfLink={groupDatails.pdfFileLink}
                pdfFileLink={groupDatails.pdfFile}
                pdfFileLinkDate={moment(groupDatails.pdfLinkCreatedAt).format("DD/MM/YYYY HH:mm") }

            />
            : null}
         

            <div className="footer-wave bg-violet mt-md-0 pb-5 lumen-report  header-minusHeight">
                <div className="lumenreport__topspace">
                    <div className="container">
                            <div className="smallcontainer">
                           
                            <LumenReportSearch 
                                value={searchTerm}
                                searchByTitle={searchByTitle}
                            />
                            {   
                                userlists &&  userlists.filter(filterItem => {
                                   let usernamelowercase = filterItem !== null && filterItem !== undefined ?
                                   filterItem && filterItem.name.toLowerCase() : '';
                                   let filtername = usernamelowercase.includes(searchTerm.toLowerCase())
                                   
                                   if (searchTerm ==='' || searchTerm.trim() ) {
                                      return filterItem;
                                    }else if (filtername) {
                                        
                                      return filterItem;
                                    }
                                  }).map((userlist,index)=>{
                                    let userAttemptValueArray = userlist.userAttempts;
                                    
                                    let categoriesData = userlist.categories !==0 ? userlist.categories  : null; 
                                    
                                    if(userlist.userAttempts.length == 1){
                                            for(let i=0; i < 2 ; i++){
                                                userAttemptValueArray.push(categoriesData )
                                            }
                                    }else if(userlist.userAttempts.length == 2){
                                            userAttemptValueArray.push(categoriesData)
                                    }

                                   return(
                                        <div className="component-graph"  key={index}>
                                            <RendarGraphPie 
                                                jobTitle={userlist.job_title}
                                                userName={userlist.name}
                                                userAttempts={userAttemptValueArray}
                                            /> 
                                        </div>
                                    )
                                })
                                // :
                            }
                            {
                                userlists && userlists.length === 0 ?
                                <p className="nodata textstyle font-size-18 text-white py-3 font-weight-bold">Data not Found...</p>
                                : null
                            }
                            
                            { PaginationList()  }  
                            </div>
                        </div> 
                </div>
            </div>
            <CategoryShowList />
                         
            </>
        }
    </>
    );
}

export default LumenReport;