import React from 'react'
import moment from 'moment';

const ProgressBarPie = (props) => {
    const {categoryCorePercentage,categoryManagerialPercentage,categoryTalentedPercentage} = props;
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-2 pb-1 mt-2 pt-1">
               {
                   props.questionBankCompletedAt ? 
                   <h3 className="titleWidthSize  text-white font-size-16 font-family-ubuntu font-weight-medium line-height-18 title mb-0"
                >{moment(props.questionBankCompletedAt).format("DD/MM/YYYY")}</h3>
                :
                <h3 className="titleWidthSize  text-white font-size-16 font-family-ubuntu font-weight-medium line-height-18 title mb-0"
                >-</h3>
               }
                {
                    props.percent ? 
                    <p className="progress-content text-white font-weight-bold mb-0">{props.percent}%</p>
                    :
                    <p className="progress-content text-white font-weight-bold mb-0">-</p>
                }
                
               
            </div>
            <div className="progresscard__content">
                <div className="progressbar progress__chart borderRadius3">
                    {props.percent &&
                       props.percent >= 100+'%'  ? <>
                        
                    <div className="linedot" 
                    style={{
                        position: 'absolute',
                        height: '15px',
                        width: '1px',
                        left: props.percent + '%',
                        // borderRight: '0.5px dashed #fff',
                        borderWidth:"0.5px",
                        borderColor:'#fff',
                        borderStyle:'dashed',
                        bottom: '-5px',
                    }}
                   ></div>
                   </>
                    : null
                }
                    <div 
                        className={
                        props.categoryCorePercentage === "100"
                          ? "bg-Cyan progress__chart-bar "
                          : "bg-Cyan bar3 borderRadius3 progress__chart-bar"
                        }
                        aria-valuemin="0" 
                        aria-valuenow={categoryCorePercentage} 
                        aria-valuemax="100" 
                        style={{width:categoryCorePercentage + '%'}}
                    ></div>
                    <div 
                        className={
                            props.categoryManagerialPercentage === "100"
                              ? "bg-lightBlue progress__chart-bar"
                              : "bg-lightBlue bar2 borderRadius3 progress__chart-bar" 
                          }
                        aria-valuemin="0" 
                        aria-valuenow={categoryManagerialPercentage} 
                        aria-valuemax="100" 
                        style={{width:categoryManagerialPercentage + '%'}}
                    ></div>
                    <div 
                        className={
                            props.categoryTalentedPercentage === "100"
                            ? "bg-color-pink progress__chart-bar"
                            : "bg-color-pink bar1 borderRadius3 progress__chart-bar"
                        }
                        aria-valuemin="0" 
                        aria-valuenow={categoryTalentedPercentage} 
                        aria-valuemax="100"
                        style={{width:categoryTalentedPercentage + '%'}}
                    ></div>
                </div>
            </div>
              
        </>
    )
}

export default ProgressBarPie
