import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function categoryAPI_Action(token, data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/category/all`,
      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
      },
      body: "",
      types: [
        types.CATEGORY_REQUEST,
        types.CATEGORY_RECEIVE,
        {
          type: types.CATEGORY_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const resetcategoryAPI_Action = () => {
  return {
    type: types.RESET_CATEGORY,
    payload: null,
  };
};
