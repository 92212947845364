import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { StringConstants } from "../config";

const Welcome = (props) => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    }


    return (
        <div className={ Array.isArray(props.data) && props.data.length > 0  ? "welcome" : "welcome single-data"}>
            <div className="welcome-content-container">
                <h1>
                    {props.title}
                </h1>
                {Array.isArray(props.data) && props.data.length > 0 ? (
                <Slider {...settings}>
                    {props.data.map((items, index) => {
                    return (
                        <div key={index}>   
                            <p>
                                {items}
                            </p>
                        </div>
                        )
                    })}
                    
                </Slider>
                ) : props.data ? <p>{props.data}</p> : null}
            </div>
            <figure>
                <img src={props.imgSrc} />
            </figure>
        </div>
    )
}

export default Welcome;