import * as types from "../types/index";
import { setInitialState } from "../InitialState";

const INITIAL_STATE = setInitialState({
  data: null,
  isTutorial: false,
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ASSESSMENT_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isTutorial: false,
      };
    case types.ASSESSMENT_DETAILS_RECEIVE:
      return {
        ...state,
        isFetching: false,
        isError: false,
        isSuccess: true,
        data: action.payload.success,
        isTutorial:true
      };
    case types.ASSESSMENT_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        isError: true,
        data: action.payload.response,
        isTutorial: false,
      };
    case types.RESET_ASSESSMENT_DETAILS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: null,
        isTutorial: false,
      };
    default:
      return {
        ...state,
      };
  }
}
