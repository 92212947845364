import React, { useState, useCallback, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Welcome from "../../components/Welcome";
import { StringConstants } from "../../config";
import * as yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { InfinityWhite } from "../../config/Images";
import { reset_space_setup_Action, space_setup_action, resetprofileMeAPI_Action, profileMeAPI_Action, image_upload_Action, preupload_media_action, reset_preupload_media_action, organisation_admin_detail_action, reset_organisation_admin_details_Action } from "../../stores/actions";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getData, TOKEN, USER_DATA, setData, GROUP_DATA } from "../../utils/storage";
import { ChatBox } from "../../components";

const fileType = ['image/png', 'image/jpeg']

const SpaceSetup = () => {

    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const [organisationId, setOrganisationId] = useState();
    const [token, setToken] = useState();
    const adminSpaceSetup = useSelector((state) => state.adminSpaceSetup);
    const preMediaUpload = useSelector((state) => state.preMediaUpload);
    const [organisationName, setOrganisationName] = useState("");
    const profileResponse = useSelector((state) => state.organisationAdminDetails);
    const [mediaObject, setMediaObject] = useState(null);
    const [preSignedURLLoding, setPreSignedURLLoading] = useState(false);
    const [displayURL, setDisplayURL] = useState('');

    // p

    const handleFileChange = (file) => {
        console.log(file.target.files)
        if (file.target.files.length > 0) {
        setMediaObject(file.target.files[0])
        setFile(file.target.files[0]);
        }
    }

    const handleChange = (file) => {

        setMediaObject(file)
        setFile(file);
    }

    useEffect(() => {
        if (mediaObject !== null && fileType.includes(mediaObject.type)) {
            const { fileName, type } = mediaObject;
            console.log("FILE TYPE", type)
            let body = {
                filename: fileName,
                category: 'organisation-logos',
                mimetype: type
            }
            setPreSignedURLLoading(true)
            dispatch(preupload_media_action(token, body))
        } else if (mediaObject !== null && !fileType.includes(mediaObject.type)) {
            alert("Kindly upload only pictures.")
        }
    }, [mediaObject])

    useEffect(() => {
        if (preMediaUpload.isSuccess && preMediaUpload.data !== null) {
            let res = preMediaUpload.data.data;
            try {
                setDisplayURL(res.file_display_url)
                __uploadImage(res.url, res.params, mediaObject);
                dispatch(reset_preupload_media_action())
            } catch (err) {
                console.log("upload error: ", err)
            }
        } else if (preMediaUpload.isError) {
            dispatch(reset_preupload_media_action())
        }
    }, [preMediaUpload])

    const __uploadImage = async (url, params, fileUri) => {
        await fetch(url, { method: "PUT", body: fileUri }).then((response) => {
            if (response.status === 200) {
                console.log("FILE: upload success")
            }
            setPreSignedURLLoading(false)
        })
    }




    useEffect(() => {
        getData(TOKEN)
            .then((res) => {
                setToken(res.token);
                getData(USER_DATA)
                    .then((userres) => { setOrganisationName(userres ? userres.companyName : ""); setOrganisationId(userres.companyId) })
                    .catch((error) => console.log(error))
            })
            .catch((error) => console.log(error));
    }, [])


    useEffect(() => {

        if (adminSpaceSetup.isSuccess === true && adminSpaceSetup.data !== null) {
            getData(TOKEN).then((res) => {
                dispatch(organisation_admin_detail_action(res.token, organisationId));
                // getData(GROUP_DATA).then((groupData) => {
                //     dispatch(profileMeAPI_Action(res.token, groupData.groupId));
                // })
            });

            dispatch(reset_space_setup_Action())
        } else if (adminSpaceSetup.isError === true) {
            dispatch(reset_space_setup_Action())
        }
    }, [adminSpaceSetup])

    useEffect(() => {
        if (profileResponse.isSuccess === true && profileResponse.data !== null) {
            setData(USER_DATA, profileResponse.data.data.user, () => {
                dispatch(reset_organisation_admin_details_Action());
                //window.location.href = window.origin
                window.location.href = "/people-manager"
            })

        } else if (profileResponse.isError) {
            dispatch(reset_organisation_admin_details_Action())
        }
    }, [profileResponse])


    const schema = yup.object().shape({
        companyName: yup
            .string().default(organisationName !== "" ? organisationName : ""),
        companyLogo: yup.mixed().default(displayURL !== "" ? displayURL : "placeholder"),
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitData = useCallback((data) => {
        try {
            data.companyName = data.companyName === "" ? organisationName : data.companyName;
            dispatch(space_setup_action(data, token, organisationId));
        } catch (e) {
            console.log(e);
        }
    });

    const __renderUploadUI = () => {
        if (preSignedURLLoding) {
            return <div style={{ textAlign: "center" }}>Uploading</div>
        }

        if (displayURL !== '') {
            return <img src={displayURL} style={{ maxHeight: "180px" }} />
        } else {
            return <div className="drag-drop-container" style={{ width: "100%", height: "183px", paddingTop: "30px" }}><>
                <p className="drag-text text-center">Tap to <span>Browse</span>
                    <br /><br />
                    Supports JPEG and PNG</p></>
            </div>
        }
    }
    return (
        <div className="space-setup form-mobile-view bg-aliceBlue d-flex justify-content-between">
            <Welcome title={StringConstants.WelcomeTitle} data={StringConstants.WelcomeData} imgSrc={InfinityWhite} />
            <Container className="bg-aliceBlue admin-form-container">
                <Row className="pb-5 justify-content-sm-center">
                    <Col md={10} className="p-0">
                        <form onSubmit={handleSubmit(onSubmitData)} method="post">
                            <div className="wrpfrom-mobile">
                                <div className="heading-with-logo">
                                    <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                                        {StringConstants.SpaceSetupTitle}
                                    </h3>
                                    <p>{StringConstants.SpaceSetupDescription}</p>
                                </div>
                                <div className="cards-box lp-card-size  inside-space">
                                    <div className="form-group components-form field-style ddd">
                                        <label htmlFor="companyName">
                                            {StringConstants.SpaceSetupCompanyName}
                                        </label>
                                        <input
                                            type="text"
                                            name="companyName"
                                            className="form-control"
                                            id="companyName"
                                            defaultValue={organisationName !== "" ? organisationName : ''}
                                            onChange={(e) => setOrganisationName(e.target.value)}
                                            {...register("companyName")}


                                        />
                                        <span className="error">{errors.companyName?.message}</span>
                                        <p>{StringConstants.CompanyNameDescription}</p>
                                    </div>

                                    <div className="form-group components-form field-style ddd drag-drop-field">
                                        <label className="company-logo">
                                            {StringConstants.SpaceSetupCompanyLogo}
                                        </label>

                                        <div style={{ width: "100%", height: "183px", border: "1px dashed", background: "#fff", borderRadius: "5px" }}>
                                            <input type="file" onChange={handleFileChange} style={{ opacity: 0, height: "183px", position: "absolute", zIndex: 999 }} />
                                            {__renderUploadUI()}
                                        </div>
                                        {/* <FileUploader handleChange={handleChange} label="" name="file" type={fileType} children={__renderUploadUI()} /> */}
                                    </div>
                                    <p>{StringConstants.ImageUploadHelpertext}</p>
                                </div>
                                <div className="text-left lp-card-size mt-4">
                                    <button
                                        type="submit"
                                        className="btn  btn-text bg-violet text-white py-3 px-5 register-btn btn-circle-30"
                                    >
                                        {StringConstants.GetStartedCTA}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
            <ChatBox />
        </div>
    )
}

export default SpaceSetup;