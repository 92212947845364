import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AssessmentCard,
  CategoryCard,
  LearningCard,
  Loader,
  NestedPieChartProfile,
  TipsPopup,
  TopHeader,
  HelpTip,
  TopHeaderDesktop,
} from "../components";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Images, StringConstants } from "../config";
import { __redirectScrollTop } from "../utils/utility";

import { shareResultAPI_Action, shareResultPostAPI_Action } from "../stores/actions";
import { Page400, Page500, InternetConnectivity } from "../screens";
import {
  getData,
  setData,
  TOKEN,
  USER_DATA,
  clearAllData,
  SHARE_RESULT,
} from "../utils/storage";

import ProgressCard from "../components/ProgressCard";
import moment from "moment";


const ShareLumen = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const shareAbleData = useSelector((state) => state.shareResultPost);

  const [loader, setLoader] = useState(true);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [categoryArray, setcategoryArray] = useState([]);
  const [categoryImageArray, setcategoryImageArray] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
  const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
    useState([]);
  const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
    []
  );
  let params_id = useParams();
  // console.log("location",location)
  // console.log("props.id",params_id)

  // useEffect(() => {
  //   try {
  //     getData(TOKEN)
  //       .then((res) => {
  //         setBackgroundColorArraymanager([]);
  //         setBackgroundColorArraycore([]);
  //         setBackgroundColorArraytalent([]);
  //         getData("USER_DATA").then((userData) => {
  //           setUpdateData(userData);
  //             dispatch(shareResultAPI_Action(res.token,userData.share_lumen_code));
  //           })
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    document.body.classList.add('hideHeader');
    return (
      document.body.classList.add('hideHeader')
    )
  }, []);

  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        setUpdateData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   try {
  //     dispatch(shareResultAPI_Action(params_id.unicode));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    try {
      getData(SHARE_RESULT)
        .then((res) => {
          dispatch(shareResultPostAPI_Action(res, params_id.unicode))
        })
    } catch (error) {
      console.log(error);
    }

  }, [])


  useEffect(() => {
    if (shareAbleData.isFetching) {
      setLoader(true);
    } else if (shareAbleData.isSuccess && shareAbleData.data !== null) {
      if (shareAbleData.data.data.categoryArr !== undefined) {
        // console.log("in");
        let newmanagrArray = [];
        let newcoreArray = [];
        let newtalentArray = [];
        let sortedcatArray = shareAbleData.data.data.categoryArr;
        sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);

        sortedcatArray.map((item, index) => {
          if (item.highlightCategoryCoreSection === true) {
            newcoreArray.push("#48FFFF");
          } else {
            newcoreArray.push("#381474");
          }
          if (item.highlightCategoryManagerialSection === true) {
            newmanagrArray.push("#275EFF");
          } else {
            newmanagrArray.push("#381474");
          }
          if (item.highlightCategoryTalentedSection === true) {
            newtalentArray.push("#F904FF");
          } else {
            newtalentArray.push("#381474");
          }
        });
        setLoader(false);
        setBackgroundColorArraycore(newcoreArray);
        setBackgroundColorArraymanager(newmanagrArray);
        setBackgroundColorArraytalent(newtalentArray);
      }
    } else if (shareAbleData.isError) {
      setErrorModel(true);
      setErrorDataModel(shareAbleData.data);
    }
  }, [shareAbleData.data]);

  console.log("shareAbleData", shareAbleData)
  // console.log("backgroundColorArraycore",backgroundColorArraycore)

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}
      {loader === true && <Loader />}
      <div className="bg-aliceBlue share-screens ">
        <a class="container navbar-brand blue-logo py-3 d-block" href="/">
          <img src={Images.infinity} class="lumenai-logo" />
        </a>
        <div className="container">
          <div className="row justify-content-md-center">

            <div className="col-md-10 col-lg-8">
              <div className="summary-decs py-4">
                <h3 className="congrats-text text-center  font-size-24 font-family-ubuntu font-weight-bold line-height-24  text-color-violet mb-3">
                  {/* {StringConstants.Your_Lumen} */}
                  {shareAbleData &&
                    shareAbleData.data &&
                    shareAbleData.data.data &&
                    shareAbleData.data.data.userDetails &&
                    shareAbleData.data.data.userDetails.firstName}
                  's Lumen
                </h3>
                {/* <p className="congrats-desc-text text-center text-md-left font-size-16 font-family-ubuntu font-weight-medium line-height-20  text-color-violet">
                  {StringConstants.we_have}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-wave pb-5 summary-wave header-minusHeight mob-height-imp">
        <div className="container">
          <div className="row justify-content-md-center pt-3 pt-md-5">
            <div className="col-md-6 col-lg-4 mob-order-10">
              <div className=" category-progress-summary category__block1 mt-md-5">
                <ProgressCard
                  statusText={"Completed " + moment(shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.completed_at).format("DD/MM/YYYY")}
                  icon={Images.assessment_white}
                  title={shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankName}
                  percentSymbol
                  percent={
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankOverallPercentage &&
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankOverallPercentage.slice(
                      0,
                      shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankOverallPercentage
                        .length - 1
                    )
                  }
                  categoryCorePercentage={
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankCorePercentage &&
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankCorePercentage.slice(
                      0,
                      shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankCorePercentage
                        .length - 1
                    )
                  }
                  categoryManagerialPercentage={
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankManagerialPercentage &&
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankManagerialPercentage.slice(
                      0,
                      shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankManagerialPercentage
                        .length - 1
                    )
                  }
                  categoryTalentedPercentage={
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankTalentedPercentage &&
                    shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankTalentedPercentage.slice(
                      0,
                      shareAbleData && shareAbleData.data && shareAbleData.data.data && shareAbleData.data.data.questionBankDetails.questionBankTalentedPercentage
                        .length - 1
                    )
                  }
                />
              </div>


              <div className="pie-chart">
                {shareAbleData &&
                  shareAbleData.data &&
                  shareAbleData.data.data &&
                  shareAbleData.data.data.categoryArr ? (
                  <NestedPieChartProfile
                    backgroundColorArraycore={backgroundColorArraycore}
                    backgroundColorArraymanager={backgroundColorArraymanager}
                    backgroundColorArraytalent={backgroundColorArraytalent}
                    categoryImageArray={categoryImageArray}
                    catgoryArray={
                      shareAbleData &&
                      shareAbleData.data &&
                      shareAbleData.data.data &&
                      shareAbleData.data.data.categoryArr
                    }
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-70 pt-2">
              <div className="category-progress-summary category__block2 mt-5 pb-2">
                {shareAbleData &&
                  shareAbleData.data &&
                  shareAbleData.data.data &&
                  shareAbleData.data.data.categoryArr !== null &&
                  shareAbleData &&
                  shareAbleData.data &&
                  shareAbleData.data.data &&
                  shareAbleData.data.data.categoryArr !== undefined &&
                  shareAbleData &&
                  shareAbleData.data &&
                  shareAbleData.data.data &&
                  shareAbleData.data.data.categoryArr.length > 0 &&
                  shareAbleData &&
                  shareAbleData.data &&
                  shareAbleData.data.data &&
                  shareAbleData.data.data.categoryArr.map((item, index) => {
                    // console.log("items----", item);
                    let activeLearningIcon = "";
                    switch (item.categoryName) {
                      case "Problem Solving":
                        activeLearningIcon = Images.problem_solving;
                        break;
                      case "Critical Thinking":
                        activeLearningIcon = Images.criticalthink_white;
                        break;
                      case "Teamwork":
                        activeLearningIcon = Images.teamwork_white;
                        break;
                      case "Communication":
                        activeLearningIcon = Images.comms_white;
                        break;
                      case "Leadership":
                        activeLearningIcon = Images.leadershp_white;
                        break;
                      case "Time Management":
                        activeLearningIcon = Images.timemgt_white;
                        break;
                      case "Emotional Judgement":
                        activeLearningIcon =
                          Images.emotionaljudge_white;
                        break;
                      case "Creativity":
                        activeLearningIcon = Images.creativity_white;
                        break;
                      case "Flexibility/Adaptability":
                        activeLearningIcon = Images.Felixibility_inactive;
                        break;

                      default:
                        activeLearningIcon = Images.emotionaljudge;
                    }
                    // console.log("itemsssssdata", item);
                    return (
                      <ProgressCard
                        // data={item}
                        // softSkillUrl={{
                        //   pathname:
                        //     "/pathway/soft-skill-explained-detail/" +
                        //     item.categoryIconName,
                        //   state: { data: item },
                        // }}
                        icon={activeLearningIcon}
                        title={item.categoryName}
                        percent={
                          item.categoryPercentage &&
                          item.categoryPercentage.slice(
                            0,
                            item.categoryPercentage.length - 1
                          )
                        }
                        categoryCorePercentage={
                          item.categoryCorePercentage &&
                          item.categoryCorePercentage.slice(
                            0,
                            item.categoryCorePercentage.length - 1
                          )
                        }
                        categoryManagerialPercentage={
                          item.categoryManagerialPercentage &&
                          item.categoryManagerialPercentage.slice(
                            0,
                            item.categoryManagerialPercentage.length - 1
                          )
                        }
                        categoryTalentedPercentage={
                          item.categoryTalentedPercentage &&
                          item.categoryTalentedPercentage.slice(
                            0,
                            item.categoryTalentedPercentage.length - 1
                          )
                        }
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareLumen;
