export const ORGANISATION_CREATE_TAG_REQUEST = "ORGANISATION_CREATE_TAG_REQUEST";
export const ORGANISATION_CREATE_TAG_RECEIVE = "ORGANISATION_CREATE_TAG_RECEIVE";
export const ORGANISATION_CREATE_TAG_FAILURE = "ORGANISATION_CREATE_TAG_FAILURE";
export const RESET_ORGANISATION_CREATE_TAG = "RESET_ORGANISATION_CREATE_TAG";


export const ORGANISATION_DELETE_TAG_REQUEST = "ORGANISATION_DELETE_TAG_REQUEST";
export const ORGANISATION_DELETE_TAG_RECEIVE = "ORGANISATION_DELETE_TAG_RECEIVE";
export const ORGANISATION_DELETE_TAG_FAILURE = "ORGANISATION_DELETE_TAG_FAILURE";
export const RESET_ORGANISATION_DELETE_TAG = "RESET_ORGANISATION_DELETE_TAG";


export const ORGANISATION_EDIT_TAG_REQUEST = "ORGANISATION_EDIT_TAG_REQUEST";
export const ORGANISATION_EDIT_TAG_RECEIVE = "ORGANISATION_EDIT_TAG_RECEIVE";
export const ORGANISATION_EDIT_TAG_FAILURE = "ORGANISATION_EDIT_TAG_FAILURE";
export const RESET_ORGANISATION_EDIT_TAG = "RESET_ORGANISATION_EDIT_TAG";