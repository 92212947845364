import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  TopHeaderDesktop,
  HelpTip,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { faqData } from "./Data";


const Faq = (props) => {
  const [errorDataModel, setErrorDataModel] = useState();
  return (
    <>
      <div className="faq-screen bg-aliceBlue wave-section full-height">
        <TopHeader />
        {!navigator.onLine && <InternetConnectivity />}
        {errorDataModel && errorDataModel.error.status_code == "500" && (
          <Page500 />
        )}
        {errorDataModel && errorDataModel.error.status_code == "400" && (
          <Page400 />
        )}
        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0 pb-5">
            <Col md={10} lg={10}>
              <TopHeaderDesktop />
              <div className="heading-with-logo mt-3 mt-md-0 d-md-none">
                <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                  {StringConstants.Frequently}
                </h3>
              </div>
              <Row>
                <Col sm={12}>
                  <div className="faq-bock grid-2box">

                  {
                    faqData.map((content,index)=>{
                      return(
                        <div className="cards-box bg-white cardfaq px-3" key={index}>
                          <div className="content-heading py-4">
                            <h4 className="font-family-ubuntu font-weight-bold mb-1 font-size-18 line-height-22  text-black ">
                              {content.heading}
                            </h4>
                          </div>
                          <div className="card-content  py-3">
                            <p className="font-family-ubuntu font-weight-regular mb-0 font-size-15 line-height-22 text-color-black">
                              <span dangerouslySetInnerHTML={{__html: content.decs}} />
                            </p>
                          </div>
                        </div>
                      )
                    })
                  }

                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="d-none d-md-block position-fixed lumen-help fixed-lumen-help">
        <HelpTip />
        {/* <TipsPopup /> */}
      </div>
    </>
  );
};

export default Faq;
