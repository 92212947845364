import React from 'react';

const TextInputReadonly = props => {
    return (
        <div className="form-group readonly-field-style mb-3">
            <label htmlFor={props.id} className="font-size-13 font-family-ubuntu text-color-black line-height-18 font-weight-medium mb-1">{props.label}</label>
            <div className="d-flex box-wrp insde-space">
                <img src={props.Icon} className="img-icon" />
                <input type={props.type} value={props.value} className="form-control font-size-16 font-family-ubuntu text-color-violet line-height-22 font-weight-medium " id={props.id} readOnly/>
            </div>
        </div>);
};

export default TextInputReadonly;