export const IS_AUTH = "IS_AUTH";
export const IS_LOGOUT = "IS_LOGOUT";

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_RECEIVE = "SIGNIN_RECEIVE";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const RESET_SIGNIN = "RESET_SIGNIN";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_RECEIVE = "SIGNUP_RECEIVE";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const RESET_SIGNUP = "RESET_SIGNUP";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_RECEIVE = "FORGET_PASSWORD_RECEIVE";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const RESET_FORGET_PASSWORD = "RESET_FORGET_PASSWORD";

export const COMPANY_CODE_REQUEST = "COMPANY_CODE_REQUEST";
export const COMPANY_CODE_RECEIVE = "COMPANY_CODE_RECEIVE";
export const COMPANY_CODE_FAILURE = "COMPANY_CODE_FAILURE";
export const RESET_COMPANY_CODE = "RESET_COMPANY_CODE";

export const REQUEST_ACCESS_REQUEST = "REQUEST_ACCESS_REQUEST";
export const REQUEST_ACCESS_RECEIVE = "REQUEST_ACCESS_RECEIVE";
export const REQUEST_ACCESS_FAILURE = "REQUEST_ACCESS_FAILURE";
export const RESET_REQUEST_ACCESS = "RESET_REQUEST_ACCESS";

export const CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT_REQUEST";
export const CREATE_ACCOUNT_RECEIVE = "CREATE_ACCOUNT_RECEIVE";
export const CREATE_ACCOUNT_FAILURE = "CREATE_ACCOUNT_FAILURE";
export const RESET_CREATE_ACCOUNT = "RESET_CREATE_ACCOUNT";
