import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";

import {
  markPathwayComplete_API_Action,
  reset_markPathwayComplete_API_Action,
} from "../../stores/actions";

// redux

import { Colors, Images, StringConstants } from "../../config";
import { getData, USER_DATA, clearAllData, TOKEN, GROUP_DATA } from "../../utils/storage";
import {
  CategoryCard,
  LearningCard,
  ProgressCount,
  TipsPopup,
  HelpTip,
  TopHeaderDesktop,
  Loader,
} from "../../components";
import { TopHeader } from "../../components";
import SoftSkillCheckIn from "../../components/SoftSkillCheckIn";
import { pointer } from "d3";
import moment from "moment";

import { Page400, Page500, InternetConnectivity } from "../../screens";
const PathActivityDetail = (props) => {
  const history = useHistory();
  const [pathwayActivity, setPathwayActivity] = useState(false);
  const markCompleteData = useSelector((state) => state.markPathwayComplete);
  const location = useLocation();
  const [loader, setLoader] = useState();

  const [firstLoader, setFirstLoader] = useState(false);
  const [displayData, setDisplayData] = useState();
  const dispatch = useDispatch();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [completedDate, setcompletedDate] = useState();
  const data = location.state.data;
  // console.log("location:::::", location);


  const markComplete = useCallback((e, data) => {
    e.preventDefault();
    try {
      getData(TOKEN)
        .then((res) => {
          getData(GROUP_DATA).then((groupData) => dispatch(markPathwayComplete_API_Action(res.token, data, groupData.groupId)))
          
          // setTimeout(() => {
          //   history.goBack();
          // }, 3000);
          
        })
        .catch((error) => {
          // unable to fine user profile
          // console.log("ERROR GET STORAGE");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  },[]);

  useEffect(() => {
    try {
      dispatch(reset_markPathwayComplete_API_Action());
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (markCompleteData.isFetching) {
      setLoader(true);
    } else if (markCompleteData.isSuccess && markCompleteData.data !== null) {
      setLoader(false);
      let dashboardData = markCompleteData && markCompleteData.data;
      setDisplayData(dashboardData);
      // setModalShow(true)
      dispatch(reset_markPathwayComplete_API_Action());
    } else if (markCompleteData.isError) {
      setErrorModel(true);
      setErrorDataModel(markCompleteData.data);
    }
  }, [markCompleteData]);

  // update icon base on skill
  let largeActiveLearningIcon = "";
  let activeLearningIcon = "";

  switch (data.pathwayCategories[0]) {
    case "Problem Solving":
      activeLearningIcon = Images.probsolving_whitecard;
      largeActiveLearningIcon = Images.large_prob_solving;
      break;
    case "Critical Thinking":
      activeLearningIcon = Images.criticalthinking_whitecard;
      largeActiveLearningIcon = Images.large_crit_thinking;
      break;
    case "Teamwork":
      activeLearningIcon = Images.teamwork_whitecard;
      largeActiveLearningIcon = Images.large_teamwork;
      break;
    case "Communication":
      activeLearningIcon = Images.communication_whitecard;
      largeActiveLearningIcon = Images.large_comunication_bg_large;
      break;
    case "Leadership":
      activeLearningIcon = Images.leadership;
      largeActiveLearningIcon = Images.large_leader;
      break;
    case "Time Management":
      activeLearningIcon = Images.timemgt_whitecard;
      largeActiveLearningIcon = Images.large_time_mgt;
      break;
    case "Emotional Judgement":
      activeLearningIcon = Images.emotionaljudge;
      largeActiveLearningIcon = Images.large_emo_judge;
      break;
    case "Creativity":
      activeLearningIcon = Images.creativity;
      largeActiveLearningIcon = Images.large_creative;
      break;
    default:
      activeLearningIcon = Images.emotionaljudge;
      largeActiveLearningIcon = Images.large_emo_judge;
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-right">
            <button
              className="p-3 background-transparent border-0"
              onClick={props.onHide}
            >
              <img src={Images.close_skill} />
            </button>
          </div>
          <div className="popup-content">
            <div className="text-center mt-3 mb-5 px-sm-3">
              <img
                src={Images.assessment_icon}
                alt="icon"
                className="icon-width-112"
              />

              <p className="px-4 mt-4 mb-3 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet">
                You are ready for your next check in on your soft skills
              </p>

              <button className="btn mt-3  min-width-296 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30">
                {StringConstants.start_assessment}
              </button>
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-1">
                Not ready to start yet?
              </p>
              <button
                onClick={props.onHide}
                className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.skip_now}
              </button>
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }



  return (
    <>
      {firstLoader === true && <Loader />}
      {!navigator.onLine && <InternetConnectivity />}{" "}
      <div className="pathway-active-details-screen background-icon  bg-aliceBlue header-minusHeight mob-height position-relative  overflow-hidden">
        <div
          className="background-icon-postion"
          style={{
            backgroundImage: `url(${largeActiveLearningIcon})`,
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8 mb-5">
              <div className="mt-md-4">
                <div class="breadcrumbs-desktop mb-4 pb-2 pt-3 ">
                  <div class="breadcrumbs-box d-flex position-relative">
                    <button onClick={() => {
                              history.goBack();
                              window.scrollTo(0, 0);
                            }} 
                        class="border-0 p-0 font-size-14 d-flex  align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back">
                      <img src={Images.backArrow} className="back-icon pr-2" />
                      <span class="back_text ">{StringConstants.returnTrainingPathway}</span>
                    </button>
                  </div>
                </div>
                
              </div>
              <div className="emp-assessment mt-sm-5 mt-md-2">
                <h4 className="mb-3 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-darkblue mb-3 ">
                  {StringConstants.TrainingPathway}
                </h4>
                <p class="font-size-16 mt-3 mt-md-1 soft-heading-d font-family-ubuntu line-height-22  font-weight-400 text-color-violet  mb-2">
                  {StringConstants.TrainingPathwayStep1}
                </p>
                <p class="font-size-16 mt-3 mt-md-1 soft-heading-d font-family-ubuntu line-height-22  font-weight-400 text-color-violet  mb-2">
                  {StringConstants.TrainingPathwayStep2}
                </p>
                <p class="font-size-16 mt-3 mt-md-1 soft-heading-d font-family-ubuntu line-height-22  font-weight-400 text-color-violet  mb-4">
                  {StringConstants.TrainingPathwayStep3}
                </p>
                <div className="wrp-for-mobile assesment-box assessment-inner-space mt-1">
                  <div className="cards-box padding-lr top-heading jc-inside-space jc-card-size d-flex align-items-center mb-32 ">
                    <div className="company-media">
                      <img
                        src={activeLearningIcon}
                        className="card-img-left mr-3"
                      />
                    </div>
                    <div className="company-content">
                      <h4 className="font-family-ubuntu font-weight-bold mb-1 font-size-18 line-height-22  ">
                        {data.pathwayTitle}
                      </h4>
                      <p className="font-family-ubuntu font-weight-medium mb-0 font-size-13 line-height-20 text-color-darkgray">
                        {data.pathwayCategories[0]}
                      </p>
                    </div>
                  </div>

                  <div className="cards-box bottom-content  overflow-hidden key={props.keyvalue} ">
                    <div className="card-content padding-lr jc-inside-space">
                      <div
                        className="font-family-ubuntu font-weight-regular mb-0 font-size-16 line-height-22 text-color-violet"
                        dangerouslySetInnerHTML={{
                          __html: data.pathwayDescription,
                        }}
                      />
                      {/* <Link target={"_blank"} to={data.pathwayURL} >{data.pathwayURL}</Link> */}
                      {/* <button
                        className="background-transparent border-0 p-0 text-color-blue font-weight-medium"
                        onClick={() => window.open(data.pathwayURL, "_blank")}
                      >
                        {data.pathwayURL}
                      </button> */}
                    </div>
                    <div className="content-footer bg-dithered jc-inside-space bg- d-flex align-items-center justify-content-between">
                      <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                        {StringConstants.Approx_effort}
                      </p>
                      <p className="font-family-ubuntu font-weight-bold mb-0 font-size-16 line-height-18 text-color-violet ">
                        {data.pathwayApproxEffort}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5 ">
                <div className="complete-box d-md-flex justify-content-between  align-items-center">
                  {(displayData && displayData.status_code === 200) ||
                  data.userPathwayStatus === 1 ? (
                    <>
                      <div className="complete-box-pathway">
                        <div className="text-block">
                          <p className="mt-4 mb-3 font-size-16 font-weight-medium text-color-black font-family-ubuntu mt-3">
                            {StringConstants.You_have}
                          </p>
                        </div>
                        <div className="pathway-activity-card cards-wrp py-4">
                          <div className="rwp d-flex align-items-center justify-content-between">
                            <p class="mb-0 font-size-16 line-height-18 font-family-ubuntu font-weight-medium text-color-purple">
                              {StringConstants.Completed}{" "}
                              {moment(data.userPathwayUpdatedAt).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                            <img
                              className="check-icon icon"
                              src={Images.complete_checkbox}
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="button-box align-self-end text-md-right">
                        <button
                          onClick={() => window.open(data.pathwayURL, "_blank")}
                          className="mt-5 my-md-0 btn min-width-245 bg-violet font-family-ubuntu font-weight-bold font-size-18 line-height-32 text-white py-2 px-3 text-white btn-circle-30 "
                        >
                          {StringConstants.Training}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="box-pathway">
                        <div className="text-block">
                          <p className="mt-4 mb-3 font-size-16 font-weight-medium text-color-black font-family-ubuntu mt-3">
                            Already completed this activity?
                          </p>
                        </div>

                        <div className="pathway-activity-card cards-wrp py-4">
                          <div className="rwp d-flex align-items-center justify-content-between">
                            <p class="mb-0 font-size-16 line-height-18 font-family-ubuntu font-weight-medium text-color-violet">
                              {StringConstants.Mark_as}
                            </p>

                            <div
                              onClick={(e) => {
                                markComplete(e, {
                                  userPathwayId:
                                    location.state.data.userPathwayId,
                                  userPathwayQuestionBankId:
                                    location.state.data
                                      .userPathwayQuestionBankId,
                                  userPathwayQuestionId:
                                    location.state.data.userPathwayQuestionId,
                                  pathwayId: location.state.data.pathwayId,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                              class="incomplete-circle"
                            ></div>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="button-box align-self-end text-md-right">
                        <button
                          //onClick={MyVerticallyCenteredModal()}
                          onClick={() => window.open(data.pathwayURL, "_blank")}
                          className="mt-5 my-md-0 btn min-width-245 bg-violet font-family-ubuntu font-weight-bold font-size-18 line-height-32 text-white py-2 px-3 text-white btn-circle-30 "
                        >
                          {StringConstants.Training}
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <p className="error mt-3">
                  {" "}
                  {errorDataModel && errorDataModel.message}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" position-fixed lumen-help fixed-lumen-help">
          <HelpTip />
          {/* <TipsPopup /> */}
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default PathActivityDetail;
