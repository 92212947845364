

// id: number
// question: string
// answerset: object
// parent: number


export const chatQeustions = [{
    id: 1, question: "What are you looking to get help with?", answerset: [
        {
            id: 2,
            question: "Creating a team",
            answerset: "object",
            parent: 4,
        }, {
            id: 3,
            question: "Group insight search ",
            answerset: "object",
            parent: 5,
        }, {
            id: 7,
            question: "Tag lead insight",
            answerset: "object",
            parent: 5,
        }
    ], parent: null
},
{
    id: 4, question: "How many people are you looking for?", answerset: [
        {
            id: 11,
            value: 3,
            question: "2-3",
            answerset: "object",
            parent: 5,
        }, {
            id: 12,
            value: 6,
            question: "4-6",
            answerset: "object",
            parent: 5,
        }, {
            id: 13,
            value: 10,
            question: "7-10",
            answerset: "object",
            parent: 5,
        }, {
            id: 14,
            value: 11,
            question: "11+",
            answerset: "object",
            parent: 5,
        }
    ], parent: null
}, {
    id: 5, question: "From a single group or whole company?", answerset: "apiCall",
    apiCallId: [6, { id: 8, optionCall: "group-category" }],
    parent: null
}, {
    id: 6, question: "What traits are you looking to cover?", answerset: "apiTaxonomyCall",
    apiCallId: 4,
    parent: null
}, {
    id: 8,
    question: "Do you want to select Category and Lumen Level?",
    apiCall: "option-call",
    answerset: [
        {
            id: 9,
            question: "Yes",
            answerset: "group-content-data",
            optionCall: "group-category",
            parent: null,
        }, {
            id: 10,
            question: "No",
            answerset: "object",
            optionCall: "taxonomy-list",
            parent: null,
        }
    ]
}]



