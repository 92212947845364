import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function questionAPI_Action(token,categoryId, groupId, data) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/assessment/${categoryId}/details`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token,
                groupId: groupId
                },
            body: '',
            types: [
                types.QUESTION_REQUEST,
                types.QUESTION_RECEIVE, {
                    type: types.QUESTION_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export function questionAPI_Reattempt_Action(token,categoryId,attemptId, groupId) {
  return {
      [RSAA]: {
          endpoint: `${API_ENDPOINT}/assessment/${categoryId}/details/reattempt/${attemptId}`,
          method: 'GET',
          headers: {
              ...AUTH_HEADERS,
              'Authorization': 'Bearer ' + token,
              groupId: groupId
              },
          body: '',
          types: [
              types.QUESTION_REQUEST,
              types.QUESTION_RECEIVE, {
                  type: types.QUESTION_FAILURE,
                  meta: (action, state, res) => {
                      return (action, state, res);
                  }
              }]
      }
  }
}


export function question_Response_Action(token, data,groupId,attempt) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/me/assessment/responses`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
        'Authorization': 'Bearer ' + token,
        groupId: groupId
      },

      body: JSON.stringify(data),
      types: [
        types.ANSWER_RESPONSE_REQUEST,
        types.ANSWER_RESPONSE_RECEIVE,
        {
          type: types.ANSWER_RESPONSE_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}


export const resetQuestionAPI_Action = () => {
    return {
        type: types.RESET_QUESTION,
        payload: null
    }
};


export const resetAnswerResponseAPI_Action = () => {
  return {
      type: types.RESET_ANSWER_RESPONSE,
      payload: null
  }
};
