import React,{useState,useEffect} from 'react'
import { guide__icon,leveltwo,trainingrequire,twolevelinfo,levelthree, infinity,flexibiltyDark,emotionaljudgeDark,creativityDark,criticalthinkingDark,timemgtDark,teamworkDark,leadershipDark,communicationDark, search,probsolvingDark,showkey__icon,cross__icon } from '../../config/Images';
import {StringConstants} from '../../config'
const CategoryShowList = () => {

    const [open,setOpen] = useState(false);
    const [guide,setGuide] = useState(false);

    useEffect(() => {
        document.documentElement.classList.remove("position__fixed");
    }, [])

    const handleToggle = ()=>{
        setOpen(!open);
        setGuide(false);
        document.documentElement.classList.remove("position__fixed");
    }
    const handleGuideToggle = ()=>{
        // if(guide !== true){
        //     document.documentElement.classList.add("position__fixed");
        // }else{
        //     document.documentElement.classList.remove("position__fixed");
        // }
       
        setGuide(!guide);
        setOpen(false);

       
    }
      

    const ShowlistData = [
        {
            id:1,
            categoryname:"Time Management ",
            categoryicon:timemgtDark,
        },
        {
            id:2,
            categoryname:"Critical Thinking",
            categoryicon:criticalthinkingDark,
        },
        {
            id:3,
            categoryname:"Teamwork ",
            categoryicon:teamworkDark,
        },
        {
            id:4,
            categoryname:"Leadership ",
            categoryicon:leadershipDark,
        },
        {
            id:5,
            categoryname:"Problem Solving",
            categoryicon:probsolvingDark,
        },
        {
            id:6,
            categoryname:"Communication ",
            categoryicon:communicationDark,
        },
        {
            id:7,
            categoryname:"Creativity ",
            categoryicon:creativityDark,
        },
        {
            id:8,
            categoryname:"Emotional Judgement ",
            categoryicon:emotionaljudgeDark,
        },
        {
            id:9,
            categoryname:"Flexibility/Adaptability ",
            categoryicon:flexibiltyDark,
        },
        
    ];



    const ShowKayList = ()=>{
        return(
            <>
                <div className="showkey__box key__fixed  showlist__position">
                    <ul className="showkey__list">
                        {ShowlistData.map((item,i)=>{
                            return(
                                <li className="item" key={i}>
                                    <img src={item.categoryicon} alt="icons" className="category--icon" />
                                    <span className="category--name">{item.categoryname}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </>
        );
    }

    const GuidList = () =>{
        return(
            <>
                <div className="lumenreport__guides key__fixed guidelist__position">
                    <div className="lumenreport__content">
                        <p className="guide--title">{StringConstants.lumenwork}</p>
                        {/* <p className="guide--text">{StringConstants.threetime}</p>
                        <p className="guide--text1 font-weight-bold font-size-14 mb-2 mt-3">1. {StringConstants.Lstep1}</p>
                        <p className="guide--text2 font-weight-bold font-size-14 mb-2">2. {StringConstants.Lstep2}</p>
                        <p className="guide--text3 font-weight-bold font-size-14 ">3. {StringConstants.Lstep3}</p> */}
                    </div>
                    <div className="guide__list">
                        <div className="guide--item order1">
                            <div className="guide__flex d-flex">
                                <div className="guide__block1">
                                    {/* <div className="guide--count color--step1">One</div> */}
                                    <div className="guide--subtitle color--step1">Training required</div>    
                                </div>
                                <div className="guide__block2">
                                   <img src={trainingrequire} alt="category" className="icons--symbol" /> 
                                </div>
                            </div> 
                            <p className="guide--text">{StringConstants.Tqdecs}</p>
                        </div>
                    
                        <div className="guide--item order2">
                            <div className="guide__flex d-flex">
                                <div className="guide__block1">
                                    {/* <div className="guide--count color--step3">Three</div> */}
                                    <div className="guide--subtitle color--step3"> Managerially Competent</div>    
                                </div>
                                <div className="guide__block2">
                                   <img src={leveltwo} alt="category" className="icons--symbol" /> 
                                </div>
                            </div> 
                            <p className="guide--text">{StringConstants.Mcdecs}</p>
                        </div>
                    
                        <div className="guide--item order3">
                            <div className="guide__flex d-flex">
                                <div className="guide__block1">
                                    {/* <div className="guide--count color--step2">Two</div> */}
                                    <div className="guide--subtitle color--step2">Company Benchmark </div>    
                                </div>
                                <div className="guide__block2">
                                   <img src={twolevelinfo} alt="category" className="icons--symbol" /> 
                                </div>
                            </div> 
                            <p className="guide--text">{StringConstants.Cbdecs}</p>
                        </div>
                    
                        <div className="guide--item order4">
                            <div className="guide__flex d-flex">
                                <div className="guide__block1">
                                    {/* <div className="guide--count color--step4">Four</div> */}
                                    <div className="guide--subtitle color--step4">Talented Skillset </div>    
                                </div>
                                <div className="guide__block2">
                                   <img src={levelthree} alt="category" className="icons--symbol" /> 
                                </div>
                            </div> 
                            <p className="guide--text">{StringConstants.Tsdecs}</p>
                        </div>
                    
                    </div>
                </div>
            </>
        );
    }


    return (
        <>
             <>
           {open ? ShowKayList() : null }
           {guide ? GuidList() : null  }
         
            <div className="lumenreport__sticky posotion--left  lumenreport__showkey">
                
                <button type="button" className="btn lumenreport__key--btn" onClick={handleToggle}>
                    <img src={open ? cross__icon : showkey__icon} alt="show key" className="key--icons" />
                    <span className="text">{open ? 'Hide Key' : 'Show Key' } </span>
                </button>
            </div>
            <div className="lumenreport__sticky posotion--right  lumenreport__guid">
                <button type="button" className="btn lumenreport__key--btn" onClick={handleGuideToggle}>
                    <img src={guide ? cross__icon :  guide__icon} alt="guid key" className="key--icons" />
                    <span className="text">{guide ? 'Hide Guide' : 'Guide'}</span>
                </button>
            </div>
            </>
        </>
    )
}

export default CategoryShowList
