import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function taxonomy_list_post_action(data, token, organisationId, groupId) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/organisation/group/${groupId}/taxonomy-list`,
            method: "POST",
            headers: {
                ...AUTH_HEADERS,
                Authorization: "Bearer " + token,
                organisationId: organisationId
            },

            body: JSON.stringify(data),
            types: [
                types.TAXONOMY_LIST_POST_REQUEST,
                types.TAXONOMY_LIST_POST_RECEIVE,
                {
                    type: types.TAXONOMY_LIST_POST_FAILURE,
                    meta: (action, state, res) => {
                        return action, state, res;
                    },
                },
            ],
        },
    };
}

export const reset_taxonomy_list_post_Action = () => {
    return {
        type: types.RESET_TAXONOMY_LIST_POST,
        payload: null,
    };
};