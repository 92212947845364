import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function isAuthenticated(data) {
  return {
    type: types.IS_AUTH,
    data: data,
  };
}

export function isLogout() {
  return {
    type: types.IS_LOGOUT,
    data: { status: false },
  };
}

export function signup_Action(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/signup`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
      },

      body: JSON.stringify(data),
      types: [
        types.SIGNUP_REQUEST,
        types.SIGNUP_RECEIVE,
        {
          type: types.SIGNUP_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_signup_Action = () => {
  return {
    type: types.RESET_SIGNUP,
    payload: null,
  };
};

export function signin_Action(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/login`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
        // Authorization: "Bearer " + token,
      },
      //body: data,
      body: JSON.stringify(data),
      types: [
        types.SIGNIN_REQUEST,
        types.SIGNIN_RECEIVE,
        {
          type: types.SIGNIN_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_signin_Action = () => {
  return {
    type: types.RESET_SIGNIN,
    payload: null,
  };
};

export function forget_password_Action(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/forgot-password-link`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
      },
      //body: data,
      body: JSON.stringify(data),
      types: [
        types.FORGET_PASSWORD_REQUEST,
        types.FORGET_PASSWORD_RECEIVE,
        {
          type: types.FORGET_PASSWORD_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_forget_password_Action = () => {
  return {
    type: types.RESET_FORGET_PASSWORD,
    payload: null,
  };
};

export function company_code_Action(token, data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/company/verify-code`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
      },
      //body: data,
      body: JSON.stringify(data),
      types: [
        types.COMPANY_CODE_REQUEST,
        types.COMPANY_CODE_RECEIVE,
        {
          type: types.COMPANY_CODE_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_company_code_Action = () => {
  return {
    type: types.RESET_COMPANY_CODE,
    payload: null,
  };
};

export function request_access_Action(token, data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/company/request-access`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
      },
      //body: data,
      body: JSON.stringify(data),
      types: [
        types.REQUEST_ACCESS_REQUEST,
        types.REQUEST_ACCESS_RECEIVE,
        {
          type: types.REQUEST_ACCESS_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_request_access_Action = () => {
  return {
    type: types.RESET_REQUEST_ACCESS,
    payload: null,
  };
};
