import * as types from "../types/index";

const INITIAL_STATE = {
    groupSelectionToggle: false,
    selectedGroup: null
};


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.TOGGLE_GROUP_SELECTION:
            return {
                ...state,
                groupSelectionToggle: action.payload
            };
        case types.SET_GROUP:
            return {
                ...state,
                selectedGroup: action.payload.data,
                groupSelectionToggle: action.payload.toggle_menu
            };
        default:
            return {
                ...state
            }
    }
}