import React, { useCallback, useEffect, useState } from "react";
import { Images } from "../../config";
import { Link } from "react-router-dom";
import { getData, USER_DATA } from "../../utils/storage";
import { clearAllData } from "../../utils/storage";
import { isLogout } from "../../stores/actions";
import { useDispatch } from "react-redux";


const AdminHeader = (props) => {

  const [companyLogo, setCompanyLogo] = useState();
  const [companyName, setCompanyName] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getData(USER_DATA).then(
      (res) => { setCompanyName(res.companyName); setCompanyLogo(res.companyLogo) }
    ).catch((error) => console.log(error));

  }, [])

  const logout = useCallback(() => {
    // console.log("logout");
    clearAllData();
    dispatch(isLogout());
    window.location.href = window.location.origin;
  });

  return (
    <header className="lumenai-navbar  bg-violet nav-bgcolor  d-md-block">
      <nav className="navbar navbar-expand-lg nav-height ">
        <div className="container">
          {props.bluelogo ? (
            <Link to="/hub" className="navbar-brand blue-logo">
              <img src={Images.infinity} className="lumenai-logo" />
            </Link>
          ) : (
            <Link to="/" className="navbar-brand">
              <img src={Images.infinity_white} className="lumenai-logo" />
            </Link>
          )}
          {props.companyDetails ? (
            <div className=" admin-header-container ml-auto d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center flex-row">

                <div>
                  <img src={companyLogo !== "placeholder" ? companyLogo : Images.lumenai_helper} className="logo-width img50"  />
                </div>

                <div className="d-flex justify-content-between">
                {companyName ? (
                  <p className="ml-3 mb-0 font-size-16 font-family-ubuntu font-weight-bold line-height-18 text-color-white align-self-center">{companyName}</p>
                ) : null}

                <div className="chevron-rotate-diemension-container">
                    <img className="chevron-rotate ml-3 mb-0 chevron-rotate-diemension" src={Images.back_arrow_white} /></div>
                </div>
              </div>
              <div className="admin-header-dropdown">
                <p className="">
                  <Link to="/hub">Hub</Link>
                </p>
                <p className="">
                  <Link to="/edit-admin-user">Edit user</Link>
                  {/* <img className="" src={Images.chevron} /> */}
                </p>
                <p className="d-flex justify-content-between flex-row">
                  <button onClick={logout}>Logout</button>
                  {/* <img className="" src={Images.chevron} /> */}
                </p>
              </div>
            </div>
          ) : null}
          {/* {props.headingTitle && (
              <div className="ml-auto">
                <p className="mb-0 font-size-16 font-family-ubuntu font-weight-bold line-height-18 text-color-white">
                  {props.headingTitle}
                </p>
              </div>
            )} */}
        </div>
      </nav>
    </header>
  )
}

export default AdminHeader;