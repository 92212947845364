import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Images, StringConstants } from "../config";

const QuestionsHeader = (props) => {
  const history = useHistory();
  const __closePopup = () => {
    history.push("/hub");
  };
  return (
    <>
      <div className="question-box-size d-flex justify-content-between align-items-center mb-3  pt-3 pt-md-5">
        <Link to="/" className="d-md-none ">
          <img width={50} src={Images.infinity} className="infinite" />
        </Link>
        <div className="d-none d-md-block">
          <button
            type="button"
            className=" border-0 background-transparent close-div d-flex align-items-center"
            onClick={() => {
              __closePopup();
            }}
          >
            <img
              src={Images.close_skill}
              style={{ width: "11px" }}
              alt="close icon"
              className="close-icon mr-2"
            />
            <p className="font-size-14 font-family-ubuntu line-height-18 text-color-sapphire mb-0">
              close
            </p>
          </button>
        </div>
      </div>

      <div>
        {/* <img
                    width={120}
                    style={{position: "absolute",zIndex:-1}}
                    src={Images.leadership_bg}
                    className="bg-image"
                /> */}

        <div
          className="background-icon-postion"
          style={{
            // backgroundImage: `url(${Images.leadership_bg})`,
            backgroundImage: `url(${Images.Office_Work_Question})`,
            
          }}
        ></div>
      </div>
      <div className="d-flex justify-content-between align-items-center question-card">
        <h3 className="mb-1 employability-assess font-size-22 text-color-darkblue line-height-24 font-family-ubuntu font-weight-bold ">
          {props.title} check-in
        </h3>
        <div className="question-lot-grp">
          <small className="ques-lot">
            {props.questionNo + 1}/{props.total}
          </small>
        </div>
      </div>
    </>
  );
};

export default QuestionsHeader;
