import React, { useEffect,useState } from "react";
import { Images, StringConstants } from "../../config";
import { Link, useHistory } from "react-router-dom";
import { QuestionsHeader, TipsPopup } from "../../components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { __redirectScrollTop } from "../../utils/utility";
import { setData,getData,TOKEN,SUMMARY_ATTEMPT } from "../../utils/storage";

const QuestionExample = () => {
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [attempCount, setAttempCount] = useState(1);


  const __popupbox = () => {
    setToggle(!toggle);
  };
  const __closePopup = () => {
    history.push("/hub");
  };


  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData(SUMMARY_ATTEMPT).then((sumAttp) => {
                setAttempCount(sumAttp)
          })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const _onClicktutorialValue = () =>{
    setData('TUTS',true)
   
    window.scrollTo(0,0);
    if(attempCount === 1 ){
      window.location.replace('/initial-soft-skill');
    }else{
      window.location.replace('/assessment-detail');
    }
   
  }
  

  return (
    <>
      {toggle === false ? (
        <>
        <div className="overlay_cirlce circle__Postion1"></div>

          {/* <div className="container position-relative">
            <div className="ovcer result-overlay">
              <div className="hole"></div>
            </div>
          </div> */}


          <div className="position-fixed lumen-help fixed-lumen-help increase-zindex">
            <>
            
              <div className="tips-block initial-postion">
                <div className="d-flex justify-content-between mb-2">
                  <h3 className="font-size-20 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0">
                    How the Lumen works
                  </h3>
                  <button
                    type="button"
                    className="border-0 background-transparent "
                    onClick={__popupbox}
                  >
                    <img src={Images.close_skill} className="close-icon" />
                  </button>
                </div>

                <p className="font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-violet mb-0 pr-5">
                  for each lumenai question you will be asked to give two
                  answers,one from answer A and one from answer B.
                </p>

                <div className="d-flex justify-content-md-between">
                  <button
                    onClick={_onClicktutorialValue}
                    className="background-transparent border-0 p-0 font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-blue mt-2 mb-0 pr-2"
                  >
                    {"Skip"}
                  </button>

                  <button
                    onClick={() => setToggle(!toggle)}
                    className="background-transparent border-0 p-0 font-size-14 font-family-ubuntu line-height-22 font-weight-medium text-color-blue mt-2 mb-0 pr-5"
                  >
                    {"Next >"}
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="tips-button-size border-0 over-position"
                onClick={__popupbox}
              >
                <img
                  src={Images.lumenai_helper}
                  className="logo-width img100"
                />
              </button>
            </>
          </div>
        </>
      ) : null}
      <div className="question lp-screen bg-aliceBlue bg-color0  header-minusHeight position-relative">
       
        <div className="container">
          <div className="row justify-content-md-center ">
            <div className="col-md-12 col-lg-10 q-wrp mb-70 pb-4">
              <>
                <div className="question-box-size d-flex justify-content-between align-items-center mb-3  pt-3 pt-md-5">
                  <Link to="/" className="d-md-none ">
                    <img
                      width={50}
                      src={Images.infinity}
                      className="infinite"
                    />
                  </Link>
                  <div className="d-none d-md-block">
                    <button
                      type="button"
                      className=" border-0 background-transparent close-div d-flex align-items-center"
                      onClick={_onClicktutorialValue}
                    >
                      <img
                        src={Images.close_skill}
                        style={{ width: "11px" }}
                        alt="close icon"
                        className="close-icon mr-2"
                      />
                      <p className="font-size-14 font-family-ubuntu line-height-18 text-color-sapphire mb-0">
                        close
                      </p>
                    </button>
                  </div>
                </div>

                <div>
                  <div
                    className="background-icon-postion"
                    style={{
                      backgroundImage: `url(${Images.leadership_bg})`,
                    }}
                  ></div>
                </div>
                <div className="d-flex justify-content-between align-items-center question-card">
                  <h3 className="mb-1 employability-assess font-size-22 text-color-darkblue line-height-24 font-family-ubuntu font-weight-bold ">
                    Employability assessment check-in
                  </h3>
                  <div className="question-lot-grp">
                    <small className="ques-lot">01/20</small>
                  </div>
                </div>
                
                <div className="box-b">
                  <div className="text-cl mb-md-4 question-card qcard-size ques-card-hide">
                    <div className="cards-title mt-1">
                      <h4 className="mb-md-3 font-size-18 line-height-22 family-font-ubuntu font-weight-bold text-color-darkblue question-text">
                        What would you think if I was to ask you the following
                        question?
                      </h4>
                    </div>
                    <div className="question-info d-flex align-items-center">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            simple tooltips
                          </Tooltip>
                        }
                      >
                        <img
                          className="icon-hint"
                          src={Images.info}
                          alt="info"
                        />
                      </OverlayTrigger>
                      <p className="ml-2 question-hint text-color-gray mb-0 font-size-13 font-weight-medium">
                        Question Hint
                      </p>
                    </div>
                  </div>
                </div>

                <div className="quest-wrp wrp-box-question">
                  <div className="box1  mt-md-4 mb-4">
                    <h6 className="mb-3 select-one-answer font-size-15 font-family-ubuntu font-weight-bold line-height-20  text-color-darkblue" >Answer A</h6>
                    <div className="wrp_box1 qcard-size">

                    <div className="radio-btn-grp mb-3 d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer"} id={"ans"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="radio-btn-grp mb-3 d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer"} id={"ans"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="radio-btn-grp mb-3 d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer"} id={"ans"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="radio-btn-grp  d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer"} id={"ans"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="box2 mt-md-4 mb-4">
                  <h6 className="mb-3 select-one-answer font-size-15 font-family-ubuntu font-weight-bold line-height-20  text-color-darkblue" >Answer B</h6>
                    <div  className="wrp_box1 qcard-size">
                    <div className="radio-btn-grp mb-3 d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer2"} id={"ans1"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="radio-btn-grp mb-3 d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer2"} id={"ans2"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="radio-btn-grp mb-3 d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer2"} id={"ans3"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="radio-btn-grp  d-flex justify-content-between">
                      <label className="radio-container">
                        <p className="mb-0 radio-text radio-btn line-height-18 font-size-16 font-family-ubuntu font-weight-regular text-color-violet">
                          A sample answer
                        </p>
                        <input type="radio" name={"answer2"} id={"ans4"} />
                        <span className="radio-checkmark"></span>
                      </label>

                      <div className="q_hint ml-2">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip-3">
                              simple tooltips
                            </Tooltip>
                          }
                        >
                          <img className="icon-radio-hint" src={Images.info} />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                <div className="question-btn text-center mt-4">
                  <Link
                    to="/end-of-example"
                    onClick={__redirectScrollTop}
                    className="btn min-width-245 px-4 py-2 mt-2 bg-violet  line-height-32 font-size-18 font-weight-bold  border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.next}
                  </Link>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionExample;
