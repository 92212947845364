export default {
  white: "#ffffff",
  color_Sapphire: "#04226B",
  Electric_Purple: "#BF06FF",
  Navy_Blue: "#006BF1",
  pink: "#FD00FF",
  blue: "#005EFF",
  green: "#00F",
  cyan: "#00FFFF",
  lightBlue: "#0067FF",
  darkblue: "#381474",
  royalblue: "#04226B",
};
