import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { Images } from "../../config";
import {
  getData,
  setData,
  TOKEN,
  USER_DATA,
  GROUP_DATA,
  clearAllData,
} from "../../utils/storage";
import {
  activityAPI_Action, set_group,
} from "../../stores/actions";
import { __redirectScrollTop } from "../../utils/utility";
import { NavDropdown } from "react-bootstrap";
import GroupCard from "../GroupCard";
import { group } from "d3";

const AfterLoginHeader = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const activityData = useSelector((state) => state.activity);
  const getUiState = useSelector((state) => state.getUiState);
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [profileCount, setProfileCount] = useState();
  const [groupId, setGroupId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const redirectUrl = (e) => {
    history.push(e);
  };

  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {

        setUserData(res);

        // if (res.user_invite_status === 2 || res.user_invite_status === 0) {
        if (res.user_invite_status !== 2) {
          // console.log("user invite", res);
        } else {
          // console.log("user invite join comp", res);
          history.push({
            pathname: "/hub",
          });
        }


        if (res.groupsPopUp.length > 1 && res.userType === "group-user") {
          getData(GROUP_DATA).then((groupres) => {
            handleGroupId(groupres)
          }).catch((groupcatch) => {
            if (location.pathname.indexOf("/s/") === 0 || location.pathname.indexOf("/lumen-report/") === 0) {
              setModalVisible(false)
            } else {
              setModalVisible(true)
            }
          });

        } else {
          handleGroupId(res.groupsPopUp[0])
        }

      })
      .catch(() => {
        console.log("REDIRECT to LOGIN");
      });
  }, []);

  // functionality is commented due to 
  // useEffect(() => {
  //   try {
  //     getData(TOKEN)
  //       .then((res) => {
  //         dispatch(activityAPI_Action(res.token));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    console.log("UI STATE", getUiState)

    //  if (getUiState.selectedGroup.groupId !== groupId) {
    //    setGroupId(getUiState.selectedGroup.groupId)
    // }


  }, [getUiState])

  useEffect(() => {
    if (activityData.isFetching) {
      setLoader(true);
    } else if (activityData.isSuccess && activityData.data !== null) {
      setLoader(false);
      let setRecentCount = activityData && activityData.data && activityData.data.data;
      setProfileCount(setRecentCount)
    } else if (activityData.isError) {
      setErrorModel(true);
      setErrorDataModel(activityData.data);
    }
  }, [activityData]);




  const handleGroupId = (item) => {
    dispatch(set_group(item, false))
    setGroupId(item.groupId);
    setModalVisible(false)
    setData(GROUP_DATA, item)
  }

  const findAndUpdateGroup = (id) => {
    if (userData !== null) {
      let group = userData.groupsPopUp.find((e, v) => {
        return e.groupId === id && e
      });

      handleGroupId(group)
    }
  }

  const renderCondition = () => {
    const getPath = (val) => {
      let ar = val.split("/", 2)

      if (ar.length > 0) {
        return "/" + ar[1]
      }

      return false
    }

    return (location.pathname === '/me' || location.pathname === '/summary-new' || location.pathname === '/assessment-detail' || location.pathname === '/try-example' ||
      location.pathname === '/me/summary' || location.pathname === '/me/initial-summary' || location.pathname === '/me/congratulation' ||
      getPath(location.pathname) === '/questions')
  }


  const __renderGroupSelectionMobile = () => {
    if (renderCondition()) {
      return '';
    }

    return userData && userData.groupsPopUp.length > 1 ? (
      <select value={groupId} onChange={(e) => {
        findAndUpdateGroup(e.target.value)
      }} className="mobilegroupselect">
        {userData.groupsPopUp.map((items, index) => {
          return (
            <option key={index} value={items.groupId}>{items.groupName}</option>
          )
        })}
      </select>
    ) : <span className="group-name">
      {userData ? userData.groupsPopUp[0].groupName : null}
    </span>
  }
  const __renderGroupSelection = () => {
    if (renderCondition()) {
      return '';
    }

    return userData && userData.groupsPopUp.length > 1 ? (
      <select value={groupId} onChange={(e) => {
        findAndUpdateGroup(e.target.value)
      }}>
        {userData.groupsPopUp.map((items, index) => {
          return (
            <option key={index} value={items.groupId}>{items.groupName}</option>
          )
        })}
      </select>
    ) : <span className="group-name">
      {userData ? userData.groupsPopUp[0].groupName : null}
    </span>
  }


  return (
    <>
      {/* desktop menu  */}
      <nav className="navbar lumenheader py-4 bg-violet d-none d-md-block ">
        <div className="container">
          {

            <>
              {props.bluelogo &&
                <Link to="/" className="navbar-brand blue-logo">
                  <img src={Images.infinity} className="lumenai-logo" />
                </Link>}

              {props.whiteLogo &&
                <Link to="/" className="navbar-brand blue-logo">
                  <img src={Images.infinity_white} className="lumenai-logo" />
                </Link>}

              {props.rootpathwhite ?

                <Link to={"/hub/your-activity"} className="navbar-brand position-relative ">
                  {profileCount && profileCount.recent_activity_badge_count > 0 ? (
                    <div className="badges">
                      {profileCount.recent_activity_badge_count}
                    </div>
                  ) : null}
                  <img src={Images.infinity} className="lumenai-logo" />
                </Link>
                : <Link to={"/hub/your-activity"} className="navbar-brand position-relative hide">
                  {profileCount && profileCount.recent_activity_badge_count > 0 ? (
                    <div className="badges">
                      {profileCount.recent_activity_badge_count}
                    </div>
                  ) : null}
                  <img src={Images.infinity_white} className="lumenai-logo" />
                </Link>
              }

            </>
          }

          <div className="desktop-menu  mr-auto ml-4">
            <ul className="nav ">
              <li className="nav-item ml-3">
                <NavLink
                  strict
                  activeClassName="active"
                  className="nav-link align-self-stretch font-size-16 font-family-ubuntu font-weight-bold"
                  to="/hub"
                  onClick={() => {
                    __redirectScrollTop()
                  }}
                >
                  <span>Hub</span>
                </NavLink>
              </li>
              <li className="nav-item ml-3">
                <NavLink
                  strict
                  activeClassName="active"
                  className="nav-link align-self-stretch  font-size-16 font-family-ubuntu font-weight-bold"
                  to="/pathway"
                  onClick={() => {
                    __redirectScrollTop()
                  }}
                >
                  <span>Pathway</span>
                </NavLink>
              </li>
              <li className="nav-item ml-3">
                <NavLink
                  strict
                  activeClassName="active"
                  className="nav-link align-self-stretch font-size-16 font-family-ubuntu font-weight-bold"
                  to="/company"
                  onClick={() => {
                    __redirectScrollTop();
                  }}
                >
                  <span>Company</span>
                </NavLink>
              </li>
              <li className="nav-item ml-3">
                <NavLink
                  strict
                  activeClassName="active"
                  className="nav-link align-self-stretch font-size-16 font-family-ubuntu font-weight-bold"
                  to="/me"
                  onClick={() => {
                    __redirectScrollTop();
                  }}
                >
                  <span>Me</span>
                </NavLink>
              </li>
            </ul>


            {__renderGroupSelection()}
          </div>
        </div>
      </nav>

      {/* only for mobile tab menu */}
      {props.blueheader ? (
        <div
          className="mobile-footer-menu d-md-none"
          style={{ backgroundColor: "#180248" }}
        >
          <ul className="nav  d-flex justify-content-between pl-0 py-1 px-3">
            <li className="nav-item ">
              <NavLink
                strict
                activeClassName="active"
                className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-white"
                to="/hub"
                onClick={() => {
                  __redirectScrollTop();
                }}
              >
                <img
                  src={Images.hub_active}
                  className="active-icon icon img100"
                />
                <img
                  src={Images.hub_active_w}
                  className="inactive-icon icon img100"
                />
                <span>Hub</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                strict
                activeClassName="active"
                className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-white"
                to="/pathway"
                onClick={() => {
                  __redirectScrollTop();
                }}
              >
                <img
                  src={Images.pathway_active}
                  className="active-icon icon img100"
                />
                <img
                  src={Images.pathway_active_w}
                  className="inactive-icon icon img100"
                />
                <span>Pathway</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                strict
                activeClassName="active"
                className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-white"
                to="/company"
                onClick={() => {
                  __redirectScrollTop();
                }}
              >
                <img
                  src={Images.company_active}
                  className="active-icon icon img100"
                />
                <img
                  src={Images.company_active_w}
                  className="inactive-icon icon img100"
                />
                <span>Company</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                strict
                activeClassName="active"
                className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-white"
                to="/me"
                onClick={() => {
                  __redirectScrollTop();
                }}
              >
                <img
                  src={Images.me_active}
                  className="active-icon icon img100"
                />
                <img
                  src={Images.me_active_w}
                  className="inactive-icon icon img100"
                />
                <span>Me</span>
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        <>
          <div className="mobile-footer-menu d-md-none">
            <ul className="nav  d-flex justify-content-between pl-0 py-1 pb-4 px-4 ">
              <li className="nav-item ">
                <NavLink
                  strict
                  activeClassName="active"
                  className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-violet"
                  to="/hub"
                  onClick={() => {
                    __redirectScrollTop();
                  }}
                >
                  <img
                    src={Images.hub_active}
                    className="active-icon icon img100"
                  />
                  <img
                    src={Images.hub_inactive}
                    className="inactive-icon icon img100"
                  />
                  <span>Hub</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  strict
                  activeClassName="active"
                  className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-violet"
                  to="/pathway"
                  onClick={() => {
                    __redirectScrollTop();
                  }}
                >
                  <img
                    src={Images.pathway_active}
                    className="active-icon icon img100"
                  />
                  <img
                    src={Images.pathway_inactive}
                    className="inactive-icon icon img100"
                  />
                  <span>Pathway</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  strict
                  activeClassName="active"
                  className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-violet"
                  to="/company"
                  onClick={() => {
                    __redirectScrollTop();
                  }}
                >
                  <img
                    src={Images.company_active}
                    className="active-icon icon img100"
                  />
                  <img
                    src={Images.company_inactive}
                    className="inactive-icon icon img100"
                  />
                  <span>Company</span>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  strict
                  activeClassName="active"
                  className="d-flex flex-column nav-link align-self-stretch font-size-13 font-family-ubuntu font-weight-bold line-height-18 text-center text-color-violet"
                  to="/me"
                  onClick={() => {
                    __redirectScrollTop();
                  }}
                >
                  <img
                    src={Images.me_active}
                    className="active-icon icon img100"
                  />
                  <img
                    src={Images.me_inactive}
                    className="inactive-icon icon img100"
                  />
                  <span>Me</span>
                </NavLink>
              </li>
            </ul>
          </div>

          {
            (props?.isMobileGroup) ?
              <div className="mobileGroupSelection">
                {__renderGroupSelectionMobile()}
              </div> : ''
          }

        </>
      )}
      {modalVisible ? (
        <GroupCard handleGroupId={(e) => handleGroupId(e)} groupState={groupId} groupData={userData.groupsPopUp.length >= 2 ? userData.groupsPopUp : null} />
      ) : null}
    </>
  );
};

export default AfterLoginHeader;
