import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StringConstants from "../../config/StringConstants";

// redux
import { testAPI_Action, resetAPI_Action } from "../../stores/actions";

const ErrorInternet = (props) => {
  return (
    <>
      <div
        className="bg-dithered py-3 px3 "
        style={{
          position: "absolute",
          top: "88",
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        <h3 className="font-size-16 font-family-ubuntu font-weight-bold text-center text-color-blue mb-0">
          Internet Connection Issue, Please check
        </h3>
      </div>
    </>
  );
};

export default ErrorInternet;
