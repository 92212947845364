export const CategoryDataDummy = [
    {
        id: 1,
        categoryOrder: 1,
        categoryDescription: "<p>Description&nbsp;Time Management</p>",
        categoryName: "Time Management",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 2,
        categoryOrder: 2,
        categoryDescription: "<p>Critical Thinking</p>",
        categoryName: "Critical Thinking",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 3,
        categoryOrder: 3,
        categoryDescription: "<p>Teamwork</p>",
        categoryName: "Teamwork",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 4,
        categoryOrder: 4,
        categoryDescription: "<p>leadership</p>",
        categoryName: "Leadership",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 5,
        categoryOrder: 5,
        categoryDescription: "<p>Problem Solving </p>",
        categoryName: "Problem Solving",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 6,
        categoryOrder: 6,
        categoryDescription: "<p>Communication  </p>",
        categoryName: "Communication",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 7,
        categoryOrder: 7,
        categoryDescription: "<p>Creativity  </p>",
        categoryName: "Creativity",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },
    {
        id: 8,
        categoryOrder: 8,
        categoryDescription: "<p>CreEmotional Judgementativity  </p>",
        categoryName: "Emotional Judgement",
        highlightCategoryCoreSection: false,
        highlightCategoryManagerialSection: false,
        highlightCategoryTalentedSection: false,
        categoryPercentage: "0%",
        categoryCorePercentage: "0%",
        categoryManagerialPercentage: "0%",
        categoryTalentedPercentage: "0%"
    },

];
