export const EDIT_ACCOUNT_DETAILS_REQUEST = "EDIT_ACCOUNT_DETAILS_REQUEST";
export const EDIT_ACCOUNT_DETAILS_RECEIVE = "EDIT_ACCOUNT_DETAILS_RECEIVE";
export const EDIT_ACCOUNT_DETAILS_FAILURE = "EDIT_ACCOUNT_DETAILS_FAILURE";
export const RESET_EDIT_ACCOUNT_DETAILS = "RESET_EDIT_ACCOUNT_DETAILS";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_RECEIVE = "CHANGE_PASSWORD_RECEIVE";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_RECEIVE = "DELETE_ACCOUNT_RECEIVE";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";
export const RESET_DELETE_ACCOUNT = "RESET_DELETE_ACCOUNT";
