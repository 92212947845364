import React, { useCallback, useEffect, useRef, useState } from "react";
import { CrossWhite, LumenaiChatbox, SendChatBox, infinity_white } from "../config/Images";
import { chatQeustions } from "../config/ChatboxQuestion";
import { GROUP_DATA, TOKEN, USER_DATA, getData } from "../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import {
    grouptaxonomieListRestAPI_Action,
    grouptaxonomieList_Action,
} from "../stores/actions/group-taxonomies-list-action";
import Select from "react-select";
import { reset_team_creation_data_Action, team_creation_data_action } from "../stores/actions/team-creation-data-action";
import { group_category_Action, resetTaxonomyData_Action, reset_group_category_Action, taxonomyDataAPI_Action } from "../stores/actions";
import { reset_taxonomy_list_post_Action, taxonomy_list_post_action } from "../stores/actions/taxonomy-list-post-action";
import { infinity } from "../config/Images";

const ChatBox = () => {
    const [chatboxState, setChatboxState] = useState(false);
    const [chatContent, setChatContent] = useState([]);
    // const [answerValueState, setAnswerValueState] = useState("");
    const [questionId, setQuestionId] = useState(0);
    const [chatopen, setChatOpen] = useState(false);
    const [groupSelect, setGroupSelect] = useState();
    const [apiCall, setApiCall] = useState(false);
    const [token, setToken] = useState("");
    const [organisationId, setorganisationId] = useState("");
    const [taxonomies, setTaxonomies] = useState([]);
    const [selectedTaxonomyList, setSelectedTaxonomyList] = useState([]);
    const [itemValue, setItemValue] = useState("");
    const [groupId, setGroupId] = useState("");
    const [questionMainId, setQuestionMainId] = useState("");

    const [dataContent, setDataContent] = useState({
        teamSize: "",
        taxonomyArr: [],
    })
    const data = chatQeustions;
    const dispatch = useDispatch();
    const taxonomyState = useSelector((state) => state.grouptaxonomieList);
    const taxoData = useSelector((state) => state.taxonomyData);
    const teamData = useSelector((state) => state.teamCreationData);
    const [responseData, setResponseData] = useState([]);
    const [submitButtoneEnable, setSubmitButtonEnable] = useState(true);
    const [optionApiState, setOptionApiState] = useState("");
    const groupCategory = useSelector((state) => state.groupCategory);
    const [categoryList, setCategoryList] = useState([]);
    const [groupLevel, setGroupLevel] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedLevel, setSelectedLevel] = useState([]);
    const [categoryData, setCategoryData] = useState({});
    const [singleTaxonomy, setSingleTaxonomy] = useState({});
    const [activeQuestion, setActiveQuestion] = useState([]);
    const taxonomyPostList = useSelector((state) => state.taxonomyListPost);
    const [clearChatPopup, setClearChatPopup] = useState(false);


    // get the token and user data from localstorage 

    useEffect(() => {
        try {
            getData(TOKEN).then(
                (res) => setToken(res.token),
                getData(USER_DATA).then((data) => {
                    setGroupSelect(data.groupsPopUp);
                    setorganisationId(data.companyId);
                })
            );


        } catch (e) {
            console.log(e);
        }
    }, []);

    // taxonomy data success response 
    useEffect(() => {
        if (taxoData.isSuccess && taxoData.data !== null) {
            setResponseData(taxoData.data.data);
            handleScrollClick();

            dispatch(resetTaxonomyData_Action());
            // setSubmitButtonEnable(true);
        } else if (taxoData.isError) {
            dispatch(resetTaxonomyData_Action())
        }
    }, [taxoData])

    // team data success response 
    useEffect(() => {
        if (teamData.isSuccess && teamData.data !== null) {
            setResponseData(teamData.data.data);
            handleScrollClick();

            dispatch(reset_team_creation_data_Action());
            // setSubmitButtonEnable(true);
        } else if (teamData.isError) {
            dispatch(reset_team_creation_data_Action());
        }
    }, [teamData])

    // taxonomy list success response 
    useEffect(() => {
        if (taxonomyState.isSuccess && taxonomyState.data !== null) {
            const taxonomyData = taxonomyState.data.data.map((items) => {
                return { value: items.uuid, label: items.name };
            });

            setTaxonomies(taxonomyData);
            if (questionMainId === 3) {
                setResponseData(taxonomyState.data.data);
                handleScrollClick();


            }
            dispatch(grouptaxonomieListRestAPI_Action());
        } else if (taxonomyState.isError) {
            dispatch(grouptaxonomieListRestAPI_Action());
        }
    }, [taxonomyState]);


    // step two api calls 
    useEffect(() => {
        if (optionApiState === "group-category") {
            dispatch(group_category_Action(token, organisationId, groupId));
        } else if (optionApiState === "taxonomy-list") {
            dispatch(grouptaxonomieList_Action(token, organisationId, groupId));
        }
    }, [optionApiState])


    useEffect(() => {
        if (groupCategory.isSuccess && groupCategory.data !== null) {
            const categories = groupCategory.data.data.categories;
            const level = groupCategory.data.data.lumenLevel;

            const levelArray = Object.keys(level).map((items, index) => {
                return {
                    value: items,
                    label: level[items]
                }
            })

            setGroupLevel(levelArray);


            const categoryData = categories.map((items, index) => {
                const categoryObj = {
                    value: items.categoryId,
                    label: items.categoryName
                }
                return categoryObj;
            },)
            setCategoryList(categoryData);
            dispatch(reset_group_category_Action());
        } else if (groupCategory.isError) {
            dispatch(reset_group_category_Action());
        }
    }, [groupCategory])



    // setting the questionId 
    useEffect(() => {
        questionContent(questionId);
    }, [questionId]);

    // function for to perform action to move to the next step 
    const getTaxonomyCall = useCallback((e, questionParent) => {
        setQuestionId(questionParent);
        setGroupId(e.currentTarget.id);
        if (questionParent === 6) {
            dispatch(grouptaxonomieList_Action(token, organisationId, e.currentTarget.id.toString()));
        }
        handleScrollClick();

    });

    // to make a stack of the chat box it contains all the question and answer 
    const questionContent = (questionid) => {
        let chatdata = data.find((items) => {
            if (items.id === questionid) {
                const { question, answerset } = items;
                if (answerset === "apiCall") {
                    setApiCall(true);
                    return { question, answerset: groupSelect };
                } else {
                    return { question, answerset };
                }
            }
        });

        if (chatdata !== undefined) {

            const objCheck = chatContent.some((elem) => {
                if (elem.id === chatdata.id) {
                    return true
                }

                return false
            })
            if (!objCheck) {
                setChatContent((current) => [...current, chatdata]);
            }
        }
    };


    // function for the select taxonomy 
    const change = (selectedOption) => {
        if (questionMainId === 7) {
            setSingleTaxonomy(selectedOption);
        } else {
            setSelectedTaxonomyList(Array.isArray(selectedOption) ? selectedOption.map((x) => x.value) : []);
        }
        handleScrollClick();
    };

    useEffect(() => {
        if (Object.keys(singleTaxonomy).length > 0) {
            dispatch(taxonomyDataAPI_Action(token, organisationId, groupId, singleTaxonomy.value))
        }
    }, [singleTaxonomy])

    useEffect(() => {
        console.log(selectedTaxonomyList)
    }, [selectedTaxonomyList])

    // setting the parameters for the create team api 
    useEffect(() => {
        setDataContent({
            teamSize: itemValue,
            taxonomyArr: selectedTaxonomyList,
        })
    }, [itemValue, selectedTaxonomyList]);


    const handleOptionSubmit = (answeritem) => {
        setQuestionId(answeritem.parent);
        setQuestionMainId(questionMainId !== "" ? questionMainId : answeritem.id);
        setActiveQuestion(current => [...current, answeritem.id]);
        answeritem && setItemValue(answeritem.value);

        handleScrollClick();
    }


    useEffect(() => {
        console.log(activeQuestion.length, chatContent.length, activeQuestion);

    }, [activeQuestion])


    // handle category change 
    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption.value);
        handleScrollClick();
    }

    const handleLevelChange = (selectedOption) => {
        setSelectedLevel(Array.isArray(selectedOption) ? selectedOption.map((x) => x.value) : [])
        handleScrollClick();
    }


    useEffect(() => {
        setCategoryData({
            categoryId: selectedCategory,
            lumenLevelArr: selectedLevel,
        })


    }, [selectedCategory, selectedLevel])

    // handle level and category submit 
    const handleCategory = useCallback(() => {
        setSubmitButtonEnable(false);
        dispatch(taxonomy_list_post_action(categoryData, token, organisationId, groupId));
        handleScrollClick();
        console.log(submitButtoneEnable);

    })

    useEffect(() => {
        if (taxonomyPostList.isSuccess && taxonomyPostList.data !== null) {
            setResponseData(taxonomyPostList.data.data);
            handleScrollClick();

            dispatch(reset_taxonomy_list_post_Action());
        } else if (taxonomyPostList.isError) {
            dispatch(reset_taxonomy_list_post_Action());
        }
    }, [taxonomyPostList])

    // function to handle the submit 
    const handleClick = useCallback(() => {

        dispatch(team_creation_data_action(dataContent, token, organisationId, groupId))
        console.log(activeQuestion)

        setSubmitButtonEnable(false);

        handleScrollClick();
    })

    const clearChat = () => {
        setChatContent([]);
        setResponseData([]);
        setQuestionId(1);
        setGroupId("");
        setQuestionMainId("");
        setOptionApiState("");
        setSubmitButtonEnable(true);
        setCategoryList([]);
        setGroupLevel("");
        setOptionApiState("");
        setItemValue("");
        setSelectedTaxonomyList([]);
        setCategoryList([]);
        setSelectedLevel([]);
        setSelectedCategory([]);
        setActiveQuestion([]);
        setSingleTaxonomy({});
        setClearChatPopup(false);
    }


    // handlescroll 
    const handleScrollClick = () => {
        setTimeout(() => {
            const content = document.getElementById("chat-list");
            const responseData = document.getElementById("response-logo");
            const optionSelect = document.querySelector(".options-list-select");
            console.log(optionSelect ? optionSelect.offsetTop : "data val");
            content.scrollTop = responseData ? responseData.offsetTop : optionSelect ? optionSelect.offsetTop : content.scrollHeight;
        }, 500)

    }

    return (
        <div
            className={
                chatboxState
                    ? "chatbox-container chatbox content-open"
                    : "chatbox-container chatbox"
            }
        >
            {!chatboxState ? (
                <figure
                    onClick={() => {
                        setChatboxState(true);
                        setChatOpen(true);
                        !chatopen && setQuestionId(1);
                    }}
                >
                    <img src={LumenaiChatbox} />
                </figure>
            ) : null}
            {chatboxState ? (
                <div className="chatbox-content">
                    <div className="chatbox-title d-flex justify-content-between align-items-center">
                        <div className="logo-container d-flex">
                            <img src={infinity_white} />
                            <h3>Insight</h3>
                        </div>
                        <img className="close-chat" onClick={() => { chatContent.length === 1 ? setChatboxState(false) : setClearChatPopup(true) }} src={CrossWhite} />
                    </div>

                    <div className="chat-container">
                        <ul className="chat-list" id="chat-list">
                            {chatContent.map((items, chatindex) => {
                                console.log(items.id);
                                return (
                                    <li className="message" key={chatindex}>
                                        <p className="question">{items.question}</p>
                                        {/* main stack */}
                                        {items.answerset === "apiCall" ? (
                                            <ul className="option-container">
                                                {Array.isArray(groupSelect) && groupSelect.length > 1 ? (
                                                    <li
                                                        id="all"
                                                        onClick={(e) => getTaxonomyCall(e, questionMainId !== 3 ? items.apiCallId[0] : items.apiCallId[1])}
                                                    >
                                                        <p style={groupId === "all" ? { opacity: 1, color: "#180148", fontWeight: 700 } : groupId === "" ? { opacity: 1 } : { opacity: 0.3 }}>All</p>
                                                    </li>
                                                ) : null}
                                                {/* group section  */}
                                                {groupSelect.map((answeritem, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            id={answeritem.groupId}
                                                            onClick={(e) =>
                                                                getTaxonomyCall(e, questionMainId !== 3 ? items.apiCallId[0] : setOptionApiState(items.apiCallId[1].optionCall))
                                                            }
                                                        >
                                                            <p style={groupId === answeritem.groupId ? { opacity: 1, color: "#180148", fontWeight: 700 } : groupId === "" ? { opacity: 1 } : { opacity: 0.3 }}

                                                            >{answeritem.groupName}</p>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        ) : items.answerset === "apiTaxonomyCall" ? (  //taxonomy section 
                                            <>
                                                {questionMainId === 7 ? (
                                                    <Select options={taxonomies} onChange={change} value={Object.keys(singleTaxonomy).length && singleTaxonomy} />

                                                ) :
                                                    <Select options={taxonomies} isMulti={true} onChange={change} isOptionDisabled={() => selectedTaxonomyList.length > itemValue - 1} />

                                                }
                                                {/* <button disabled={submitButtoneEnable} onClick={() => handleClick()}>Create my team</button> */}
                                            </>
                                        ) : (
                                            // static section data from the chatquestion files 
                                            <ul className="option-container">
                                                {items.answerset.map((answeritem, index) => {
                                                    return items.apiCall === "option-call" ? (
                                                        <li onClick={() => { setOptionApiState(answeritem.optionCall); setActiveQuestion(current => [...current, answeritem.id]); handleScrollClick() }}>
                                                            <p style={activeQuestion.length === 0 ? { opacity: 1 } : activeQuestion.includes(answeritem.id) ? { opacity: 1, color: "#180148", fontWeight: 700 } : activeQuestion.length + 1 === chatindex ? { opacity: 1 } : { opacity: 0.3 }} id={answeritem.optionCall} disabled={optionApiState !== "" ? true : false} >{answeritem.question}</p>
                                                        </li>
                                                    ) : (
                                                        <li
                                                            key={index}
                                                            id={answeritem.id}
                                                            onClick={() => { handleOptionSubmit(answeritem) }}
                                                            style={chatContent.length > 1 && [2, 3, 7].includes(answeritem.id) ? { pointerEvents: "none" } : null}
                                                        >
                                                            <p style={activeQuestion.length === 0 ? { opacity: 1 } : activeQuestion.includes(answeritem.id) ? { opacity: 1, color: "#180148", fontWeight: 700 } : activeQuestion.length === chatindex ? { opacity: 1 } : { opacity: 0.3 }}>{answeritem.question}</p>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )
                                        }
                                    </li>
                                );
                            })}
                            {/* response from the api  */}
                            {(Array.isArray(categoryList) && categoryList.length) ? (
                                <li className="message select-container options-list-select">

                                    <p className="question">Please select the category to cover</p>
                                    {Array.isArray(categoryList) && categoryList.length ? (
                                        <Select placeholder={<div>Please select</div>} options={categoryList} onChange={handleCategoryChange} />
                                    ) : null}
                                </li>) : null}

                            {(Array.isArray(groupLevel) && groupLevel.length) ? (
                                <li className="message select-container options-list-select">
                                    <p className="question">And what level(s) to include</p>
                                    {Array.isArray(groupLevel) && groupLevel.length ? (
                                        <Select placeholder={<div>Please select</div>} options={groupLevel} isMulti={true} onChange={handleLevelChange} />
                                    ) : null}
                                </li>
                            ) : null}
                            {console.log(submitButtoneEnable)}
                            {((Array.isArray(selectedTaxonomyList) && selectedTaxonomyList.length && submitButtoneEnable)) || (((Array.isArray(selectedCategory) && selectedCategory.length) || (Array.isArray(selectedLevel) && selectedLevel.length)) && submitButtoneEnable) ? (
                                <li className="text-center">
                                    {questionMainId !== 7 ? (
                                        <button className="chat-button mt-4" onClick={(e) => (Array.isArray(selectedTaxonomyList) && selectedTaxonomyList.length) ? handleClick(e) : ((Array.isArray(selectedCategory) && selectedCategory.length) || (Array.isArray(selectedLevel) && selectedLevel.length)) ? handleCategory(e) : null}>
                                            {questionMainId === 2 ? (
                                                "Create my team"
                                            ) : questionMainId === 3 ? (
                                                "Generate my insight"
                                            ) : null
                                            }

                                        </button>
                                    ) : null}
                                </li>
                            ) : null}

                            {Array.isArray(responseData) && responseData.length ? (
                                <li className="logo-center" id="response-logo"><img src={infinity} /></li>
                            ) : null}
                            {Array.isArray(responseData) && responseData.length ? (
                                <li className="message response">
                                    {(questionMainId === 2) ? (
                                        <p className="title">We've made you the perfect team, please find their detail below.</p>
                                    ) : questionMainId === 3 ? (
                                        <p className="title">Based on the above we've ranked your top traits and percentage recorded!</p>

                                    ) : questionMainId === 7 ? (
                                        <p className="title">There are top ranked individuals for the selected trait.</p>
                                    ) : null}
                                    <div className="response-list">
                                        {responseData.map((itemsData, index) => {
                                            return <p className="question" key={itemsData.uuid} id={itemsData.uuid}>

                                                {itemsData.name ? (
                                                    <span className="user-name">{itemsData.name}</span>
                                                ) : null}

                                                <p className={questionMainId === 7 ? "d-flex justify-content-between flex-wrap" : null}>
                                                    {itemsData.email ? (
                                                        <span style={questionMainId === 7 ? { marginRight: "5%" } : null}>{itemsData.email}</span>
                                                    ) : null}

                                                    {itemsData.taxonomyPercentage ? (
                                                        <span>{itemsData.taxonomyPercentage}%</span>
                                                    ) : null}
                                                </p>
                                            </p>
                                        })}
                                    </div>


                                </li>
                            ) : null}
                            {responseData.length ? (
                                <li className="retest-button-container text-center mt-4">
                                    <p>Got another insight you are looking for?</p>
                                    <button onClick={() => clearChat()} className="chat-button">Start again</button>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                    {clearChatPopup ? (
                        <div className="close-popup">
                            <div className="close-popup-content">
                                <p>Closing the chat will clear the insight</p>
                                <p>Do you want to close the chat?</p>
                                <div className="mt-4">
                                    <button onClick={() => { clearChat(); setChatboxState(false); }} className="chat-button">Yes</button>
                                    <button className="chat-button" onClick={() => setClearChatPopup(false)}>No</button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null
            }

        </div >
    );
};

export default ChatBox;