import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Divider } from ".";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Images, StringConstants } from "../config";

const CircularButtonImg = (props) => {
  const answer = props.answer;
  const [donutid, setdonutId] = useState(0);
  const [update2, setUpdate2] = useState();

  useEffect(() => {
    if (props.answerKey === 1) {
    
      let index = answer.findIndex(
        (obj) => obj.questionOptionId === props.selectedAnswer
      );
      setdonutId(index+1)
    
    }
    if (props.answerKey === 2) {
      
      let index = answer.findIndex(
        (obj) => obj.questionOptionId === props.selectedAnswer
      );
      setdonutId(index+1)
    }
  
   
  }, [props.selectedAnswer]);
 

  const onclickDonut=(id)=>{
    setdonutId(id)
    
    let index=id-1
    props.selectAnswer(
      answer[index].questionOptionId,
      answer[index].questionOptionAnswerSet
    );
  
  }

  return (
    <>
     
        {/* <Divider/> */}
        <div className="answer d-flex justify-content-between">
          <h6
            className="mb-0 select-one-answer font-size-15 font-family-ubuntu font-weight-bold line-height-20  text-color-darkblue"
            style={{ color: "#284482" }}
          >
            {props.answerKey === 1 ? StringConstants.AnswerA : props.answerKey === 2 ? StringConstants.AnswerB : StringConstants.Select_one }
            {/* {StringConstants.AnswerA} */}
          </h6>

          <h6 className="question-lot">{props.answerKey}/2</h6>
        </div>
        <div className="qcard-size">
        <div className="answers d-flex justify-content-between">
          <div className="wrp1 top-left-answer">
            <h6 className="font-size-16 font-family-ubuntu font-weight-regular line-height-18 text-color-violet">
              {answer[3].questionOptionText}
            </h6>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip-3">
                  {answer[3].questionOptionTip}
                </Tooltip>
              }
            >
              <img className="icon-radio-hint" src={Images.info} />
            </OverlayTrigger>
          </div>
          <div className="wrp2 top-right-answer ">
            <h6 className="font-size-16 font-family-ubuntu font-weight-regular line-height-18 text-color-violet ">
              {answer[0].questionOptionText}
            </h6>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip-3">
                  {answer[0].questionOptionTip}
                </Tooltip>
              }
            >
              <img className="icon-radio-hint" src={Images.info} />
            </OverlayTrigger>
          </div>
        </div>

            <div className="donut-box w-216">
                <div className={donutid==1?"one slice-size one-slice activeSlice":"one slice-size one-slice "}  onClick={() => onclickDonut(1)}></div>
                <div className= {donutid==2? "two slice-size two-slice activeSlice" :"two slice-size two-slice"} onClick={() =>onclickDonut(2)}></div>
                <div className= {donutid==3? "three slice-size three-slice activeSlice":"three slice-size three-slice"} onClick={() =>onclickDonut(3)}></div>
                <div className= {donutid==4? "four slice-size four-slice activeSlice":"four slice-size four-slice"} onClick={() =>onclickDonut(4)}></div>
            </div>



        {/* <Doughnut
          data={data}
          key={props.answerKey === 1 ? update1 : update2}
          className="donut"
          options={options}
          getElementAtEvent={(elems) => {
            let index = elems[0] && elems[0].index;
            console.log("index", index);
            if (props.answerKey === 1) {
              backgroundColor1 = [
                "rgba(201,205,226,0.65)",
                "rgba(201,205,226,0.65)",
                "rgba(201,205,226,0.65)",
                "rgba(201,205,226,0.65)",
              ];
              backgroundColor1[index] =
                "linear-gradient(203.05deg, #0EFFFF 0%, rgba(0,107,241,0.71) 40.06%, #BF06FF 100%)";
              elems[0] &&
                props.selectAnswer(
                  answer[index].questionOptionId,
                  answer[index].questionOptionAnswerSet
                );
              setUpdate1(Math.random());
            } else {
              backgroundColor2 = [
                "rgba(201,205,226,0.65)",
                "rgba(201,205,226,0.65)",
                "rgba(201,205,226,0.65)",
                "rgba(201,205,226,0.65)",
              ];
              backgroundColor2[index] =
                "linear-gradient(203.05deg, #0EFFFF 0%, rgba(0,107,241,0.71) 40.06%, #BF06FF 100%)";
              elems[0] &&
                props.selectAnswer(
                  answer[index].questionOptionId,
                  answer[index].questionOptionAnswerSet
                );
              setUpdate2(Math.random());
            }
          }}
        /> */}
        <div className="d-flex justify-content-between">
          <div className="wrp3 top-left-answer">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip-3">
                  {answer[2].questionOptionTip}
                </Tooltip>
              }
            >
              <img className="icon-radio-hint mb-3" src={Images.info} />
            </OverlayTrigger>
            <h6 className=" font-size-16 font-family-ubuntu font-weight-regular line-height-18 text-color-violet">
              {answer[2].questionOptionText}
            </h6>
          </div>
          <div className="wrp4 top-right-answer">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip-3">
                  {answer[1].questionOptionTip}
                </Tooltip>
              }
            >
              <img className="icon-radio-hint mb-3" src={Images.info} />
            </OverlayTrigger>
            <h6 className=" font-size-16 font-family-ubuntu font-weight-regular line-height-18 text-color-violet">
              {answer[1].questionOptionText}
            </h6>
          </div>
        </div>
      </div>
      {props.error && (
        <h6 className="answer-text mb-3 font-family-ubuntu font-weight-bold mb-0 font-size-15 line-height-20 text-danger">
          Please Select one answer
        </h6>
      )}
    </>
  );
};

export default CircularButtonImg;
