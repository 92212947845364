import React, { useState } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { Images } from "../../config";

const BeforeLoginHeader = (props) => {
  let history = useHistory();
  const [BooleanValue, setBooleanValue] = useState(false);
  const redirectUrl = (e) => {
    history.push(e);
  };

  const __scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* plain header with logo */}

  {/* {
    props.bluelogo ? */}
      <header className="lumenai-navbar  bg-violet nav-bgcolor  d-md-block">
        <nav className="navbar navbar-expand-lg nav-height ">
          <div className="container">
            {props.bluelogo ? (
              <Link to="/hub" className="navbar-brand blue-logo">
                <img src={Images.infinity} className="lumenai-logo" />
              </Link>
            ) : (
              <Link to="/" className="navbar-brand">
                <img src={Images.infinity_white} className="lumenai-logo" />
              </Link>
            )}
            {props.headingTitle && (
              <div className="ml-auto">
                <p className="mb-0 font-size-16 font-family-ubuntu font-weight-bold line-height-18 text-color-white">
                  {props.headingTitle}
                </p>
              </div>
            )}
          </div>
        </nav>
      </header>
      {/* :
      null
      } */}
    </>
  );
};

export default BeforeLoginHeader;
