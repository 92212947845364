import React from "react";
import { Modal } from "react-bootstrap";
const ModalContent = (props) => {


    return (
        <>
        {props.action && props.action !== undefined ? (
        <div className="modal-container" onClick={(e) => props.modalAction(e)}>
            {props.action === "delete user" ? (
            <div className="content">
                <h3>Delete user</h3>
                <p>Are you sure you want to delete this user and all of their data?</p>
                <div className="d-flex justify-content-between">
                    <button onClick={(e) => props.modalAction(e, "delete user", props.id)} className="bg-blue">Delete</button>
                    <button onClick={(e) => props.modalAction(e, "cancel", "")} className="bg-violet cancel">Cancel</button>
                </div>
            </div>
            ) : props.action === "Reset password" ? (
            <div className="content text-center">
                <h3>Reset password</h3>
                <p>We have sent instructions to the user’s email address to reset their password</p>
                <button onClick={(e) => props.modalAction(e, "done")}>Done</button>
            </div>) : props.action === "Resend invite" ? (
                 <div className="content text-center">
                 <h3>Resend invite</h3>
                 <p>We have resend the invite to the user’s email address</p>
                 <button onClick={(e) => props.modalAction(e, "done")}>Done</button>
             </div>
            ) : null }
            
        </div> 
        ) : null}
        </>
    )
}

export default ModalContent;