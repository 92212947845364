import React from "react";

const CategoryCard = (props) => {
  return (
    <>
      <div className="category-card text-center cards-size  mr-sm-3  mb-sm-3"  style={{...props}}>
        <div className="cards-icon mb-2">
          <img src={props.cardIcon} className="icon img100" />
        </div>
        <div className="cards-title mt-1 d-flex justify-content-center align-items-center">
          <h4 className="font-size-13 line-height-18  font-family-ubuntu  font-weight-medium">
            {props.categoryName}
          </h4>
        </div>
      </div>
    </>
  );
};

export default CategoryCard;
