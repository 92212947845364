import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function pathwayIncomplete_API_Action(token, data, pageNo, groupId) {
  var searchFor = data ? data : "";
  // var page = 1;
  // console.log("pageno--action", pageNo);
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/pathway/in-completed?q=${searchFor}&is_all_pathway=1&page=${pageNo}`,

      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
        groupId: groupId,
      },
      body: "",
      types: [
        types.PATHWAY_INCOMPLETE_REQUEST,
        types.PATHWAY_INCOMPLETE_RECEIVE,
        {
          type: types.PATHWAY_INCOMPLETE_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_PathwayIncomplete_API_Action = () => {
  return {
    type: types.RESET_PATHWAY_INCOMPLETE,
    payload: null,
  };
};
