import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function group_category_Action(token, organisationId, groupId) {

    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/organisation/group/${groupId}/categories`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': `Bearer ${token}`,
                organisationId: organisationId,
            },
            // body:"",
            types: [
                types.GROUP_CATEGORY_REQUEST,
                types.GROUP_CATEGORY_RECEIVE, {
                    type: types.GROUP_CATEGORY_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_group_category_Action = () => {
    return {
        type: types.RESET_GROUP_CATEGORY,
        payload: null
    }
};
