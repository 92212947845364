import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function contactuslistApi_Action(token, data) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/contact-us/subject/all`,
      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
      types: [
        types.CONTACT_US_LIST_REQUEST,
        types.CONTACT_US_LIST_RECEIVE,
        {
          type: types.CONTACT_US_LIST_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const resetcontactuslistApi_Action = () => {
  return {
    type: types.RESET_CONTACT_US_LIST,
    payload: null,
  };
};

export function contactusbuttonApi_Action(token, dataForm) {
  // console.log("contactusbuttonApi:", dataForm);
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/contact-us`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dataForm),
      types: [
        types.CONTACT_US_BUTTON_REQUEST,
        types.CONTACT_US_BUTTON_RECEIVE,
        {
          type: types.CONTACT_US_BUTTON_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const resetcontactusbuttonApi_Action = () => {
  return {
    type: types.RESET_CONTACT_US_BUTTON,
    payload: null,
  };
};
