import React from "react";
import { Images, StringConstants } from "../config";

const SoftSkillCheckIn = (props) => {
  return (
    <>
      <div className="softskill" key={props.keyvalue}>
        <div className="d-flex align-items-center overflow-hidden position-relative softskill-box">
          <div className="icon-soft">
            <img src={Images.assessment_white} className="thumnail img100" />
          </div>
          <div className="box-title pl-18 w-100">
            <h4 className="titles">
              {/* {StringConstants.softSkill} */}
              {props.title}
            </h4>
            {props.subtitle && (
              <p className="sub-titles width-percent">
                {/* {StringConstants.ready_to_start} */}
                {props.subtitle
                  ? props.subtitle
                  : StringConstants.ready_to_start}
              </p>
            )}
            <div className="start-btn position-absolute right-position">
              <button type="button" className="sm-btns">
                {StringConstants.Start}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftSkillCheckIn;
