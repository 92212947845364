import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function assessmentSummaryAPI_Action(token, categoryId, attemptId, groupId) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/assessment/${categoryId}/summary?attempt=${attemptId}`,
      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
        groupId: groupId
      },
      body: "",
      types: [
        types.ASSESSMENT_SUMMARY_REQUEST,
        types.ASSESSMENT_SUMMARY_RECEIVE,
        {
          type: types.ASSESSMENT_SUMMARY_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const resetAssessmentSummaryAPI_Action = () => {
  return {
    type: types.RESET_ASSESSMENT_SUMMARY,
    payload: null,
  };
};
