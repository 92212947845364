const GroupCard = (props) => {
    console.log(props.groupData);
    return (
        <>
        <div className="group-card-container fixed-top start-0 vw-100 vh-100">
            <div className="group-cards position-absolute top-50 start-50" >
                <div className="title-container">
                    <h3>Which group would you like to log in to?</h3>
                </div>
                {props.groupData ? (
                <ul className={props.groupData.length > 5 ? "groups-list-data" : null}>
                    {props.groupData.map((item, index) => {
                        console.log(item);
                        return (
                            <li key={item.groupId} id={item.groupId}>
                                <button onClick={(e) => props.handleGroupId(item)} className="btn btn-transparent btn-block">
                                <h4>{item.groupName}</h4>
                                {/* <p>{item.questionBank[0].questionBankName}</p> */}
                                </button>
                            </li>
                        )
                    })}
                </ul>
                ) : null}
            </div>
        </div>
        </>
    )
}

export default GroupCard;