import React, { useEffect, useCallback, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isDesktop, isTablet } from "react-device-detect";
// custome imports
import { Button, Form, Col, Container, Row } from "react-bootstrap";
import TextInput from "../../components/UIComponent/TextInput";
import { StringConstants } from "../../config";
import { TipsPopup, TopHeader, TopHeaderDesktop } from "../../components";
import { __redirectScrollTop } from "../../utils/utility";
import { clearAllData } from "../../utils/storage";
import { Page400, Page500, InternetConnectivity } from "../../screens";
const ResetPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (location.state) {
      setEmail(location.state.email);
    } else {
      window.scrollTo(0, 0);
      history.push("/login");
      clearAllData();
      window.location.replace("/login");
    }
  }, []);

  return (
    <>
      <div className="reset-screen bg-aliceBlue wave-section">
        {!navigator.onLine && <InternetConnectivity />}
        <Container>
          <Row className="pb-5 justify-content-sm-center">
            <Col md={8} className="p-0">
              <TopHeader />
              <TopHeaderDesktop />

              <div className="wrpfrom-mobile">
                <div className="heading-with-logo d-none d-sm-block">
                  <h3 className="subtitle  font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                    {StringConstants.Login_Title}
                  </h3>
                </div>
                <div className="cards-box lp-card-size  inside-space">
                  <div className="heading-with-logo">
                    <p className="mb-1 d-sm-none email-subtitle font-family-ubuntu text-color-violet line-height-22 font-weight-medium font-size-16">
                      {StringConstants.send_email}
                    </p>
                    <p className="email-text font-family-ubuntu text-color-blue font-weight-medium font-size-20 mb-2">
                      {email}
                    </p>
                    <p className="subtitle font-family-ubuntu text-color-violet line-height-22 font-weight-medium font-size-16 mb-3 text-left">
                      {StringConstants.reset_tips}
                    </p>
                  </div>
                </div>
              </div>

              <div className="text-right lp-card-size mt-4">
                <Link
                  to="/forgot-password"
                  onClick={__redirectScrollTop}
                  className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                >
                  {StringConstants.Retry}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave  pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-md-left">
            <Col md={8} className="pt-sm-5">
              <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-2">
                {StringConstants.ready_login}
              </p>
              <Link
                to="/login"
                onClick={__redirectScrollTop}
                className="btn mobile-w-243 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center px-5"
              >
                {StringConstants.Login_now}
              </Link>
            </Col>
          </Row>
          <div className="position-absolute init-top-right d-none d-md-block">
            <TipsPopup />
          </div>
        </Container>
      </div>
      {/* fotoer wave end */}
    </>
  );
};

export default ResetPassword;
