export const faqData=[
    {
        id:1,
        heading:'Can I keep retaking my Lumen until I reach Managerially Competent or Talented?',
        decs:"Yes. Your first Lumen identifies your innate skill capabilities in Managerial and Talented Competence. It also identifies where you meet organisational benchmarks and training needs. The purpose of retaking the Lumen is to give you an opportunity to complete the necessary training to ensure you understand your organisational benchmarks and how to improve your Lumen levels."
    },
    {
        id:2,
        heading:'I am worried that I will fail this test and that my manager will use it against me.',
        decs:"Lumen measurements are designed to get insight into your innate skills intelligence and training needs. You cannot fail a Lumen, rather Lumenai provides a range of questions that could all be correct but are designed to help you articulate your skills intelligence in ways that remove unconscious bias and allow you to better self-represent your capabilities to your existing or future employers no matter what your background or access to opportunities."
    },
    {
        id:3,
        heading:'Can I use my Lumens to build a digital skills portfolio that I can use in appraisals and to build my CV?',
        decs:"Yes. The aim of Lumenai is to help you get to where you want to get to in your career, both in your existing and future job roles."
    },
    // {
    //     id:4,
    //     heading:'How does the Lumen work',
    //     decs:`'You have up to 3 attempts at each Lumen:</br></br> Snapshot: </br> This is your first attempt and gives insight into your innate capabilities, especially in areas of management and talent against different workflows.</br></br> Upskilling </br> This is your second attempt after you have followed your personalised learning programme that you were given after your snapshot Lumen. </br></br> Outgoing </br> This is your final attempt, if you have not already met all of your company Benchmarks. '
    //     `
    // },


]