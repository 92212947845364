import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function space_setup_action(data, token, organisationId) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/organisation/space-setup`,
      method: "POST",
      headers: {
        ...AUTH_HEADERS,
        'Authorization': 'Bearer ' + token,
        'organisationId': organisationId
      },

      body: JSON.stringify(data),
      types: [
        types.SPACE_SETUP_REQUEST,
        types.SPACE_SETUP_RECEIVE,
        {
          type: types.SPACE_SETUP_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const reset_space_setup_Action = () => {
  return {
    type: types.RESET_SPACE_SETUP,
    payload: null,
  };
};