import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Welcome from "../../components/Welcome";
import { StringConstants } from "../../config";
import { CompanyIcon, InfinityWhite } from "../../config/Images";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getData, GROUP_SELECTED, setData, TOKEN, USER_DATA } from "../../utils/storage";
import { yupResolver } from "@hookform/resolvers/yup";
import { reset_user_profile_Action, user_profile_Action } from "../../stores/actions";
import { useHistory } from "react-router-dom";


const UserProfile = () => {

    const [ageNumber, setAgeNumber] = useState([]);
    const [token, setToken] = useState();
    const [userData, setUserData] = useState();
    const history = useHistory();

    const dispatch = useDispatch();
    const profileResponse = useSelector((state) => state.userProfile);

    const numberData = [];
    for(let i=16;i <= 65;i++) {
        numberData.push(
        <option key={i} value={i}>{i}</option>

        )
    }

    useEffect(() => {
        if (profileResponse.isSuccess && profileResponse.data !== null) {
            setData(USER_DATA, profileResponse.data.data.user, () => {
                window.location.href = window.origin
            })
        }
    }, [profileResponse])

    useEffect(() => {
        try {
            getData(TOKEN)
            .then((res) => 
                {
                    setToken(res.token);
                    getData(USER_DATA)
                    .then((userData) => setUserData(userData))
                    .catch((error) => console.log(error))
                    .catch((error) => console.log(error))
                }
            )
        } catch {
            console.log("error")
        }
    }, [])

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const schema = yup.object().shape({
        mobile_number: yup
        .string().when('mobile' , {
            is:(mobile) => mobile.length > 0,
           then: yup.string().matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Phone number is not valid."
          ),
        }),
        first_name: yup.string().default(userData ? userData.first_name : ""),
        last_name: yup.string().default(userData ? userData.last_name : ""),
        jobTitle: yup.string().default(userData ? userData.job_title : ""),
        age: yup.string().default(''),
        gender: yup.string().default(''),
      });

      const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
      });

      const onSubmitData = useCallback((data) => {
        try {
            dispatch(user_profile_Action(token, data));
        } catch (e) {
          console.log(e);
        }
      });

    return (
        <div className="bg-aliceBlue form-mobile-view wave-section d-flex justify-content-between">
           <Welcome title={StringConstants.WelcomeTitle} data={ StringConstants.WelcomeData} imgSrc={InfinityWhite} />
            <Container className="bg-aliceBlue admin-form-container">
                <Row className="pb-5 justify-content-sm-center">
                    <Col md={10}>
                        <form onSubmit={handleSubmit(onSubmitData)}>
                            <div className="heading-with-logo">
                                <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                                {StringConstants.UserProfileTitle}
                                </h3>
                                <p>{StringConstants.UserProfileDescription}</p>
                            </div>
                            <div className="cards-box lp-card-size  inside-space">
                                <div className="user-company-detail">
                                    <span className="title">{StringConstants.UserProfileDetail}</span>
                                    <div className="d-flex justify-content-between user-details">
                                        <div className="image">
                                            
                                            {userData && userData?.companyLogo !== ''? (
                                                userData?.companyLogo !== 'placeholder'  ? <figure>
                                                <img src={userData.companyLogo} />
                                            </figure> : ''
                                            ) : null}
                                            {userData && userData.companyName ? (
                                            <h4>{userData.companyName}</h4>
                                            ) : null}
                                            {userData && userData.companyEmployeeCount ? (
                                            <span>{userData.companyEmployeeCount} {userData.companyEmployeeCount > 1 ? "Employees" : "Employee"}</span>
                                            ) : null}
                                        </div>
                                        {userData ? (
                                        <div className="user-company-name">
                                            <h4>{userData.name}</h4>
                                            <span>{userData.job_title}</span>
                                            <span>{userData.department}</span>
                                        </div>
                                        ) : null}
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: StringConstants.UserParaData}}  />
                                    <div className="form-group components-form field-style ddd">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="mobile">
                                                {StringConstants.Mobile}
                                            </label>
                                            <span>
                                                optional
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            name="mobile"
                                            className="form-control"
                                            id="mobile"
                                            {...register("mobile")}
                                        />
                                        <span className="error">{errors.mobile?.message}</span>
                                    </div>
                                    <div className="form-group components-form field-style ddd">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="age">
                                                {StringConstants.Age}
                                            </label>
                                            <span>
                                                optional
                                            </span>
                                        </div>
                                        <div className="form-select">
                                        <select  placeholder="Please Select" {...register("age")}>
                                            <option value="" disabled selected>Please Select</option>
                                            {numberData}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="form-group components-form field-style ddd">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="gender">
                                                {StringConstants.Gender}
                                            </label>
                                            <span>
                                                optional
                                            </span>
                                        </div>
                                        <div className="form-select">
                                        <select {...register("gender")}> 
                                            <option value="" disabled selected>Please Select</option>
                                            <option value="He/him">He/him</option>
                                            <option value="She/her">She/her</option>
                                            <option value="They/them">They/them</option>
                                        </select>
                                        </div>
                                        {/* <span className="error">{errors.email?.message}</span> */}
                                    </div>
                                    <div className="text-left lp-card-size mt-4">
                                    <button
                                    type="submit"
                                    className="btn mr-3 btn-text bg-violet text-white py-3 px-5 register-btn btn-circle-30"
                                    >
                                    {StringConstants.GetStartedCTA}
                                    </button>
                                </div>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserProfile;