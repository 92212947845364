import React from "react";
import { Images, StringConstants } from "../config";

const ProgressCount = (props) => {
  return (
    <>
      <div
        className={
          props.status === "complete" && props.count > 0
            ? "progress-count cards-wrp d-flex  justify-content-between align-items-center complete"
            : "progress-count cards-wrp d-flex  justify-content-between align-items-center "
        }
      >
        <div className="countbox d-flex align-items-center">
          <p
            className={
              props.status === "complete" && props.count > 0
                ? "mb-0 font-size-30 font-family-ubuntu font-weight-medium mr-2 text-color-purple"
                : "mb-0 font-size-30 font-family-ubuntu font-weight-medium mr-2 text-color-violet"
            }
          >
            {props.count}
          </p>
          <p
            className={
              props.status === "complete" && props.count > 0
                ? "mb-0 font-size-14 line-height-18 font-family-ubuntu font-weight-medium text-color-purple"
                : "mb-0 font-size-14 line-height-18 font-family-ubuntu font-weight-medium text-color-violet"
            }
          >
            {props.statusName}
          </p>
        </div>
        <div className="status-block">
          {props.status === "complete"  && props.count > 0 ? (
            <div className="complete-circle">
              <img src={Images.complete_checkbox} alt="" />
            </div>
          ) : (
            <div className="incomplete-circle"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgressCount;
