import React from "react";
import { CircularButton, OGButton } from ".";
import { Images } from "../config";
import CircularButtonImg from "./CircularButtonImg";

const QuestionTypeSelector = (props) => {
    const answer = props.answer;
  
    return(
        <>
        {props.selectAnswer && props.type === "donut" ? (
            <div className="qmain-wrp mb-4" style={{ ...props }}>
                <CircularButtonImg 
                     answer={answer}
                     selectAnswer={(data, answerKey) => props.selectAnswer(data, answerKey)}
                     selectedAnswer = {props.selectedAnswer}
                     answerKey={props.answerKey} 
                     error={props.error}
                     setError={props.setError}
                />  
                
                {/* <CircularButton 
                    answer={answer}
                    selectAnswer={(data, answerKey) => props.selectAnswer(data, answerKey)}
                    selectedAnswer = {props.selectedAnswer}
                    answerKey={props.answerKey} 
                    error={props.error}
                    setError={props.setError}
                /> */}
            </div>
        ) : null}
          
        {props.selectAnswer && props.type === "radio" ? (
        <div className="qmain-wrp mb-4" style={{ ...props }}>
            <OGButton 
                answer={answer} 
                answers={props.answers}
                selectAnswer={(data, answerKey) => props.selectAnswer(data, answerKey)} 
                answerKey={props.answerKey}
                error={props.error}
                selectedAnswer = {props.selectedAnswer}
                setError={props.setError} 
            />
        </div>
        ) : null}
        </>
    )
}

export default QuestionTypeSelector;