import React, { useEffect, useState } from 'react';
import initReactFastclick from 'react-fastclick';
import { AfterLoginHeader, BeforeLoginHeader, Footer, Header } from './components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "./stores/actions";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import './assets/css/scss/bootstrap.scss';
import './assets/css/scss/theme.scss';
import { getData, setData, TOKEN, USER_DATA } from "./utils/storage";

const App = props => {
  // initialization of fast click
  initReactFastclick();
  

  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const isLoggedIn = useSelector((state) => state.auth.data);

  useEffect(() => {
    // check if user is already logged in
    getData(TOKEN)
      .then((res) => {
        getData(USER_DATA).then((userRes) => {
          setUserData(userRes);
          dispatch(
            isAuthenticated({
              status: true,
              token: userRes.token,
              expires_at: userRes.expires_at,
            })
          );
        });
      })
      .catch((error) => {
        // unable to fine user profile
        dispatch(isAuthenticated({ status: false }));
      });
  }, []);


  return (
    <>
      {/* <Header /> */}
        {
          isLoggedIn.status === false ? <BeforeLoginHeader /> : <Header /> 
        }
        {props.children}
      {/* <Footer /> */}
    </>
  );
};

export default App;
