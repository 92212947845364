import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images, StringConstants } from "../../config";
import {
  TextInput,
  TopHeader,
  TipsPopup,
  HelpTip,
  TopHeaderDesktop,
} from "../../components";
import { Page400, Page500, InternetConnectivity } from "../../screens";
const TermsAndConditions = (props) => {
  const [errorDataModel, setErrorDataModel] = useState();
  const onMessage = () => {
    window.open(`https://lumenai.tech/privacy-policy/`, "_blank");
  };
  return (
    <>
      <div className="content-box bg-aliceBlue wave-section pt-lg-5 pt-md-4 pb-lg-3 header-minusHeight mob-height">
        <TopHeader />
        {!navigator.onLine && <InternetConnectivity />}
        {errorDataModel && errorDataModel.error.status_code == "500" && (
          <Page500 />
        )}
        {errorDataModel && errorDataModel.error.status_code == "400" && (
          <Page400 />
        )}
        <Container>
          <Row className="justify-content-sm-center pt-3 pt-sm-0">
            <Col md={10} lg={8} className="pb-5">
              <TopHeaderDesktop />
              <div className="heading-with-logo mt-3 mt-md-0 d-md-none">
                <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                  {StringConstants.Term_and_conditions}
                </h3>
              </div>
              <div className="cards-policy bottom-content  overflow-hidden">
                <div className="card-content p-4 padding-lr jc-inside-space">
                  <p className="font-family-ubuntu font-weight-regular mb-0 font-size-16 line-height-22 text-color-violet">
                  For Terms and Conditions please see <Link to='#'
                              onClick={
                                ()=>(
                                  window.open( 
                                    "https://lumenai.tech/privacy-policy/", "_blank"))
                              }
                            > www.lumenai.tech</Link>
                    {/* <button
                      onClick={onMessage}
                      className="background-transparent  font-family-ubuntu  px-4 py-2 mt-2 border-2 border-color-white text-color-blue "
                    >
                      Terms & Conditions
                    </button> */}
                  </p>
                </div>
                <div className=" position-fixed lumen-help fixed-lumen-help d-none d-sm-block">
                  <button
                    type="button"
                    className="tips-button-size border-0 over-position"
                    onClick={() =>
                      window.open(
                        "http://lumenai.staging.pocketappserver.net.s3-website-eu-west-1.amazonaws.com/pathway/soft-skill-explained"
                      )
                    }
                  >
                    <img
                      src={Images.lumenai_helper}
                      className="logo-width img100"
                    />
                  </button>
                  {/* <TipsPopup /> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="d-none d-md-block position-fixed lumen-help fixed-lumen-help">
        <HelpTip />
        {/* <TipsPopup /> */}
      </div>
    </>
  );
};

export default TermsAndConditions;
