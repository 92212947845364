import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function team_creation_data_action(data, token, organisationId, groupId) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/organisation/group/${groupId}/create-team`,
            method: "POST",
            headers: {
                ...AUTH_HEADERS,
                Authorization: "Bearer " + token,
                organisationId: organisationId
            },

            body: JSON.stringify(data),
            types: [
                types.TEAM_CREATION_DATA_REQUEST,
                types.TEAM_CREATION_DATA_RECEIVE,
                {
                    type: types.TEAM_CREATION_DATA_FAILURE,
                    meta: (action, state, res) => {
                        return action, state, res;
                    },
                },
            ],
        },
    };
}

export const reset_team_creation_data_Action = () => {
    return {
        type: types.RESET_TEAM_CREATION_DATA,
        payload: null,
    };
};