import React, { useEffect, useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isDesktop, isTablet } from "react-device-detect";
import { Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// custome imports
import { StringConstants } from "../../config";
import {
  TipsPopup,
  TopHeader,
  TopHeaderDesktop,
  TextInput,
} from "../../components";
import {
  forget_password_Action,
  reset_forget_password_Action,
} from "../../stores/actions";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { __redirectScrollTop } from "../../utils/utility";

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [emailstring, setEmailString] = useState();
  const [loader, setLoader] = useState(false);
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [data, setData] = useState();

  const fpData = useSelector((state) => state.forgetpassword);

  //schema validation
  const ForgotPassword_schema = yup.object().shape({
    email: yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPassword_schema),
  });

  const onSubmit = (data) => {
    // console.log("fpData", fpData);
    try {
      dispatch(forget_password_Action(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("fpData",fpData )
    if (fpData.isFetching) {
      setLoader(true);
    } else if (fpData.isSuccess && fpData.data !== null) {
      setLoader(false);
      window.scrollTo(0, 0);

      let emailValue = fpData.data && fpData.data.data.email;
      if (emailValue !== null && emailValue !== undefined) {
        history.push({
          pathname: "/reset-password",
          state: { email: emailValue },
        });
        dispatch(reset_forget_password_Action());
      } else {
        history.push({
          pathname: "/reset-password",
          //state: { email: emailValue },
        });
      }
    } else if (fpData.isError) {
      setErrorModel(true);
      setErrorDataModel(fpData.data);
      // if(fpData.data.error.status_code === 422){
      //   history.push({
      //     pathname: "/login",
      //   });
      // }
    }
  }, [fpData]);

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      <div className="login-screen bg-aliceBlue wave-section">
        <Container>
          <Row className="pb-4 justify-content-sm-center">
            <Col md={8} className="p-0">
              <TopHeader />
              <TopHeaderDesktop />
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="wrpfrom-mobile">
                  <div className="heading-with-logo d-none d-sm-block">
                    <h3 className="subtitle  font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                      {StringConstants.Login_Title}
                    </h3>
                  </div>
                  <div className="cards-box lp-card-size  inside-space">
                    <div className="heading-with-logo">
                      <p className="subtitle font-family-ubuntu text-color-violet line-height-22 font-weight-medium font-size-16 mb-3 text-left">
                        {StringConstants.forgot_tips}
                      </p>
                    </div>
                    {/* <TextInput
                      id="email"
                      name="email"
                      label={StringConstants.EmailAddress}
                      type="email"
                      {...register("email")}
                      showerror={errors.email?.message}
                    /> */}
                    <div className="form-group components-form field-style ddd">
                      <label htmlFor="email">
                        {StringConstants.EmailAddress}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        {...register("email")}
                      />
                      <span className="error">{errors.email?.message}</span>
                    </div>

                    <span className="error">
                      {errorDataModel && errorDataModel.error.message}
                    </span>
                  </div>
                </div>

                {fpData.isFetching ? (
                  <div className="text-right lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.Loading}
                    </button>
                  </div>
                ) : (
                  <div className="text-right lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.Reset_password}
                    </button>
                  </div>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      {/* footer of wave */}
      <div className="footer-wave  pb-5 position-relative">
        <Container>
          <Row className="justify-content-sm-center text-center text-md-left">
            <Col sm={8} className="pt-sm-5">
              <p className="font-size-14 text-white font-family-ubuntu font-weight-regular mb-2">
                {StringConstants.ready_login}
              </p>
              <Link
                to="/login"
                onClick={__redirectScrollTop}
                className="btn mobile-w-243 min-width-178 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
              >
                {StringConstants.Login_now}
              </Link>
            </Col>
          </Row>
          {/* <div className="position-absolute init-top-right d-none d-sm-block">
             <HelpTip />
            <TipsPopup />
          </div> */}
        </Container>
      </div>
      {/* fotoer wave end */}
    </>
  );
};

export default ForgotPassword;
