import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Welcome from "../../components/Welcome";
import { Images, StringConstants } from "../../config";
import { ChatBox, ShowPassword } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputTags } from 'react-bootstrap-tagsinput';
import { UserBack } from "../../config/Images";
import { getData, GROUP_SELECTED, setData, TOKEN, USER_DATA } from "../../utils/storage";
import { edit_admin_Action, edit_user_Action, invite_new_user_Action, reset_edit_admin_Action } from "../../stores/actions";
import { useDispatch, useSelector } from "react-redux";


const EditAdminUser = () => {

    const [tagData, setTagData] = useState([]);
    const [token, setToken] = useState();
    const [ organisationID, setOrganisationId ] = useState();
    const [userData, setUserData] = useState();
    const [groupState, setGroupState] = useState();
    const [tagState, setTagState] = useState();
    const dispatch = useDispatch();
    const location = useLocation();
    const editAdminSelector = useSelector((state) => state.editAdmin);
    const history = useHistory();
    const [adminState, setAdminState] = useState({
        emailId: "",
        firstName: "",
        lastName: "",
    });

  

  const schema = yup.object().shape({
    email: yup.string().email("Please enter valid email id"),
    first_name: yup
      .string()
      .trim("space are not allow")
      .required("Please enter first name"),
    last_name: yup
      .string()
      .trim("space are not allow")
      .required("Please enter last name"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: adminState || {}
  });


  useEffect(() => {
    reset(adminState)
  }, [reset, adminState])

    useEffect(() => {
      try {
        getData(TOKEN)
        .then((res) => 
          {
            setToken(res.token);
            getData(USER_DATA)
            .then((userRes) => {setOrganisationId(userRes.companyId); setUserData(userRes); setAdminState({...adminState, emailId: userRes.email, firstName: userRes.first_name, lastName: userRes.last_name})}).catch((error) => console.log(error));
            getData(GROUP_SELECTED)
            .then((groupSelected) => {setGroupState(groupSelected.groupState);setTagState(groupSelected.tagData);
            })
          } 

        ).catch((error) => console.log(error))
      }catch(error) {
        console.log(error)
      }
    }, [])


    useEffect(() => {
      if(editAdminSelector.isSuccess && editAdminSelector.data !== null) {
        const userDataValue = editAdminSelector.data.data.user;
        setData(USER_DATA, userDataValue);
        history.goBack();
        dispatch(reset_edit_admin_Action());
      } else if(editAdminSelector.isError) {
        dispatch(reset_edit_admin_Action());
      }
    }, [editAdminSelector])


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Simple tooltip
        </Tooltip>
      );

      const handleChange = (e) => {
        setGroupState(e.target.value);
      }

      console.log();

      const onSubmitData = useCallback((data) => {
        try {
          dispatch(edit_admin_Action(data, token, organisationID))
        } catch (e) {
          console.log(e);
        }
      });

    
    const [errorDataModel, setErrorDataModel] = useState("");

    useEffect(() => {

    }, [tagState])


    return (
        <div className="admin-user-creation form-mobile-view user-tag-detail invite-user d-flex bg-aliceBlue justify-content-between">
            <Welcome title={StringConstants.EditAdminUserTitle}  imgSrc={UserBack} />
            <Container className="bg-aliceBlue admin-form-container">
                <Row className="pb-5 justify-content-sm-center">
                    <Col md={10} className="p-0">
                <form onSubmit={handleSubmit(onSubmitData)}>
                  <div className="wrpfrom-mobile">
                    <div className="heading-with-logo">
                        <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                        {StringConstants.EditUserTitle}
                        </h3>
                        <p>{StringConstants.EdituserDescription}</p>
                    </div>
                    <div className="cards-box lp-card-size  inside-space">
                    <div className="form-group components-form field-style ddd">
                      <label htmlFor="email">
                        {StringConstants.EmailAddress}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        {...register("email")}
                        value={adminState.emailId !== "" ? adminState.emailId : ""}

                        // onChange={(e) => setAdminState({...adminState, emailId: e.target.value})}
                        disabled
                      />
                      <span className="error">{errors.email?.message}</span>
                    </div>
                    <Row>
                    <div className="col-md-6 form-group components-form field-style ddd">
                      <label htmlFor="first-name">
                        {StringConstants.Fname}
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        className="form-control"
                        id="first-name"
                        value={adminState.firstName !== "" ? adminState.firstName : ""}
                        {...register("first_name")}
                        onChange={(e) => setAdminState({...adminState, firstName: e.target.value})}

                      />
                      <span className="error">{errors.first_name?.message}</span>
                    </div>
                    <div className="col-md-6 form-group components-form field-style ddd">
                      <label htmlFor="last-name">
                        {StringConstants.Lname}
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        className="form-control"
                        id="last-name"
                        value={adminState.lastName !== "" ? adminState.lastName : ""}
                        {...register("last_name")}
                        onChange={(e) => setAdminState({...adminState, lastName: e.target.value})}

                      />
                      <span className="error">{errors.last_name?.message}</span>
                    </div>
                    </Row>
                  </div>
                  <div className="text-left button-container lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mr-3 mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.SaveChanges}
                    </button>
                    <button
                      onClick={(e) => {e.preventDefault(); history.goBack()}}
                      className="btn cancel  btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.Cancel}
                    </button>
                  </div>
                  </div>
                </form>
                </Col>
                </Row>
            </Container>
            <ChatBox />
        </div>
    )
    
}

export default EditAdminUser;