import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function invite_new_user_Action(data, token, groupId, organisationId) {
    // var searchbyname = data ? data : "";
    // console.log("data Action--",data);
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/organisation/group/${groupId}/invite-user`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': `Bearer ${token}`,
                organisationId: organisationId,
                },
            body:JSON.stringify(data),
            types: [
                types.INVITE_NEW_USER_REQUEST,
                types.INVITE_NEW_USER_RECEIVE, {
                    type: types.INVITE_NEW_USER_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetinvite_new_user_Action = () => {
    return {
        type: types.RESET_INVITE_NEW_USER,
        payload: null
    }
};
