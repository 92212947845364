import React from "react";
import { PasswordOff, PasswordOn } from "../config/Images";


const ShowPassword = (props) => {

    console.log(props.actionProp);

    return (
        <span className="show-password" onClick={props.passFun}>
            {/* {props.actionProp ? "notshow" : "show"}  */}
            <img src={props.actionProp ? PasswordOff : PasswordOn} />
        </span>
    )
}

export default ShowPassword;