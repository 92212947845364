import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";

export function grouptaxonomieList_Action(token, organisationId, groupId) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/organisation/group/${groupId}/taxonomy-list`,
            method: "GET",
            headers: {
                ...AUTH_HEADERS,
                Authorization: "Bearer " + token,
                organisationId: organisationId
            },
            body: "",
            types: [
                types.GROUP_TAXONOMIES_LIST_REQUEST,
                types.GROUP_TAXONOMIES_LIST_RECEIVE,
                {
                    type: types.GROUP_TAXONOMIES_LIST_FAILURE,
                    meta: (action, state, res) => {
                        return action, state, res;
                    },
                },
            ],
        },
    };
}

export const grouptaxonomieListRestAPI_Action = () => {
    return {
        type: types.RESET_GROUP_TAXONOMIES_LIST,
        payload: null,
    };
};
