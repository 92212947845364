import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CircularButton, OGButton } from ".";
import { Images } from "../config";

const QuestionCard = (props) => {
  const question = props.question;
  const answer = props.answer;
  // console.log(
  //   "ans",
  //   props.answerKey == 1 && answer[0].questionOptionType === "text"
  // );

  return (
    props.question !== undefined && (
      <>
        <div className="box-b">
          <div
            className="text-cl mb-md-4 question-card qcard-size ques-card-hide"
            style={{ ...props }}
          >
            <div className="cards-title mt-1">
              <h4 className="mb-md-3 font-size-18 line-height-22 family-font-ubuntu font-weight-bold text-color-darkblue question-text">
                {props.question.questionContent}
              </h4>
            </div>
            <div className="question-info d-flex align-items-center">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {props.question.questionLumenTip}
                  </Tooltip>
                }
              >
                <img className="icon-hint" src={Images.info} alt="info" />
              </OverlayTrigger>
              <p className="ml-2 question-hint text-color-darkgray mb-0 font-size-13 font-weight-medium">
                Question Hint
              </p>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default QuestionCard;
