import React, { useState } from "react";
import { Row, Col, Image, Button, Container, Card } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
//custome imports
import { Colors, Images } from "../config";
import { Page400, Page500, InternetConnectivity } from "../screens";
import {
  CategoryCard,
  TipsPopup,
  TopPopup,
  HelpTip,
  TopHeaderDesktop,
  SoftSkillCard,
} from "../components";
import { isMobile, isMobileOnly } from "react-device-detect";
import StringConstants from "../config/StringConstants";
import { __redirectScrollTop } from "../utils/utility";
import { LearningCard, ProgressCount } from "../components";
import StaticText from "../components/StaticText";


 const listData = [
   {
        id:1,
        icon:Images.trainingrequire,
        color:Colors.darkblue,
        series:"One",
        heading:'Training required',    
        description:"Your training needs are where you have not met the company benchmark standards. These are laid out in your personalised learning programme. You will be assessed through questionnaires that will evaluate your upskilling as you progress through your personalised learning pathway."
    },
    {
        id:2,
        icon:Images.twolevelinfo,
        color:Colors.cyan,
        series:"Two",
        heading:'Company Benchmark',    
        
        description:"Company Benchmarks are the skill capability benchmarks set by your current or future employer. These are in relation to specific workflows, so you won’t always understand what is expected or easily meet all of these benchmarks in your first Lumen. "
    },
    {
        id:3,
        icon:Images.leveltwo,
        color:Colors.lightBlue,
        series:"Three",
        heading:'Managerially Competent',   

        description:"To achieve management capability level in your Lumen is very hard. If you achieve this level in your Lumen then you are exceeding the basic standards set by your current or future employer. You are demonstrating that you have management capabilities in relation to the specific workflow that the Lumen measurement has been assigned to. Management Capabilities might be assessed differently across different Lumens as your decision making will be different for different workflows and will demonstrate strengths and weaknesses in different ways.  The key way to work out your overall strengths would be to compare your skill capabilities across different Lumens."
    },
    {
        id:4,
        icon:Images.levelthree,
        color:Colors.Electric_Purple,
        series:"Four",
        heading:'Talented Skillset', 
        description:"To achieve Talent capability level in your Lumen is very hard. If you achieve this level in your Lumen then you are exceeding the basic standards set by your current or future employer. You are demonstrating that you have talent capabilities in relation to the specific workflow that the Lumen measurement has been assigned to. Talent Capabilities might be assessed differently across different Lumens as your decision making will be different for different workflows and will demonstrate strengths and weaknesses in different ways.  The key way to work out your overall strengths would be to compare your skill capabilities across different Lumens."
    },
 ]




const SoftSkillExplainedDetail = (props) => {
  const [errorDataModel, setErrorDataModel] = useState();
  // console.log("props", props);
  const history = useHistory();
  const location = useLocation();
  const data = location.state.data;
  const __closePopup = () => {
    history.goBack();
  };
  const [displayData, setDisplayData] = useState();
  let activeLearningIcon = "";
  //console.log("data", data);

  
  switch (data.categoryName) {
    case "Problem Solving":
      activeLearningIcon = Images.probsolving_whitecard;
      break;
    case "Critical Thinking":
      activeLearningIcon = Images.criticalthinking_whitecard;
      break;
    case "Teamwork":
      activeLearningIcon = Images.teamwork_whitecard;
      break;
    case "Communication":
      activeLearningIcon = Images.communication_whitecard;
      break;
    case "Leadership":
      activeLearningIcon = Images.leadership;
      break;
    case "Time Management":
      activeLearningIcon = Images.timemgt_whitecard;
      break;
    case "Emotional Judgement":
      activeLearningIcon = Images.emotionaljudge;
      break;
    case "Creativity":
      activeLearningIcon = Images.creativity;
    break;
    case "Flexibility/Adaptability":
      activeLearningIcon = Images.Flexibilty_active;
      break;
    default:
      activeLearningIcon = Images.infinity;
  }

  const activeLearning = () => {
    return (
      <div className="active-learning-box learning-box mt-5 pt-5 mb-5 ">
        <p className="font-size-18 mt-3 mt-md-1 soft-heading-d font-family-ubuntu line-height-22  font-weight-bold text-color-violet  mb-3">
          {StringConstants.Select_a_soft_skill}
        </p>
        <div className="wrp-for-mobile assesment-box assessment-inner-space">
          <div className="w-100 max-size-503 cards-box padding-lr top-heading py-3 px-3  d-flex align-items-center mb-sm-4 mob-tab0">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex align-items-center w-100">
                <img
                  src={activeLearningIcon}
                  className="icon-w-30 card-img-left mr-4"
                />
                <h3 className="text-color-violet mb-0 font-size-16 font-family-ubuntu font-weight-medium line-height-18 mb-0">
                  {data.categoryName}
                </h3>
              </div>
              <button
                type="button"
                onClick={() => {
                  __closePopup();
                }}
                className="background-transparent border-0"
              >
                <img src={Images.close_skill} className="ml-5" />
              </button>
            </div>
          </div>

          <div className="cards-box bottom-content  overflow-hidden">
            <div className="card-content padding-lr jc-inside-space">
              <p
                className="font-family-ubuntu font-weight-regular font-size-15 line-height-22 text-color-violet"
                dangerouslySetInnerHTML={{
                  __html: data.categoryDescription,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="soft-skill-details-screen soft-skill-curve   header-minusHeight  bg-aliceBlue before-curve-pathway ">
        {errorDataModel && errorDataModel.error.status_code == "500" && (
          <Page500 />
        )}
        {errorDataModel && errorDataModel.error.status_code == "400" && (
          <Page400 />
        )}
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10 col-lg-8 ">
              <TopHeaderDesktop white />
              <div className="inner-soft mt-4">
                <h4 className="text-color-aliceBlue line-height-24 font-size-20 font-family-ubuntu font-weight-bold mb-2">
                  {StringConstants.Everything}
                </h4>
                <p className="text-left text-white line-height-17 font-size-15 font-family-ubuntu font-weight-300 mb-2">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim adem. */}
                </p>
              </div>
              {activeLearning()}
            </div>
          </div>
        </div>
        <div className="soft-skill-footer footer-wave header-minusHeight">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-10 col-lg-8 mb-70">
                <div className="mt-5 pt-3">
                  <h4 className="font-family-ubuntu font-size-18 font-weight-bold text-color line-height-22 select-skill mb-4">
                    {StringConstants.The_different_levels}
                  </h4>
                  <p className="font-family-ubuntu font-size-16 font-weight-bold text-color line-height-22 select-skill mb-2">
                    {StringConstants.attempts3}
                  </p>
                  <p className="font-family-ubuntu font-size-16 font-weight-bold text-color line-height-22 select-skill mb-1">
                    {StringConstants.Snapshot}
                  </p>
                  <p className="font-family-ubuntu font-size-14  text-color line-height-22 select-skill mb-4">
                    {StringConstants.first_attempt_}
                  </p>
                  <p className="font-family-ubuntu font-size-16 font-weight-bold text-color line-height-22 select-skill mb-1">
                    {StringConstants.Upskilling}
                  </p>
                  <p className="font-family-ubuntu font-size-14  text-color line-height-22 select-skill mb-4">
                    {StringConstants.second_attempt}
                  </p>
                  <p className="font-family-ubuntu font-size-16 font-weight-bold text-color line-height-22 select-skill mb-1">
                    {StringConstants.Outgoing}
                  </p>
                  <p className="font-family-ubuntu font-size-14  text-color line-height-22 select-skill mb-4">
                    {StringConstants.third_attempt}
                  </p>
                  
                  <div className="soft-active-block pb-3">
                    {
                      listData.map((item)=>(
                        <div key={item.id}> 
                          <StaticText
                            color={item.color}
                            icon={item.icon}
                            // series={item.series}
                            tr={item.heading}
                            description={item.description} 
                            />
                          </div>
                      ))
                    }

                    {/* <StaticText
                      color={Colors.darkblue}
                      icon={Images.trainingrequire}
                      series={"One"}
                      tr={"Training required"}
                      description={}
                    /> */}
                    {/* <StaticText
                      color={Colors.cyan}
                      icon={Images.twolevelinfo}
                      series={"Two"}
                      tr={"Company Benchmark"}
                    /> */}
                    {/* <StaticText
                      color={Colors.lightBlue}
                      icon={Images.leveltwo}
                      series={"Three"}
                      tr={"Managerially Competent"}
                    />
                    <StaticText
                      color={Colors.Electric_Purple}
                      icon={Images.levelthree}
                      series={"Four"}
                      tr={"Talented Skillset"}
                    /> */}

                  </div>
                  <div className=" position-fixed lumen-help fixed-lumen-help">
                    <HelpTip />
                    {/* <TipsPopup /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftSkillExplainedDetail;
