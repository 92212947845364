import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Images, StringConstants } from "../config";

const TopHeaderDesktop = (props) => {
  const history = useHistory();

  return (
    <>
      <div
        className={
          props.white
            ? "breadcrumbs-desktop mb-3 pb-md-1 pt-3 "
            : props.black
              ? "breadcrumbs-desktop mb-4 pb-2 pt-3 "
              : "breadcrumbs-desktop mb-4 pb-2 pt-3 d-none d-md-block"
        }
      >
        <div className="breadcrumbs-box d-flex position-relative">
          {props.redirectHub === "/hub" ? (
            <button
              className="border-0 p-0 font-size-14 d-flex  align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back"
              onClick={() => {
                history.push({ pathname: "/hub" });
                window.scrollTo(0, 0);
              }}
            >
              {
                // props.white ? (
                //   <>
                //     <img
                //       src={Images.back_arrow_white}
                //       className="back-icon pr-2"
                //     />
                //     <span className="back_text text-color-white">
                //       {StringConstants.back}
                //     </span>
                //   </>
                // ) :
                props.black ? (
                  <>
                    <img src={Images.backArrow} className="back-icon pr-2" />
                    <span className="back_text ">{StringConstants.back}</span>
                  </>
                ) : (
                  <>
                    <img src={Images.backArrow} className="back-icon pr-2" />
                    <span className="back_text ">{StringConstants.back}</span>
                  </>
                )
              }
            </button>
          ) : (
            <button
              className="border-0 p-0 font-size-14 d-flex  align-items-center font-weight-bold font-family-ubuntu line-height-22 background-transparent text-color-violet lu_back"
              onClick={() => {
                history.goBack();
                window.scrollTo(0, 0);
              }}
            >
              {props.white ? (
                <>
                  <img
                    src={Images.back_arrow_white}
                    className="back-icon pr-2"
                  />
                  <span className="back_text text-color-white">
                    {StringConstants.back}
                  </span>
                </>
              ) : props.black ? (
                <>
                  <img src={Images.backArrow} className="back-icon pr-2" />
                  <span className="back_text ">{StringConstants.back}</span>
                </>
              ) : (
                <>
                  <img src={Images.backArrow} className="back-icon pr-2" />
                  <span className="back_text ">
                    {StringConstants.back}
                  </span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default TopHeaderDesktop;
