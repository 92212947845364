import React,{useEffect} from "react";
import {Chart, Pie } from "react-chartjs-2";
import { Images, StringConstants } from "../config";

const NestedPiChart = (props) => {
  const data = {
    //labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: "# of Votes",
        data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
        backgroundColor: [
          "#381474",
          "#F904FF",
          "#381474",
          "#381474",
          "#381474",
          "#F904FF",
          "#F904FF",
          "#381474",
        ],
        borderColor: ["#180148"],
        borderWidth: 1.5,
        weight: 0.5,
      },
      {
        label: "# of Votes",
        data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
        backgroundColor: [
          "#275EFF",
          "#275EFF",
          "#381474",
          "#381474",
          "#275EFF",
          "#275EFF",
          "#275EFF",
          "#381474",
        ],
        borderWidth: 1.5,
        borderColor: ["#180148"],
        weight: 1,
      },
      {
        label: "3 of Votes",
        data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
        backgroundColor: [
          "#48FFFF",
          "#48FFFF",
          "#48FFFF",
          "#381474",
          "#48FFFF",
          "#48FFFF",
          "#48FFFF",
          "#381474",
        ],
        borderWidth: 1.5,
        borderColor: "#180148",
        weight: 3,
      },
    ],
  };

  const blankData = {
    //labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: "Talented",
        data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
        backgroundColor: [
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
        ],
        borderColor: ["#180148"],
        borderWidth: 1.5,
        weight: 0.5,
      },
      {
        label: "managerial",
        data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
        backgroundColor: [
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
        ],
        borderWidth: 1.5,
        borderColor: ["#180148"],
        weight: 1,
      },
      {
        label: "core",
        data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5],
        backgroundColor: [
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
          "#381474",
        ],
        borderWidth: 1.5,
        borderColor: "#180148",
        weight: 3,
      },
    ],
  };

  const options = {
    events : [],
    animation: {
      duration: 0,
    },
    plugins : {
      tooltip : {
        enabled: false,
      }
    }
  };


// for static icon only
 

// const images = [
//   Images.communication_whitecard,
//   Images.creativity_white,
//   Images.emotionaljudge_white,
//   Images.leadershp_white,
//   Images.timemgt_white,
//   Images.teamwork_white
// ]
useEffect(() => {
  Chart.defaults.plugins.tooltip.enabled = false;
}, [])

const drawIcon = (ctx, x, y, icon) => {
  var image = new Image();
  image.src = icon;
  image.addEventListener("load", (e) => {
    ctx.drawImage(image, x, y);
  });
}
const plugins = [{
  afterDraw: chart => {
    var { height, width } = chart;
    var ctx = chart.ctx;
    // const  circleXY=(r, theta)=> {
    //   // Convert angle to radians
    //   theta = (theta-68) * Math.PI/180;
    //   return {x: r*Math.cos(theta),
    //           y: -r*Math.sin(theta)}
    // }
    
    drawIcon(ctx, width/1.8, height/4, props.backgroundColorArraycore[0] === "#381474" ? Images.timemgt_whitecard : Images.timemgt_white );
    drawIcon(ctx, width/1.45, height/2.6, props.backgroundColorArraycore[1] === "#381474" ? Images.criticalthinking_whitecard : Images.criticalthink_white);
    drawIcon(ctx, width/1.45, height/1.8, props.backgroundColorArraycore[2] === "#381474" ? Images.teamwork_whitecard : Images.teamwork_white);
    drawIcon(ctx, width/1.9, height/1.45, props.backgroundColorArraycore[3] === "#444444" ? Images.leadership : Images.leadershp_white);
    drawIcon(ctx, width/2.9, height/1.45, props.backgroundColorArraycore[4] === "#381474" ? Images.probsolving_whitecard : Images.problem_solving);
    drawIcon(ctx, width/4.5, height/1.8, props.backgroundColorArraycore[5] === "#381474" ? Images.communication_whitecard : Images.comms_white);
    drawIcon(ctx, width/4.5, height/2.7, props.backgroundColorArraycore[6] === "#381474" ? Images.creativity : Images.creativity_white);
    drawIcon(ctx, width/2.8, height/4.2, props.backgroundColorArraycore[7] === "#444444" ? Images.emotionaljudge : Images.emotionaljudge_white);
  }
}]


  return (
    <>
      <div className="pie-graph-chart position-init1">
        {props.chartBlank   ? 
            <Pie data={blankData} options={options} />
            :
            <Pie data={data} options={options} plugins={plugins} />
        }
       </div>
    </>
  );
};

export default NestedPiChart;
