import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {  CircularButton, OGButton } from ".";
import { Colors, Images } from "../config";
const AnswerCard = (props) => {
    const [selected, setSelected] = useState(props.selectedAnswer);
    let answers = props.answers;

  const selectHandler = (questionOptionId, questionOptionAnswerSet) => {
    setSelected(questionOptionId);
    props.selectAnswer(questionOptionId, questionOptionAnswerSet);
  }

  useEffect(() => {
    // setSelected('');
  }, [answers])
  
    return (
      <>
      <div className="answer-grp mb-4">
        {
          answers.map((item, key) => (
            <div className="answer-card0 qcard-size mb-0 position-relative" key={item.questionOptionId} style={{ backgroundColor: item.questionOptionId === selected ? '#180148' : '#FFFFFF'}}   onClick={() => selectHandler(item.questionOptionId, item.questionOptionAnswerSet)}>
                <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip-3">{item.questionOptionTip}</Tooltip>
                }
              >
                <div className="icon-hint-ans">
                  <img className="info" src={Images.info} alt="tips"/>
                </div>
              </OverlayTrigger>
                <h6 className="answer-text mb-0 font-family-ubuntu font-weight-regular mb-0 font-size-16 line-height-22 text-color-violet" style={{ color: item.questionOptionId === selected ? '#FFFFFF':'#180148' }}>{item.questionOptionText}</h6>
            </div>
          ))
        }
      </div>
      </>
    );
  };
  
  export default AnswerCard;