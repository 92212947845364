export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const UIAlert = (type, response) => {
    alert(JSON.stringify(response))
}

export const __redirectScrollTop = () => {
    window.scrollTo(0, 0);
};