import { RSAA } from "redux-api-middleware";
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from "../../config/ApiConstants";
import { group } from "d3";

export function hubAPI_Action(token, groupId) {
  return {
    [RSAA]: {
      endpoint: `${API_ENDPOINT}/hub`,
      method: "GET",
      headers: {
        ...AUTH_HEADERS,
        Authorization: "Bearer " + token,
        groupId: groupId
      },
      body: "",
      types: [
        types.HUB_REQUEST,
        types.HUB_RECEIVE,
        {
          type: types.HUB_FAILURE,
          meta: (action, state, res) => {
            return action, state, res;
          },
        },
      ],
    },
  };
}

export const resetHubAPI_Action = () => {
  return {
    type: types.RESET_HUB,
    payload: null,
  };
};
