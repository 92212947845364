import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Welcome from "../../components/Welcome";
import { Images, StringConstants } from "../../config";
import { ShowPassword } from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputTags } from 'react-bootstrap-tagsinput';
import { UserBack } from "../../config/Images";
import { getData, GROUP_SELECTED, TOKEN, USER_DATA } from "../../utils/storage";
import { invite_new_user_Action, organisation_create_tag_action, reset_organisation_create_tag_Action, resetinvite_new_user_Action } from "../../stores/actions";
import { useDispatch, useSelector } from "react-redux";


const InviteUser = () => {

    const [tagData, setTagData] = useState([]);
    const [token, setToken] = useState();
    const [ organisationID, setOrganisationId ] = useState();
    const [userData, setUserData] = useState();
    const [groupState, setGroupState] = useState();
    const [tagState, setTagState] = useState();
    const dispatch = useDispatch();
    const inviteState = useSelector((state) => state.inviteNewUser);
    const [showTagList, setShowTagList] = useState(false);
    const [tagValue, setTagValue] = useState();
    const [tagList, setTagList] = useState([]);
    const createTagSuccess = useSelector((state) => state.organisationCreateTag);
    const history = useHistory();

    useEffect(() => {
      if(inviteState.isSuccess && inviteState.data !== null) {
        history.goBack();
        dispatch(resetinvite_new_user_Action());
      } else if(inviteState.isError) {
        alert("Sorry, we were unable to process your request at this time.")
        dispatch(resetinvite_new_user_Action());
      }
    }, [inviteState]);



    useEffect(() => {
      try {
        getData(TOKEN)
        .then((res) => 
          {
            setToken(res.token);
            getData(USER_DATA)
            .then((userRes) => {setOrganisationId(userRes.companyId); setUserData(userRes)}).catch((error) => console.log(error));
            getData(GROUP_SELECTED)
            .then((groupSelected) => {setGroupState(groupSelected.groupState);setTagState(groupSelected.tagData)})
          } 

        ).catch((error) => console.log(error))
      }catch(error) {
        console.log(error)
      }
    }, [])



    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          You can add tags to each user to give you more options when exploring your Lumenai insights and analytics. These tags can be added or removed across groups, to ensure you get a deeper dive into the potential of your organisational Lumenai data
        </Tooltip>
      );

      const handleChange = (e) => {
        setGroupState(e.target.value);
      }

      // remove tags 
    const removetag = (index) => {
      const newReducer = [...tagList];
      newReducer.splice(index, 1);
      setTagList(newReducer);
    }

    const schema = yup.object().shape({
        email: yup.string().required("Please enter valid email id").email("Please enter valid email id"),
        first_name: yup
            .string()
            .trim("space are not allow")
            .required("Please enter first name"),
        last_name: yup
            .string()
            .trim("space are not allow")
            .required("Please enter last name"),
        jobTitle: yup.string().required("Please enter job title"),
        select: yup.string().default(groupState).required(),
        tagIds: yup.string().default(tagList && tagList.length > 0 ? tagList.map(value => value.tagId).join(",") : "")
      });

      const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
      });


      const onSubmitData = useCallback((data) => {
        console.log(data);
        try {
          dispatch(invite_new_user_Action(data, token , groupState, organisationID));
        } catch (e) {
          console.log(e);
        }
      });

    
    const [errorDataModel, setErrorDataModel] = useState("");

    useEffect(() => {

    }, [tagState])

    const createTagValue = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const data = {name: e.currentTarget.id}
      console.log(data)
      if(data.name !== "") {
        dispatch(organisation_create_tag_action(token, organisationID, data));
      }
    }

    useEffect(() => {
      if(createTagSuccess.isSuccess && createTagSuccess.data !== null) {
        setTagState([...tagState, {tagId: createTagSuccess.data.data.tag.tagId, tagName: createTagSuccess.data.data.tag.tagName, tagUserCount: createTagSuccess.data.data.tag.tagUserCount}])
        setTagValue("");
        addtagValue(createTagSuccess.data.data.tag.tagId, createTagSuccess.data.data.tag.tagName, 0);
        dispatch(reset_organisation_create_tag_Action());
        setShowTagList(false)
      } else if(createTagSuccess.isError) {
        setShowTagList(false);
        alert(createTagSuccess.data.error.message);
        dispatch(reset_organisation_create_tag_Action());
      }
    }, [createTagSuccess])

    const addtagValue = (id, tagname, count) => {
      if(tagList.length && id !== "") {
        const tagStatus = tagList.some(elem => elem.tagId ===id);
        if(!tagStatus) {
          setTagList([...tagList, {tagId:id, tagName:tagname, tagUserCount:count}]);
        }
      } else if(id !== "") {
        setTagList([...tagList, {tagId:id, tagName:tagname, tagUserCount:count}]);
      }
    }

    const handleCancel = () => {
      history.push({pathname: "/people-manager"});
    }


    return (
        <div className="admin-user-creation form-mobile-view user-tag-detail invite-user d-flex bg-aliceBlue justify-content-between">
            <Welcome title={StringConstants.InviteUserWelcomeTitle} data={StringConstants.InviteUserWelcomeData} imgSrc={UserBack} />
            <Container className="bg-aliceBlue admin-form-container user-detail-form-page">
                <Row className="pb-5 justify-content-sm-center">
                    <Col md={10} className="p-0">
                <form onSubmit={handleSubmit(onSubmitData)}>
                  <div className="wrpfrom-mobile">
                    <div className="heading-with-logo">
                        <h3 className="subtitle font-family-ubuntu text-color-violet font-weight-medium font-size-20 mb-3 text-left">
                        {StringConstants.Newuser}
                        </h3>
                        <p>{StringConstants.NewUserDetail}</p>
                    </div>
                    <div className="cards-box lp-card-size  inside-space">
                    <div className="form-group components-form field-style ddd">
                    <label htmlFor="email">
                        Group
                      </label>
                      <div class="group-data">
                      {userData && userData.groupsPopUp !== undefined ? (
                      <div className="form-select">
                          <select  {...register("select")} onChange={(e) => handleChange(e)}  >
                            {userData.groupsPopUp.map((items, index) => {
                              return (
                                <option value={items.groupId} selected={items.groupId === groupState}>{items.groupName}</option>
                              )
                            })}
                          </select>
                      </div>
                      ) : null}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="form-group components-form field-style ddd">
                      <label htmlFor="email">
                        {StringConstants.EmailAddress}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        {...register("email")}
                      />
                      <span className="error">{errors.email?.message}</span>
                    </div>
                    <Row>
                    <div className="col-md-6 form-group components-form field-style ddd">
                      <label htmlFor="first-name">
                        {StringConstants.Fname}
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        className="form-control"
                        id="first-name"
                        {...register("first_name")}
                      />
                      <span className="error">{errors.first_name?.message}</span>
                    </div>
                    <div className="col-md-6 form-group components-form field-style ddd">
                      <label htmlFor="last-name">
                        {StringConstants.Lname}
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        className="form-control"
                        id="last-name"
                        {...register("last_name")}
                      />
                      <span className="error">{errors.last_name?.message}</span>
                    </div>
                    </Row>
                    <div className="form-group components-form field-style ddd">
                      <label htmlFor="job-title">
                        {StringConstants.Job_title}
                      </label>
                      <input
                        type="text"
                        name="job-title"
                        className="form-control"
                        id="job-title"
                        {...register("jobTitle")}
                      />
                      <span className="error">{errors.jobTitle?.message}</span>
                    </div>
                    <span className="error">
                      {errorDataModel && errorDataModel.error.message}
                    </span>
                  </div>
                  <div className="form-group tags-input components-form field-style ddd">
                  <div className="tag-container">
                    <label className="d-flex justify-content-between" htmlFor="tags">
                     <span>Tags</span>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <img src={Images.ToolTip} className="info-icon" alt="" />
                      </OverlayTrigger>
                      </label>
                      <div className="tag-field d-flex" onFocus={() => setShowTagList(true)}>
                        {tagList.length ? (
                        <ul className="tag-data d-flex justify-content-start">
                          {tagList.map((items, index) => {
                            return (
                              <li>
                                <span className="mr-3" data-attr={items.tagUserCount} id={items.tagId}>{items.tagName}</span>
                                <span onClick={() => removetag(index)}><img src={Images.close_skill} /></span>
                              </li>
                            )
                          })}
                        </ul>
                        ) : null}
                        <div class="input-group mb-3">
                          <input placeholder="Select an existing tag or enter a new one" className={ tagList.length ? "form-control border-show" : "form-control"}  type="text" name="tags" maxLength={20} onKeyPress={(e) => { if(e.key === "Enter") {createTagValue(e)} }} autoComplete="off" value={tagValue} onChange={(e) => setTagValue(e.target.value)} />
            
                          <div class="input-group-append tag-creation-form ml-3">
                          <button className="btn-text">
                            <span id={tagValue} onClick={(e) => createTagValue(e)}>
                            Create Tag
                            </span>
                          </button>
                          </div>
                        </div>
                      </div>
                      {tagState && showTagList && tagState !== undefined ? (
                        <div className="tag-list">
                      <ul>
                        {tagState.slice(1).map((items) => {
                          return (
                          <li onClick={() => setShowTagList(false)}>
                              <button
                                onClick={(e) => { e.preventDefault(); addtagValue(items.tagId, items.tagName, items.tagUserCount) }} className="btn btn-block">
                            <span id={items.tagId} data-attr={items.tagUserCount} >
                             {items.tagName}
                             </span>
                             </button>
                            </li>
                          )
                        })}
                         </ul>
                         
                        </div>
                      ) : null}
                  </div>
                    {/* <InputTags values={tagData} onTags={(value) => setTagData(value.values)} /> */}
                  </div>
                  {/* {loginRData.isFetching === true ? ( */}
                  {/* <div className="text-left lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mobile-middle-btn btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.Loading}
                    </button>
                  </div> */}
                {/* ) : ( */}
                  <div className="text-left button-container lp-card-size mt-4">
                    <button
                      type="submit"
                      className="btn mr-3  btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                    >
                      {StringConstants.SendInvite}
                    </button>
                    <button
                      type="submit"
                      className="btn cancel btn-text bg-violet text-white py-3 px-3 register-btn btn-circle-30"
                      onClick={(e) => handleCancel(e)}
                    >
                      {StringConstants.Cancel}
                    </button>
                  </div>
                  
                {/* )} */}
                </div>
                </form>
                </Col>
                </Row>
            </Container>
        </div>
    )
    
}

export default InviteUser;